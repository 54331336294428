import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import NewLaunches from "../../components/Home/newLaunches";
import ShopByPrice from "../../components/mobiles/shopByPrice";
import BestBudget from "../../components/Home/bestBudget";
import ShopByBrand from "../../components/mobiles/shopByBrand";
import { getShopByBrandWatcher, getShopByPriceWatcher } from "../../store/actionCreators/home";
import DiscountCard from "../../components/mobiles/discountCard";
import BannerSlideindex from "../../components/Slider";
import RecommendedItem from "../../components/mobiles/recommendedItems";
import SEO from "../../utils/SEO";
import { Card, Grid, useMediaQuery } from "@mui/material";
import TopRated from "../../components/Home/topRated";
import { addPrimarycategory, addProductCategory, filterTagBasedProductWatcher } from "../../store/actionCreators/common";
import { getDeliveryChargeWatcher } from "../../store/actionCreators/cart";

export default function HomePage() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('1550'));
  const { commonReducers: { isLoader } } = useSelector((state) => state)
  const dispatch = useDispatch()
  const location = useLocation()
  const searchPath = '/search'

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [location.pathname, isLoader])

  useEffect(() => {
    dispatch(getDeliveryChargeWatcher())
  }, [])

  const priceArr = [{ id: 1, min: '100', max: '1,000', type: 'between' },
  { id: 2, min: '1,000', max: '5,000', type: 'between' },
  { id: 3, min: '5,000', max: '15,000', type: 'between' },
  { id: 4, min: '15,000', max: '50,000', type: 'between' },
  { id: 5, min: '50,000', max: '1,00,000', type: 'between' },
    // { id: 6, min: '1,00,000', max: '2,00,000', type: 'between' }
  ]

  const productArr = [{ id: 1, name: "20%-Off-or-More", imgPath: "/images/Phone4.png", productName: "Mobiles", offer: "20% Off or More", category: "15", perc: 20, condition: "above" },
  { id: 2, name: "Upto-50%-Off", imgPath: "/images/Accessories.png", productName: "Accessories", offer: "Upto 50% Off", category: "249", perc: 50, condition: "below" },
  { id: 3, name: "30%-Off-or-More", imgPath: "/images/SmartWatches2.png", productName: "Wearables", offer: "30% Off or More", category: "756", perc: 30, condition: "above" },
  { id: 4, name: "Under-1500", imgPath: "/images/Headphones.png", productName: "Headphones", offer: "Under 1500", category: "377", max_price: 1500, min_price: 0 }]

  return (
    <>
      <SEO title='Buy Mobile Phones and Accessories Online at Best Prices' description='Amazing deals on smartphones, Free Backcase and Tempered Glass, iPhones original accessories.' keywords='best smartphone deals, 5G smartphones, offer price, 5G phone lowest price, mobile offers, mobiles, phones, new launches, Free Backcase and Tempered Glass, Headphones, Chargers, Cables, ,budget friendly mobiles, apple accessories, iPhones' />
      <section className="sectionHome" style={{ margin: matches && "60px 100px" }}>
        <Box mt='26.8px'>
          <BannerSlideindex />
        </Box>

        <section className="container homeoffers clear">
          <ul>
            {
              productArr.map((m, i) => {
                console.log(m, "console");
                return <li key={m.id}>
                  <Link to={encodeURI(m?.name || searchPath)} onClick={() => {
                    let data = {
                      perPage: 100,
                      category: m.category,
                      name: m.name
                    }
                    if (m.perc !== undefined && m.condition !== undefined) {
                      data = {
                        ...data, perc: m.perc,
                        condition: m.condition
                      }
                    }
                    if (m.min_price !== undefined && m.max_price !== undefined) {
                      data = {
                        ...data, min_price: m.min_price,
                        max_price: m.max_price
                      }
                    }

                    dispatch(addPrimarycategory(m.productName.toLowerCase()))
                    dispatch(addProductCategory(m.productName.toLowerCase()))
                    dispatch(filterTagBasedProductWatcher(data))
                  }}>
                    <Box height={{ xs: '300px', sm: '320px', md: '300px' }}>
                      <figure>
                        <img src={m.imgPath} alt='' style={{ height: '200px' }} />
                      </figure>
                      <figcaption>
                        <p>{m.productName}</p>
                        <h2>{m.offer}</h2>
                      </figcaption>
                    </Box>
                  </Link>
                </li>
              })
            }
          </ul>
        </section>

        <Box m='0px 30px 0px 30px'>
          <NewLaunches />
        </Box>

        <Box m='25px 30px 0 30px'>
          <TopRated />
        </Box>

        <Box m='0px 30px 0px 30px'>
          <RecommendedItem />
        </Box>

        <Box m='20px 30px 0 30px'>
          <ShopByBrand categoryName={'Home'} shopByBrandApi={getShopByBrandWatcher} page='Home' />
        </Box>

        <Box m='0px 30px 0 30px'>
          <ShopByPrice shopByPriceApi={getShopByPriceWatcher} priceArr={priceArr} page='Home' />
        </Box>

        <Box m='0px 0px 0 0px'>
          <DiscountCard />
        </Box>

        <Box m='25px 30px 0 30px'>
          <BestBudget />
        </Box>

        <Box>
          <section className="container contact-strip clear">

            <Grid container spacing={3}>
              <Grid item xs='12' lg='8' md='8' sm='12' mt='5px'>
                <Box>
                  <h2>We’er Always Here to Help</h2>
                  <p>Reach out to us through any of these you Repair & Services</p>
                </Box>
              </Grid>

              <Grid item xs='12' lg='3' md='3' sm='6'>
                <Link to="/mobile-repair-and-services">
                  <Card sx={{ display: 'flex', mr: '0px', borderRadius: '6px', height: '75px', width: '100%', alignItems: 'center', cursor: 'pointer' }}>
                    <Box p='10px 20px 10px 20px'>
                      <img src="/images/phone.svg" alt='' />
                    </Box>
                    <Box p='10px'>
                      <h3>Book your Repair</h3>
                    </Box>
                  </Card>
                </Link>
              </Grid>
            </Grid>
          </section>
        </Box>
      </section >
    </>
  );
};


