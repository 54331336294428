import React from 'react'
import { useSelector } from 'react-redux';
import { Button, Dialog, Grid, Typography, useMediaQuery } from '@mui/material';
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';
import InvoiceDetail from '../../components/common/invoiceDetail';

export default function Invoice({ open, handleClose, data }) {
  const resolution = useMediaQuery((theme) => theme.breakpoints.down("836"))
  let componentRef = useRef()
  const { orderReducers: { orderData } } = useSelector(state => state)
  console.log(orderData, "invoice");

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"lg"} style={{ marginTop: resolution ? "60px" : "0px" }}>
      <div style={{ overflowX: "scroll", width: "970px" }}>
        <div ref={(e) => componentRef = e} style={{ padding: "50px" }}>
          <InvoiceDetail data={data} />
        </div>
        <Grid container display="flex" justifyContent="center" marginBottom="20px">
          <Grid>
            <ReactToPrint trigger={() => (
              <Button height="50px" style={{ width: "150px", marginRight: "15px" }} sx={{ color: "white !important", border: '1px solid #F26B23', backgroundColor: "secondary.main", "&:hover": { backgroundColor: "#F26B23" } }} border='1px solid #F26B23' borderRadius="5px">
                <Typography variant='p15_500' textTransform="none" padding="8px" sx={{ color: "primaryColors.loginWhite" }}> Print </Typography>
              </Button>
            )} content={() => componentRef} />
          </Grid>
          <Grid>
            <Button height="50px" style={{ width: "150px" }} sx={{ color: "white !important", border: '1px solid #F26B23', backgroundColor: "secondary.main", "&:hover": { backgroundColor: "#F26B23" } }} border='1px solid #F26B23' borderRadius="5px" onClick={handleClose}>
              <Typography variant='p15_500' textTransform="none" padding="8px" sx={{ color: "primaryColors.loginWhite" }}> Close </Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>

  )
}
