import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import NewLaunches from '../../components/Accessories/newLaunches'
import PowerBank from '../../components/Accessories/powerBank'
import ShopByBrand from '../../components/mobiles/shopByBrand'
import ShopByPrice from '../../components/mobiles/shopByPrice'
import TagCardDesign from "../../components/common/tagCards"
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAccessoriesNewLaunchesWatcher, getAccessoriesTagCardsWatcher, getCaseAndCoversWatcher, getMemoryCardsWatcher, getPowerBanksWatcher } from '../../store/actionCreators/accessorires'
import { getProductBrandWatcher } from '../../store/actionCreators/mobile'
import TopDeals from '../../components/Accessories/topDeals'
import { getProductCardsWatcher } from '../../store/actionCreators/home'
import { useLocation } from 'react-router-dom'
import BannerSlideindex from '../../components/Slider'
import SEO from '../../utils/SEO'
import TopRated from '../../components/Home/topRated'

export default function Index() {
  const { accessoriesReducer: { AccessoriesTagCards } } = useSelector(state => state)
  const matches = useMediaQuery((theme) => theme.breakpoints.up('1550'));
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    let data = {
      pageNO: 1,
      perPage: 4
    }
    let Dealdata = {
      pageNO: 1,
      perPage: 3
    }
    dispatch(getAccessoriesTagCardsWatcher(249))
    dispatch(getAccessoriesNewLaunchesWatcher(data))
    dispatch(getCaseAndCoversWatcher(data))
    dispatch(getMemoryCardsWatcher(data))
    dispatch(getPowerBanksWatcher(data))
    dispatch(getProductBrandWatcher(data))
    dispatch(getProductCardsWatcher(Dealdata))
  }, [dispatch])

  // const tagCardArr = [{ categoryId: 537, urlname: 'Top-Selling', tagName: 'Top Selling', imgSrc: '/images/Accessories/mobilestore.png' },
  // { categoryId: 538, urlname: 'Budget-Friendly', tagName: 'Budget Friendly', imgSrc: '/images/Accessories/badge.png' },
  // { categoryId: 539, urlname: 'Premium', tagName: 'Premium', imgSrc: '/images/Accessories/notifications.png' },
  // { categoryId: 540, urlname: `Today's-Deals`, tagName: `Today's Deals`, imgSrc: '/images/Accessories/rating.png' },
  // ]

  const priceArr = [{ id: 1, min: '100', max: '2000', type: 'between' },
  { id: 2, min: '2000', max: '5,000', type: 'between' },
    // { id: 3, min: '5,500', max: '10,000', type: 'between' },
    // { id: 4, min: '10,000', max: '30,000', type: 'between' },
    // { id: 5, min: '30,000', max: '60,000', type: 'between' },
    // { id: 6, min: '60,000', max: '1,00,000', type: 'between' }
  ]

  return (
    <>
      <SEO title='Buy Best Mobile Accessories Online Shopping in Chennai' description='Shop Online Mobile Phone Accessories like Wall Charger, Travel Adapter, Lightning Cable, Power Bank& Stands, Tempered Glass, Mobile USB Cable & Adapter, USB Data Cable with Type C, Headphone (Earphone/ ...' />
      <Box mb='30px' style={{ margin: matches && "0px 100px" }}>
        <Box m='100px 30px 0 30px'>
          <TagCardDesign tagCardArr={AccessoriesTagCards} categoryId={249} />
        </Box>

        <Box>
          <BannerSlideindex />
        </Box>

        <Box m='0 30px 20px 30px'>
          <NewLaunches category_id={249}/>
        </Box>

        <Box m='25px 30px 0 30px'>
          <TopRated category_id={249} />
        </Box>

        <Box>
          <TopDeals category_id={249} />
        </Box>

        <Box m='20px 30px 0 30px'>
          <ShopByBrand categoryName={'accessories'} categoryId={249} page='accessories' />
        </Box>

        <Box m='0px 30px 0 30px'>
          <ShopByPrice priceArr={priceArr} categoryId={249} page='accessories' />
        </Box>

        <Box m='25px 30px 0 30px'>
          <PowerBank />
        </Box>
        {/* } */}
      </Box>

    </>
  )
}
