import axios from 'axios';

const baseURL = "https://dev.phonebuy.com"
const instance = axios.create({
  baseURL,
  timeout: 100000,
  headers: {
    'Content-type': 'application/json',
  }
});

export const WOOCOMMERCE_SERVICES = {
  URL: 'https://dev.phonebuy.com',
  CONSUMER_KEY: 'ck_5c6f82c68695d9eee780c888bf9c539f8a015a61',
  CONSUMER_SECRET: 'cs_b197e59eb82a2c04f238c274e92c9a07da551efb'
}

// const baseURL1 = 'http://localhost:4000/'
const baseURL1 = 'https://server.phonebuy.com'

export const http1 = axios.create({
  baseURL: baseURL1,
  timeout: 100000,
  headers: {
    'Content-type': 'application/json',
  }
});

export const base_url = baseURL;
export default instance;
