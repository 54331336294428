import React from 'react'
import { Grid, Typography, Box, Button } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import ProductItemCard from '../ProductListItemCard'
import { Link } from 'react-router-dom';
import { addPrimarycategory, filterTagBasedProductWatcher } from '../../store/actionCreators/common';
import { getprimarycolor } from '../../utils/common';

export default function NewLaunches() {
  const dispatch = useDispatch()
  const { headPhonesReducers: { newLaunchesList } } = useSelector((state) => state)

  const mapData = newLaunchesList.length ? newLaunchesList : []
  const searchPath = '/search'

  // useEffect(() => {
  //   if (pageCount === 1) setPreviousEnable(false)
  //   if (!newLaunchesList?.length) {
  //     setNextEnable(false)
  //     // dispatch(newLaunchesList(prevData))
  //   }
  //   else {
  //     setNextEnable(true)
  //   }
  // }, [pageCount, newLaunchesList])


  const viewFunction = () => {
    // setPageCount(1)
    // setPerPageData(8)
    // setPaginationEnable(true)
    // setViewAllEnable(false)
    // let data = {
    //   pageNO: pageCount,
    //   perPage: 8
    // }
    // dispatch(getHeadPhonesNewLaunchesWatcher(data))
    let data = {
      perPage: 50,
      category: 377,
      name: "NewLaunches"
    }
    const pathName= window.location.pathname.substr(1,).toLowerCase()
        dispatch(addPrimarycategory(pathName))
    dispatch(filterTagBasedProductWatcher(data))
  }

  // const previousClick = () => {
  //   setPageCount(pageCount - 1)
  //   let data = {
  //     pageNO: pageCount - 1,
  //     perPage: perPageData
  //   }
  //   dispatch(getHeadPhonesNewLaunchesWatcher(data))
  // }

  // const nextClick = () => {
  //   setPrevData(newLaunchesList)
  //   setPreviousEnable(true)
  //   setPageCount(pageCount + 1)
  //   let data = {
  //     pageNO: pageCount + 1,
  //     perPage: perPageData
  //   }
  //   dispatch(getHeadPhonesNewLaunchesWatcher(data))
  // }
  return (
    <div>
      {Boolean(mapData.length) &&
        <>
          <Box>
            <section className="container products-container padding-top-none clear">
              <Grid container>
                <Grid lg={8} md={8} sm={8} xs={12}>
                  <h2 className="left">
                    New Launches
                  </h2>
                </Grid>
                <Grid lg={4} md={4} sm={4} xs={12}>
                  {Boolean(mapData.length >= 4) &&
                    <Link to={"/NewLaunches?name=NewLaunches&category=377"} state={{ category: 377 }}>
                      <Box display='flex'>
                        <Button variant='contained'
                          sx={{
                            width: '130px', height: '40px', backgroundColor: "#F26B23",
                            '&:hover': { backgroundColor: "#F26B23" }, textTransform: 'none', m: '0px 0px 10px auto'
                          }} onClick={viewFunction} width='100px' >
                          <Typography color='white !important'>View All</Typography>
                        </Button>
                      </Box>
                    </Link>
                  }
                </Grid>
              </Grid>
            </section>

            <Grid container spacing={3} sx={{ pr: '20px', pl: '20px', pb: '20px' }} >
              {
                mapData.map((v, i) => {
                  return <Grid item lg={3} xs={12} sm={6} md={4} key={v.id}>
                    <ProductItemCard productData={v} bgclr={'primaryColors.productListItemBg'} categoryName='New Launches' categoryType='Headphones' />
                  </Grid>
                })
              }
            </Grid>
          </Box>

          {/* {paginationEnable &&
            <Stack direction='row' justifyContent='right' spacing={3} pr='45px' pb='20px' marginTop="30px">
              <Button variant='contained' startIcon={<KeyboardDoubleArrowLeftIcon color='white' />}
                sx={{
                  width: '130px', height: '38px', backgroundColor: 'orange.main',
                  '&:hover': { backgroundColor: 'orange.main' }, textTransform: 'none'
                }} onClick={previousClick} width='100px' disabled={!previousEnable}>
                <Typography color='white !important'>Previous</Typography>
              </Button>

              <Button variant='contained' endIcon={<KeyboardDoubleArrowRightIcon color='white' />}
                sx={{
                  width: '130px', height: '38px', backgroundColor: 'orange.main',
                  '&:hover': { backgroundColor: 'orange.main' }, textTransform: 'none'
                }} onClick={nextClick} width='100px' disabled={!nextEnable}>
                <Typography color='white !important'>Next</Typography>
              </Button>
            </Stack>
          } */}
        </>
      }
    </div>
  )
}
