import { GET_SHOP_BY_BRAND_WATCHER, GET_SHOP_BY_PRICE_WATCHER, GET_TABLETS_NEW_LAUNCHES_WATCHER, SHOP_BY_BRAND_LIST, SHOP_BY_PRICE_LIST, TABLETS_NEW_LAUNCHES_LIST } from "../actionTypes/tablets";

export function getTabletsNewLaunchesWatcher(data) {
    return { type: GET_TABLETS_NEW_LAUNCHES_WATCHER, payload: data };
  }

  export function getShopByBrandWatcher(data) {
    return { type: GET_SHOP_BY_BRAND_WATCHER, payload: data };
  }
  
  export function getShopByPriceWatcher(data) {
    return { type: GET_SHOP_BY_PRICE_WATCHER, payload: data };
  }

  // Redux state changing actionCreators
export function tabletsNewLaunchesList(data) {
    return { type: TABLETS_NEW_LAUNCHES_LIST, payload: data };
  }

  export function shopByBrandList(data) {
    return { type: SHOP_BY_BRAND_LIST, payload: data };
  }
  
  export function shopByPriceList(data) {
    return { type: SHOP_BY_PRICE_LIST, payload: data };
  }