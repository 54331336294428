import React, { useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import ShopByBrand from "../../components/mobiles/shopByBrand"
import ShopByPrice from "../../components/mobiles/shopByPrice"
import BestBudget from "../../components/mobiles/bestBudget"
import RecommendedItem from "../../components/mobiles/recommendedItems"
import NewLaunches from "../../components/mobiles/newLaunches"
import DiscountCard from "../../components/mobiles/discountCard"
import TagCardDesign from "../../components/common/tagCards"
import BannerSlideindex from "../../components/Slider"
import { useDispatch, useSelector } from "react-redux";
import { getMobileNewLaunchesWatcher, getMobileRecommendedWatcher, getMobileBestBudgetWatcher, getProductBrandWatcher, getMobileTagCardsWatcher } from "../../store/actionCreators/mobile"
import { getDealsWatcher } from "../../store/actionCreators/home";
import SEO from "../../utils/SEO"
import TopRated from "../../components/Home/topRated";

export default function Index() {

  const { mobileReducers: { tagCards } } = useSelector(state => state)
  console.log("tagcard", tagCards)
  const matches = useMediaQuery((theme) => theme.breakpoints.up('1550'));

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const dispatch = useDispatch()

  useEffect(() => {
    let data = {
      pageNO: 1,
      perPage: 4
    }

    let Dealdata = {
      pageNO: 1,
      perPage: 3
    }
    dispatch(getMobileTagCardsWatcher(15))
    dispatch(getMobileNewLaunchesWatcher(data))
    dispatch(getMobileRecommendedWatcher(data))
    dispatch(getMobileBestBudgetWatcher(data))
    dispatch(getProductBrandWatcher(data))
    dispatch(getDealsWatcher(Dealdata))
  }, [dispatch])

  // const tagCardArr = [{ categoryId: 529, urlname:'Latest-Models', tagName: 'Latest Models', imgSrc: '/images/mobile/mobilestore.png' },
  // { categoryId: 530, urlname:'5G-Mobiles', tagName: '5G Mobiles', imgSrc: '/images/mobile/badge.png' },
  // { categoryId: 531, urlname:'With-Free-Gifts', tagName: 'With Free Gifts', imgSrc: '/images/mobile/notifications.png' },
  // { categoryId: 532, urlname:'Gaming-Mobiles', tagName: 'Gaming Mobiles', imgSrc: '/images/mobile/rating.png' },
  // { categoryId: 533, urlname:'Best-Camera', tagName: 'Best Camera', imgSrc: '/images/mobile/wallet(1).png' },
  // { categoryId: 534, urlname:'Top-Selling', tagName: 'Top Selling', imgSrc: '/images/mobile/cut.png' },
  // { categoryId: 535, urlname:'Budget-Friendly', tagName: 'Budget Friendly', imgSrc: '/images/mobile/shoppingbag.png' },
  // { categoryId: 536, urlname:`Today's-Deals`, tagName: `Today's Deals`, imgSrc: '/images/mobile/wallet(2).png' }]

  const priceArr = [{ id: 1, min: '5,000', max: '20,000', type: 'between' },
  { id: 2, min: '20,000', max: '40,000', type: 'between' },
  { id: 3, min: '40,000', max: '60,000', type: 'between' },
    // { id: 4, min: '60,000', max: '80,000', type: 'between' },
    // { id: 5, min: '80,000', max: '1,00,000', type: 'between' },
    // { id: 6, min: '1,00,000', max: '2,00,000', type: 'between' }
  ]

  return (
    <>
      <SEO title='Buy Latest New Mobiles Online at Best Prices in Chennai' description='Buy latest mobile phones at best prices in Chennai. Phonebuy.com is the right platform for you to look for a mobile phone that fits your budget.' />
      <Box m='30px 0' style={{ margin: matches && "0px 100px" }}>

        <Box m='100px 30px 0 30px'>
          <TagCardDesign tagCardArr={tagCards} categoryId={15} />
        </Box>

        <Box>
          <BannerSlideindex />
        </Box>

        <Box m='25px 30px 0px 30px'>
          <NewLaunches />
        </Box>

        <Box m='25px 30px 0px 30px'>
          <TopRated category_id={15} />
        </Box>

        <Box mt='30px'>
          <DiscountCard pageType='mobile' category_id={15} />
        </Box>

        <Box m='25px 30px 0 30px'>
          <RecommendedItem category={15} />
        </Box>

        <Box m='20px 30px 0 30px'>
          <ShopByBrand categoryName={'Mobiles'} categoryId={15} page='mobile' />
        </Box>

        <Box m='0px 30px 0 30px'>
          <ShopByPrice priceArr={priceArr} categoryId={15} page='mobile' />
        </Box>

        <Box m='0px 30px 0 30px'>
          <BestBudget />
        </Box>
      </Box>
    </>
  );
}
