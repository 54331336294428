import { ADD_HEADPHONES_TAG_CARDS, GET_HEADPHONES_NEW_LAUNCHES_WATCHER, GET_HEADPHONES_TAG_CARDS_WATCHER, GET_SHOP_BY_BRAND_WATCHER, GET_SHOP_BY_PRICE_WATCHER, HEADPHONES_NEW_LAUNCHES_LIST, SHOP_BY_BRAND_LIST, SHOP_BY_PRICE_LIST } from "../actionTypes/headPhones";

export function getHeadPhonesNewLaunchesWatcher(data) {
  return { type: GET_HEADPHONES_NEW_LAUNCHES_WATCHER, payload: data };
}

export function getShopByBrandWatcher(data) {
  return { type: GET_SHOP_BY_BRAND_WATCHER, payload: data };
}

export function getShopByPriceWatcher(data) {
  return { type: GET_SHOP_BY_PRICE_WATCHER, payload: data };
}

export function getHeadphonesTagCardsWatcher(data) {
  return { type: GET_HEADPHONES_TAG_CARDS_WATCHER, payload: data };
}

// Redux state changing actionCreators
export function headphonesNewLaunchesList(data) {
  return { type: HEADPHONES_NEW_LAUNCHES_LIST, payload: data };
}

export function shopByBrandList(data) {
  return { type: SHOP_BY_BRAND_LIST, payload: data };
}

export function shopByPriceList(data) {
  return { type: SHOP_BY_PRICE_LIST, payload: data };
}

export function addHeadphonesTagCards(data) {
  return { type: ADD_HEADPHONES_TAG_CARDS, data: data };
}