import { DELETE_ORDER_WATCHER, GET_ORDER_WATCHER, GET_SINGLE_ORDER_WATCHER, ORDER_LIST, TAX_DETAIL_WATCHER, TAX_LIST, VIEW_ORDER } from "../actionTypes/order";


// Worker triggering actionCreators

export function getOrderWatcher(userId) {
  return { type: GET_ORDER_WATCHER, userId };
}

export function viewOrder(data) {
  return { type: VIEW_ORDER, payload: data };
}

export function singleOrderWatcher(orderId) {
  return { type: GET_SINGLE_ORDER_WATCHER, orderId };
}

export function deleteOrderWatcher(orderId) {
  return { type: DELETE_ORDER_WATCHER, orderId };
}

export function taxDetailWatcher(data) {
  return { type: TAX_DETAIL_WATCHER, data };
}

// Redux state changing actionCreators

export function orderList(data) {
  return { type: ORDER_LIST, payload: data };
}

export function taxList(data) {
  return { type: TAX_LIST, payload: data };
}