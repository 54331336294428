import React, { useEffect, useRef } from 'react';
import { Button, TextField, Typography, Grid, Stack, Box } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { repairAndServiceWatcher } from '../../store/actionCreators/common';
import { getConvertedDate } from '../../utils/common';
import { Link } from 'react-router-dom';
import SEO from '../../utils/SEO';
import { addUserPincodeWatcher } from '../../store/actionCreators/product';

function RepairDesign({ pageType, breadCrumbs }) {
    const dispatch = useDispatch()

    let modelref = useRef()
    let contactref = useRef()
    let issueref = useRef()
    let dateref = useRef()
    let timeref = useRef()
    let addressref = useRef()
    let pincoderef = useRef()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { loginReducers: { userData }, productReducers: { userPincode } } = useSelector(state => state)
    console.log(userPincode,"userpincode");
    const userName = userData.length && userData[0].firstName
    const userId = userData.length && userData[0].userId

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const Schema = Yup.object().shape({
        brand: Yup.string().required('Brand is required'),
        model: Yup.string('Enter your name').required('Model is required'),
        address: Yup.string('Enter your address').required('Address is required'),
        issue: Yup.string('Enter your address').required('Issue is required'),
        pincode: Yup.string('Enter your pincode').min(6, 'enter valid pincode').max(6, 'enter valid pincode').required('Pincode is required'),
        contact: Yup.string().matches(new RegExp('[0-9]{9}'), 'Invalid').required('Contact number is required').min(10, 'enter valid Phone number').max(10, 'enter valid Phone number'),
    });

    const formik = useFormik({
        initialValues: {
            brand: '',
            model: '',
            contact: '',
            address: '',
            selectedDate: new Date(),
            selectedTime: new Date(),
            issue: '',
            pincode: '',
        },

        validationSchema: Schema,

        onSubmit: () => {
            let { brand, contact, address, selectedDate, selectedTime, issue, model, pincode } = { ...formik.values }
            let data = {
                "userName": userName,
                "userId": userId,
                "model": model,
                "brand": brand,
                "issue": issue,
                "contactNumber": contact,
                "address": address,
                "date": getConvertedDate(selectedDate),
                "time": new Date(selectedTime).toLocaleTimeString(),
                pincode
            }
            if(userPincode) {
            dispatch(repairAndServiceWatcher(data,() => {
                formik.resetForm()
            }))
            }
        }
    });
console.log(formik.values.pincode);
    useEffect(() => {
        if (formik.values.pincode?.toString().length === 6) {
            dispatch(addUserPincodeWatcher(formik.values.pincode))
        }
    }, [formik.values.pincode, dispatch])

    const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = formik;

    const handleDateChange = (e, v) => {
        setFieldValue("selectedDate", e._d)
    }

    const handleTimeChange = (e) => {
        setFieldValue("selectedTime", e._d)
    }

    return (
        <>
            <SEO title='Mobile Phone Repair & Services At Doorstep in Chennai' description='PhoneBuy.com offers doorstep mobile repair & services at Doorstep in Chennai at low pricing. Book Your Repair Now!' />
            <section className={!pageType ? 'sectionBanner' : 'repairTop'}>
                <Box height="319px" className='bannerBox' backgroundColor="orange.main" marginBottom="10px">
                    <Grid container spacing={1} >
                        <Grid item xs={1} sm={1} md={0.5} lg={0.5}></Grid>

                        <Grid item xs={11} sm={6} md={7.5} lg={8.5}>
                            <>
                                {
                                    breadCrumbs !== 'hide' ?
                                        <Box marginBottom="60px" marginTop="10px">
                                            <Typography sx={{ color: 'primaryColors.textWhite', '&:hover': { textDecoration: 'underline', color: 'primaryColors.textWhite' }, paddingRight: '10px' }} to='/' component={Link} variant="p14_500"> Home </Typography>
                                            <Typography variant="p13_500" sx={{ color: 'primaryColors.textWhite', paddingRight: '10px' }}> &gt; </Typography>
                                            <Typography variant="p14_500" sx={{ color: 'primaryColors.textWhite' }}> Repair & Service </Typography>
                                        </Box>
                                        :
                                        <Box marginBottom="65px" marginTop="15px"></Box>
                                }

                                <Typography variant='p32_600' color="primaryColors.loginWhite" > Repair your phone at doorstep </Typography>
                                <Box marginTop="12px">
                                    <Typography variant='p16_500' color="primaryColors.repairDesc" >Repair is a revolutionary service designed to simplify mobile repairs without making them too hard on your wallet.</Typography>
                                </Box>
                            </>
                        </Grid>

                        <Grid item xs={10} sm={5} md={4} lg={3}>
                            <>
                                <img src="/images/repairServices.png" alt='' />
                            </>
                        </Grid>
                    </Grid>
                </Box>

                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                        <Box mt={pageType ? '20px' : '30px'} mr='20px' ml='30px' mb='20px'>
                            <Typography variant='h5'
                                align='left'
                                style={{ paddingBottom: '10px' }}>
                                Book Your Repair
                            </Typography>

                            <Grid
                                container
                                style={{ paddingTop: '10px' }}
                                spacing={3}
                                direction='row'
                            >
                                <Grid
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    item={true}
                                >
                                    <TextField
                                        required={true}
                                        style={{}}
                                        fullWidth={true}
                                        placeholder='Enter your brand'
                                        label='Brand'
                                        name='brand'
                                        color='primary'
                                        type='text'
                                        variant='outlined'
                                        {...getFieldProps("brand")}
                                        error={Boolean(touched.brand && errors.brand)}
                                        helperText={touched.brand && errors.brand}
                                        onKeyPress={(e) => {
                                            if(e.key === "Enter") {
                                                modelref.current?.focus()
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    item={true}
                                >
                                    <TextField
                                        required={true}
                                        style={{}}
                                        fullWidth={true}
                                        placeholder='Enter your model'
                                        label='Model'
                                        name='model'
                                        color='primary'
                                        type='text'
                                        variant='outlined'
                                        {...getFieldProps("model")}
                                        error={Boolean(touched.model && errors.model)}
                                        helperText={touched.model && errors.model}
                                        inputRef={modelref}
                                        onKeyPress={(e) => {
                                            if(e.key === "Enter") {
                                                contactref.current?.focus()
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    item={true}
                                >
                                    <TextField
                                        required={true}
                                        style={{}}
                                        fullWidth={true}
                                        placeholder='Enter your contact number'
                                        label='Contact number'
                                        name='contact'
                                        color='primary'
                                        type='number'
                                        variant='outlined'
                                        {...getFieldProps("contact")}
                                        error={Boolean(touched.contact && errors.contact)}
                                        helperText={touched.contact && errors.contact}
                                        inputRef={contactref}
                                        onKeyPress={(e) => {
                                            if(e.key === "Enter") {
                                            issueref.current?.focus()
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    item={true}
                                >
                                    <TextField
                                        required={true}
                                        style={{}}
                                        minRows={2}
                                        fullWidth={true}
                                        placeholder='Enter your issue'
                                        label='Issue'
                                        name='issue'
                                        color='primary'
                                        type='text'
                                        variant='outlined'
                                        {...getFieldProps("issue")}
                                        error={Boolean(touched.issue && errors.issue)}
                                        helperText={touched.issue && errors.issue}
                                        inputRef={issueref}
                                        onKeyPress={(e) => {
                                            if(e.key === "Enter") {
                                                dateref.current?.focus()
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    lg={1.5}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    item={true}
                                >
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            label="Select Date"
                                            inputFormat="DD/MM/YYYY"
                                            value={values.selectedDate}
                                            name="selectedDate"
                                            onChange={handleDateChange}
                                            renderInput={(params) => <TextField {...params}
                                                {...getFieldProps("selectedDate")}
                                                inputRef={dateref}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter'){
                                                        timeref.current?.focus();
                                                    }
                                                }}
                                            />}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid
                                    lg={1.5}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    item={true}
                                >
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <TimePicker
                                            label="Select Time"
                                            value={values.selectedTime}
                                            name="selectedTime"
                                            onChange={handleTimeChange}
                                            renderInput={(params) => <TextField {...params}
                                            inputRef={timeref}
                                            onKeyPress={(e) => {
                                                if(e.key === "Enter") {
                                                    addressref.current?.focus()
                                                }
                                            }}
                                            />}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    item={true}
                                >
                                    <TextField
                                        required={true}
                                        style={{}}
                                        fullWidth={true}
                                        placeholder='Enter your address'
                                        label='Address'
                                        name='address'
                                        color='primary'
                                        type='text'
                                        variant='outlined'
                                        {...getFieldProps("address")}
                                        error={Boolean(touched.address && errors.address)}
                                        helperText={touched.address && errors.address}
                                        inputRef={addressref}
                                        onKeyPress={(e) => {
                                            if(e.key === "Enter") {
                                                pincoderef.current?.focus()
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    item={true}
                                >
                                    <TextField
                                        required={true}
                                        style={{}}
                                        fullWidth={true}
                                        placeholder='Enter your pincode'
                                        label='Pincode'
                                        name='pincode'
                                        color='primary'
                                        type='number'
                                        variant='outlined'
                                        {...getFieldProps("pincode")}
                                        error={Boolean(touched.pincode && errors.pincode)}
                                        helperText={touched.pincode && errors.pincode}
                                        inputRef={pincoderef}
                                        onKeyPress={(e) => {
                                            console.log("handlesubmit");
                                            if(e.key === "Enter") {
                                                handleSubmit()
                                            }
                                        }}
                                    />
                                    {(values.pincode?.toString().length === 6 && !userPincode) && <div className='pincode-txt-error'> Service is not available for this pincode </div>}
                                </Grid>

                                <Grid
                                    xs={12}
                                    item={true}
                                >
                                    <Stack spacing={2} direction='row' justifyContent='end' mt='10px'>
                                        <Button onClick={handleSubmit}
                                            sx={{
                                                width: '130px', height: '40px', backgroundColor: 'orange.main',
                                                '&:hover': { backgroundColor: 'orange.main' }, textTransform: 'none'
                                            }}
                                            variant='contained'
                                            size='medium'
                                            type='button'
                                        >
                                            <Typography variant='t14_400' color='primaryColors.textWhite'>Submit</Typography>
                                        </Button>
                                    </Stack>

                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                </FormikProvider>
            </section>
        </>
    );

}

export default RepairDesign;