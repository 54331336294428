import { takeEvery, call, put } from "redux-saga/effects";
import { mobileNewLaunchesList, mobileRecommendedList, mobileBestBudgetList, productBrandList, shopByBrandList, shopByPriceList, addMobilesLoading, addMobilesTagCards } from "../actionCreators/mobile";
import { GET_MOBILE_NEW_LAUNCHES_WATCHER, GET_MOBILE_BEST_BUDGET_WATCHER, GET_MOBILE_RECOMMENDED_WATCHER, GET_PRODUCT_BRAND_WATCHER, GET_SHOPBY_BRAND_WATCHER, GET_SHOPBY_PRICE_WATCHER, GET_MOBILES_TAG_CARDS_WATCHER } from "../actionTypes/mobile"
import mobileService from "../../services/mobile"
import { updateOpenLoader, updateAlert, updateFalseLoader } from '../actionCreators/common'
import commonService from '../../services/common'


function* newLaunchesListEffect(action) {
  try {
    yield put(updateOpenLoader())
    let { data } = yield call(mobileService.getNewLaunchesList, action.payload);
    yield put(mobileNewLaunchesList(data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* recommendedListEffect(action) {
  try {
    // yield put(updateOpenLoader())
    yield put(addMobilesLoading("recommendedMobileListLoading", true))

    let { data } = yield call(mobileService.getRecommendedList, action.payload);
    yield put(mobileRecommendedList(data));
    // yield put(updateFalseLoader())
    yield put(addMobilesLoading("recommendedMobileListLoading", false))

  } catch (e) {
    // yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
    yield put(addMobilesLoading("recommendedMobileListLoading", false))
  }
}

function* bestBudgetListEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(mobileService.getBestBudgetList, action.payload);
    yield put(mobileBestBudgetList(data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* productBrandEffect(action) {
  try {
    // yield put(updateOpenLoader())

    let { data } = yield call(mobileService.getProductBrandList, action.payload);
    let brands = []
    data?.map((v, i) => {
      let val = v
      try {
        let { title, description, categories } = JSON.parse(v?.description)
        val = { ...val, meta_title: title, meta_description: description, categories }
      }
      catch {
        val = { ...val, meta_title: "", meta_description: "", categories: "" }
      }
      brands.push(val)
    })
    yield put(productBrandList(brands));
    // yield put(updateFalseLoader())

  } catch (e) {
    // yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* shopByBrandEffect(action) {
  try {
    // yield put(updateOpenLoader())

    let { data } = yield call(mobileService.getShopByBrandList, action.payload);
    yield put(shopByBrandList(data));
    // yield put(updateFalseLoader())

  } catch (e) {
    // yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* shopByPriceEffect(action) {
  try {
    // yield put(updateOpenLoader())

    let { data } = yield call(mobileService.getShopByPriceList, action.payload);
    yield put(shopByPriceList(data));
    // yield put(updateFalseLoader())

  } catch (e) {
    // yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* getMobileCategoryTagEffect(action) {
  try {
    yield put(updateOpenLoader())
    let { data } = yield call(commonService.getCategoryByTagIdData, action.payload);
    yield put(addMobilesTagCards(data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

export const MobileCategorySaga = [
  takeEvery(GET_MOBILE_NEW_LAUNCHES_WATCHER, newLaunchesListEffect),
  takeEvery(GET_MOBILE_RECOMMENDED_WATCHER, recommendedListEffect),
  takeEvery(GET_MOBILE_BEST_BUDGET_WATCHER, bestBudgetListEffect),
  takeEvery(GET_PRODUCT_BRAND_WATCHER, productBrandEffect),
  takeEvery(GET_SHOPBY_BRAND_WATCHER, shopByBrandEffect),
  takeEvery(GET_SHOPBY_PRICE_WATCHER, shopByPriceEffect),
  takeEvery(GET_MOBILES_TAG_CARDS_WATCHER, getMobileCategoryTagEffect)
]
