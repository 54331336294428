import { takeEvery, call, put } from "redux-saga/effects";
import { wishListProductIdList, wishListProductDataList, productDetailDataList, userPincodeList, productReviewsList, multipleProductList, accessoriesSingleProduct, productVariants, productReviewEmptyList, modifyProductDetail } from "../actionCreators/product";
import { GET_WISH_LIST_PRODUCT_ID_WATCHER, GET_WISH_LIST_PRODUCT_DATA_WATCHER, REMOVE_FROM_WISH_LIST_WATCHER, ADD_TO_WISH_LIST_WATCHER, GET_PRODUCT_DETAIL_WATCHER, ADD_USER_PINCODE_WATCHER, GET_PRODUCT_REVIEWS_WATCHER, GET_MULTIPLE_PRODUCT_DATA, ADD_PRODUCT_REVIEW } from "../actionTypes/product"
import productService from "../../services/product"
import { updateOpenLoader, updateAlert, updateFalseLoader } from '../actionCreators/common'
import moment from "moment";

function* getWishListProductIdEffect(action) {
    try {
        // yield put(updateOpenLoader())

        let { data } = yield call(productService.getWishListProductId, action.userId);
        yield put(wishListProductIdList(data));
        if (data?.length) {
            let productData = []
            let ind = 0
            yield data.map(async (m, i) => {
                try {
                    // action?.dispatch(updateOpenLoader())
                    let { data: data1 } = await productService.getProductDetail(m.productId)
                    console.log(data1, "data1")
                    let { data: data2 } = await productService.getProductVariants(m.productId)
                    let prodData = data2?.length ? data2?.find(d => +d.id === +m.variantId) : data1
                    prodData.wishListVariantId = +m?.variantId
                    prodData.wishListProductId = +m.productId
                    if (data2?.length) {
                        prodData.name = data1?.name
                        prodData.images = prodData?.woo_variation_gallery_images
                        prodData.product_attributes = data1?.attributes
                        prodData.primary_category = data1?.meta_data
                        prodData.categories = data1?.categories
                        prodData.average_rating = data1?.average_rating
                    }
                    productData.splice(i, 0, prodData)
                    if (data.length - 1 === ind) {
                        action?.dispatch(wishListProductDataList(productData));
                    }
                    ind += 1
                    // action?.dispatch(updateFalseLoader())
                }
                catch (e) {
                    // action?.dispatch(updateFalseLoader())
                }
            })

            //old code
            // let products = data.map(m => m.productId).join()
            // let { data: productData } = yield call(productService.getWishListProductData, products);

        }
        else {
            yield put(wishListProductDataList([]));
        }
        // yield put(updateFalseLoader())

    } catch (e) {
        // yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* AddWishListProductEffect(action) {
    try {
        yield put(updateOpenLoader())

        let { data } = yield call(productService.addWishListProduct, action.payload);
        yield put(wishListProductIdList(data));
        if (data?.length) {
            let productData = []
            let ind = 0
            yield data.map(async (m, i) => {
                try {
                    action?.dispatch(updateOpenLoader())
                    let { data: data1 } = await productService.getProductDetail(m.productId)
                    let { data: data2 } = await productService.getProductVariants(m.productId)
                    let prodData = data2?.length ? data2?.find(d => +d.id === +m.variantId) : data1
                    prodData.wishListVariantId = +m.variantId
                    prodData.wishListProductId = +m.productId
                    if (data2?.length) {
                        prodData.name = data1?.name
                        prodData.images = prodData?.woo_variation_gallery_images
                        prodData.product_attributes = data1?.attributes
                        prodData.primary_category = data1?.meta_data
                        prodData.categories = data1?.categories
                        prodData.average_rating = data1?.average_rating
                    }
                    productData.splice(i, 0, prodData)
                    if (data.length - 1 === ind) {
                        action?.dispatch(wishListProductDataList(productData));
                    }
                    ind += 1
                    action?.dispatch(updateFalseLoader())
                }
                catch (e) {
                    action?.dispatch(updateFalseLoader())
                }
            })

        }
        else {
            yield put(wishListProductDataList([]));
        }
        yield put(updateFalseLoader())
        yield put(updateAlert('Product added to wishlist!', 'success'));

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* getWishListProductDataEffect(action) {
    try {
        yield put(updateOpenLoader())

        let { data } = yield call(productService.getWishListProductData, action.payload);
        yield put(wishListProductDataList(data));
        yield put(updateFalseLoader())

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* removeFromWishListEffect(action) {
    try {
        yield put(updateOpenLoader())

        let { data } = yield call(productService.removeWishListProductId, action.userId, action.productId, action.variantId);
        yield put(wishListProductIdList(data));
        if (data.length) {
            let productData = []
            let ind = 0
            yield data.map(async (m, i) => {
                try {
                    action?.dispatch(updateOpenLoader())
                    let { data: data1 } = await productService.getProductDetail(m.productId)
                    let { data: data2 } = await productService.getProductVariants(m.productId)
                    let prodData = data2?.length ? data2?.find(d => +d.id === +m.variantId) : data1
                    prodData.wishListVariantId = +m.variantId
                    prodData.wishListProductId = +m.productId
                    if (data2?.length) {
                        prodData.name = data1?.name
                        prodData.images = prodData?.woo_variation_gallery_images
                        prodData.product_attributes = data1?.attributes
                        prodData.primary_category = data1?.meta_data
                        prodData.categories = data1?.categories
                        prodData.average_rating = data1?.average_rating
                    }
                    productData.splice(i, 0, prodData)
                    if (data.length - 1 === ind) {
                        action?.dispatch(wishListProductDataList(productData));
                    }
                    ind += 1
                    action?.dispatch(updateFalseLoader())
                }
                catch (e) {
                    action?.dispatch(updateFalseLoader())
                }
            })
        }
        else {
            yield put(wishListProductDataList([]));
        }
        yield put(updateFalseLoader())
        yield put(updateAlert('Product removed from wishlist!', 'success'));

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* getProductDetailEffect(action) {
    yield put(productDetailDataList({}));
    try {
        yield put(accessoriesSingleProduct([]))
        yield put(updateOpenLoader())

        let { data } = yield call(productService.getProductDetail, action.productId);
        if (data?.variations?.length > 0) {
            data.images = []
        }
        yield put(productDetailDataList(data));
        let freeproductkey = data?.meta_data?.find(m => m.key === '_accessory_ids') || {}
        if (freeproductkey.value?.length > 0) {
            let id = freeproductkey?.value?.toString()
            let res = yield call(productService.getAccessoriesProductData, id);
            yield put(accessoriesSingleProduct(res.data));
        }
        if (data?.variations?.length > 0) {
            let { data } = yield call(productService.getProductVariants, action.productId);
            yield put(productVariants(data));
        }
        yield put(updateFalseLoader())

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* addUserPincodeEffect(action) {
    try {
        yield put(updateOpenLoader())

        let res = yield call(productService.addUserPincode, action.payload);
        if (res.data?.delivery_info !== undefined) {
            let data = {
                available: true, message: res.data?.delivery_info
            }
            yield put(userPincodeList(data));
            if(action.res) {
                action.res(true)
            }
        }
        else {
            let data = {
                available: false, message: res.data?.message
            }
            yield put(userPincodeList(data));
            if(action.res) {
                action.res(false)
            }
        }
        // yield put(userPincodeList(res.data));
        // action.response(res.data)
        yield put(updateFalseLoader())

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* getProductReviewsEffect(action) {
    try {
        yield put(updateOpenLoader())

        let { data } = yield call(productService.getProductReview, action.productId, action.data);
        if (action.data?.page === 1) {
            yield put(productReviewEmptyList())
        }
        yield put(productReviewsList(data));
        yield put(updateFalseLoader())

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* getMultipleProductDataEffect(action) {
    try {
        yield put(updateOpenLoader())

        let res = yield call(productService.getMultipleProductData, action.payload);
        yield put(multipleProductList(res.data));
        action?.res(res.data)
        yield put(updateFalseLoader())

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* addProductReviewEffect(action) {
    try {
        yield put(updateOpenLoader())
        let { data } = yield call(productService.productReviewById, action.data);
        if (data?.length > 0) {
            yield call(productService.updateProductReview, data[0]?.id, { ...action.data, "date_created": moment(new Date()).format("YYYY-MM-DDTHH:mm:ss") });
            yield put(updateFalseLoader());
            yield put(productReviewEmptyList())
            yield call(getProductReviewsEffect, { productId: action?.data?.product_id, data: { page: 1 } })
            let { data: data1 } = yield call(productService.getProductDetail, action?.data?.product_id);
            if (data1?.rating_count && data1?.average_rating) {
                let payload = { rating_count: data1?.rating_count, average_rating: data1?.average_rating }
                yield put(modifyProductDetail(payload))
            }
        }
        else {
            yield call(productService.addProductReview, action.data);
            yield put(updateFalseLoader());
            yield put(productReviewEmptyList())
            yield call(getProductReviewsEffect, { productId: action?.data?.product_id, data: { page: 1 } })
            let { data: data1 } = yield call(productService.getProductDetail, action?.data?.product_id);
            console.log(data1, "dt1");
            if (data1?.rating_count && data1?.average_rating) {
                let payload = { rating_count: data1?.rating_count, average_rating: data1?.average_rating }
                console.log(payload, "payload");
                yield put(modifyProductDetail(payload))
            }
        }
    } catch (e) {
        yield put(updateFalseLoader());
    }
}


export const ProductSaga = [
    takeEvery(GET_WISH_LIST_PRODUCT_ID_WATCHER, getWishListProductIdEffect),
    takeEvery(ADD_TO_WISH_LIST_WATCHER, AddWishListProductEffect),
    takeEvery(GET_WISH_LIST_PRODUCT_DATA_WATCHER, getWishListProductDataEffect),
    takeEvery(REMOVE_FROM_WISH_LIST_WATCHER, removeFromWishListEffect),
    takeEvery(GET_PRODUCT_DETAIL_WATCHER, getProductDetailEffect),
    takeEvery(ADD_USER_PINCODE_WATCHER, addUserPincodeEffect),
    takeEvery(GET_PRODUCT_REVIEWS_WATCHER, getProductReviewsEffect),
    takeEvery(GET_MULTIPLE_PRODUCT_DATA, getMultipleProductDataEffect),
    takeEvery(ADD_PRODUCT_REVIEW, addProductReviewEffect),
]
