import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import TagCardDesign from "../../components/common/tagCards"
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NewLaunches from '../../components/HeadPhones/newLaunches'
import ShopByBrand from '../../components/mobiles/shopByBrand'
import ShopByPrice from '../../components/mobiles/shopByPrice'
import { getHeadPhonesNewLaunchesWatcher, getHeadphonesTagCardsWatcher } from '../../store/actionCreators/headPhones'
import { getProductBrandWatcher } from '../../store/actionCreators/mobile'
import { getProductCardsWatcher } from '../../store/actionCreators/home'
import TopDeals from '../../components/Accessories/topDeals'
import { useLocation } from 'react-router-dom'
import BannerSlideindex from '../../components/Slider'
import SEO from '../../utils/SEO'
import TopRated from '../../components/Home/topRated'

export default function HeadPhones() {
  const { headPhonesReducers: { HeadphonesTagCards } } = useSelector(state => state)
  const matches = useMediaQuery((theme) => theme.breakpoints.up('1550'));
  const location = useLocation()
  // const { homeReducer: { ProductCardsList} } = useSelector((state) => state)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  const dispatch = useDispatch()

  useEffect(() => {
    let data = {
      pageNO: 1,
      perPage: 4
    }
    let Dealdata = {
      pageNO: 1,
      perPage: 3
    }
    dispatch(getHeadphonesTagCardsWatcher(377))
    dispatch(getHeadPhonesNewLaunchesWatcher(data))
    dispatch(getProductBrandWatcher(data))
    dispatch(getProductCardsWatcher(Dealdata))
  }, [dispatch])

  // const tagCardArr = [{ categoryId: 541, urlname: 'Latest-Models', tagName: 'Latest Models', imgSrc: '/images/headphones/store.png' },
  // { categoryId: 542, urlname: 'Premium', tagName: 'Premium', imgSrc: '/images/headphones/badge.png' },
  // { categoryId: 543, urlname: 'Budget-Friendly', tagName: 'Budget Friendly', imgSrc: '/images/headphones/notifications.png' },
  // { categoryId: 544, urlname: `Today's-Deals`, tagName: `Today's Deals`, imgSrc: '/images/headphones/rating.png' },
  // ]

  const priceArr = [{ id: 1, min: '100', max: '1,000', type: 'between' },
  { id: 2, min: '1,000', max: '3,000', type: 'between' },
    // { id: 3, min: '3,000', max: '6,000', type: 'between' },
    // { id: 4, min: '6,000', max: '10,000', type: 'between' },
    // { id: 5, min: '10,000', max: '50,000', type: 'between' },
    // { id: 6, min: '50,00,000', max: '1,00,000', type: 'between' }
  ]

  return (
    <>
      <SEO title='Buy Headphones & Earphones Online at Best Prices' description='Get branded headphones online at best prices in Chennai. Choose from a wide range of Wireless & Bluetooth Earphones at Phonebuy.com' />
      <Box m='30px 0' style={{ margin: matches && "0px 100px" }}>
        {/* <section class="section sectionCard"> */}
        <Box m='100px 30px 0 30px'>
          <TagCardDesign tagCardArr={HeadphonesTagCards} categoryId={377} />
        </Box>
        <Box>
          <BannerSlideindex />
        </Box>

        {/* {Boolean(newLaunchesList.length) && */}
        <Box m='0 30px 20px 30px'>
          <NewLaunches />
        </Box>
        {/* } */}

        <Box m='0 30px 20px 30px'>
          <TopRated category_id={377} />
        </Box>

        {/* {Boolean(productBrandList.length) && */}
        <Box m='0px 30px 0 30px'>
          <TopDeals category_id={377} />
        </Box>
        {/* } */}

        {/* {Boolean(ProductCardsList.length) && */}
        <Box m='0px 30px 0 30px'>
          <ShopByBrand categoryName={'headphones'} categoryId={377} page='headphones' />
        </Box>
        {/* } */}

        <Box m='0px 30px 0 30px'>
          <ShopByPrice priceArr={priceArr} categoryId={377} page='headphones' />
        </Box>
        {/* </section> */}
      </Box>
    </>
  )
}