import { InputAdornment, Menu, Typography, useMediaQuery, MenuItem, Button } from "@mui/material";
import React, { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { getCartProductIdWatcher } from "../../store/actionCreators/cart";
import { addProductCategory, addSearchText, filterTagBasedProductWatcher, prevPathName } from "../../store/actionCreators/common";
import { getWishlistProductIdWatcher } from "../../store/actionCreators/product";
import Footer from "../Footer";
import SearchField from "../common/searchField"
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { Box } from "@mui/system";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';

const Navbar = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('1201'));
  const match = useMediaQuery((theme) => theme.breakpoints.down('1024'));
  const menu = useMediaQuery((theme) => theme.breakpoints.down('836'));
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loginReducers: { userData }, cartReducers: { cartProductData, cartWithoutLoginData }, commonReducers: { category } } = useSelector(state => state)
  console.log(userData, "navbar");
  const [searchText, setSearchText] = useState('')
  const [path, setPath] = useState("")
  const [initialColor, setInitialColor] = useState('#000')
  const [menuVal, setMenuVal] = useState(false);

  const logoutHandler = () => {
    localStorage.clear()
    window.location.href = "/"
  }

  const menuArr = useMemo(
    () => [{ id: 1, menuName: 'Mobiles', menuPath: "/mobiles" },
    { id: 2, menuName: 'Accessories', menuPath: "/mobile-accessories" },
    { id: 4, menuName: 'Wearables', menuPath: "/wearables" },
    { id: 5, menuName: 'Headphones', menuPath: "/mobile-headphones-headsets" },
    { id: 6, menuName: 'Repair & Services', menuPath: "/mobile-repair-and-services" },
    ],
    [],
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick_ = (event) => {
    {
      accPath !== "/login" &&
        setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (pathname !== "/search") {
      dispatch(addProductCategory(""))
    }
  }, [pathname])

  useEffect(() => {
    let color = menuArr.find(v => v.menuPath === path)?.backgroundColor
    if (color !== undefined) {
      setInitialColor(color)
    }
    else {
      setInitialColor("#000")
    }
  }, [path])

  useEffect(() => {
    let isavailable = menuArr.find(v => v?.menuName?.toLowerCase() === category?.toLowerCase())?.menuPath
    if (isavailable !== undefined) {
      setPath(isavailable)
    }
    else {
      setPath(pathname)
    }
  }, [pathname, category])

  useEffect(() => {
    if (userData.length) {
      let userId = userData[0].userId
      dispatch(getCartProductIdWatcher(userId, dispatch))
      dispatch(getWishlistProductIdWatcher(userId, dispatch))
    }
    let search_session = sessionStorage.getItem("search");
    if (search_session !== undefined) {
      let search = JSON.parse(search_session)
      if (search?.search !== undefined) {
        setSearchText(search?.search)
      }
    }
  }, [userData, dispatch])

  useEffect(() => {
    const accArr = ["/orders", "/cart", "/CartEmpty", "/login", '/profilepage', '/mobile-repair-and-services', '/addresses', '/wishlist']
    const menuRoute = menuArr.find(f => f.menuPath === pathname) || {};
    if (!Object.keys(menuRoute).length && !accArr.includes(pathname))
      sessionStorage.removeItem('MenuName');
  }, [pathname, menuArr])

  const savePath = () => {
    dispatch(prevPathName(pathname))
  }

  const handleSearch = () => {
    // console.log("checkingconsole")
    // if (searchText) {
    //   let data = {
    //     perPage: 100,
    //     search: searchText
    //   }
    //   dispatch(filterTagBasedProductWatcher(data))
    // }
    // return
  }

  const handleClick = (menuName) => {
    sessionStorage.setItem('MenuName', menuName);
  }

  const userName = userData.length ? `Hello, ${userData[0]?.firstName}` : "Sign in"
  console.log(userName, userData.length, "userlength");
  const msgContent = userData.length ? "My Account" : "Account"
  const cartCount = userData.length ? cartProductData.length : cartWithoutLoginData.length
  const cartPath = cartCount ? "/cart" : "/CartEmpty"
  const accPath = userData.length ? "" : "/login"
  const searchPath = searchText ? `/search?name=${searchText}&search=${searchText}` : '#/'
  console.log("path", searchPath)
  return (
    <>

      <header className="header">
        <header className="site-header clear" style={{ backgroundColor: initialColor }}>
          <h1 className="left">
            <Link to="/">
              <img src="/images/logo.svg" alt="Phone Buy" title="Phone Buy" />
            </Link>
          </h1>

          <aside className={matches ? "cart right" : "cart navcart right"} style={{ margin: match ? "23px 0 2px 4px" : "" }}>
            <Box sx={{ pr: { md: '20px', sm: '10px', lg: '0' }, pl: { md: '30px', sm: '20px', lg: 'auto', xs: 'auto' } }}>
              <Link to={cartPath} onClick={() => handleClick('Cart')}>
                <Box sx={{ opacity: pathname === cartPath ? 1 : 0.5, color: "#ffffff", display: 'flex' }}>
                  <figure>
                    <img src="/images/cart.svg" alt='' />
                    <span>{cartCount}</span>
                  </figure>
                  <Typography mr='10px' sx={{ opacity: pathname === cartPath ? 1 : 0.8, color: "#ffffff", fontSize: "12px", fontWeight: "300" }}> Cart</Typography>
                </Box>
              </Link>
            </Box>
          </aside>

          <aside className="signin right" style={{ padding: "0px" }}>
            {/* <Link to={accPath} onClick={() => { handleClick(userName); savePath(); }}> */}
            {/* <Box sx={{ opacity: pathname === accPath ? 1 : 0.5, color: "#ffffff" }}> <small>{userName}</small> {msgContent} </Box> */}
            <div>
              <Button
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick_}
                onMouseOver={handleClick_}
                onMouseDown={handleClick_}
              >
                <Link to={accPath} onClick={() => { handleClick(userName); savePath(); }}>
                  <Box sx={{ opacity: pathname === accPath ? 1 : 0.5, color: "#ffffff", textTransform: "capitalize" }}> <small>{userName}</small> {msgContent} </Box>
                </Link>
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                style={{ marginTop: menu ? "105px" : "50px" }}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Link to="/orders"> <MenuItem onClick={handleClose}> <ShoppingCartIcon color="black" /> Orders</MenuItem> </Link>
                <Link to="/mobile-repair-and-services"> <MenuItem onClick={handleClose}> <HomeRepairServiceIcon color="black" /> Repair&Services</MenuItem> </Link>
                <Link to="/addresses"> <MenuItem onClick={handleClose}> <HomeIcon color="black" /> Address</MenuItem> </Link>
                <Link to="/wishlist"> <MenuItem onClick={handleClose}> <FavoriteIcon color="black" /> Wishlist</MenuItem> </Link>
                <Link to="/profilepage"> <MenuItem onClick={handleClose}> <AccountBoxIcon color="black" /> Profile</MenuItem> </Link>
                <MenuItem onClick={logoutHandler}> <LogoutIcon color="black" /> Logout</MenuItem>
              </Menu>
            </div>
            {/* </Link> */}
          </aside>

          <aside className="search right">
            <SearchField
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  console.log("enterworking");
                  navigate(searchPath)
                  dispatch(addSearchText(searchText))
                }
              }}
              InputProps={{
                endAdornment: (
                  <Link to={searchPath} state={{ searchVal: searchText }} onClick={() => {
                    dispatch(addSearchText(searchText))
                  }}>
                    <InputAdornment position="end">
                      <SearchIcon color='#ffff' />
                    </InputAdornment>
                  </Link>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: 35,
                  mt: '14px'
                }
              }}
              placeholder='Search here...'
              fullWidth
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
            />
          </aside>
          <nav className="nav right">
            <label htmlFor="toggleMenu" className="menuTitle">
              <div>
                <div className='bar1'></div>
                <div className='bar2'></div>
                <div className='bar3'></div>
              </div>
            </label>
            <input type="checkbox" id="toggleMenu" onChange={(e) => setMenuVal(e.target.checked)} checked={menuVal} />
            <ul className="menu">
              {menuArr?.map((m, i) => {
                return <li key={m.menuName}>
                  <Link to={m.menuPath} >
                    <Typography onClick={(e) => { setMenuVal(false); handleClick(m.menuName); }}
                      sx={{ opacity: m.menuPath === path ? 1 : 0.5, color: "#ffffff", fontWeight: m.menuPath === path ? 700 : 500 }}>
                      {m.menuName}
                    </Typography>
                  </Link>
                </li>
              })}
            </ul>
          </nav>
        </header>
      </header>
      <Outlet />
      <Footer />

    </>
  );
};

export default Navbar;
