import React from 'react'

export default function AboutUs() {
    return (
        <div className="section" style={{ margin: "80px" }}>
            <div style={{ display: "flex", justifyContent: "center", fontSize: "40px", fontFamily: "serif", padding: "20px" }}> About Us </div>
            <div style={{ padding: "30px 100px 150px 100px" }}>
                <li>
                    Phonebuy is leading mobile phone retail chain business; having three showrooms in Chennai.
                </li>
                <li>
                    Phonebuy caters mobile phones, related products and services in our retails stores and on our exclusive e-commerce platform www.phonebuy.com as well.
                </li>
                <li>
                    Our e-commerce website provides Same Day Delivery, One Day Delivery, and Store Pickup options based on their location in Chennai and rest of Tamilnadu.
                </li>
                <li>
                    Phonbuy partnered with all industry leading mobile brands and sells original products.
                </li>
            </div>
        </div>
    )
}
