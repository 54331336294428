import React from 'react';
import { Button, TextField, Typography, Grid, Stack, Box, useMediaQuery, Autocomplete } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux'
import { addUserWatcher, checkIsExist, showUserDataAddress, userPhNumber } from '../../store/actionCreators/login';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { addUserPincodeWatcher } from '../../store/actionCreators/product';
import SEO from '../../utils/SEO';
import { useState } from 'react';
import { useRef } from 'react';

function SignUpDesign(props) {
    const { productReducers: { userPincode } } = useSelector((state) => state)
    const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let lastNameref = useRef()
    let phoneNumberref = useRef()
    let emailref = useRef()
    let passwordref = useRef()
    let addressRef = useRef()
    let houseref = useRef()
    let arearef = useRef()
    let districtref = useRef()
    let stateref = useRef()
    let pincoderef = useRef()

    const { loginReducers: { phNumber } } = useSelector(state => state)
    const [signinPage, setSigninPage] = useState(1)
    const [selectedOption, setSelectedOption] = useState(null);
    const options = [
        { id: 1, short: "AN", state: "Andaman and Nicobar Islands" },
        { id: 2, short: "AP", state: "Andhra Pradesh" },
        { id: 3, short: "AR", state: "Arunachal Pradesh" },
        { id: 4, short: "AS", state: "Assam" },
        { id: 5, short: "BR", state: "Bihar" },
        { id: 6, short: "CG", state: "Chandigarh" },
        { id: 7, short: "CH", state: "Chhattisgarh" },
        { id: 8, short: "DH", state: "Dadra and Nagar Haveli" },
        { id: 9, short: "DD", state: "Daman and Diu" },
        { id: 10, short: "DL", state: "Delhi" },
        { id: 11, short: "GA", state: "Goa" },
        { id: 12, short: "GJ", state: "Gujarat" },
        { id: 13, short: "HR", state: "Haryana" },
        { id: 14, short: "HP", state: "Himachal Pradesh" },
        { id: 15, short: "JK", state: "Jammu and Kashmir" },
        { id: 16, short: "JH", state: "Jharkhand" },
        { id: 17, short: "KA", state: "Karnataka" },
        { id: 18, short: "KL", state: "Kerala" },
        { id: 19, short: "LD", state: "Lakshadweep" },
        { id: 20, short: "MP", state: "Madhya Pradesh" },
        { id: 21, short: "MH", state: "Maharashtra" },
        { id: 22, short: "MN", state: "Manipur" },
        { id: 23, short: "ML", state: "Meghalaya" },
        { id: 24, short: "MZ", state: "Mizoram" },
        { id: 25, short: "NL", state: "Nagaland" },
        { id: 26, short: "OR", state: "Odisha" },
        { id: 27, short: "PY", state: "Puducherry" },
        { id: 28, short: "PB", state: "Punjab" },
        { id: 29, short: "RJ", state: "Rajasthan" },
        { id: 30, short: "SK", state: "Sikkim" },
        { id: 31, short: "TN", state: "Tamil Nadu" },
        { id: 32, short: "TS", state: "Telangana" },
        { id: 33, short: "TR", state: "Tripura" },
        { id: 34, short: "UK", state: "Uttar Pradesh" },
        { id: 35, short: "UP", state: "Uttarakhand" }
        // Add more options here
    ];


    const Schema = Yup.object().shape({
        firstName: Yup.string().required('FirstName is required'),
        lastName: Yup.string().required('lastName is required'),
        phoneNumber: Yup.string('Enter your Phone number').required('Phone number is required').min(10, 'enter valid Phone number').max(10, 'enter valid Phone number'),
        email: Yup.string('Enter your Email').email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('password is required').min(6, 'must be min 6 characters').matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$,._])[A-Za-z0-9@#$,._]+$/, 'Password must contain at least one uppercase letter, one special character, and one numeric digit')
    });

    const Schema1 = Yup.object().shape({
        addressType: Yup.string().required('AddressType is required'),
        houseNo: Yup.string().required('House Number is required'),
        area: Yup.string().required('Area is required'),
        district: Yup.string().required('District is required'),
        state: Yup.string().required('State is required'),
        pincode: Yup.string().min(6, 'enter valid pincode').max(6, 'enter valid pincode').required('Pincode is required')
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            password: '',
            houseNo: '',
            area: '',
            district: '',
            state: '',
            pincode: '',
        },

        validationSchema: Schema,

        onSubmit: (v) => {
            dispatch(checkIsExist({ mobileNumber: v?.phoneNumber, email: v?.email }, () => {
                console.log("working");
                setSigninPage(2)
                addressRef.current?.focus()
            }))
        }
    });

    const formik1 = useFormik({
        initialValues: {
            addressType: '',
            houseNo: '',
            area: '',
            district: '',
            state: '',
            pincode: '',
        },

        validationSchema: Schema1,

        onSubmit: () => {
            signUpHandler()
        }
    });


    const signUpHandler = () => {
        let { firstName, lastName, phoneNumber, email, password } = { ...formik.values }
        let { addressType, houseNo, district, area, state, pincode } = { ...formik1.values }
        let data = {
            "firstName": firstName,
            "lastName": lastName,
            "mobileNumber": phoneNumber,
            // "contact": contact,
            "email": email,
            "addressType": addressType,
            "house": houseNo,
            "area": area,
            "district": district,
            "state": state,
            "pincode": pincode,
            "password": password
        }
        // if (userPincode) {
        dispatch(addUserWatcher(data, (res) => {
            dispatch(userPhNumber(phoneNumber))
            dispatch(showUserDataAddress({ addressType, house: houseNo, district, area, state, pincode, firstName, lastName, mobileNumber: phoneNumber }))
            navigate('/otp')
        }))
    }

    useEffect(() => {
        if (formik.values.pincode.length === 6) {
            dispatch(addUserPincodeWatcher(formik.values.pincode))
        }
    }, [formik.values.pincode, dispatch])

    const { errors, touched, handleSubmit, getFieldProps, values } = formik;

    const addressType = ["home", "office"]

    return (
        <div style={{ backgroundColor: "#FFFFFF" }}>
            <SEO title='Sign Up' description='Sign Up Your Account' />
            {/* <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}> */}
            <Box m={matches ? '100px 120px 30px 120px' : '180px 50px 30px 50px'} style={{ backgroundColor: "#E5E5E5", padding: "30px", borderRadius: "5px" }}>
                <Typography variant='h5'
                    align='left'
                    style={{ paddingBottom: '10px', fontSize: '30px' }}>
                    Sign Up
                </Typography>

                <Grid
                    container
                    style={{ paddingTop: '10px' }}
                    spacing={3}
                    direction='row'
                >
                    {signinPage === 1 ? <>
                        <Grid
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <TextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Enter your first name'
                                label='First Name'
                                name='firstName'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("firstName")}
                                error={Boolean(touched.firstName && errors.firstName)}
                                helperText={touched.firstName && errors.firstName}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        lastNameref.current?.focus()
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <TextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Enter your last name'
                                label='last Name'
                                name='lastName'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("lastName")}
                                error={Boolean(touched.lastName && errors.lastName)}
                                helperText={touched.lastName && errors.lastName}
                                inputRef={lastNameref}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        phoneNumberref.current?.focus()
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <TextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Enter your phone number'
                                label='phoneNumber'
                                name='phoneNumber'
                                color='primary'
                                type='number'
                                variant='outlined'
                                {...getFieldProps("phoneNumber")}
                                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                helperText={touched.phoneNumber && errors.phoneNumber}
                                inputRef={phoneNumberref}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        emailref.current.focus()
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <TextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Enter your email'
                                label='email'
                                name='email'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("email")}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                                inputRef={emailref}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        passwordref.current?.focus()
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                            item={true}
                        >
                            <TextField
                                required={true}
                                style={{}}
                                fullWidth={true}
                                placeholder='Enter your password'
                                label='password'
                                name='password'
                                color='primary'
                                type='text'
                                variant='outlined'
                                {...getFieldProps("password")}
                                error={Boolean(touched.password && errors.password)}
                                helperText={touched.password && errors.password}
                                inputRef={passwordref}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        handleSubmit()
                                    }
                                }}
                            />
                            <Typography > note : Password must contain min 6 characters, atleast one uppercase, one special case(@ # , . $ _), one numeric. </Typography>
                        </Grid>
                    </> :
                        <>
                            <Grid
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                                item={true}
                            >
                                <Autocomplete
                                    autoComplete="off"
                                    disablePortal
                                    id="combo-box-demo"
                                    options={addressType}
                                    renderInput={(params) => <TextField {...params} label="AddressType"
                                        required={false}
                                        minRows={2}
                                        fullWidth={true}
                                        placeholder='Enter your address type'
                                        name='addressType'
                                        color='primary'
                                        type='text'
                                        variant='outlined'
                                        {...formik1.getFieldProps("addressType")}
                                        error={Boolean(formik1.touched.addressType && formik1.errors.addressType)}
                                        helperText={formik1.touched.addressType && formik1.errors.addressType}
                                        inputRef={addressRef}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                console.log(e.target.value, "adressref");
                                                formik1.setFieldValue("addressType", e.target.value)
                                                houseref.current?.focus()
                                            }
                                        }}
                                    />} />
                            </Grid>
                            <Grid
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                                item={true}
                            >
                                <TextField
                                    required={false}
                                    style={{}}
                                    minRows={2}
                                    fullWidth={true}
                                    placeholder='Enter your house'
                                    label='House, Flat, Apartment Number'
                                    name='houseNo'
                                    color='primary'
                                    type='text'
                                    variant='outlined'
                                    {...formik1.getFieldProps("houseNo")}
                                    error={Boolean(formik1.touched.houseNo && formik1.errors.houseNo)}
                                    helperText={formik1.touched.houseNo && formik1.errors.houseNo}
                                    inputRef={houseref}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            arearef.current?.focus()
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                                item={true}
                            >
                                <TextField
                                    required={false}
                                    style={{}}
                                    fullWidth={true}
                                    placeholder='Enter your area'
                                    label='Area, Street'
                                    name='area'
                                    color='primary'
                                    type='text'
                                    variant='outlined'
                                    {...formik1.getFieldProps("area")}
                                    error={Boolean(formik1.touched.area && formik1.errors.area)}
                                    helperText={formik1.touched.area && formik1.errors.area}
                                    inputRef={arearef}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            districtref.current?.focus()
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                                item={true}
                            >
                                <TextField
                                    required={false}
                                    style={{}}
                                    fullWidth={true}
                                    placeholder='Enter your district'
                                    label='District'
                                    name='district'
                                    color='primary'
                                    type='text'
                                    variant='outlined'
                                    {...formik1.getFieldProps("district")}
                                    error={Boolean(formik1.touched.district && formik1.errors.district)}
                                    helperText={formik1.touched.district && formik1.errors.district}
                                    inputRef={districtref}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            stateref.current?.focus()
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                                item={true}
                            >
                                <Autocomplete
                                    options={options}
                                    getOptionLabel={(option) => `${option.state} - ${option.short}`}
                                    value={selectedOption}
                                    onChange={(_, newValue) => setSelectedOption(newValue)}
                                    renderInput={(params) => <TextField {...params} label="state"
                                        required={false}
                                        style={{}}
                                        fullWidth={true}
                                        placeholder='Enter your state'
                                        name='state'
                                        color='primary'
                                        type='text'
                                        variant='outlined'
                                        {...formik1.getFieldProps("state")}
                                        error={Boolean(formik1.touched.state && formik1.errors.state)}
                                        helperText={formik1.touched.state && formik1.errors.state}
                                        inputRef={stateref}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                formik1.setFieldValue("state", e.target.value)
                                                pincoderef.current?.focus();
                                            }
                                        }}
                                    />}
                                />
                                {/* <TextField
                                    required={false}
                                    style={{}}
                                    fullWidth={true}
                                    placeholder='Enter your state'
                                    label='State'
                                    name='state'
                                    color='primary'
                                    type='text'
                                    variant='outlined'
                                    {...formik1.getFieldProps("state")}
                                    error={Boolean(formik1.touched.state && formik1.errors.state)}
                                    helperText={formik1.touched.state && formik1.errors.state}
                                    inputRef={stateref}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            pincoderef.current?.focus();
                                        }
                                    }}
                                /> */}
                            </Grid>
                            <Grid
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                                item={true}
                            >
                                <TextField
                                    required={false}
                                    style={{}}
                                    fullWidth={true}
                                    placeholder='Enter your pincode'
                                    label='Pincode'
                                    name='pincode'
                                    color='primary'
                                    type='text'
                                    variant='outlined'
                                    {...formik1.getFieldProps("pincode")}
                                    error={Boolean(formik1.touched.pincode && formik1.errors.pincode)}
                                    helperText={formik1.touched.pincode && formik1.errors.pincode}
                                    inputRef={pincoderef}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            signUpHandler()
                                        }
                                    }}
                                />
                                {/* {(values.pincode.length === 6 && !userPincode) && <div className='pincode-txt-error'> Delivery is not available for this pincode </div>} */}
                            </Grid>
                        </>}

                    <Grid
                        xs={12}
                        item={true}
                    >
                        <Stack spacing={2} direction='row' justifyContent='end' mt='10px'>
                            {signinPage === 2 && <Button
                                onClick={signUpHandler}
                                sx={{
                                    width: '130px', height: '40px', backgroundColor: 'orange.main',
                                    '&:hover': { backgroundColor: 'orange.main' }, textTransform: 'none'
                                }}
                                variant='contained'
                                size='medium'
                            // type='button'
                            >
                                <Typography variant='t14_400' color='grey.0'> Skip </Typography>
                            </Button>}
                            <Button onClick={() => {
                                if (signinPage == 1) {
                                    handleSubmit()
                                } else {
                                    formik1.handleSubmit();
                                }
                            }}
                                sx={{
                                    width: '130px', height: '40px', backgroundColor: 'orange.main',
                                    '&:hover': { backgroundColor: 'orange.main' }, textTransform: 'none'
                                }}
                                variant='contained'
                                size='medium'
                            // type='button'
                            >
                                <Typography variant='t14_400' color='grey.0'>{signinPage === 1 ? "Continue" : "Submit"}</Typography>
                            </Button>
                        </Stack>

                    </Grid>
                </Grid>
            </Box>
            {/* </Form>
            </FormikProvider> */}
        </div>
    );

}

export default SignUpDesign;