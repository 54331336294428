import { Box, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { removeCartWithoutLoginList, removeFromCartWatcher, updateCartProductWatcher } from '../../store/actionCreators/cart'
import { AddWishlistProductWatcher, removeFromWishlistWatcher } from '../../store/actionCreators/product'
import Screenwise from './screenwise'
import { useLocation, useNavigate } from "react-router-dom";

const ProductCart = () => {
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const dispatch = useDispatch()
    const { loginReducers: { userData }, cartReducers: { cartProductData, cartWithoutLoginData } } = useSelector(state => state)

    const cartData = userData.length ? cartProductData : cartWithoutLoginData

    const removeFunction = (productData) => {
        if (userData.length) {
            dispatch(removeFromCartWatcher(userData[0]?.userId,productData?.cartProductId, productData?.cartVariantId, dispatch))
        }
        else {
            const data = cartWithoutLoginData.filter(m =>{
             return productData?.cartVariantId ? +m.cartVariantId !== +productData?.cartVariantId : +m.cartProductId !== +productData?.cartProductId
            })
            dispatch(removeCartWithoutLoginList(data))
          }
        }
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {cartData?.map((m, i) => {
                return <div key={m.id}>
                    {matches ? <CartCard productData={m} removeFunction={removeFunction}/> : <Screenwise productData={m} removeFunction={removeFunction}/>}
                </div>
            })}
        </div>
    )
}

const CartCard = ({ productData, removeFunction }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { loginReducers: { userData }, productReducers: { wishListProductData } } = useSelector((state) => state)

    const [qty, setQty] = useState(1)
    const isSelected = wishListProductData.some(d => {
        return +d.wishListVariantId ? +d.wishListVariantId === +productData?.cartVariantId : +d.wishListProductId === +productData?.cartProductId
      })
    const userId = userData[0]?.userId
    const productId = productData?.id
    const [productName, setProductName] = useState('')
    const [selectedVariant, setSelectedVariant] = useState('')
    let  location= useLocation();

    useEffect(() => {
        setQty(productData.quantity)
    }, [productData.quantity])

    useEffect(() => {
        let prodName = productData?.name
        //route code
        if (productData?.attributes?.length && productData?.attributes[0]?.options?.length) {
            let attribute = productData?.attributes?.filter(s => s?.variation) || []
            let getName = attribute?.length >= 2 ? attribute[0]?.options[0] + ' - ' + attribute[1]?.options[0] : attribute[0]?.options[0]
            const prodName = `${productData?.name}(${getName})`
            setProductName(prodName)
      
            let chooseColor = attribute[0]
            chooseColor.selected = chooseColor?.options[0]
            if (attribute?.length >= 2) {
              let chooseGB = attribute[1]
              chooseGB.selected = chooseGB?.options[0]
            }
            setSelectedVariant(attribute)
          }
          else {
            setProductName(productData?.name)
          }
    }, [productData?.attributes,productData?.name])


    const imageName = (imageArr) => {
        let imgName = (imageArr?.length && imageArr[0]?.src) || "/images/Phone1.png"
        return imgName
    }

    const qtyChange = (e) => {
        setQty(e.target.value)

        let data = {
            quantity: e.target.value
        }
        dispatch(updateCartProductWatcher(data, userId, productData?.cartProductId, productData?.cartVariantId, dispatch))
    }

    const addToFavourite = () => {
        if (!isSelected) {
            let data =
            {
                userId,
                productId,
                variantId : productData?.cartVariantId
            }
            dispatch(AddWishlistProductWatcher(data, dispatch))
        }
        else {
            dispatch(removeFromWishlistWatcher(userId, productId, productData?.cartVariantId, dispatch))
        }
    }

    const handleNavigate = async () => {
        let prodName = productData?.name
        let pathName = "home"
        const path = window.location.pathname.substr(1,).toLowerCase() || "home"
        if(path?.split("/")?.length>1){
            pathName = path?.split("/")[0]
        }
       
          //route code
          if (productData?.cartVariantId!== 0) {
            navigate(`/${encodeURIComponent(pathName)}/${productName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${productData?.cartProductId}&vid=${productData?.cartVariantId}`,{replace:true})
          }
        
        else {
            console.log(`/${encodeURIComponent(pathName)}/${productName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${productData?.cartProductId}&vid=0`,"cartview");
          navigate(`/${(pathName)}/${productName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${productData?.cartProductId}&vid=0`,{replace:true})
        }
      }

    return (
        <>
            <Box sx={{ cursor: 'pointer', padding:"5px"}} onClick={handleNavigate}>
                <Box className="frame-1000002000 flex-col-hstart-vstart clip-contents">
                    <Box className="frame-1000001921 flex-row-vstart-hstart" p='20px'>
                            <img
                                src={imageName(productData?.images)}
                                alt="not found"
                                className="frame-1000001918" style={{ height: '250px', cursor: 'pointer' }}  />
                        <Box className="frame-1000001912 flex-col-hstart-vsb clip-contents" height='250px'>
                            <Box className="frame-1000002068 flex-col-hstart-vstart">

                                <Box className="frame-10000019181 flex-row-vstart-hsb">
                                    <Box className="frame-1000002068 flex-col-hstart-vstart">
                                        <Box>
                                        </Box>
                                    </Box>
                                    {Boolean(userData.length) &&
                                        <img src={isSelected ? "/images/repairs/like.jpg" : "/images/OrderConfirmation/Heart.png"} onClick={(e) => { e.stopPropagation(); addToFavourite() }} style={{ height: '40px', width: '40px', cursor: 'pointer', marginRight: '5px' }} alt='' />}
                                </Box>

                                <Box className="frame-1000001920 flex-col-hstart-vstart">
                                    <Box className="frame-1000002074 flex-row-vstart-hstart">
                                        <Box className="frame-1000002073 flex-col-hstart-vstart">
                                            <Box className="frame-1000001919 flex-row-vstart-hstart">
                                                <p className="txt-954">
                                                    {productName}
                                                </p>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className="frame-10000019182 flex-row-vcenter-hstart">
                                        <p className="txt-531">₹{productData?.price}</p>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="frame-1000002023 flex-row-vend-hsb" mt='auto'>
                                {Boolean(userData.length) &&
                                    <Box className="frame-1000002068 flex-col-hstart-vstart" minWidth='60px'>
                                        <Typography variant='p11_500' >Quantity</Typography>
                                        <select name="Quantity" id="Quantity" value={qty} onClick={(e)=> e.stopPropagation()} onChange={(e) => { e.stopPropagation(); qtyChange(e) }} style={{ width: '45px', height: '35px', marginTop: '10px', cursor: 'pointer' }}>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                        </select>
                                    </Box>
                                }
                                <Box className="frame-1000002071 flex-row-vcenter-hcenter" sx={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); removeFunction(productData) }}>
                                    <img
                                        src="https://firebasestorage.googleapis.com/v0/b/unify-bc2ad.appspot.com/o/G6hBvXFMP81M1WvQjWNcaZ-1%3A107?alt=media&token=8d47f43c-0498-446c-b3f5-6408233ef2c7"
                                        alt="not found"
                                        className="trash" />
                                    <h1 className="txt-278"> RemoveItem </h1>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ProductCart