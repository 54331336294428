import React from "react";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import theme from "../../theme";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { filterAttributes } from "./FilterAttributes";
import { getcardprimarycolor } from "../../utils/common";

function valuetext(value) {
  return `${value}°C`;
}

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: theme.palette.primaryColors.productListItemBg,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.primaryColors.productListItemBg,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.primaryColors.productListItemBg,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primaryColors.productListItemBg,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primaryColors.productListItemBg,
    },
  },
  background: theme.palette.primaryColors.productListItemBg,
  borderRadius: "4px",
  marginBottom: 8,
});

// const allBrandList = ['Apple', 'Samsung', 'Oppo', 'Mi', 'vivo', 'Poco', 'Oneplus']
// const allRamList = ['1 GB and Below', '1 GB', '2 GB', '3 GB', '4 GB', '5 GB']
// const allStorageList = ['32 GB', '64 GB', '128 GB', '256 GB']
// const allScreenSize = ['Less than 3 inch', '5 inch - 5.9 inch', '6 inch - 6.4 inch', '6.5 inch above']
// const allOperatingSystem = ['iOS', 'Android']
// const allColorList = [{ id: 1, name: 'White', color: 'white.1' }, { id: 2, name: 'Black', color: 'primaryColors.mobileCardText' }, { id: 3, name: 'Red', color: 'filter.red' }, { id: 4, name: 'Blue', color: 'filter.blue' }, { id: 5, name: 'Green', color: 'filter.green' }, { id: 6, name: 'Yellow', color: 'filter.yellow' }]

const FilterSection = ({ handleChange1, value1, applyFunction, handleFilterChange, formFilter, setValue1 }) => {
  const smlfilter = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const { commonReducers: { searchProductData, primaryCategory } } = useSelector(state => state)

  const [attributes, setAttributes] = useState([])
  const [price, setPrice] = useState({
    min: 500, max: 50000, default: [500, 50000]
  })

  const CustomSlider = styled(Slider)(({ theme }) => ({

    color: "#F26B23", //color of the slider between thumbs

    "& .MuiSlider-thumb": {

      backgroundColor: "#F26B23" //color of thumbs

    },

    "& .MuiSlider-rail": {

      color: "#F26B23" ////color of the slider outside teh area between thumbs

    }

  }));

  // const [brandList, setBrandList] = useState(allBrandList.slice(0, 4))
  // const [isAllBrand, setIsAllBrand] = useState(false)
  // const [isSearch, setIsSearch] = useState(false)

  useEffect(() => {
    // setBrandList((brandList) => !isSearch ? allBrandList.slice(0, 4) : brandList)
    setAttributeshandler()
    setPriceHandler()
    return () => {
      setAttributes([])
    }
  }, [searchProductData?.length])

  const setAttributeshandler = async () => {
    let filter_attributes = await filterAttributes(searchProductData)
    setAttributes(filter_attributes)
  }

  const setPriceHandler = () => {
    let productPrices = searchProductData?.map((v) => parseInt(v?.price))
    let priceMin = Math.min(...productPrices)
    let Max = Math.max(...productPrices);
    let priceMax = (Math.round(Max / 1000)) * 1000
    console.log(priceMin,priceMax,"minmax");
    // setPrice({ min: priceMin, max: priceMax, default: [priceMin, priceMax] })
    setPrice({...price, min: parseInt(priceMin), max: parseInt(priceMax)})
    if(!isNaN(parseInt(priceMin)) && !isNaN(parseInt(priceMax))){
      setValue1([parseInt(priceMin),parseInt(priceMax)])
    }
  }

  // function escapeRegExp(value) {
  //   return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  // }

  // const seeAllBrand = () => {
  //   setIsAllBrand(!isAllBrand)
  //   setBrandList(isAllBrand ? allBrandList.slice(0, 4) : allBrandList)
  // }

  // const searchBrand = (e) => {
  //   setIsSearch(e.target.value !== '' ? true : false)
  //   const searchRegex = new RegExp(escapeRegExp(e.target.value), 'i');
  //   setBrandList(allBrandList.filter(d => searchRegex.test(d)))
  // }

  // console.log('mapData',allColorList, allOperatingSystem, allScreenSize, allStorageList, allRamList, brandList)
  const seeAllhandler = (i) => {
    let arr = [...attributes]
    if (arr[i]?.toggle) {
      arr[i].options = arr[i]?.top4
      arr[i].toggle = false
    }
    else {
      arr[i].options = arr[i]?.alloptions
      arr[i].toggle = true
    }
    setAttributes(arr)
  }
  console.log(price, "filterprice");
  return (
    <section className="sectionFilterLeft">
      <Box className="category_left" display='flex' justifyContent='center'>
        <Box width={{ xs: '100%', sm: '60%', md: '100%' }} >
          <Box display='flex'>
            <Typography variant="p16_b" color="primaryColors.breadcrumbs">
              Filters
            </Typography>

            {smlfilter ? <Button variant='contained' sx={{ backgroundColor: "#F26B23", textTransform: "none", color: "white !important", ml: 'auto', '&:hover': { backgroundColor: "#F26B23" } }} onClick={applyFunction}>Apply</Button> :
              <Button variant='contained' sx={{ backgroundColor: "#F26B23", textTransform: "none", color: "white !important", ml: '60%', '&:hover': { backgroundColor: "#F26B23" } }} onClick={applyFunction}>Apply</Button>}
          </Box>
          <div>
            <div className="category_left_line"></div>
            <div className="category_left_price">
              <div className="mb_16px">
                <Typography variant="p13_500" color="primaryColors.filterLeftText">
                  Price Range
                </Typography>
              </div>

              <CustomSlider
                getAriaLabel={() => 'Temperature range'}
                value={value1}
                onChange={handleChange1}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                max={price.max}
                min={price.min}
                defaultValue={price.default}
              // sx={{p:'10px'}}
              />
              <div className="category_left_range">
                <Typography variant="p12_600" color="primaryColors.filterLeftInnerText">
                  {value1[0]}
                </Typography>
                <Typography variant="p12_600" color="primaryColors.filterLeftInnerText">
                  {value1[1]}
                </Typography>
              </div>
            </div>
          </div>

          <div>
            {/* <div className="category_left_line"></div> */}
            <div className="category_left_price">
              {/* <div className="mb_16px">
                <Typography variant="p13_500" color="primaryColors.filterLeftText">
                  Brands
                </Typography>
              </div> */}

              {/* <CssTextField
                size="small"
                fullWidth
                placeholder="search"
                onChange={searchBrand}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              /> */}
              {/* <div>
                {brandList.map((val, i) => {
                  return <div className="category_left_brand_flex" key={val.id}>
                    <Checkbox
                      sx={{
                        "&:hover": { bgcolor: "transparent" },
                      }}
                      disableRipple
                      color="orange"
                      name='brandVal'
                      onChange={(e) => handleFilterChange(e, val, formFilter.brandVal)}
                      checked={formFilter.brandVal.includes(val)}
                    />
                    <Typography variant="p12_600" color="primaryColors.filterLeftInnerText">
                      {val}
                    </Typography>
                    <Typography variant="p12_600" ml="auto" color="primaryColors.filterLeftInnerText">
                      1,717
                    </Typography>
                  </div>
                })}
                <Box onClick={seeAllBrand} sx={{ cursor: 'pointer' }}>
                  <Typography variant="p12_600" color="orange.main">
                    {isAllBrand ? ' See less' : 'See all'}
                  </Typography>
                </Box>
              </div> */}

              {
                attributes?.map((v, i) => {
                  return v?.alloptions?.length > 0 && <div>
                    <div className="category_left_line"></div>
                    <div className="mb_8px">
                      <Typography variant="p13_500" color="primaryColors.filterLeftText">
                        {v?.name}
                      </Typography>
                    </div>

                    <div>
                      {v?.options.map((option) => {
                        let isChecked = formFilter[v?.name]?.some(a => a === option)
                        console.log("checked", isChecked)
                        return <div className="category_left_brand_flex">
                          <Checkbox
                            sx={{
                              "&:hover": { bgcolor: "transparent" },
                            }}
                            disableRipple
                            color="orange"
                            name='ramVal'
                            onChange={(e) => handleFilterChange(v?.name, option)}
                            checked={isChecked}
                          />

                          <Typography variant="p12_600" color="primaryColors.filterLeftInnerText">
                            {option}
                          </Typography>
                        </div>
                      })}
                    </div>
                    {v?.alloptions?.length > 4 &&
                      <Box onClick={() => seeAllhandler(i)} sx={{ cursor: 'pointer' }}>
                        <Typography variant="p12_600" color="orange.main">
                          {v?.toggle ? ' See less' : 'See all'}
                        </Typography>
                      </Box>
                    }
                  </div>
                })
              }

              {/* <div>
                <div className="category_left_line"></div>
                <div className="mb_8px">
                  <Typography variant="p13_500" color="primaryColors.filterLeftText">
                    RAM
                  </Typography>
                </div>

                <div>
                  {allRamList.map((ram) => {
                    return <div className="category_left_brand_flex">
                      <Checkbox
                        sx={{
                          "&:hover": { bgcolor: "transparent" },
                        }}
                        disableRipple
                        color="orange"
                        name='ramVal'
                        onChange={(e) => handleFilterChange(e, ram, formFilter.ramVal)}
                      />

                      <Typography variant="p12_600" color="primaryColors.filterLeftInnerText">
                        {ram}
                      </Typography>
                    </div>
                  })}
                </div>
              </div> */}

              {/* <div>
                <div className="category_left_line"></div>
                <div className="mb_8px">
                  <Typography variant="p13_500" color="primaryColors.filterLeftText">
                    Storage
                  </Typography>
                </div>

                <div>
                  {allStorageList.map((val) => {
                    return <div className="category_left_brand_flex">
                      <Checkbox
                        sx={{
                          "&:hover": { bgcolor: "transparent" },
                        }}
                        disableRipple
                        color="orange"
                        name='storageVal'
                        onChange={(e) => handleFilterChange(e, val, formFilter.storageVal)}
                      />
                      <Typography variant="p12_600" color="primaryColors.filterLeftInnerText">
                        {val}
                      </Typography>
                    </div>
                  })}
                </div>
              </div> */}

              {/* <div>
                <div className="category_left_line"></div>
                <div className="mb_8px">
                  <Typography variant="p13_500" color="primaryColors.filterLeftText">
                    Screen Size
                  </Typography>
                </div>

                <div>
                  {allScreenSize.map((val) => {
                    return <div className="category_left_brand_flex">
                      <Checkbox
                        sx={{
                          "&:hover": { bgcolor: "transparent" },
                        }}
                        disableRipple
                        color="orange"
                        name='screenVal'
                        onChange={(e) => handleFilterChange(e, val, formFilter.screenVal)}

                      />
                      <Typography variant="p12_600" color="primaryColors.filterLeftInnerText">
                        {val}
                      </Typography>
                    </div>
                  })}
                </div>
              </div> */}

              {/* <div>
                <div className="category_left_line"></div>
                <div className="mb_8px">
                  <Typography variant="p13_500" color="primaryColors.filterLeftText">
                    Operating System
                  </Typography>
                </div>

                <div>
                  {allOperatingSystem.map((val) => {
                    return <div className="category_left_brand_flex">
                      <Checkbox
                        sx={{
                          "&:hover": { bgcolor: "transparent" },
                        }}
                        disableRipple
                        color="orange"
                        name='OSVal'
                        onChange={(e) => handleFilterChange(e, val, formFilter.OSVal)}
                      />
                      <Typography variant="p12_600" color="primaryColors.filterLeftInnerText">
                        {val}
                      </Typography>
                    </div>
                  })}
                </div>
              </div> */}

              {/* <div>
                <div className="category_left_line"></div>
                <div className="mb_8px">
                  <Typography variant="p13_500" color="primaryColors.filterLeftText">
                    Color
                  </Typography>
                </div>

                <Grid container spacing={1}>
                  {allColorList.map((val) => {
                    let e = { target: { name: 'colorVal' } }
                    return <Grid item
                      name='colorVal'
                      onClick={() => handleFilterChange(e, val.name, formFilter.colorVal)}
                    >
                      <Box className="category_left_color" sx={{ cursor: 'pointer', backgroundColor: !formFilter.colorVal.includes(val.name) ? 'primaryColors.filterColor' : 'filter.orange' }} >
                        <Box sx={{ backgroundColor: val.color, padding: '3.75px', height: '17px', width: '17px', mr: '5px', borderRadius: '2px' }}></Box>
                        <Typography variant="p12_600" color="primaryColors.filterLeftInnerText">
                          {val.name}
                        </Typography>
                      </Box>
                    </Grid>
                  })}
                </Grid>
              </div> */}
            </div>
          </div>
        </Box>
      </Box>
    </section>
  );
};

export default FilterSection;
