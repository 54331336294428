import React, { useState } from 'react'
import { Box } from '@mui/system'
import { Typography, Grid, TextField, Button } from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { addPage, checkUserNumberWatcher, loginWatcher, userPhNumber } from '../../store/actionCreators/login';
import { updateAlert } from '../../store/actionCreators/common';
import { useEffect } from 'react';
import SEO from '../../utils/SEO';
import { useRef } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function LoginDesign() {
    const { loginReducers: { page } } = useSelector(state => state)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    let passwordref = useRef()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])

    const [phoneNumber, setPhoneNumber] = useState('')
    const [isPhErr, setIsPhErr] = useState(false)
    const [isPhRegErr, setIsPhRegErr] = useState(false)
    const [password, setPassword] = useState('')
    const [isPsErr, setIsPsErr] = useState(false)
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };

    const continueFunction = () => {
        if (phoneNumber == "") {
            setIsPhErr(true)
            setIsPsErr(false)
            return
        }
        if (phoneNumber.length !== 10) {
            setIsPhRegErr(true)
            setIsPhErr(false)
            return
        }
        if (password == "") {
            setIsPhErr(false)
            setIsPsErr(true)
            setIsPhRegErr(false)
            return
        }
        let data = {
            mobileNumber: phoneNumber,
            password: password
        }
        dispatch(userPhNumber(phoneNumber))
        sessionStorage.setItem('phNumber', JSON.stringify(phoneNumber))
        dispatch(loginWatcher(data, (isExistUser) => {
            if (isExistUser[0]?.wooId) {
                localStorage.setItem("userDetails", JSON.stringify(isExistUser))
                dispatch(userPhNumber(""))
                navigate('/')
            }
            else {
                navigate('/signUp')
            }
        }))
    }

    const forgetPassword = () => {
        dispatch(addPage("login"))
        navigate("/ForgetPassword")
    }

    return (
        <>
            <SEO title='Login' description='Login Your account' />
            <section className="Login" style={{ backgroundColor: "#E5E5E5", paddingBottom: "150px" }}>
                <Grid container height="500px" width="100%" backgroundColor="primaryColors.loginBg" justifyContent='center'>
                    <Grid item xs={11} lg={4} md={6} sm={8} >
                        <Box
                            className='loginBox'
                            width='90%'
                            height="322px"
                            backgroundColor="primaryColors.loginWhite"
                            borderRadius="16px"
                            padding="25px">
                            <Grid container rowSpacing={1} >
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <Typography variant='p24_700' color="orange.main"> Login </Typography>
                                </Grid>
                                {/* <Grid item xs={1} lg={1} md={1} sm={1}>
                                    <img src="/images/close.png" alt='' />
                                </Grid> */}
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <Typography variant='p14_400' color="primaryColors.loginText" paddingTop="50px"> Enter your Registered phone number and password to continue </Typography>
                                </Grid>
                                <Grid item xs={2.8} lg={2} md={2} sm={2}>
                                    <Box width="59px" height="56px" border='1px solid rgba(230, 231, 232, 1)' borderRadius="5px" padding="10px" color="primaryColors.checkoutPointerHeader" >
                                        <Typography variant='p14_400'> +91 </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={9.2} lg={10} md={10} sm={10}>
                                    <TextField
                                        required={true}
                                        // sx={{ mt: "20px" }}
                                        size='medium'
                                        fullWidth={true}
                                        placeholder='Enter your Phone Number'
                                        label='Phone Number'
                                        color='primary'
                                        type='text'
                                        variant='outlined'
                                        value={phoneNumber}
                                        onChange={(e) => { setPhoneNumber(e.target.value); setIsPhErr(false); setIsPhRegErr(false) }}
                                        onBlur={(e) => { setPhoneNumber(e.target.value); setIsPhErr(false) }}
                                        error={isPhErr || isPhRegErr || false}
                                        helperText={isPhErr ? 'Please enter Phone Number' : (isPhRegErr ? 'Enter valid PhoneNumber' : '')}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                if (phoneNumber === "") {
                                                    setIsPhErr(true)
                                                }
                                                else if (phoneNumber.length !== 10) {
                                                    setIsPhRegErr(true)
                                                    setIsPhErr(false)
                                                }
                                                else {
                                                    setIsPhErr(false)
                                                    setIsPhRegErr(false)
                                                    passwordref.current?.focus()
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <Grid container>
                                        <Grid  xs={11} lg={11} md={11} sm={11}>
                                        <TextField
                                        required={true}
                                        // sx={{ mt: "20px" }}
                                        size='medium'
                                        fullWidth={true}
                                        placeholder='Enter your Password'
                                        label='Password'
                                        color='primary'
                                        type={showPassword ? 'text' : 'password'}
                                        variant='outlined'
                                        value={password}
                                        onChange={(e) => { setPassword(e.target.value); setIsPsErr(false) }}
                                        onBlur={(e) => { setPassword(e.target.value); setIsPsErr(false) }}
                                        error={isPsErr}
                                        helperText={isPsErr ? 'Please enter Password' : ''}
                                        inputRef={passwordref}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                if (phoneNumber === "") {
                                                    setIsPhErr(true)
                                                }
                                                else if (phoneNumber.length !== 10) {
                                                    setIsPhRegErr(true)
                                                    setIsPhErr(false)
                                                }
                                                else if (password === "") {
                                                    setIsPhErr(false)
                                                    setIsPsErr(true)
                                                    setIsPhRegErr(false)
                                                }
                                                else {
                                                    setIsPhErr(false)
                                                    setIsPhRegErr(false)
                                                    setIsPsErr(false)
                                                    continueFunction()
                                                }
                                            }
                                        }}
                                    />
                                        </Grid>
                                        <Grid  xs={1} lg={1} md={1} sm={1} display="flex" alignItems="center" justifyContent="center">
                                            <div onClick={togglePasswordVisibility} style={{cursor:"pointer"}}>
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <Box height="48px" backgroundColor="orange.main" borderRadius="5px" marginTop="5px" paddingTop="13px" onClick={continueFunction} sx={{ cursor: 'pointer' }} style={{ textAlign: 'center' }}>
                                        <Typography variant='p15_500' color="primaryColors.loginWhite" style={{ textAlign: 'center' }}> Continue </Typography>
                                    </Box>
                                    <Grid container marginTop="10px">
                                        <Grid item xs={6} lg={6} md={6} sm={6}>
                                            <Typography variant='p14_400' color="primaryColors.checkoutAccountdesc" style={{ cursor: "pointer" }} onClick={forgetPassword}> forget password </Typography>
                                        </Grid>
                                        <Grid item xs={6} lg={6} md={6} sm={6} display="flex" justifyContent="end">
                                            <Link to="/signUp"> <Button sx={{ textTransform: 'none' }}>SignUp</Button> </Link>
                                        </Grid>
                                    </Grid>
                                    <Box paddingTop="10px" style={{ textAlign: 'center' }}>
                                        <Typography variant='p14_400' color="primaryColors.checkoutAccountdesc"> By signing in you agree to our </Typography>
                                        <Link to="/termsCondition"> <Typography variant='p14_400' color="orange.main"> terms and conditions</Typography> </Link>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </section>







            {/* <Box backgroundColor="primaryColors.loginBg">
                <Box
                    backgroundColor="primaryColors.loginWhite"
                    borderRadius="16px"
                    marginTop="15pc"
                    marginBottom="10pc"
                    // marginLeft="33pc"
                    // padding="25px"
                >
                        <Grid container spacing={3} backgroundColor="primaryColors.loginWhite">

                            <Grid item xs={1} lg={4} md={3.5} sm={3} backgroundColor="primaryColors.loginBg">
                            </Grid>
                            <Grid item xs={8.5} lg={3.5} md={4.1} sm={5}>
                                <Typography variant='p24_700' color="orange.main"> Login / Signup </Typography>
                            </Grid>
                            <Grid item xs={2} lg={0.5} md={0.9} sm={1}>
                                <img src="/images/close.png" />
                            </Grid>
                            <Grid item xs={0.5} lg={4} md={3.5} sm={3} backgroundColor="primaryColors.loginBg">
                            </Grid>

                            <Grid item xs={1} lg={4} md={3.5} sm={3} backgroundColor="primaryColors.loginBg">
                            </Grid>
                            <Grid item xs={10.5} lg={4} md={5} sm={6}>
                                <Typography variant='p14_400' color="primaryColors.loginText" paddingTop="50px"> Enter your phone number to continue </Typography>
                            </Grid>
                            <Grid item xs={0.5} lg={4} md={3.5} sm={3} backgroundColor="primaryColors.loginBg">
                            </Grid>

                            <Grid item xs={1} lg={4} md={3.5} sm={3} backgroundColor="primaryColors.loginBg">
                            </Grid>
                            <Grid item xs={3} lg={0.7} md={0.8} sm={1}>
                                <Box height="56px" border='1px solid rgba(230, 231, 232, 1)' borderRadius="5px" color="primaryColors.checkoutPointerHeader" width="59px" padding="10px" marginTop="40px"> 
                                    <Typography variant='p14_400'> +91 </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={7.5} lg={3.3} md={4.2} sm={5}>
                                <TextField
                                    required={true}
                                    sx={{ mt: "40px",pr:'30px' }}
                                    // width: "340px",
                                    size='medium'
                                    fullWidth={true}
                                    placeholder='Enter your brand'
                                    label='Phone Number'
                                    color='primary'
                                    type='text'
                                    variant='outlined'
                                    value={phoneNumber}
                                    onChange={(e) => { setPhoneNumber(e.target.value); setIsPhErr(false) }}
                                    onBlur={(e) => { setPhoneNumber(e.target.value); setIsPhErr(false) }}
                                    error={isPhErr}
                                    helperText={isPhErr ? 'Please enter Phone Number' : ''}
                                />
                            </Grid>
                            <Grid item xs={0.5} lg={4} md={3.5} sm={3} backgroundColor="primaryColors.loginBg">
                            </Grid>

                            <Grid item xs={1} lg={4} md={3.5} sm={3} backgroundColor="primaryColors.loginBg">
                            </Grid>
                            <Grid item xs={10.5} lg={4} md={5} sm={6} sx={{pr: '30px'}}>
                                <Box height="48px" marginTop="30px" paddingTop="13px" backgroundColor="#F26B23" borderRadius="5px" onClick={continueFunction} sx={{ cursor: 'pointer' }} style={{textAlign : 'center' }}  >
                                    <Typography variant='p15_500' color="primaryColors.loginWhite" style={{textAlign : 'center' }} > Continue </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={0.5} lg={4} md={3.5} sm={3} backgroundColor="primaryColors.loginBg">
                            </Grid>

                            <Grid item xs={1} lg={4} md={3.5} sm={3} backgroundColor="primaryColors.loginBg">
                            </Grid>
                            <Grid item xs={10.5} lg={4} md={5} sm={6}>
                                <Box paddingTop="10px" style={{textAlign : 'center' }}>
                                    <Typography variant='p14_400' color="primaryColors.checkoutAccountdesc" > By signing in you agree to our </Typography>
                                    <Typography variant='p14_400' color="orange.main" > terms and conditions</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={0.5} lg={4} md={3.5} sm={3} backgroundColor="primaryColors.loginBg">
                            </Grid>

                        </Grid>
                </Box>
            </Box> */}
        </>
    )
} 
