// Login Watcher Actions

export const GET_MOBILE_NEW_LAUNCHES_WATCHER = "GET_MOBILE_NEW_LAUNCHES_WATCHER";
export const GET_MOBILE_RECOMMENDED_WATCHER = "GET_MOBILE_RECOMMENDED_WATCHER";
export const GET_MOBILE_BEST_BUDGET_WATCHER = "GET_MOBILE_BEST_BUDGET_WATCHER";
export const GET_PRODUCT_BRAND_WATCHER = "GET_PRODUCT_BRAND_WATCHER";
export const GET_SHOPBY_BRAND_WATCHER = "GET_SHOPBY_BRAND_WATCHER";
export const GET_SHOPBY_PRICE_WATCHER = "GET_SHOPBY_PRICE_WATCHER";
export const GET_MOBILES_TAG_CARDS_WATCHER = "GET_MOBILES_TAG_CARDS_WATCHER";

// Login Reducer Actions

export const MOBILE_NEW_LAUNCHES_LIST = "MOBILE_NEW_LAUNCHES_LIST";
export const MOBILE_RECOMMENDED_LIST = "MOBILE_RECOMMENDED_LIST";
export const MOBILE_BEST_BUDGET_LIST = "MOBILE_BEST_BUDGET_LIST";
export const PRODUCT_BRAND_LIST = "PRODUCT_BRAND_LIST";
export const SHOPBY_BRAND_LIST = "SHOPBY_BRAND_LIST";
export const SHOPBY_PRICE_LIST = "SHOPBY_PRICE_LIST";
export const ADD_MOBILES_LOADING = "ADD_MOBILES_LOADING"
export const ADD_MOBILES_TAG_CARDS = "ADD_MOBILES_TAG_CARDS"
