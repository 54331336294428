import { takeEvery, call, put, select } from "redux-saga/effects";
import { userDetails, isUser, addUserId, addAddress, addUserAddress, addPrimaryAddress } from "../actionCreators/login";
import { CHECK_USER_NUMBER, ADD_USER, VERIFY_OTP, UPDATE_USER, LOGIN, FORGOT_PASSWORD, FORGOT_PASSWORD_VERIFY_OTP, UPDATE_PASSWORD, CHECK_USER_EXIST, ADD_ADDRESS, GET_ADDRESS, UPDATE_ADDRESS, DELETE_ADDRESS } from "../actionTypes/login"
import loginService from "../../services/login"
import { cartProductIdList, getCartProductDataWatcher, getCartProductIdWatcher } from "../actionCreators/cart";
import { getWishlistProductIdWatcher } from "../actionCreators/product";
import cartService from "../../services/cart"
import { updateOpenLoader, updateAlert, updateFalseLoader } from '../actionCreators/common'


function* checkUserEffect(action) {
  try {
    yield put(updateOpenLoader())

    let res = yield call(loginService.checkUserNumber, action.payload);
    yield put(isUser(res.data));
    action.response(res.data)
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* checkIsExistEffect(action) {
  try {
    yield put(updateOpenLoader())

    let res = yield call(loginService.checkIsExist, action.payload);
    if (res.data) {
      if (res.data == "not exist") {
        action.response(res.data)
      }
      else {
        yield put(updateAlert(res.data, 'error'));
      }
    }
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}


function* addUserEffect(action) {
  try {
    yield put(updateOpenLoader())
    let res1 = yield call(loginService.checkWooUserEmail, action.payload?.email);
    if (res1?.data?.length > 0) {
      yield put(updateFalseLoader())
      yield put(updateAlert('Email is already exist', 'error'));
      return
    }
    let res = yield call(loginService.addUser, action.payload);
    // yield put(userDetails(res.data));
    if (res?.data?.message) {
      yield put(updateFalseLoader())
      yield put(updateAlert(res?.data?.message, 'error'));
      return;
    }
    action.response(res.data)
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* updateUserEffect(action) {
  try {
    yield put(updateOpenLoader())

    let res = yield call(loginService.updateUser, action.payload, action.userId);
    yield put(userDetails(res.data));
    action.response(res)
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* verifyOtpEffect(action) {
  try {
    yield put(updateOpenLoader())

    const { cartReducers: { cartWithoutLoginData } } = yield select((state) => state)

    let res = yield call(loginService.verifyOtp, action.payload);
    yield put(userDetails(res.data));
    action.response(res.status, res.data)
    yield put(addUserId(res.data[0]?.userId));
    if (cartWithoutLoginData.length) {
      let { data: cartIdData } = yield call(cartService.addBulkCart, cartWithoutLoginData, res.data[0]?.userId);
      yield put(cartProductIdList(cartIdData));
      yield put(getCartProductDataWatcher(cartIdData));
    }
    else {
      yield put(getCartProductIdWatcher(res.data[0]?.userId));
    }
    yield put(getWishlistProductIdWatcher(res.data[0]?.userId));
    yield put(updateFalseLoader())

  } catch (e) {
    console.log(e)
    const errMsg = e.response.data || e.message
    action.response(e.response.status, errMsg)
    yield put(updateFalseLoader());
    yield put(updateAlert(errMsg, 'error'));

  }
}

function* loginEffect(action) {
  try {
    yield put(updateOpenLoader())

    let res = yield call(loginService.login, action.payload);
    yield put(updateFalseLoader())
    if (res?.data?.message) {
      yield put(updateAlert(res?.data?.message, 'error'));
      return
    }
    yield put(userDetails(res.data));
    action.response(res?.data)
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || e?.message || "something went wrong", 'error'));
  }
}
function* forgotPasswordEffect(action) {
  try {
    yield put(updateOpenLoader())

    let res = yield call(loginService.forgotpassword, action.payload);
    yield put(updateFalseLoader())
    if (res?.data?.userId) {
      action.response(res?.data)
    } else if (res?.data == "not found") {
      yield put(updateAlert('mobile number does not exist!', 'error'));
    }
    else {
      yield put(updateAlert('Something Went Wrong!', 'error'));
    }
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* forgotPasswordVerifyOtpEffect(action) {
  try {
    yield put(updateOpenLoader())
    let res = yield call(loginService.verifyOtp, action.payload);
    if (res.data?.length > 0) {
      yield put(addUserId(res.data[0]?.userId))
    }
    action.response(res.status, res.data)
    yield put(updateFalseLoader())
  } catch (e) {
    console.log(e)
    const errMsg = e.response.data || e.message
    action.response(e.response.status, errMsg)
    yield put(updateFalseLoader());
    yield put(updateAlert(errMsg, 'error'));

  }
}

function* updatePasswordEffect(action) {
  try {
    yield put(updateOpenLoader())

    let res = yield call(loginService.updatepassword, action.payload);
    yield put(updateFalseLoader())
    if (res?.data?.message) {
      yield put(updateAlert('Your password updated successfully', 'success'));
      action.response(res?.data)
    } else {
      yield put(updateAlert('Something Went Wrong!', 'error'));
    }
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* updateAddressEffect(action) {
  try {
    yield put(updateOpenLoader())

    let res1 = yield call(loginService.updateAddress, action.payload, action.id);
    action.res(res1)
    let res = yield call(loginService.getAddress, action.payload.userId);
    yield put(addUserAddress(res.data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* getAddressEffect(action) {
  try {
    yield put(updateOpenLoader())

    let res = yield call(loginService.getAddress, action.userId);
    yield put(addUserAddress(res.data));
    yield put(updateFalseLoader())
    if (res.data?.length > 0) {
      yield put(addPrimaryAddress(res.data[0]))
    }
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* deleteAddressEffect(action) {
  try {
    yield put(updateOpenLoader())

    let res1 = yield call(loginService.deleteAddress, action.userId, action.id);
    action.res(res1)
    let res = yield call(loginService.getAddress, action.userId);
    yield put(addUserAddress(res.data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* addAddressEffect(action) {
  try {
    yield put(updateOpenLoader())

    let res1 = yield call(loginService.addAddress, action.payload);
    let res = yield call(loginService.getAddress, action.payload.userId);
    yield put(addUserAddress(res.data));
    action.res(res.data[0])
    yield put(updateFalseLoader())

  } catch (e) {
    console.log(e, "env");
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

export const LoginSaga = [
  takeEvery(CHECK_USER_NUMBER, checkUserEffect),
  takeEvery(ADD_USER, addUserEffect),
  takeEvery(VERIFY_OTP, verifyOtpEffect),
  takeEvery(UPDATE_USER, updateUserEffect),
  takeEvery(LOGIN, loginEffect),
  takeEvery(FORGOT_PASSWORD, forgotPasswordEffect),
  takeEvery(FORGOT_PASSWORD_VERIFY_OTP, forgotPasswordVerifyOtpEffect),
  takeEvery(UPDATE_PASSWORD, updatePasswordEffect),
  takeEvery(CHECK_USER_EXIST, checkIsExistEffect),
  takeEvery(ADD_ADDRESS, addAddressEffect),
  takeEvery(GET_ADDRESS, getAddressEffect),
  takeEvery(UPDATE_ADDRESS, updateAddressEffect),
  takeEvery(DELETE_ADDRESS, deleteAddressEffect),
]
