import { USER_DATA, IS_USER, PHONE_NUMBER, ADD_PAGE, ADD_USER_ID, ADD_USER_ADDRESS, GET_USER_ADDRESS, UPDATE_USER_ADDRESS, DELETE_USER_ADDRESS, ADD_PRIMARY_ADDRESS, SHOW_USERDATA_ADDRESS } from '../actionTypes/login'

const intialState = {
  isUserExist: [],
  userData: JSON.parse(localStorage.getItem("userDetails")) || [],
  phNumber: JSON.parse(sessionStorage.getItem('phNumber')) || '',
  page: "",
  userId: "",
  address: [],
  primaryAddress: {},
  userDataAddress: {},
};


export default function loginReducers(state = intialState, action) {
  switch (action.type) {

    case IS_USER:
      return { ...state, isUserExist: action.payload };

    case USER_DATA:
      return { ...state, userData: action.payload };

    case PHONE_NUMBER:
      return { ...state, phNumber: action.payload };

    case ADD_PAGE:
      return { ...state, page: action.payload };

    case ADD_USER_ID:
      return { ...state, userId: action.payload };

    case ADD_USER_ADDRESS:
      return { ...state, address: action.payload };

    case ADD_PRIMARY_ADDRESS:
      return { ...state, primaryAddress: action.payload };

    case SHOW_USERDATA_ADDRESS:
      return { ...state, userDataAddress: action.payload };

    default:
      return state;
  }
}
