import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primaryColors.check,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primaryColors.check,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: theme.palette.primaryColors.check,
    }),
    '& .QontoStepIcon-completedIcon': {
        color: theme.palette.primaryColors.check,
        zIndex: 1,
        fontSize: 30,
    },
    '& .QontoStepIcon-circle': {
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <CheckCircleIcon className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};


const steps = [{ label: 'Order Received', desc: 'We have received your order' },
{ label: 'Confirmed', desc: 'Your order has been confirmed' },
{ label: 'On the way', desc: 'Your orders is on its way' },
{ label: 'Delivered', desc: 'soon will be delivered' }
];

export default function CustomizedSteppers() {
    const { cartReducers: { orderByIdData } } = useSelector(state => state)
    const status = orderByIdData?.status === 'processing' ? 2 : orderByIdData?.status === 'shipped' ? 3 : orderByIdData?.status === 'delivered' ? 4 : 1

    return (
        <Stepper alternativeLabel activeStep={status} connector={<QontoConnector />}>
            {steps.map((m) => (
                <Step key={m.label}>
                    <StepLabel StepIconComponent={QontoStepIcon}>
                        <Typography variant='p16_b'> {m.label} </Typography><br />
                        <Typography variant='p12_400'> {m.desc} </Typography>
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}
