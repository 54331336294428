import React, { useState } from "react";
import { Grid, Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { addPrimarycategory, filterTagBasedProductWatcher, getCategoryData } from "../../store/actionCreators/common"
import ProductItemCard from "../ProductListItemCard"
import { Link } from "react-router-dom";

const TagCardDesign = ({ tagCardArr, categoryId }) => {

  const dispatch = useDispatch()
  const { commonReducers: { tagCardData } } = useSelector(state => state)

  const [tagClickName, setTagClickName] = useState('')
  const mapData = tagCardData.length > 0 ? tagCardData : []
  const searchPath = '/search'

  const tagClick = (categoryId, tagName) => {
    // let data = {
    //   perPage: 50,
    //   category: categoryId,
    //   name: tagName
    // }
    // console.log(data, "dt");
    // const pathName = window.location.pathname.substr(1,).toLowerCase()
    // dispatch(addPrimarycategory(pathName))
    // dispatch(filterTagBasedProductWatcher(data))
    // dispatch(getCategoryData(categoryId))
  }

  return (
    <>
      <Grid container spacing={3}>
        {tagCardArr?.map(m => {
          return <Grid item xs={12} sm={6} md={4} lg={3} key={m.image?.src} onClick={() => tagClick(m.id, m.name)} style={{ cursor: 'pointer' }}>
            <Link to={`/${m?.name?.replace(" ", "-") || "search"}?category=${m.id}`} state={{ searchVal: m?.id }}>
              <Box
                height="85px"
                backgroundColor={tagClickName === m.name ? "orange.light" : "primaryColors.productListItemBg"}
                borderRadius="8px"
                padding="18px" display='flex'>
                <Box pr='15px'>
                  <><img src={m.image?.src} alt='images/mobile/mobilestore.png' /></>
                </Box>
                <Box marginTop="15px">
                  <Typography variant='p20_600' color="primaryColors.mobileCardText"> {m.name} </Typography> <br />
                </Box>
              </Box>
            </Link>
          </Grid>
        })}
      </Grid>

      {tagClickName &&
        <Box pt='20px'>
          <section className="container products-container padding-top-none clear">
            <Grid container>
              <Grid lg={8} md={8} sm={8} xs={12}>
                <h2 className="left">
                  {tagClickName}
                </h2>
              </Grid>
              <Grid lg={4} md={4} sm={4} xs={12}>
              </Grid>
            </Grid>
          </section>

          {
            Boolean(mapData.length) ?
              <Grid container spacing={3} sx={{ pr: '20px', pl: '20px', pb: '20px' }} >
                {mapData?.map((m, i) => {
                  return <Grid item lg={3} xs={12} sm={6} md={4} key={m.id}>
                    <ProductItemCard productData={m} bgclr={'primaryColors.productListItemBg'} />
                  </Grid>
                })}
              </Grid>
              :
              <h1 style={{ textAlign: 'center' }}>No records found</h1>
          }

        </Box>
      }
    </>

  );
};

export default TagCardDesign;
