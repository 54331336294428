// Login Watcher Actions

export const GET_HOME_NEW_LAUNCHES_WATCHER = "GET_HOME_NEW_LAUNCHES_WATCHER";
export const GET_TOP_RATED_PRODUCT_WATCHER = "GET_TOP_RATED_PRODUCT_WATCHER";
export const GET_SMART_WATCH_WATCHER = "GET_SMART_WATCH_WATCHER";
export const GET_SAMSUNG_MOBILES_WATCHER = "GET_SAMSUNG_MOBILES_WATCHER";
export const GET_SHOP_BY_BRAND_WATCHER = "GET_SHOP_BY_BRAND_WATCHER";
export const GET_SHOP_BY_PRICE_WATCHER = "GET_SHOP_BY_PRICE_WATCHER";
export const GET_BEST_BUDGET_WATCHER = "GET_BEST_BUDGET_WATCHER";
export const GET_DEALS_WATCHER = "GET_DEALS_WATCHER";
export const GET_PRODUCT_CARDS_WATCHER = "GET_PRODUCT_CARDS_WATCHER";
export const GET_MOBILES_BRANDS_WATCHER = "GET_MOBILES_BRANDS_WATCHER";
export const GET_ACCESSORIES_BRANDS_WATCHER = "GET_ACCESSORIES_BRANDS_WATCHER";
export const GET_WEARABLES_BRANDS_WATCHER = "GET_WEARABLES_BRANDS_WATCHER";
export const GET_HEADPHONES_BRANDS_WATCHER = "GET_HEADPHONES_BRANDS_WATCHER";

// Login Reducer Actions

export const HOME_NEW_LAUNCHES_LIST = "HOME_NEW_LAUNCHES_LIST";
export const TOP_RATED_PRODUCT_LIST = "TOP_RATED_PRODUCT_LIST";
export const SMART_WATCH_LIST = "SMART_WATCH_LIST";
export const SAMSUNG_MOBILES_LIST = "SAMSUNG_MOBILES_LIST";
export const SHOP_BY_BRAND_LIST = "SHOP_BY_BRAND_LIST";
export const SHOP_BY_PRICE_LIST = "SHOP_BY_PRICE_LIST";
export const BEST_BUDGET_LIST = "BEST_BUDGET_LIST";
export const DEALS_LIST = "DEALS_LIST";
export const PRODUCT_CARDS_LIST = "PRODUCT_CARDS_LIST";
export const ADD_HOME_LOADING = "ADD_HOME_LOADING"
export const MOBILES_BRANDS_LIST = "MOBILES_BRANDS_LIST"
export const ACCESSORIES_BRANDS_LIST = "ACCESSORIES_BRANDS_LIST"
export const WEARABLES_BRANDS_LIST = "WEARABLES_BRANDS_LIST"
export const HEADPHONES_BRANDS_LIST = "HEADPHONES_BRANDS_LIST"