// Login Watcher Actions

export const CHECK_USER_NUMBER = "CHECK_USER_NUMBER";
export const ADD_USER = "ADD_USER";
export const VERIFY_OTP = "VERIFY_OTP";
export const UPDATE_USER = "UPDATE_USER";
export const CHECK_EMAIL = "CHECK_EMAIL";
export const LOGIN = "LOGIN";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_VERIFY_OTP = "FORGOT_PASSWORD_VERIFY_OTP";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const CHECK_USER_EXIST = "CHECK_USER_EXIST"
export const GET_ADDRESS = "GET_ADDRESS"
export const DELETE_ADDRESS = "DELETE_ADDRESS"
export const UPDATE_ADDRESS = "UPDATE_ADDRESS"
export const ADD_ADDRESS = "ADD_ADDRESS"

// Login Reducer Actions

export const IS_USER = "IS_USER";
export const USER_DATA = "USER_DATA";
export const PHONE_NUMBER = "PHONE_NUMBER";
export const ADD_PAGE = "ADD_PAGE"
export const ADD_USER_ID = "ADD_USER_ID"
export const ADD_USER_ADDRESS = "ADD_USER_ADDRESS"
export const GET_USER_ADDRESS = "GET_USER_ADDRESS"
export const DELETE_USER_ADDRESS = "DELETE_USER_ADDRESS"
export const UPDATE_USER_ADDRESS = "UPDATE_USER_ADDRESS"
export const ADD_PRIMARY_ADDRESS = "ADD_PRIMARY_ADDRESS"
export const SHOW_USERDATA_ADDRESS = "SHOW_USERDATA_ADDRESS"

