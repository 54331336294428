import React from 'react'
import { Button, Dialog, Rating, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProductReviewWatcher } from '../../store/actionCreators/product';
import CloseIcon from '@mui/icons-material/Close';

export default function ReviewDialog({ open, HandleClose }) {
  let dispatch = useDispatch()
  const { loginReducers: { userData } } = useSelector(state => (state))
  const { productReducers: { productDetailData } } = useSelector((state) => state)
  const [review, setReview] = useState(0)
  const [reviewText, setReviewText] = useState("")
  const [error, setError] = useState(false)

  const cancel = () => {
    setReview("")
    setReviewText("")
  }

  const SubmitReviewHandler = () => {
    if (reviewText === "") {
      setError(true)
    }
    else {
      setError(false)

      let data =
      {
        "product_id": productDetailData?.id,
        "review": reviewText,
        "reviewer": userData[0]?.firstName,
        "reviewer_email": userData[0]?.email,
        "rating": review,
        "verified": true

      }
      dispatch(addProductReviewWatcher(data))
      HandleClose()
    }
  }


  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={HandleClose} PaperProps={{ sx: { position: "fixed", top: "25%", display: "flex" } }}>
      <div style={{ padding: "20px", justifyContent: "center", paddingLeft: "30px", paddingRight: "30px" }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}><CloseIcon onClick={HandleClose} /></div>

        <Typography component="legend">Give Your Reviews</Typography>
        <Rating name="simple-controlled" value={review} onChange={(event, newValue) => { setReview(newValue) }} />
        <div style={{ paddingTop: "10px" }}>
          <TextField value={reviewText} fullWidth placeholder="Write a Review" onChange={(e) => {
            if (e.target.value.length > 0) {
              setError(false)
            }
            else {
              setError(true)
            }
            setReviewText(e.target.value)
          }} />
          {error && <div style={{ color: "red" }}> Review Required </div>}
          <div style={{ justifyContent: "right", display: "flex" }}>
            <Button onClick={cancel} style={{ backgroundColor: "orange", color: "white", marginTop: "15px" }}> Cancel </Button>
            <Button onClick={SubmitReviewHandler} style={{ backgroundColor: "orange", color: "white", marginLeft: "20px", marginTop: "15px" }}> Submit </Button>
          </div>
        </div>
      </div>
    </Dialog>

  )
}
