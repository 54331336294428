import { Box, Typography } from "@mui/material";
import React from "react";
import "./styles.css";
import StarIcon from "@mui/icons-material/Star";
import { Link, useNavigate } from 'react-router-dom'
import { AddWishlistProductWatcher, removeFromWishlistWatcher } from '../../store/actionCreators/product'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from "react";
import { useState } from "react";
import { updateFalseLoader, updateOpenLoader } from "../../store/actionCreators/common";
import productService from "../../services/product"
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import { getcardprimarycolor } from "../../utils/common";

const Index = ({ cl, productData }) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loginReducers: { userData }, cartReducers: { cartProductData, cartWithoutLoginData }, productReducers: { wishListProductData } } = useSelector((state) => state)
  const [price, setPrice] = useState({ sale_price: "", regular_price: "", discount: "" })
  const [productName, setProductName] = useState('')
  const [selectedVariant, setSelectedVariant] = useState('')

  const variantId = productData?.variations?.length ? productData?.variations[0] : 0

  const isSelected = wishListProductData.some(d => {
    return +d.wishListVariantId ? +d.wishListVariantId === +variantId : +d.wishListProductId === +productData?.id
  })

  const isCart = userData.length ? cartProductData.some(d => +d.id === +productData.id) : cartWithoutLoginData.some(d => +d.id === +productData.id)
  const userId = userData[0]?.userId
  const productId = productData?.id

  useEffect(() => {
    if (productData?.attributes?.length && productData?.attributes[0]?.options?.length) {
      let attribute = productData?.attributes?.filter(s => s?.variation) || []
      let getName = attribute?.length >= 2 ? attribute[0]?.options[0] + ' - ' + attribute[1]?.options[0] : attribute[0]?.options[0]
      const prodName = `${productData?.name}(${getName})`
      setProductName(prodName)

      let chooseColor = attribute[0]
      chooseColor.selected = chooseColor?.options[0]
      if (attribute?.length >= 2) {
        let chooseGB = attribute[1]
        chooseGB.selected = chooseGB?.options[0]
      }
      setSelectedVariant(attribute)
    }
    else {
      setProductName(productData?.name)
    }
    let div = document.createElement('div')
    div.innerHTML = productData?.price_html
    let price1 = ""
    let price2 = ""
    price1 = div.getElementsByClassName("woocommerce-Price-amount amount")[0]?.innerText?.split(".")[0]?.replace(/,/g, '')?.match(/(\d+)/)[0] || ""
    price2 = div.getElementsByClassName("woocommerce-Price-amount amount")[1]?.innerText?.split(".")[0]?.replace(/,/g, '')?.match(/(\d+)/)[0] || ""
  
    setPrice({
      regular_price: parseInt(price1) > parseInt(price2) ? price1 : price2,
      sale_price: parseInt(price1) > parseInt(price2) ? price2 : price1,
      discount: (100 - parseInt(parseInt(price1) > parseInt(price2) ? parseInt(price2) : price1) * 100 / parseInt(parseInt(price1) > parseInt(price2) ? price1 : price2))?.toFixed(0) || ""
    })

  }, [productData?.attributes, productData?.name])
  const imageName = (imageArr) => {
    var array = [".png", ".jpg", ".webp"];
    let url = '';

    let v = productData
    let isExist = array.find(x => x?.includes(v?.images[0]?.src || ''))
    if (isExist !== undefined) {
      let imgName = v?.images[0]?.src?.replace(new RegExp(array.join("|")), "")
      if (imgName !== undefined) {
        url = imgName + "-600x600" + isExist;
      } else {
        url = v?.images[0]?.src || "/images/Phone1.png";
      }
    } else {
      url = v?.images[0]?.src || "/images/Phone1.png";
    }
    let imgName = imageArr?.length ? imageArr[0]?.src : "/images/Phone1.png"
    return url
  }

  const findFreeProduct = () => {
    let freeproductkey = productData?.meta_data?.find(m => m.key === '_accessory_ids') || {}
    return freeproductkey.value?.length > 0
  }

  const addToFavourite = () => {
    if (!isSelected) {
      let data = {
        userId,
        productId,
        variantId
      }
      dispatch(AddWishlistProductWatcher(data, dispatch))
    }
    else {
      dispatch(removeFromWishlistWatcher(userId, productId, variantId, dispatch))
    }
  }

  const handleNavigate = async () => {
    let prodName = productData?.name
    const pathName = window.location.pathname.substr(1,).toLowerCase() || "home"
      
    if (selectedVariant?.length) {
      //route code
      if (productData?.variations?.length > 0) {
        dispatch(updateOpenLoader())
        let { data: data1, status } = await productService.getProductVariants(productData?.id)
        if (status === 200) {
          let selectedProdVariant = selectedVariant?.map(d => d.selected).join('-')
          let routeProdVariant = data1?.map(d => d.attributes?.map(f => f.option).join('-'))
          let findInd = routeProdVariant?.findIndex(v => selectedProdVariant === v) || 0
          let routePId = data1.length && data1[findInd]?.id
          //product name code
          prodName = `${productData?.name}(${selectedProdVariant})`
          setProductName(prodName)
          //final navigation
       //   window.open(`/productDetails/${productData?.id}/${routePId}/${prodName.replace(' ', "-")?.replace('/', "-")}`)
       
       navigate(`/${encodeURIComponent(pathName)}/${prodName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${productData?.id}&vid=${routePId}`)
          
      }
        dispatch(updateFalseLoader());
        // navigate(`/productDetails/${productData?.id}/${routePId}/${prodName.replace(' ', "-")}`)
      }
    }
    else {
      setProductName(prodName)
    //  window.open(`/productDetails/${productData?.id}/${0}/${prodName.replace(' ', "-")?.replace('/', "-")}`)
    navigate(`/${encodeURIComponent(pathName)}/${prodName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${productData?.id}&vid=0`)
       
  }
  }
  let cat_name = productData.categories[0]?.name || '' 
  let cat_id = productData?.meta_data?.find(m => m.key === 'epc_primary_product_cat')?.value
  let primaryCategory = cat_id !== undefined ? (productData?.categories?.find(v => v?.id == cat_id)?.name || cat_name) : cat_name

  return (
    <div className={`products-list-item ${cl?.products_grid_nwidth}`} style={{ width: '100%', height: '100%', cursor: 'pointer' }} onClick={handleNavigate}>
      <article style={{ width: '100%', height: '100%' }}>
        <>
          <Box sx={{ cursor: 'pointer', width: '100%', height: '100%' }}>
            <Box className="frame-1000002000 flex-col-hstart-vstart clip-contents">
              <Box className="frame-1000001921 flex-row-vstart-hstart" p='20px'>
                {/* <Link to={`/productDetails/${productId}`} target="_blank"> */}
                <img
                  // src="https://firebasestorage.googleapis.com/v0/b/unify-bc2ad.appspot.com/o/G6hBvXFMP81M1WvQjWNcaZ-1%3A6?alt=media&token=d6723c20-9253-49d1-bd0d-92b559110dc4"
                  src={imageName(productData?.images)}
                  alt="/images/Phone1.png"
                  className="frame-1000001918" style={{ height: '250px', width:"250px", objectFit:"scale-down", cursor: 'pointer' }} />
                {/* </Link> */}
                {findFreeProduct() &&
                  <div className="products-free-items-grid">
                    <h5 >Free</h5>
                    <img src="/images/Free-Case.svg" alt='' />
                    <img src="/images/Free-Case2.svg" alt='' />
                  </div>
                }
                <Box className="frame-1000001912 flex-col-hstart-vsb clip-contents">
                  <Box className="frame-1000002068 flex-col-hstart-vstart" style={{ paddingLeft: "15px" }}>
                    <Typography variant='p12_400' color="primaryColors.loginText">{primaryCategory}</Typography>
                    <Box className="frame-10000019181 flex-row-vstart-hsb" display='flex'>
                      <Box
                        display="flex"
                        color="primaryColors.textLight4"
                        mt="5px"
                        className="rating-grid"
                      >
                        <StarRateRoundedIcon sx={{fontSize:16, color:"#F26B23"}}/><span style={{fontSize:"14px", color:"#888888"}}>{parseInt(productData?.average_rating)}</span>
                      </Box>
                      <Box ml='auto'>
                        {Boolean(userData.length) &&
                          <img src={isSelected ? "/images/repairs/like.jpg" : "/images/OrderConfirmation/Heart.png"} onClick={(e) => { e.stopPropagation(); addToFavourite() }} style={{ height: '40px', width: '40px', cursor: 'pointer', marginRight: '5px' }} alt='' />}
                        {/* <img src="/images/OrderConfirmation/Arrow.png" alt=''/> */}
                      </Box>
                    </Box>
                    <Box className="frame-1000001920 flex-col-hstart-vstart">
                      <Box className="frame-1000002074 flex-row-vstart-hstart">
                        <Box className="frame-1000002073 flex-col-hstart-vstart">
                          <Box className="frame-1000001919 flex-row-vstart-hstart">
                            <p className="txt-954">
                              {productName}
                            </p>
                          </Box>
                        </Box>
                      </Box>

                      <div className="products-specs clear">
                        {
                          productData?.attributes?.filter(v => !v?.variation)?.map((v, i) => {
                            return (
                              <aside key={v.name}><img src={`https://dev.phonebuy.com/wp-content/uploads/2022/11/${v?.name}.png`} alt='' /><span className="attribute_break">{v?.options?.toString()}</span></aside>
                            )
                          })
                        }
                      </div>

                      {/* <div class="products-specs-grid clear">
                        <aside>
                          <img src="/images/smartphone.svg" />
                          <span>6.5 inch</span>
                        </aside>
                        <aside>
                          <img src="/images/camera.svg" />
                          <span>12 MP</span>
                        </aside>
                        <aside>
                          <img src="/images/battery.svg" />
                          <span>4373 mAh</span>
                        </aside>
                        <aside>
                          <img src="/images/signal.svg" />
                          <span>5G</span>
                        </aside>
                        <aside>
                          <img src="/images/sim-card.svg" />
                          <span>Single SIM + eSIM</span>
                        </aside>
                      </div> */}
                      <br />

                      <div>
                        <Typography variant='p24_700' color="primaryColors.mobileCardText" marginTop='10px'> ₹{price?.sale_price} </Typography>
                        <br />
                        {price?.sale_price !== "" && !isNaN(price.discount) &&
                          <Typography className="mrp-price-off" marginTop='10px'> <del style={{ paddingRight: "10px" }}>₹{price?.regular_price}</del> <strong style={{ zIndex: -1 }}>{price.discount}% Off</strong> </Typography>}
                      </div>

                      {/* <Box className="frame-10000019182 flex-row-vcenter-hstart">
                        <p className="txt-531">₹{productData?.price}</p>
                      </Box> */}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>

      </article>
    </div>
  );
};

export default Index;
