import React, { useState } from 'react'
import { Box } from '@mui/system'
import { Typography, Grid, TextField } from '@mui/material'
import { useDispatch, useSelector } from "react-redux";
import { verifyOtpWatcher, updateUserWatcher, checkUserNumberWatcher, addPage, forgotPasswordVerifyOtpWatcher } from '../../store/actionCreators/login';
import { useLocation, useNavigate } from 'react-router-dom'
import service from '../../services/login'
import { updateAlert, updateFalseLoader, updateOpenLoader } from '../../store/actionCreators/common';
import { useEffect } from 'react';
import SEO from '../../utils/SEO';
import ReactPixel from 'react-facebook-pixel';

export default function ForgotPasswordVerify() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { loginReducers: { phNumber, page }, commonReducers: { prevPath } } = useSelector(state => state)
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])

    const [otpVal, setOtpVal] = useState('')
    const [isOTPErr, setIsOTPErr] = useState(false)

    const otpResend = () => {
        let data = {
            mobileNumber: phNumber,
            isResend: true
        }
        dispatch(checkUserNumberWatcher(data, (isExistUser) => {
            if (isExistUser === 'otp sent') {
                dispatch(updateAlert('OTP sent successfully!', 'success'));
            }
            else {
                dispatch(updateAlert(isExistUser, 'error'));

            }
        }))
    }

    const otpFunction = () => {
        if (!otpVal) return setIsOTPErr(true)

        let data = {
            otp: otpVal,
            mobileNumber: phNumber
        }
        dispatch(forgotPasswordVerifyOtpWatcher(data, async (status, res) => {
            if (status === 200) {
                dispatch(addPage("forgetPassword"))
                navigate("/ForgetPassword")
            }
            else {
                setOtpVal('')
            }
        }))
    }

    const completeRegPixelHandler = async (userData, id) => {
        await ReactPixel.track('CompleteRegistration', {
            content_name: 'User Registration',
            content_category: 'Registration',
            value: 0.00,
            currency: 'USD',
            email: userData?.email,
            phone: userData?.phone,
            user_id: id
        });
        console.log({
            content_name: 'User Registration',
            content_category: 'Registration',
            value: 0.00,
            currency: 'INR',
            email: userData?.email,
            phone: userData?.phone,
            user_id: id
        }, 'signin')
    }

    return (
        <>
            <SEO title='Sign Up' description='Sign Up Your Account' />
            <section className="sectionLogin">
                <Grid container height="345px" className='otpBox' marginBottom='150px' width="100%" backgroundColor="primaryColors.loginBg" justifyContent='center'>
                    <Grid xs={11} lg={4} md={6} sm={8} >
                        <Box
                            className='loginBox otpBox'
                            width='90%'
                            height="345px"
                            backgroundColor="primaryColors.loginWhite"
                            borderRadius="16px"
                            padding="25px">
                            <Grid container rowSpacing={1} >
                                <Grid item xs={11} lg={11} md={11} sm={11}>
                                    <Typography variant='p24_700' color="orange.main"> Verify Details </Typography>
                                </Grid>
                                <Grid item xs={1} lg={1} md={1} sm={1}>
                                    <img src="/images/close.png" alt='' />
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <Typography variant='p14_400' color="primaryColors.loginText" paddingTop="50px"> OTP sent to +91 {phNumber}</Typography>
                                </Grid>
                                <Grid item xs={10} lg={10} md={10} sm={10}>
                                    <TextField
                                        required={true}
                                        sx={{ mt: "40px" }}
                                        size='medium'
                                        fullWidth={true}
                                        placeholder=' Enter OTP'
                                        label=' Enter OTP'
                                        color='primary'
                                        type='text'
                                        variant='outlined'
                                        value={otpVal}
                                        onChange={(e) => { setOtpVal(e.target.value); setIsOTPErr(false) }}
                                        onBlur={(e) => { setOtpVal(e.target.value); setIsOTPErr(false) }}
                                        error={isOTPErr}
                                        helperText={isOTPErr ? 'Please enter valid OTP' : ''}
                                        onKeyPress={(e) => {
                                            if(e.key === "Enter") {
                                                otpFunction()
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={9.9} lg={10} md={10} sm={10}>
                                    <Typography variant='p11_500' color="primaryColors.checkoutAccountdesc"> OTP has been sent successfully </Typography>
                                </Grid>
                                <Grid item xs={2} lg={2} md={2} sm={2}>
                                    <Box onClick={otpResend} sx={{ cursor: 'pointer' }}>
                                        <Typography variant='p11_500' color="orange.main"> Resend OTP </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <Box height="48px" backgroundColor="orange.main" borderRadius="5px" marginTop="30px" paddingTop="13px" onClick={otpFunction} sx={{ cursor: 'pointer' }} style={{ textAlign: 'center' }}>
                                        <Typography variant='p15_500' color="primaryColors.loginWhite" style={{ textAlign: 'center' }}> Continue </Typography>
                                    </Box>
                                    <Box paddingTop="10px" style={{ textAlign: 'center' }}>
                                        <Typography variant='p14_400' color="primaryColors.checkoutAccountdesc"> By signing in you agree to our </Typography>
                                        <Typography variant='p14_400' color="orange.main"> terms and conditions</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </section>

        </>
    )
}
