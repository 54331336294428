import React, { useEffect } from 'react'
import { Grid, TextField, Typography, Box, Button } from '@mui/material'
import { updateUserWatcher } from '../../../store/actionCreators/login'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { updateAlert } from '../../../store/actionCreators/common';


export default function ProfileContent() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const navigate = useNavigate()
    const { loginReducers: { userData } } = useSelector(state => (state))

    let lastnameref = useRef()
    let emailref = useRef()

    const formref = useRef(null)
    const dispatch = useDispatch()

    const Schema = Yup.object().shape({
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        email: Yup.string().required('Email is required'),
    });

    useEffect(() => {
        if (userData?.length > 0 && formref.current !== undefined) {
            formref.current?.setFieldValue("firstName", userData[0]?.firstName)
            formref.current?.setFieldValue("lastName", userData[0]?.lastName)
            formref.current?.setFieldValue("email", userData[0]?.email)
        }
    }, [userData])

    useEffect(() => {
        if (!userData.length) navigate('/login')
    }, [userData, navigate])

    const updatefunction = (data) => {
        const ischeck = Object.values(data).filter((x) => Object.values(userData[0]).some((y) => x === y))
        if (Object.values(data).length !== ischeck.length) {
            dispatch(updateUserWatcher(data, userData[0]?.userId, () => {
                dispatch(updateAlert('profile updated successfully!', 'success'))
            }))
        }
    }

    return (
        <div>
            <Grid>
                <Box backgroundColor="primaryColors.profileBg" padding="30px" borderRadius="3px" marginTop="30px">
                    <Typography variant='p16_700'> General Information </Typography>
                </Box>
                <Formik
                    initialValues={{
                        firstName: "",
                        lastName: "",
                        email: ""
                    }}
                    innerRef={formref}
                    validationSchema={Schema}
                    onSubmit={(values) => {
                        updatefunction(values)
                    }}
                >
                    {({ errors, touched, handleSubmit, values, setFieldValue }) => (
                        <Form>
                            <Box backgroundColor="primaryColors.loginWhite" padding="20px" borderRadius="3px">
                                <Grid container spacing={3} >
                                    <Grid item xs={12} sm={6} md={3} lg={3.5} >
                                        <TextField value={values.firstName} placeholder="FirstName" fullWidth onChange={(e) => setFieldValue("firstName", e.target.value)}
                                            error={Boolean(touched.firstName && errors.firstName)}
                                            helperText={touched.firstName && errors.firstName}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    lastnameref.current?.focus()
                                                }
                                            }} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={3.5} >
                                        <TextField value={values.lastName} placeholder="LastName" fullWidth onChange={(e) => setFieldValue("lastName", e.target.value)}
                                            error={Boolean(touched.lastName && errors.lastName)}
                                            helperText={touched.lastName && errors.lastName}
                                            inputRef={lastnameref}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    emailref.current?.focus()
                                                }
                                            }} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={3.5} >
                                        <Box >
                                            <TextField value={values.email} placeholder="Email Id" fullWidth onChange={(e) => setFieldValue("email", e.target.value)}
                                                error={Boolean(touched.email && errors.email)}
                                                helperText={touched.email && errors.email}
                                                inputRef={emailref}
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                        handleSubmit()
                                                    }
                                                }} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={1.5} >
                                        <Box justifyContent="right" display="flex">
                                            <Button onClick={handleSubmit} sx={{ display: "flex", justifyContent: "center", width: "99px", height: "48px", backgroundColor: "secondary.main", "&:hover": { color: "secondary.main", backgroundColor: "secondary.main" } }}>
                                                <Typography variant='p15_500' sx={{ color: "primaryColors.loginWhite", textTransform: "none" }} borderRadius="5px"> Save </Typography>
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Grid>
        </div>
    )
}
