import { Typography } from '@mui/material'
import React, { useEffect } from 'react'

export default function TermsCondition() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="section" style={{ margin: "80px" }}>
            <div style={{ display: "flex", justifyContent: "center", fontSize: "40px", fontFamily: "serif", padding: "20px" }}> Terms & Conditions </div>
            <p>
                These terms and conditions of use ("Terms of Use") of the Website describe the terms on which Penta Phones Private Limited, hereafter “Company”, offers you access to the Website and the Services (as defined below) through the Website.
            </p>
            <p>
                PLEASE READ THE TERMS OF USE CAREFULLY BEFORE USING OR REGISTERING ON THE WEBSITE OR ACCESSING ANY MATERIAL, INFORMATION OR SERVICES THROUGH THE WEBSITE. YOUR USE OF THE WEBSITE OR THE SERVICES PROVIDED BY THE WEBSITE SHALL SIGNIFY YOUR ACCEPTANCE OF THE TERMS OF USE AND YOUR AGREEMENT TO BE LEGALLY BOUND BY THE SAME.
            </p>
            <p>
                YOUR USE OF THE WEBSITE IMPLIES THAT YOU AGREE WITH THE TERMS OF USE.
            </p>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black", paddingTop: "10px" }}> General </Typography>
            <p style={{ paddingTop: "10px" }}>
                www.phonebuy.com is an Internet based portal owned and operated by the Company, incorporated under the laws of India, with its registered office in Chennai, India. Use of the Website is offered to You, conditioned on acceptance of all the terms, conditions and notices contained in these Terms, along with any amendments made by Company at its sole discretion and posted on the Website, including by way of imposing an additional charge for access to or use of a Service.            </p>
            <p>
                Company shall not be required to notify You, whether as a registered user or not, of any changes made to the Terms of Use. The revised Terms of Use shall be made available on the Website. Your use of the Website and the Services is subject to the most current version of the Terms of Use made available on the Website at the time of such use. You are requested to regularly visit the home page www.phonebuy.com to view the most current Terms of Use. You can determine when Company last modified the Terms of Use by referring to the "Last Updated" legend below. It shall be Your responsibility to check these Terms of Use periodically for changes. Company may require You to provide Your consent to the updated Terms of Use in a specified manner before prior to any further use of the Website and the Services is provided on the Website. If no such separate consent is sought, Your continued use of the Website, following changes to the Terms of Use, will constitute Your acceptance of those changes.
            </p>
            <p>
                By (i) using this Website or any facility or Service provided by this Website in any way; or (ii) merely browsing the Website, You agree that You have read, understood and agreed to be bound by these Terms of Use and the Website's Privacy Policy available at the homepage, www.phonebuy.com.
            </p>
            <p>
                Company agrees to deliver the merchandise ordered by you only at such locations as per the Order Confirmation Form. Company shall not be liable to deliver any merchandize or Services purchased by Users for delivery in locations outside Tamil Nadu-India shall not be entertained.
            </p>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black", paddingTop: "10px" }}> Eligibility to Use </Typography>
            <p style={{ paddingTop: "10px" }}>
                The Services are not available to minors under the age of eighteen (18) or to any Users suspended or removed from the system by Company for any reason whatsoever. If You do not confirm to the above qualification, You shall not be permitted to avail of the Services or use the Website. You represent that You are of legal age to form a binding contract and are not a person barred from receiving services under the laws as applicable in India. Notwithstanding the foregoing, if You are below the age of eighteen (18) years, You may avail the Services provided by Company through Your legal guardian in accordance with the applicable laws.            </p>
            <p>
                Company reserves the right to refuse access to use the Services offered at the Website to new Users or to terminate access granted to existing Users at any time without according any reasons for doing so.
            </p>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black", paddingTop: "10px" }}> User Account, Password and Security </Typography>
            <p style={{ paddingTop: "10px" }}>
                The Website requires You to register as a User by creating an Account in order to avail of the Services provided by the Website. You will be responsible for maintaining the confidentiality of the Account Information, and are fully responsible for all activities that occur under Your Account. You agree to (a) immediately notify Company of any unauthorized use of Your Account Information or any other breach of security, and (b) ensure that You exit from Your Account at the end of each session. Company cannot and will not be liable for any loss or damage arising from Your failure to comply with this Section 3.1. You may be held liable for losses incurred by Company or any other user of or visitor to the Website due to authorized or unauthorized use of Your Account as a result of Your failure in keeping Your Account Information secure and confidential.            </p>
            <p>
                The Website also allows restricted access to the Services for unregistered Users.
            </p>
            <p>
                You shall ensure that the Account Information provided by You in the Website's registration form is complete, accurate and up-to-date. Use of another user's Account Information for availing the Services is expressly prohibited.            </p>
            <p>
                If You provide any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or Company has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Company has the right to suspend or terminate Your Account and refuse any and all current or future use of the Website (or any portion thereof).
            </p>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black", paddingTop: "10px" }}> Pricing Information </Typography>
            <p style={{ paddingTop: "10px" }}>
                You agree to provide correct and accurate credit/ debit card details to the approved payment gateway for availing Services on the Website. You shall not use the credit/ debit card which is not lawfully owned by You, i.e. in any transaction, You must use Your own credit/ debit card. The information provided by You will not be utilized or shared with any third party unless required in relation to fraud verifications or by law, regulation or court order. You will be solely responsible for the security and confidentiality of Your credit/ debit card details. Company expressly disclaims all liabilities that may arise as a consequence of any unauthorized use of Your credit/ debit card.
            </p>
            <p>
                Company shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any transaction, on account of you/cardholder having exceeded their limit.
            </p>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black", paddingTop: "10px" }}> User Obligation </Typography>
            <p style={{ paddingTop: "10px" }}>
                Subject to compliance with the Terms of Use, Company grants You a non-exclusive, limited privilege to access and use this Website and the Services provided therein.
            </p>
            <p>
                You agree to use the Services, Website and the materials provided therein only for purposes that are permitted by: (a) the Terms of Use; and (b) any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions.
            </p>
            <p>
                You agree not to access (or attempt to access) the Website and the materials or Services by any means other than through the interface that is provided by the Company. You shall not use any deep-link, robot, spider or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Website or Content (as defined below), or in any way reproduce or circumvent the navigational structure or presentation of the Website, materials or any Content, to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the Website.
            </p>
            <p>
                You agree that Company may, at any time, modify or discontinue all or part of the Website, charge, modify or waive fees required to use the Website, or offer opportunities to some or all Website Users.
            </p>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black", paddingTop: "10px" }}> Use of Materials </Typography>
            <p style={{ paddingTop: "10px" }}>
                Except as expressly indicated to the contrary in any applicable Additional Terms, Company hereby grants You a non-exclusive, freely revocable (upon notice from Company), non-transferable access to view, download and print product catalogues or any other materials available on the Website, subject to the following conditions:
            </p>
            <div style={{ padding: "10px 30px" }}>
                <p>
                    •	You may access and use the materials solely for personal, informational, and internal purposes, in accordance with the Terms of Use;
                </p>
                <p>
                    •	You may not modify or alter product catalogues or any other materials available on the Website;
                </p>
                <p>
                    •	You may not distribute or sell, rent, lease, license or otherwise make the product catalogues or any other materials available on the Website available to others; and
                </p>
                <p>
                    •	You may not remove any text, copyright or other proprietary notices contained in the product catalogues or any other materials available on the Website.
                </p>
            </div>
            <p>
                The rights granted to You in the product catalogues or any other materials as specified above are not applicable to the design, layout or look and feel of the Website. Such elements of the Website are protected by intellectual property rights and may not be copied or imitated in whole or in part. The product catalogues or any other materials available on the Website may be copied or retransmitted unless expressly permitted by Company
            </p>
            <p>
                Any purchase of the merchandise or Services from the Website will be strictly for personal use of the User. The User hereby expressly agrees that any merchandize or Services purchased by the User will not be sold, resold, bartered or in any way used for any commercial purposes or for profit. The User hereby acknowledges that the Services or merchandize purchased is not transferrable to any third party for profit.
            </p>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black", paddingTop: "10px" }}> Usage Conduct </Typography>
            <p style={{ paddingTop: "10px" }}>
                You shall solely be responsible for maintaining the necessary computer equipment and Internet connections that may be required to access, use and transact on the Website.
            </p>
            <p>
                You are also under an obligation to use this Website for reasonable and lawful purposes only, and shall not indulge in any activity that is not envisaged through the Website.
            </p>
            <p>
                You shall use this Website, and any voucher/ coupons purchased through it, for personal, non-commercial use only and shall not re-sell the same to any other person.
            </p>
            <p>
                Company shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any transaction, on account of you/cardholder having exceeded their credit limit.
            </p>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black", paddingTop: "10px" }}> Standard delivery Timeline </Typography>
            <p style={{ paddingTop: "10px" }}>
                Cash on Delivery option available
            </p>
            <p>
                Estimated time of delivery 3-4 days from the date of technically and commercially clear order and acceptance.
            </p>
            <p>
                Dispatch of goods will be through reputed couriers. Most of the items are shipped within 24-48 working hours after the payment is cleared. Delivery of goods will be made only on weekdays and working days. No delivery during weekends, holidays and after office hours.
            </p>
            <p>
                Estimated deliver may vary for each product and shipment.
            </p>
            <p>
                Delivery of all orders will be done to registered address of the Buyer.
            </p>
            <p>
                Specific Delivery timelines and commitments given in the product page take precedence over the standards set herein.
            </p>
            <p>
                Company has rights to cancel an order under any circumstance.
            </p>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black", paddingTop: "10px" }}> Card/EMI related terms </Typography>
            <div style={{ padding: "10px 30px" }}>
                <p>
                    •	In case of refund, though the amount is credited back to the customer’s card, the EMI closure will only happen after customer communicates the same to the bank for the same. Customer will specifically need to communicate to the banks the intent of the EMI loan closure.
                </p>
                <p>
                    •	Also if customer fails to communicate the same to bank, the interest amount that bank will keep levying will be borne by the customer.
                </p>
                <p>
                    •	Foreclosure or any other charges, if any which are levied by the customer’s bank, will need to be borne by the customer.
                </p>
            </div>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black", paddingTop: "10px" }}> Violation of Terms of Use </Typography>
            <p style={{ paddingTop: "10px" }}>
                You agree that Company may, in its sole discretion and without prior notice, terminate Your access to the Website and block Your future access to the Website if Company determines that You have violated these Terms of Use or Additional Terms. You also agree that any violation by You of these Terms of Use will constitute an unlawful and unfair business practice, and will cause irreparable harm to Company, for which monetary damages would be inadequate, and You consent to Company obtaining any injunctive or equitable relief that Company deems necessary or appropriate in such circumstances. These remedies are in addition to any other remedies Company may have at law or in equity.
            </p>
            <p>
                You agree that Company may, in its sole discretion, and without prior notice, terminate Your access to the Website, for cause, which includes (but is not limited to): (1) requests by law enforcement or other government agencies; (2) a request by You (self-initiated account deletions); (3) discontinuance or material modification of the Website or any service offered on or through the Website; or (4) unexpected technical issues or problems.
            </p>
            <p>
                If Company does take any legal action against You as a result of Your violation of these Terms of Use, Company will be entitled to recover from You, and You agree to pay, all reasonable attorneys' fees and costs of such action, in addition to any other relief granted to Company
            </p>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black", paddingTop: "10px" }}> Termination </Typography>
            <p style={{ paddingTop: "10px" }}>
                The Terms of Use will continue to apply until terminated by either You or Company as set forth below. If You want to terminate Your agreement with Company, You may do so by (i) not accessing the Website; or (ii) closing Your accounts for all of the Services that You use, where Company has made this option available to You.
            </p>
            <p>
                Company may, at any time, with or without notice, terminate the Terms of Use (or portion thereof, such as any individual Additional Terms) with You if:
            </p>
            <p>
                You breach any of the provisions of the Terms of Use, the Privacy Policy or any other terms, conditions, or policies that may be applicable to You from time to time (or have acted in a manner that clearly shows that You do not intend to, or are unable to, comply with the same); Company is required to do so by law (for example, where the provision of the Services to You is, or becomes, unlawful); The provision of the Services to You by Company is, in Company's opinion, no longer commercially viable; Company has elected to discontinue, with or without reason, access to the Website, the Services (or any part thereof); or
            </p>
            <p>
                Company may also terminate or suspend all or a portion of Your account or access to the Services with or without reason. Except as may be set forth in any Additional Terms applicable to a particular Service, termination of Your Account may include: (i) removal of access to all offerings within the Website or with respect to the Services; (ii) deletion of Your materials and Account Information, including Your personal information, log-in ID and password, and all related information, files and materials associated with or inside Your Account (or any part thereof); and (iii) barring of further use of the Services.
            </p>
            <p>
                You agree that all terminations shall be made in Company's sole discretion and that Company shall not be liable to You or any third party for any termination of Your Account (and acCompanying deletion of Your Account Information), or Your access to the Website and Services.
            </p>
            <p>
                If You or Company terminates Your use of the Website, Company may delete any content or other materials relating to Your use of the Website and Company will have no liability to You or any third party for doing so.
            </p>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black", paddingTop: "10px" }}> Governing Law </Typography>
            <p style={{ paddingTop: "10px" }}>
                These Terms of Use and all transactions entered into on or through the Website and the relationship between You and Company shall be governed in accordance with the laws of India without reference to conflict of laws principles.
            </p>
            <p>
                You agree that all claims, differences and disputes arising under or in connection with or in relation hereto the Website, the Terms of Use or any transactions entered into on or through the Website or the relationship between You and Company shall be subject to the exclusive jurisdiction of the courts at Chennai, Tamil Nadu, India and You hereby accede to and accept the jurisdiction of such courts.
            </p>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black", paddingTop: "10px" }}> Feedback and Information </Typography>
            <p style={{ paddingTop: "10px" }}>
                Any feedback You provide to this Website shall be deemed to be non-confidential. Company shall be free to use such information on an unrestricted basis. Further, by submitting the feedback, You represent and warrant that (i) Your feedback does not contain confidential or proprietary information of You or of third parties; (ii) Company is not under any obligation of confidentiality, express or implied, with respect to the feedback; (iii) Company may have something similar to the feedback already under consideration or in development; and (iv) You are not entitled to any compensation or reimbursement of any kind from Company for the feedback under any circumstances.
            </p>
        </div>
    )
}
