import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SEO from '../../../utils/SEO'

export default function CartEmpty() {
  const navigate = useNavigate()

  const { loginReducers: { userData },cartReducers: { cartProductData, cartWithoutLoginData } } = useSelector(state => state)

  const cartCount = userData.length ? cartProductData.length : cartWithoutLoginData.length

  useEffect(() => {
    if (cartCount){
      navigate('/cart')
    }
  }, [cartCount])

  useEffect(() => {
    console.log("carttest")
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <>
    <SEO title='Cart Empty' description='Your Cart is empty'/>
    <section className="section">
      <Grid container height="100%" width="100%" justifyContent='center' marginTop='8pc' marginBottom='8pc'>
        <Grid item xs={1} lg={3} md={2} sm={2}></Grid>

        <Grid item xs={10} lg={6} md={8} sm={8}>
          <Box style={{ textAlign: 'center' }}>
            <img src="/images/OrderConfirmation/Empty.webp" alt=''/>
            <br />
            <Typography variant="p24_700" style={{ textAlign: 'center' }}>
              Cart Is Empty
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={1} lg={3} md={2} sm={2}></Grid>

      </Grid>
    </section>
    </>
  )
}
