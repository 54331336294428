import { Button, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addPage } from '../../store/actionCreators/login'

export default function LoginRedirect() {
  const navigate = useNavigate()
  let dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const loginHandler = () => {
    dispatch(addPage("contact"))
    navigate('/login')
  }

  return (
    <section className="section" style={{ margin: "80px 0px" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src="/images/login.avif" alt='' />
      </div>
      <Typography style={{ textAlign: "center", margin: "30px 0px", fontSize: "26px" }}> Kindly Login to contact Us </Typography>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={loginHandler} sx={{ color: "primaryColors.loginWhite", backgroundColor: "secondary.main", "&:hover": { backgroundColor: "secondary.main" } }}> Login </Button>
      </div>
    </section>
  )
}
