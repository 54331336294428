import React, { useEffect } from 'react'
import { Typography, Grid, Box, useMediaQuery } from '@mui/material'
import SideBar from '../sideBar'
import AddressessDesign from './addressessDesign'
import { Link } from 'react-router-dom'
import SmallSideBar from '../smallSideBar'
import SEO from '../../../utils/SEO'

export default function Index() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <>
      <SEO title='Address' description='Address' />
      <section className='sectionOrderMenu'>
        <Box backgroundColor="primaryColors.loginBg">
          <Box display='flex' backgroundColor="primaryColors.loginWhite" color="primaryColors.textPath" p='80px 0 10px 50px'>
            <Typography sx={{ color: 'primaryColors.breadcrumbs', '&:hover': { textDecoration: 'underline' }, paddingRight: '10px' }} to='/' component={Link} variant="p14_500"> Home </Typography>
            <Typography variant="p13_500" sx={{ color: 'primaryColors.cartText', paddingRight: '10px' }}> &gt; </Typography>
            <Typography variant="p14_500" sx={{ color: 'grey[200]' }}> Addresses </Typography>
          </Box>

          <Grid container>
            <Grid item xs={12} sm={12} md={2.8} lg={2.6} padding="27px 20px 0px 32px">
              <Box>
                {matches ? <SideBar /> : <SmallSideBar />}
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={9.2} lg={9.4} padding="40px 40px 40px 35px">
              <Box>

                <Typography variant="p24_700"> Address </Typography>
                <br />
                <br />

                <Box>
                  <AddressessDesign />
                </Box>

              </Box>
            </Grid>
          </Grid>
        </Box>
      </section>
    </>
  )
}
