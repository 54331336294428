import { SHOP_BY_BRAND_LIST, SHOP_BY_PRICE_LIST, TABLETS_NEW_LAUNCHES_LIST } from "../actionTypes/tablets";

const intialState = {
    newLaunchesList: [],
    ShopByBrandList: [],
    ShopByPriceList: []
};


export default function tabletsReducers(state = intialState, action) {
    switch (action.type) {

        case TABLETS_NEW_LAUNCHES_LIST:
            return { ...state, newLaunchesList: action.payload };

        case SHOP_BY_BRAND_LIST:
            return { ...state, ShopByBrandList: action.payload };

        case SHOP_BY_PRICE_LIST:
            return { ...state, ShopByPriceList: action.payload };

        default:
            return state;
    }
}