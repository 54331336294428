import { Typography, Box } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'


export default function SideBar() {
  const location = useLocation()
  const { loginReducers: { userData } } = useSelector((state) => state)
  const selectedTab = location.pathname

  return (
    <div>

      <Box pb='20px'>
        <Box paddingBottom="20px">
          <Typography variant="p22_600" color="primaryColors.cartText"> Hello, {userData[0]?.firstName} </Typography>
        </Box>
        <Link to="/orders" >
          <Typography sx={{ backgroundColor: (selectedTab === "/orders") && "secondary.main", borderRadius: "5px" }} paddingTop="10px" paddingBottom="10px" color={(selectedTab === "/orders" || selectedTab === "") ? "primaryColors.textWhite" : "primaryColors.cartText"} paddingLeft="15px" id="defaultOpen"> Orders </Typography>
        </Link>

        <Link to="/mobile-repair-and-services" >
          <Typography sx={{ backgroundColor: selectedTab === "/mobile-repair-and-services" && "secondary.main", borderRadius: "5px" }} paddingTop="10px" paddingBottom="10px" color={selectedTab === "/mobile-repair-and-services" ? "primaryColors.textWhite" : "primaryColors.cartText"} paddingLeft="15px"> Repair & Services </Typography>
        </Link>

        <Link to="/addresses"  >
          <Typography sx={{ backgroundColor: selectedTab === "/addresses" && "secondary.main", borderRadius: "5px" }} paddingTop="10px" paddingBottom="10px" color={selectedTab === "/addresses" ? "primaryColors.textWhite" : "primaryColors.cartText"} paddingLeft="15px"> Address </Typography>
        </Link>

        <Link to="/wishlist" >
          <Typography sx={{ backgroundColor: selectedTab === "/wishlist" && "secondary.main", borderRadius: "5px" }} paddingTop="10px" paddingBottom="10px" color={selectedTab === "/wishlist" ? "primaryColors.textWhite" : "primaryColors.cartText"} paddingLeft="15px"> Wishlist </Typography>
        </Link>

        <Link to="/profilepage" >
          <Typography sx={{ backgroundColor: selectedTab === "/profilepage" && "secondary.main", borderRadius: "5px" }} paddingTop="10px" paddingBottom="10px" color={selectedTab === "/profilepage" ? "primaryColors.textWhite" : "primaryColors.cartText"} paddingLeft="15px"> Profile </Typography>
        </Link>

      </Box>
    </div>
  )
}
