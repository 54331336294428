import { combineReducers } from "redux";
import mobileReducers from "./mobile.js";
import accessoriesReducer from "./accessories";
import wearablesReducer from "./wearables";
import commonReducers from "./common";
import loginReducers from "./login";
import productReducers from "./product";
import homeReducer from "./home";
import cartReducers from "./cart";
import orderReducers from "./order.js";
import headPhonesReducers from "./headPhones.js";
import tabletsReducers from "./tablets.js";

export default combineReducers({
  mobileReducers,
  accessoriesReducer,
  wearablesReducer,
  commonReducers,
  productReducers,
  homeReducer,
  loginReducers,
  cartReducers,
  orderReducers,
  headPhonesReducers,
  tabletsReducers,
});