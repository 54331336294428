import React from "react";
import InputBase from "@mui/material/InputBase";
import "./styles.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import GridViewIcon from "@mui/icons-material/GridView";
import ProductListItemCard from "../../components/ProductListItemCard";
import ProductListItemGrid from "../../components/ProductListItemGrid";
import { Box, Typography, Grid, Button, Slide, Dialog, IconButton } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux'
import { addPrimarycategory, addProductCategory, filterAttributeProductList, filterBasedProductWatcher, filterTagBasedProductWatcher } from "../../store/actionCreators/common";
import SplitscreenIcon from '@mui/icons-material/Splitscreen'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import SEO from "../../utils/SEO";
import { productArr } from "../../utils/common";
import MobilesCards from "./mobilesCards";
import AccessoriesCards from "./accessoriesCards";
import WearablesCards from "./wearablesCards";
import HeadPhonesCards from "./headphonesCards";

const minDistance = 10;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid filter.grey",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 11,
    // lineHeight: 19,
    /* identical to box height, or 173% */
    textTransform: "uppercase",
    color: "primaryColors.mobileCardText",
    // "&:focus": {
    //   borderRadius: 4,
    //   borderColor: "#80bdff",
    //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    // },
  },
}));

const ProductBrandListCard = () => {
  const location = useLocation()
  const params = useParams();
  const { mobileReducers: { productBrandList } } = useSelector(state => state)
  console.log(productBrandList, "prodddt");
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(searchParams.get("tag"), params, "location");
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const smlfilter = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const topview = useMediaQuery((theme) => theme.breakpoints.down('480'));
  const dispatch = useDispatch()
  const { commonReducers: { filterBasedProductData, searchProductData, searchData, meta_title, meta_description, searchText } } = useSelector(state => state)
  console.log(params?.tag_name, "searchData")

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const Transition = React.forwardRef(function Transition(props, ref) { return <Slide direction="up" ref={ref} {...props} />; });

  const useStyles = makeStyles((theme) => { const appbarHeight = 64; return { root: { top: `${appbarHeight}px !important` } }; });
  const classes = useStyles()
  return (
    <div className="sectionHome" style={{ margin: "80px 20px" }}>
      <div style={{ display: "flex", justifyContent: "center", backgroundColor: "black", height: "50px", alignItems: "center" }}>
        <Typography variant="p24_700" style={{ color: "white", textTransform:"capitalize" }}>{params?.tag_name || ""} </Typography>
      </div>
      {(meta_title || meta_description) &&
        <SEO title={meta_title} description={meta_description} />}
      <section style={{ paddingTop: "30px" }}>

        {(searchParams.get("category") == 15 || !searchParams.get("category")) &&
          <Box m='0px 30px 0px 30px'>
            <MobilesCards categoryId={15} brand={searchParams.get("brand") || searchData?.name || params?.tag_name || ""} />
          </Box>}

        {(searchParams.get("category") == 249 || !searchParams.get("category")) &&
          <Box m='0px 30px 0px 30px'>
            <AccessoriesCards categoryId={249} brand={searchParams.get("brand") || searchData?.name || params?.tag_name || ""} />
          </Box>}

        {(searchParams.get("category") == 756 || !searchParams.get("category")) &&
          <Box m='0px 30px 0px 30px'>
            <WearablesCards categoryId={756} brand={searchParams.get("brand") || searchData?.name || params?.tag_name || ""} />
          </Box>}

        {(searchParams.get("category") == 377 || !searchParams.get("category")) &&
          <Box m='0px 30px 0px 30px'>
            <HeadPhonesCards categoryId={377} brand={searchParams.get("brand") || searchData?.name || params?.tag_name || ""} />
          </Box>}

      </section>
    </div>
  );
};

export default ProductBrandListCard;
