import { all } from "redux-saga/effects";
import { AccessoriesProductSaga } from "./accessories";
import { MobileCategorySaga } from "./mobile";
import { WearablesProductSaga } from "./wearables";
import { CommonSaga } from "./common";
import { LoginSaga } from "./login";
import { ProductSaga } from "./product";
import { HomeSaga } from "./home";
import { CartSaga } from "./cart";
import { OrderSaga } from "./order";
import { HeadPhonesProductSaga } from "./headPhones";
import { tABLETSProductSaga } from "./tablets";

export default function* rootSaga() {
  yield all([
    ...MobileCategorySaga,
    ...AccessoriesProductSaga,
    ...WearablesProductSaga,
    ...CommonSaga,
    ...ProductSaga,
    ...HomeSaga,
    ...LoginSaga,
    ...CartSaga,
    ...OrderSaga,
    ...HeadPhonesProductSaga,
    ...tABLETSProductSaga
  ]);
}
