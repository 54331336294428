import http from "../http-common"
import { WOOCOMMERCE_SERVICES } from "../http-common"

class headPhonesService {

    getAllHeadPhonesNewLaunchesList(data) {
        return http.get(`/wp-json/wc/v3/products?category=${377}&min_price=1&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getAllHeadPhonesShopByBrandList(data) {
        return http.get(`/wp-json/wc/v3/brands?per_page=100&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getAllHeadPhonesShopByPriceList(data) {
        return http.get(`/wp-json/wc/v3/brands?per_page=100&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }
}

export default new headPhonesService();