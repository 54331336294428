import React from 'react'
import { Box } from '@mui/material'
import TagCardDesign from "../../components/common/tagCards"
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import NewLaunches from '../../components/Tablets/newLaunches'
import ShopByBrand from '../../components/mobiles/shopByBrand'
import ShopByPrice from '../../components/mobiles/shopByPrice'
import { getTabletsNewLaunchesWatcher } from '../../store/actionCreators/tablets'
import { getProductBrandWatcher } from '../../store/actionCreators/mobile'
import TopDeals from '../../components/Accessories/topDeals'
import { getProductCardsWatcher } from '../../store/actionCreators/home'
import { useLocation } from 'react-router-dom'
import BannerSlideindex from '../../components/Slider'

export default function Tablets() {

  const dispatch = useDispatch()
  const location = useLocation()
  
  // const { mobileReducers: { productBrandList } } = useSelector((state) => state)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    let data = {
      pageNO: 1,
      perPage: 4
    }
    let Dealdata = {
      pageNO: 1,
      perPage: 3
    }
    dispatch(getTabletsNewLaunchesWatcher(data))
    dispatch(getProductBrandWatcher(data))
    dispatch(getProductCardsWatcher(Dealdata))
  }, [dispatch])

  const tagCardArr = [{ tagId: 383, tagName: 'New In Town', imgSrc: '/images/Accessories/mobilestore.png' },
  { tagId: 434, tagName: `Today's Deals`, imgSrc: '/images/Accessories/badge.png' },
  { tagId: 385, tagName: ' Best Loved Brands', imgSrc: '/images/Accessories/notifications.png' },
  { tagId: 386, tagName: 'Top Reviewed', imgSrc: '/images/Accessories/rating.png' },
  { tagId: 387, tagName: 'Best Saving', imgSrc: '/images/Accessories/wallet(1).png' },
  { tagId: 388, tagName: 'Half Price Sale', imgSrc: '/images/Accessories/cut.png' },
  { tagId: 389, tagName: 'Shopping Combo', imgSrc: '/images/Accessories/shoppingbag.png' },
  { tagId: 391, tagName: ' New Launches', imgSrc: '/images/Accessories/wallet(2).png' }]

  const priceArr = [{ id: 1, min: '5,000', max: '20,000', type: 'between' },
  { id: 2, min: '20,000', max: '40,000', type: 'between' },
  { id: 3, min: '40,000', max: '60,000', type: 'between' },
  { id: 4, min: '60,000', max: '80,000', type: 'between' },
  { id: 5, min: '80,000', max: '1,00,000', type: 'between' },
  { id: 6, min: '1,00,000', max: '2,00,000', type: 'between' }]

  return (
    <>
      <Box m='30px 0' className='section'>
        {/* <section class="section sectionCard"> */}
        <Box m='100px 30px 0 30px'>
          <TagCardDesign tagCardArr={tagCardArr} categoryId={294} />
        </Box>

        <Box>
          <BannerSlideindex />
        </Box>

        {/* {Boolean(ProductCardsList.length) && */}
        <Box>
          <TopDeals />
        </Box>
        {/* } */}

        {/* {Boolean(newLaunchesList.length) && */}
        <Box m='0 30px 20px 30px'>
          <NewLaunches />
        </Box>
        {/* } */}

        {/* {Boolean(productBrandList.length) && */}
        <Box m='20px 30px 0 30px'>
          <ShopByBrand categoryName={'tablets'} categoryId={294} page='tablets' />
        </Box>
        {/* } */}

        <Box m='0px 30px 0 30px'>
          <ShopByPrice priceArr={priceArr} categoryId={294} page='tablets' />
        </Box>
        {/* </section> */}
      </Box>
    </>
  )
}