var hasNumber = /\d/;

export const filterAttributes = (results) => {
  console.log(results, "result");
  return new Promise(async (resolve, reject) => {
    if (results?.length > 0) {
      let brands_arr = []
      let Color_attributes = [];
      let RAM_attributes = [];
      let Storage_attributes = [];
      let attributes = [];
      await results?.forEach((v) => attributes = [...attributes, ...v?.attributes]);

      results?.forEach(v => {
        if (v?.brands?.length > 0) {
          v?.brands?.map(b => {
            brands_arr = [...brands_arr, b?.name];
          })
        }
      })

      await attributes?.filter(x => x?.name?.toLowerCase()?.includes("color"))?.forEach((v) => {
        console.log(v?.options.filter(x => hasNumber.test(x)));
        Color_attributes = [...Color_attributes, ...v?.options]
      })

      await attributes?.filter(x => x?.name?.toLowerCase()?.includes("ram"))?.forEach((v) => {
        //  RAM_attributes = [...RAM_attributes, ...v?.options]
        v?.options?.forEach(s => {
          console.log(s);
          let matches = s?.match(/\d+/g);
          if (matches !== null && matches?.length > 0) {
            matches?.forEach(m => {
              if (parseInt(m) >= 32) {
                Storage_attributes?.push(`${m}GB`)
              } else {
                RAM_attributes.push(`${m}GB`)
              }
            })
          }

        })

      })

      await attributes?.filter(x => x?.name?.toLowerCase()?.includes("storage"))?.forEach((v) => {
        v?.options?.forEach(s => {
          let matches = s?.match(/\d+/g);
          if (matches !== null && matches?.length > 0) {
            matches?.forEach(m => {
              if (parseInt(m) >= 32) {
                Storage_attributes?.push(`${m}GB`)
              } else {
                RAM_attributes.push(`${m}GB`)
              }
            })
          }

        })
      })

      let color = { name: "color", top4: [...new Set(Color_attributes)].slice(0, 4), alloptions: [...new Set(Color_attributes)], toggle: false, options: [...new Set(Color_attributes)].slice(0, 4) };
      let Ram = { name: "Ram", top4: [...new Set(RAM_attributes)].slice(0, 4), alloptions: [...new Set(RAM_attributes)], toggle: false, options: [...new Set(RAM_attributes)].slice(0, 4) };
      let storage = { name: "Storage", top4: [...new Set(Storage_attributes)].slice(0, 4), alloptions: [...new Set(Storage_attributes)], toggle: false, options: [...new Set(Storage_attributes)].slice(0, 4) };
      let brands = { name: "Brand", top4: [...new Set(brands_arr)].slice(0, 4), alloptions: [...new Set(brands_arr)], toggle: false, options: [...new Set(brands_arr)].slice(0, 4) };
      let filter_Attributes = [brands, Ram, storage, color];

      resolve(filter_Attributes);

    }
  })

}