import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AddCartProductWatcher, updateCartProductWatcher } from '../../store/actionCreators/cart'
import { AddWishlistProductWatcher, removeFromWishlistWatcher } from '../../store/actionCreators/product'
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const Screenwise = ({ productData, removeFunction, pageType }) => {
console.log(productData,"productdetail");
    const matches = useMediaQuery((theme) => theme.breakpoints.up('380'));

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { loginReducers: { userData }, productReducers: { wishListProductData }, cartReducers: { cartProductData } } = useSelector((state) => state)
    const [qty, setQty] = useState(1)
    const isSelected = pageType === "profileOrder" ? wishListProductData.some(d => +d.wishListVariantId ? +d.wishListVariantId === +productData?.variation_id : +d.wishListProductId === +productData?.product_id) : wishListProductData.some(d => d.id === productData.id)
    const userId = userData[0]?.userId
    const productId = productData?.cartProductId !== undefined ? productData?.cartProductId : productData?.id
    const [productName, setProductName] = useState('')
    const isCart = cartProductData.some(d => d?.id === productData?.product_id)

    useEffect(() => {
        setQty(productData?.quantity)
    }, [productData?.quantity])

    useEffect(() => {
        let prodName = productData?.name
        //route code
        if (pageType === "profileOrder") {
            if (productData?.meta_data?.length) {
                let attributes = productData?.meta_data
                //product name code
                prodName = `${productData?.name}(${attributes?.map(d => d?.display_value).join('-')})`
                setProductName(prodName)
            }
            else {
                setProductName(prodName)
            }
        }
        else {
            if (productData?.attributes?.length) {
                let attributes = productData?.attributes
                //product name code
                prodName = `${productData?.name}(${attributes?.map(d => d?.option).join('-')})`
                setProductName(prodName)
            }
            else {
                setProductName(prodName)
            }
        }
    }, [productData, pageType])


    const imageName = (imageArr) => {
        let imgName = imageArr?.length ? imageArr[0]?.src : "/images/Phone1.png"
        return imgName
    }

    const qtyChange = (e) => {
        setQty(e.target.value)

        let data = {
            quantity: e.target.value
        }
        dispatch(updateCartProductWatcher(data, userId, productData?.cartProductId, productData?.cartVariantId, dispatch))
    }
    const removefunction = (e) => {
        if (pageType === "wishList") {
            let userId = userData[0]?.userId
            let productId = productData?.wishListProductId
            dispatch(removeFromWishlistWatcher(userId, productId, productData?.wishListVariantId, dispatch))
        }
        else {
            removeFunction(productData)
        }
    }

    const addToCart = (e) => {
        e.stopPropagation()
        let userId = userData[0]?.userId
        let productId = productData?.id
        if (userData.length) {
            if (!isCart) {
                let data =
                {
                    userId,
                    productId,
                    quantity: "1",
                    variantId: +productData?.wishListVariantId
                }
                dispatch(AddCartProductWatcher(data, dispatch))
                navigate('/cart')
            }
        }
    }

    const addToFavourite = (e) => {
        if (!isSelected) {
            let data =
            {
                userId,
                productId: pageType === "profileOrder" ? productData?.product_id : productId,
                variantId: pageType === "profileOrder" ? productData?.variation_id : productData?.cartVariantId
            }
            dispatch(AddWishlistProductWatcher(data, dispatch))
        }
        else {
            dispatch(removeFromWishlistWatcher(userId, pageType === "profileOrder" ? productData?.product_id : productId, pageType === "profileOrder" ? productData?.variation_id : productData?.cartVariantId, dispatch))
        }
    }

    const handleNavigate = async () => {
        let prodName = productData?.name
        const pathName = window.location.pathname.substr(1,).toLowerCase() || "home"
        if (pageType === "profileOrder") {
            if (productData?.meta_data?.length) {
                let attributes = productData?.meta_data

                //product name code
                prodName = `${productData?.name}(${attributes?.map(d => d?.display_value).join('-')})`
                setProductName(prodName)

                //final navigation
                // window.open(`/productDetails/${productData?.product_id}/${productData?.variation_id}/${prodName.replace(' ', "-")?.replace('/', "-")}`)
                navigate(`/${encodeURIComponent(pathName)}/${prodName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${productData?.wishListProductId !== undefined ? productData?.wishListProductId : productId}&vid=${productData?.wishListVariantId !== undefined ? productData?.wishListVariantId : productData?.cartVariantId}`, { replace: true })
            }
            else {
                setProductName(prodName)
                // window.open(`/productDetails/${productData?.product_id}/${0}/${prodName.replace(' ', "-")?.replace('/', "-")}`)
                navigate(`/${encodeURIComponent(pathName)}/${prodName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${productData?.wishListProductId !== undefined ? productData?.wishListProductId : productId}&vid=0`, { replace: true })
            }
        }
        else {

            //route code
            if (productData?.attributes?.length) {
                let attributes = productData?.attributes

                //product name code
                prodName = `${productData?.name}(${attributes?.map(d => d?.option).join('-')})`
                setProductName(prodName)

                //final navigation
                //      window.open(`/productDetails/${productData?.wishListProductId !== undefined ? productData?.wishListProductId : productId}/${productData?.wishListVariantId !== undefined ? productData?.wishListVariantId : productData?.cartVariantId}/${prodName.replace(' ', "-")?.replace('/', "-")}`)

                navigate(`/${encodeURIComponent(pathName)}/${prodName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${productData?.wishListProductId !== undefined ? productData?.wishListProductId : productId}&vid=${productData?.wishListVariantId !== undefined ? productData?.wishListVariantId : productData?.cartVariantId}`, { replace: true })

            }
            else {
                setProductName(prodName)
                //    window.open(`/productDetails/${productData?.wishListProductId !== undefined ? productData?.wishListProductId : productId}/${0}/${prodName.replace(' ', "-")?.replace('/', "-")}`)
                navigate(`/${encodeURIComponent(pathName)}/${prodName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${productData?.wishListProductId !== undefined ? productData?.wishListProductId : productId}&vid=0`, { replace: true })

            }
        }
    }
    let cat_name = productData?.categories?.length > 0 ? productData?.categories[0]?.name : ''
    let cat_id = productData?.meta_data?.find(m => m.key === 'epc_primary_product_cat')?.value || productData?.meta_data?.find(m => m.key === 'epc_primary_product_cat')?.value
    let primaryCategory = cat_id !== undefined ? (productData?.categories?.find(v => v?.id == cat_id)?.name || cat_name) : cat_name

    return (
        <>
            <Box sx={{ cursor: 'pointer' }} onClick={handleNavigate}>
                <Box className="frame-1000002000 flex-col-hstart-vstart clip-contents" style={{ paddingLeft: pageType === "placeOrder" ? "20px" : "0px" }}>
                    <Box className="frame-1000001921 flex-row-vstart-hstart" padding={pageType === "cart" && "20px"} display="flex" flexDirection="column" width={pageType === "wishList" ? "95%" : "90%"} margin={pageType === "wishList" && "20px 20px 0px 20px"}>
                        <div style={{ display: "flex", flexDirection: "row", marginTop: pageType === "profileOrder" ? "30px" : "0px", marginBottom: pageType === "profileOrder" ? "30px" : "0px" }}>
                            <img
                                src={pageType === "profileOrder" ? productData?.image?.src : imageName(productData?.images)}
                                alt="/images/Phone1.png"
                                className={matches ? "frame-10000019180" : "frame-100000191801"} />
                            {pageType !== ("placeOrder") && <Box display="flex" flexDirection="column" >
                                {Boolean(userData.length) &&
                                    pageType !== "wishList" && <img src={isSelected ? "/images/repairs/like.jpg" : "/images/OrderConfirmation/Heart.png"} onClick={(e) => { e.stopPropagation(); addToFavourite() }} style={{ height: '40px', width: '40px', cursor: 'pointer', margin: "5px" }} alt='' />}
                            </Box>}
                        </div>

                        <Box className="frame-1000001912 flex-col-hstart-vsb clip-contents" height='250px' padding={pageType === "placeOrder" ? "15px" : "14px"}>
                            <Box className="frame-1000002068 flex-col-hstart-vstart">

                                {pageType === "cart" && <Box className="frame-10000019181 flex-row-vstart-hsb">
                                    <Box className="frame-1000002068 flex-col-hstart-vstart">
                                        <p className="txt-4101">{primaryCategory}</p>
                                        <Box>
                                            <StarRateRoundedIcon sx={{ fontSize: 16, color: "#F26B23" }} /><span style={{ fontSize: "14px", color: "#888888" }}>{parseInt(productData?.average_rating)}</span>
                                        </Box>
                                    </Box>

                                </Box>}

                                {pageType === "wishList" && <Box className="frame-10000019181 flex-row-vstart-hsb">
                                    <Box className="frame-1000002068 flex-col-hstart-vstart">
                                        <p className="txt-4101">{primaryCategory}</p>
                                        <Box>
                                            <StarRateRoundedIcon sx={{ fontSize: 16, color: "#F26B23" }} /><span style={{ fontSize: "14px", color: "#888888" }}>{parseInt(productData?.average_rating)}</span>
                                        </Box>
                                    </Box>

                                </Box>}

                                <Box className="frame-1000001920 flex-col-hstart-vstart">
                                    <Box className="frame-1000002074 flex-row-vstart-hstart">
                                        <Box className="frame-1000002073 flex-col-hstart-vstart">
                                            <Box className="frame-1000001919 flex-row-vstart-hstart">
                                                <p className="txt-954">
                                                    {productName}
                                                </p>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <div className="products-specs clear" style={{ padding: "10px 0px" }}>
                                        {
                                            productData?.product_attributes?.filter(v => !v?.variation)?.map((v, i) => {
                                                return (
                                                    <aside key={v.name}><img src={`https://dev.phonebuy.com/wp-content/uploads/2022/11/${v?.name}.png`} alt='' /><span className="attribute_break">{v?.options?.toString()}</span></aside>
                                                )
                                            })
                                        }
                                    </div>
                                    <Box className="frame-10000019182 flex-row-vcenter-hstart" marginBottom="10px">
                                        <p className="txt-531">₹{Math.round(productData?.price)}</p>
                                    </Box>
                                </Box>
                            </Box>
                            {pageType !== "profileOrder" && <Box className="frame-1000002023 flex-row-vend-hsb-screenwise" mt='auto'>
                                {Boolean(userData.length) &&
                                    pageType !== 'wishList' && <Box className="frame-1000002068 flex-col-hstart-vstart" minWidth='60px'>
                                        <Typography variant='p11_500' >Quantity</Typography>
                                        <select name="Quantity" id="Quantity" value={qty} onClick={(e) => e.stopPropagation()} onChange={(e) => { e.stopPropagation(); qtyChange(e) }} style={{ width: '45px', height: '35px', marginTop: '10px', cursor: 'pointer' }}>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                        </select>
                                    </Box>
                                }
                                {pageType === 'wishList' &&
                                    <Button className="frame-1000002071 flex-row-vcenter-hcenter" sx={{ cursor: 'pointer', margin: "5px 5px 5px 40px", height: "40px", width: "130px", backgroundColor: "orange.main", "&:hover": { color: "orange.main", backgroundColor: "orange.main"} }} onClick={addToCart}>
                                        <LocalMallIcon color='white' className='trash'/>
                  <h1 className="txt-278" style={{color:"white"}}> Movetocart </h1>
                                    </Button>
                                }
                                <Button className="frame-1000002071 flex-row-vcenter-hcenter" sx={{ cursor: 'pointer', height: "40px", width: "130px", backgroundColor: "orange.main", "&:hover": { color: "orange.main", backgroundColor: "orange.main"} }} onClick={(e) => { e.stopPropagation(); removefunction(productData.id) }}>
                                    <DeleteForeverIcon color='white' className='trash'/>
                  <h1 className="txt-278" style={{color:"white"}}> RemoveItem </h1>
                                </Button>
                            </Box>}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Screenwise