import React, { useEffect } from 'react'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import { Link, useNavigate } from 'react-router-dom'
import SideBar from '../sideBar'
import RepairDesign from '../../RepairAndServices/repairPage'
import SmallSideBar from '../smallSideBar'
import { useSelector } from 'react-redux'
import SEO from '../../../utils/SEO'

export default function Index() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const navigate = useNavigate()

  const { loginReducers: { userData } } = useSelector((state) => state)

  useEffect(() => {
    if (!userData.length) navigate('/login')
  }, [userData, navigate])

  return (
    <>
      <SEO title='Repair and Services' description='Repair and Services' />
      <section className="sectionOrderMenu">
        <Box backgroundColor="primaryColors.loginBg" paddingBottom="30px">
          <Box display='flex' backgroundColor="primaryColors.loginWhite" color="primaryColors.textPath" p='80px 0 10px 50px'>
            <Typography sx={{ color: 'primaryColors.breadcrumbs', '&:hover': { textDecoration: 'underline' }, paddingRight: '10px' }} to='/' component={Link} variant="p14_500"> Home </Typography>
            <Typography variant="p13_500" sx={{ color: 'primaryColors.cartText', paddingRight: '10px' }}> &gt; </Typography>
            <Typography variant="p14_500" sx={{ color: 'grey[200]' }}> Repair & Services  </Typography>
          </Box>
          <Grid container>
            <Grid item xs={12} sm={12} md={2.8} lg={2.6} padding="27px 20px 0px 32px">
              <Box>
                {matches ? <SideBar /> : <SmallSideBar />}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9.2} lg={9.4} padding="40px 35px 40px 30px">
              <Typography variant="p24_700"> Repair & Services </Typography>

              <Box paddingBottom="20px"
                backgroundColor="primaryColors.loginWhite"
                borderRadius="8px">
                <RepairDesign pageType='profile' breadCrumbs='hide' />
              </Box>

            </Grid>
          </Grid>
        </Box>
      </section>
    </>
  )
}