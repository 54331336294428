import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addTotal, applyCouponCodeWatcher, getDeliveryChargeWatcher } from '../../store/actionCreators/cart'
import { useState } from 'react'
import { updateAlert } from '../../store/actionCreators/common';
import { useEffect } from 'react'

const OrderSummary = ({ pageType }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { cartReducers: { couponCodeData, cartProductData, cartWithoutLoginData, total, deliveryCharge }, loginReducers: { userData }, productReducers: { productDetailData} } = useSelector(state => state)
    console.log(!isNaN(parseInt(productDetailData?.regular_price) - parseInt(productDetailData?.price)) && (parseInt(productDetailData?.regular_price) - parseInt(productDetailData?.price)),"savingsummary");

    const cartData = userData.length ? cartProductData : cartWithoutLoginData
    console.log("delivery",cartData)

    const [couponCode, setCouponCode] = useState('')
    const [couponErr, setCouponErr] = useState(false)
    const [deliveryAmt, setdeliveryAmt] = useState(0)

    const applyFunc = () => {
        if (couponCode) {
            dispatch(applyCouponCodeWatcher(couponCode))
        }
        else {
            setCouponErr(true)
        }
    }

    const sumOfdisc = () => {
        return cartData?.reduce((a,b) => {
            let qty = b.quantity || 1
            let price = +b.regular_price -b.price
            return +a + +price * qty
             }, 0 )
    }

    useEffect(() => {
    dispatch(getDeliveryChargeWatcher())
    }, [])

    useEffect(() => {
        if(cartItemTotalFunc() < 2500) {
            let filterMobileCategoryProduct = cartData?.filter((v) => {
                    console.log(v?.categories?.some((s) => s?.name === "Mobiles"),"deli amt",v)
                    return v?.categories?.some((s) => s?.name === "Mobiles")
                })
                if(filterMobileCategoryProduct?.length>0) {
                    setdeliveryAmt(0)
                }
                else{
                    setdeliveryAmt(deliveryCharge)
                }
        }
        else{
            setdeliveryAmt(0)
        }
    },[cartData,deliveryCharge])

    useEffect(() => {
        if (couponCode !== '' && couponCodeData.length === 0) {
            dispatch(updateAlert('Invalid Coupon Code!', 'warning'));
            setCouponCode('')
        }
    }, [couponCodeData, dispatch])

    const CouponFunc = (e) => {
        setCouponErr(false)
        setCouponCode(e.target.value)
    }

    const cartItemTotalFunc = () => {
        return cartData?.reduce((a, b) => {
            let qty = b.quantity || 1
            let price = +b.price * qty
            return +a + +price
        }, 0)
    }

    const discountFunct = () => {
        return couponCodeData.length ? +couponCodeData[0].amount : 0
        
    }

    const cartTotalFunc = () => {
        return cartItemTotalFunc() - discountFunct() + deliveryAmt
    }
   let grandTotal = cartTotalFunc()
    useEffect(() => {
     if(grandTotal){
        dispatch(addTotal(grandTotal))
     }
    }, [grandTotal] )

    const checkoutNavigation = () => {
        if (!cartData?.length) {
            return dispatch(updateAlert('Your cart is empty!', 'warning'))
        }
        else {
            navigate('/checkout')
        }
    }

    return (
        <>
            <Box sx={{ backgroundColor: "primaryColors.loginWhite", borderRadius: "8px" }} p={pageType === 'productCart' ? '2px' : '20px'}>
                <Box sx={{ flexGrow: 1 }}>
                    <Box style={{ fontSize: 22, fontWeight: "bolder", paddingTop: "17px", paddingLeft: "15px" }}> Order Summary </Box>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Stack direction='row' m='10px'>
                                <TextField sx={{ mr: '10px', height: '' }}
                                    fullWidth
                                    onChange={CouponFunc}
                                    value={couponCode}
                                    label='Coupon code'
                                    placeholder='Enter Coupon code'
                                    error={couponErr}
                                    helperText={couponErr ? 'Enter coupon code' : ''}
                                    onKeyPress={(e)=>{
                                        if(e.key === "Enter") {
                                            applyFunc()
                                        }
                                    }}
                                >Enter Coupon Code</TextField>
                                <Box sx={{ height: "55px", width: "150px", textAlign: "center", backgroundColor: "secondary.main", borderRadius: "5px", "&:hover": { backgroundColor: "secondary.main" } }}>
                                    <Button onClick={applyFunc}
                                        sx={{ fontSize: 15, width: "150px", height: '55px', backgroundColor: "secondary.main", color: "primaryColors.loginWhite", borderRadius: "5px", alignItems: 'center', display: 'flex', justifyContent: 'center', cursor: 'pointer', textTransform: 'none' }}>
                                        <Typography color='primaryColors.textWhite'>Apply</Typography> </Button>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={7}>
                            <Box style={{ fontSize: 14, paddingLeft: "15px", textAlign: "left" }}>Cart Total</Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box style={{ fontSize: 14, paddingLeft: "15px", textAlign: "right" }}>₹ {cartItemTotalFunc()}</Box>
                        </Grid>
                        <Grid item xs={7}>
                            <Box style={{ fontSize: 14, paddingLeft: "15px", textAlign: "left" }}>Delivery Charges</Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box style={{ fontSize: 14, paddingLeft: "15px", textAlign: "right" }}>₹ {deliveryAmt}</Box>
                        </Grid>
                        {/* <Grid item xs={7}>
                            <Box style={{ fontSize: 14, paddingLeft: "15px", textAlign: "left" }}> Tax </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box style={{ fontSize: 14, paddingLeft: "15px", textAlign: "right" }}>₹ {deliveryAmt}</Box>
                        </Grid> */}
                        <Grid item xs={7}>
                            <Box style={{ fontSize: 14, paddingLeft: "15px", textAlign: "left" }}>Discounts</Box>
                        </Grid>
                        <Grid item xs={4}>
                            {/* <a href="#/" > */}
                                <Box style={{ fontSize: 14, paddingLeft: "15px", textAlign: "right" }} color="secondary.main">₹ {sumOfdisc()}</Box>
                            {/* </a> */}
                        </Grid>
                        <section className='Ln'></section>
                        <Grid item xs={pageType === "productCart" ? 4 : 7}>
                            <Box style={{ paddingLeft: "15px", textAlign: "left", fontSize: 23, fontWeight: "bolder" }}>Total</Box>
                        </Grid>
                        <Grid item xs={pageType === "productCart" ? 7 : 4}>
                            <Box style={{ paddingLeft: "15px", textAlign: "right", fontSize: 23, fontWeight: "bolder" }}>₹ {cartTotalFunc()}</Box>
                        </Grid>
                    </Grid>
                    {pageType === 'checkOut' &&
                        <Box onClick={checkoutNavigation} sx={{ height: "48px", width: "auto", textAlign: "center", backgroundColor: "secondary.main", margin: "20px", borderRadius: "4px", "&:hover": { backgroundColor: "secondary.main" } }}>
                            <Button sx={{ color: "primaryColors.loginWhite", textTransform: "none", fontSize: "18px", height: "48px", width: "100%", "&:hover": { backgroundColor: "secondary.main" } }}> Checkout </Button>
                        </Box>
                    }
                    {pageType === 'productCart' &&
                        <Box sx={{ height: "44px", width: "50%", textAlign: "center", marginLeft: "25%", marginTop:"10px", backgroundColor: "secondary.main", borderRadius: "4px", "&:hover": { backgroundColor: "secondary.main" } }}>
                            <Button onClick={checkoutNavigation} sx={{ color: "primaryColors.loginWhite", textTransform: "none", fontSize: "18px", height: "48px", width: "100%", "&:hover": { backgroundColor: "secondary.main" } }}> Checkout </Button>
                        </Box>
                    }
                </Box>
            </Box>
        </>
    )
}

export default OrderSummary