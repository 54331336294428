import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import "./Cart.css";
import { Box } from "@mui/system";
import CartItemIndex from "../../components/cart/cartItemIndex";
import BestBudget from "../../components/mobiles/bestBudget"
import OrderSummary from "../../components/cart/orderSummary";
import { useDispatch, useSelector } from "react-redux";
import { getMobileBestBudgetWatcher } from "../../store/actionCreators/mobile"
import { Link, useNavigate } from "react-router-dom"
import SEO from "../../utils/SEO";
import { getCartProductIdWatcher } from "../../store/actionCreators/cart";

export default function CartIndex() {
  const navigate = useNavigate()
  const { loginReducers: { userData }, cartReducers: { cartProductData, cartWithoutLoginData }, commonReducers: { isLoader } } = useSelector(state => state)

  useEffect(() => {
    if (userData.length) {
      let userId = userData[0].userId
      dispatch(getCartProductIdWatcher(userId, dispatch))
    }
    window.scrollTo(0, 0)
  }, [])

  const dispatch = useDispatch()

  const cartCount = userData.length ? cartProductData.length : cartWithoutLoginData.length
  useEffect(() => {
    let data = {
      pageNO: 1,
      perPage: 4
    }
    dispatch(getMobileBestBudgetWatcher(data))
  }, [dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const pathName = window.location.pathname.substr(1,).toLowerCase()
    console.log(pathName,"pathname");
    // let timeout = setTimeout(() => {
      console.log(cartCount,isLoader,!isLoader,cartCount == 0 && !isLoader,"cartcount");
      if (cartCount == 0 && pathName === "cart") {
        console.log("cartempty");
        navigate("/CartEmpty")
      }
    // }, 2000)
  }, [cartCount])

  return (
    <>
      <SEO title='Cart' description='Your Cart' />
      <Box className="Bg section">
        <Box display='flex' backgroundColor="primaryColors.loginWhite" color="primaryColors.textPath" p='0px 0 10px 50px'>
          <Typography sx={{ color: 'primaryColors.breadcrumbs', '&:hover': { textDecoration: 'underline' }, paddingRight: '10px' }} to='/' component={Link} variant="p14_500"> Home </Typography>
          <Typography variant="p13_500" sx={{ color: 'primaryColors.cartText', paddingRight: '10px' }}> &gt; </Typography>
          <Typography variant="p14_500" sx={{ color: 'grey[200]' }}> Cart </Typography>
        </Box>

        <section>
          <Box display='flex' m='20px 0 20px 50px'>
            <Typography sx={{ fontSize: 20, fontWeight: "bolder", color: 'primaryColors.cartText' }}> Cart </Typography>
            <Typography sx={{ mt: '3px' }}> {`(${cartCount} items)`} </Typography>
          </Box>
        </section>

        <Grid container spacing={2} pr='20px' pl='40px' >
          <Grid item xs={12} lg={7}>
            <CartItemIndex pageType='cart' />
          </Grid>

          <Grid item xs={12} lg={5} pb='20px'>
            <OrderSummary pageType='checkOut' />
          </Grid>
        </Grid>
      </Box>
      <Box m='30px'>
        <BestBudget />
      </Box>
    </>
  );
}
