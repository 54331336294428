import { takeEvery, call, put } from "redux-saga/effects";
import wearablesService from "../../services/wearables";
import { GET_APPLE_WATCH_WATCHER, GET_NOISE_WATCH_WATCHER, GET_SAMSUNG_WATCH_WATCHER, GET_WEARABLES_NEW_LAUNCHES_WATCHER, GET_WEARABLES_TAG_CARDS_WATCHER } from "../actionTypes/wearables"
import { addWearablesTagCards, appleWatchList, noiseWatchList, samsungWatchList, wearablesNewLaunchesList } from "../actionCreators/wearables"
import { updateOpenLoader, updateAlert, updateFalseLoader } from '../actionCreators/common'
import commonService from '../../services/common'

function* newLaunchesListEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(
      wearablesService.getAllWearablesNewLaunchesList,
      action.payload
    );

    yield put(wearablesNewLaunchesList(data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* appleWatchEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(
      wearablesService.getAllWearablesAppleWatchList,
      action.payload
    );

    yield put(appleWatchList(data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* noiseWatchEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(
      wearablesService.getAllWearablesNoiseWatchList,
      action.payload
    );

    yield put(noiseWatchList(data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* samsungWatchEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(
      wearablesService.getAllWearablesSamsungWatchList,
      action.payload
    );

    yield put(samsungWatchList(data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* getWearablesCategoryTagEffect(action) {
  try {
    yield put(updateOpenLoader())
    let { data } = yield call(commonService.getCategoryByTagIdData, action.payload);
    yield put(addWearablesTagCards(data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

export const WearablesProductSaga = [
  takeEvery(GET_WEARABLES_NEW_LAUNCHES_WATCHER, newLaunchesListEffect),
  takeEvery(GET_APPLE_WATCH_WATCHER, appleWatchEffect),
  takeEvery(GET_NOISE_WATCH_WATCHER, noiseWatchEffect),
  takeEvery(GET_SAMSUNG_WATCH_WATCHER, samsungWatchEffect),
  takeEvery(GET_WEARABLES_TAG_CARDS_WATCHER, getWearablesCategoryTagEffect)
];