import { TAG_CARD_LIST, TAG_CARD_WATCHER, ADD_SERVICE_AND_REPAIR_WATCHER, REPAIR_AND_SERVICE_LIST, PREV_PATH_NAME, OPEN_LOADER, FALSE_LOADER, UPDATE_ALERT, UPDATE_ALERT_CLOSE, FILTER_BASED_PRODUCT_WATCHER, FILTER_BASED_PRODUCT_LIST, CONTACT_US_WATCHER, CONTACT_US_RES, FILTER_TAG_BASED_PRODUCT_WATCHER, FILTER_ATTRIBUTE_VARIATIONS, FILTER_ATTRIBUTE_VARIATIONS_LIST, SET_SEARCH_DATA, PAGINATION_VIEW, ADD_PRODUCT_CATEGORY, ADD_PRIMARY_CATEGORY, ADD_META_TAG_DATA, GET_CATEGORY_DATA_WATCHER, GET_TAG_DATA_WATCHER, ADD_SEARCH_TEXT } from "../actionTypes/common"

// Worker triggering actionCreators

export function tagCardWatcher(data) {
  return { type: TAG_CARD_WATCHER, payload: data };
}

export function repairAndServiceWatcher(data, res) {
  return { type: ADD_SERVICE_AND_REPAIR_WATCHER, payload: data, res };
}

export function filterBasedProductWatcher(data) {
  return { type: FILTER_BASED_PRODUCT_WATCHER, payload: data };
}

export function filterTagBasedProductWatcher(data) {
  return { type: FILTER_TAG_BASED_PRODUCT_WATCHER, payload: data };
}

export function contactUsWatcher(data, response) {
  return { type: CONTACT_US_WATCHER, payload: data, response };
}


// Redux state changing actionCreators

export function tagCardList(data) {
  return { type: TAG_CARD_LIST, payload: data };
}

export function repairAndServiceList(data) {
  return { type: REPAIR_AND_SERVICE_LIST, payload: data };
}

export function prevPathName(data) {
  return { type: PREV_PATH_NAME, payload: data };
}

export function updateOpenLoader() {
  return { type: OPEN_LOADER, payload: true };
}

export function updateFalseLoader() {
  return { type: FALSE_LOADER, payload: false };
}

export function updateAlert(message, color) {
  return { type: UPDATE_ALERT, message, color  };
}

export function updateAlertClose() {
  return { type: UPDATE_ALERT_CLOSE };
}

export function filterBasedProductList(data) {
  return { type: FILTER_BASED_PRODUCT_LIST, payload: data };
}

export function filterAttributeProductList(data) {
  return { type: FILTER_ATTRIBUTE_VARIATIONS_LIST, payload: data };
}

export function contactUsResponse(data) {
  return { type: CONTACT_US_RES, payload: data };
}

export function setSearchData(data) {
  return { type: SET_SEARCH_DATA, payload: data };
}

export function paginationView(data) {
  return { type: PAGINATION_VIEW, payload: data };
}

export function addProductCategory(data) {
  return { type: ADD_PRODUCT_CATEGORY, payload: data}
}

export function addPrimarycategory(data) {
  return { type: ADD_PRIMARY_CATEGORY, payload: data}
}

export function addMetaTagData(data) {
  return { type: ADD_META_TAG_DATA, payload: data}
}

export function getCategoryData(data) {
  return { type: GET_CATEGORY_DATA_WATCHER, payload: data}
}

export function getTagData(data) {
  return { type: GET_TAG_DATA_WATCHER, payload: data}
}

export function addSearchText(data) {
  return { type: ADD_SEARCH_TEXT, payload: data}
}