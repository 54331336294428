import { Button, Dialog, Divider, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { contactUsWatcher } from '../../store/actionCreators/common'
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { useRef } from 'react';

export default function ContactUs() {

    const dispatch = useDispatch()
    const [modal, setModal] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    let mobileref = useRef()
    let emailref = useRef()
    let commentref = useRef()

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const Schema = Yup.object().shape({
        userName: Yup.string().required('User Name is required'),
        mobile: Yup.string().matches(phoneRegExp, 'Mobile number is not valid').required('Mobile number is required').min(10,'Enter valid Mobile Number').max(10,'Enter valid Mobile Number'),
        email: Yup.string('Enter your Email').email('Email must be a valid email address').required('Email is required'),
        comment: Yup.string().required('Comment is required'),
    });


    const formik = useFormik({
        initialValues: {
            userName: '',
            mobile: '',
            email: '',
            comment: ''
        },

        validationSchema: Schema,

        onSubmit: () => {
            let { userName, mobile, comment, email } = { ...formik.values }
            let data = {
                "userName": userName,
                "mobile": mobile,
                "comment": comment,
                "email": email
            }
            dispatch(contactUsWatcher(data, res => {
                console.log(res, "res")
                if (res?.data === "Mail sent successfully") {
                    setModal(true)
                    console.log("Mail sent successfully")
                }
                resetForm()
            }))
        }
    });

    const handleClose = () => {
        setModal(false)
    }

    const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik;
console.log(errors,"cuerror");
    return (
        <section className="section">
            <Dialog open={modal}>
                <Box height="100px" width="300px" margin="10px">
                    <Box display="flex" justifyContent="end">
                        <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
                    </Box>
                    <Typography style={{ fontWeight: "bolder", fontSize: "20px", display: "flex", justifyContent: "center" }}> Thank you </Typography>
                    <Typography style={{ margin: "5px" }}> we have received your email, we will contact you shortly. </Typography>
                </Box>
            </Dialog>
            <div style={{ margin: "80px" }}>
                <div style={{ display: "flex", justifyContent: "center", fontSize: "46px", fontFamily: "serif", padding: "20px" }}> Contact Us </div>
                <Grid container spacing={3}>
                    <Grid item lg={7} md={7} sm={12} xs={12}>
                        <div style={{ fontSize: "28px", paddingBottom: "20px" }}> Leave us a Message </div>
                        <Divider />

                        <div>
                            {/* <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}> */}

                                    <h2>  Name: </h2>
                                    <TextField placeholder="Enter Name" fullWidth required
                                        label='User Name'
                                        {...getFieldProps("userName")}
                                        error={Boolean(touched.userName && errors.userName)}
                                        helperText={touched.userName && errors.userName} 
                                        onKeyPress={(e) => {
                                          if(e.key === "Enter") {
                                            mobileref.current?.focus()
                                          }
                                        }}/>

                                    <h2> Mobile: </h2>
                                    <TextField placeholder="Enter Mobile Number" fullWidth required
                                        label='Mobile'
                                        {...getFieldProps("mobile")}
                                        error={Boolean(touched.mobile && errors.mobile)}
                                        helperText={touched.mobile && errors.mobile}
                                        inputRef={mobileref} 
                                        onKeyPress={(e) => {
                                            if(e.key === "Enter"){
                                                emailref.current?.focus()
                                            }
                                        }}/>

                                    <h2>Email: </h2>
                                    <TextField placeholder="Enter Email" fullWidth required
                                        label='Email'
                                        {...getFieldProps("email")}
                                        error={Boolean(touched.email && errors.email)}
                                        helperText={touched.email && errors.email} 
                                        inputRef={emailref}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                commentref.current?.focus()
                                            }
                                        }}/>

                                    <h2> Comment: </h2>
                                    <TextField placeholder="Comment" fullWidth required multiline minRows={4}
                                        label='Comment'
                                        {...getFieldProps("comment")}
                                        error={Boolean(touched.comment && errors.comment)}
                                        helperText={touched.comment && errors.comment}
                                        inputRef={commentref}
                                    />

                                    <Button onClick={handleSubmit} style={{ backgroundColor: "orange", width: "100%", color: "white", padding: "10px", marginTop: "20px", fontSize: "18px" }}> Submit </Button>
                                {/* </Form>
                            </FormikProvider> */}
                        </div>
                    </Grid>

                    <Grid item lg={4} md={4} sm={12} xs={12}>

                        <div style={{ fontSize: "28px", paddingBottom: "20px" }}> Our Address </div>
                        <Divider style={{ marginBottom: "20px" }} />
                        <p> 150, Velachery Main Road, Pallikaranai, </p>
                        <p> Chennai – 600100. </p>
                        <p> Tamil Nadu, India. </p>

                        <div style={{ fontSize: "28px", paddingBottom: "20px", paddingTop: "20px" }}> Support </div>
                        <Divider style={{ marginBottom: "20px" }} />
                        <p>Phone : <a href="https://api.whatsapp.com/send?phone=+918270050505&amp;text=Hi,%20I%27m%20interested" target="_blank" rel="noreferrer"> +91-82700 50505 </a> </p>
                        <p>Email : <a href="mailto:support@phonebuy.com"> support@phonebuy.com </a></p>
                    </Grid>
                </Grid>
            </div>
        </section>
    )
}
