import { ADD_WEARABLES_TAG_CARDS, APPLE_WATCH_LIST, NOISE_WATCH_LIST, SAMSUNG_WATCH_LIST, SHOP_BY_BRAND_LIST, SHOP_BY_PRICE_LIST, WEARABLES_NEW_LAUNCHES_LIST } from "../actionTypes/wearables";


const intialState = {
    newLaunchesList: [],
    AppleWatchList: [],
    NoiseWatchList: [],
    SamsungWatchList: [],
    ShopByBrandList: [],
    ShopByPriceList: [],
    WearablesTagCards: []
};


export default function wearablesReducer(state = intialState, action) {
    switch (action.type) {
  
      case WEARABLES_NEW_LAUNCHES_LIST:
        return { ...state, newLaunchesList: action.payload };

      case APPLE_WATCH_LIST:
        return { ...state, AppleWatchList: action.payload };

      case NOISE_WATCH_LIST:
        return { ...state, NoiseWatchList: action.payload };

      case SAMSUNG_WATCH_LIST:
        return { ...state, SamsungWatchList: action.payload };

      case SHOP_BY_BRAND_LIST:
        return { ...state, ShopByBrandList: action.payload };
    
      case SHOP_BY_PRICE_LIST:
        return { ...state, ShopByPriceList: action.payload };

      case ADD_WEARABLES_TAG_CARDS:
        return { ...state, WearablesTagCards: action.data };

        default:
      return state;
  }
}
