import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddWishlistProductWatcher, removeFromWishlistWatcher } from '../../store/actionCreators/product'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'


const ProductDesign = ({ data, meta_data }) => {
    console.log(data, "pd");
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const { loginReducers: { userData }, productReducers: { wishListProductData } } = useSelector((state) => state)

    const isSelected = wishListProductData.some(d => {
        return +d.wishListVariantId ? +d.wishListVariantId === +data?.variation_id : +d.wishListProductId === +data?.product_id
    })

    const [productName, setProductName] = useState('')
    const userId = userData[0]?.userId
    const productId = data?.product_id

    useEffect(() => {
        let prodName = data?.name
        //route code
        if (data?.meta_data?.length) {
            let attributes = data?.meta_data
            //product name code
            prodName = `${data?.name}(${attributes?.map(d => d?.display_value).join('-')})`
            setProductName(prodName)
        }
        else {
            setProductName(prodName)
        }
    }, [data])

    const addToFavourite = () => {
        if (!isSelected) {
            let data_ =
            {
                userId,
                productId,
                variantId: data?.variation_id
            }
            dispatch(AddWishlistProductWatcher(data_, dispatch))
        }
        else {
            dispatch(removeFromWishlistWatcher(userId, productId, data?.variation_id, dispatch))
        }
    }

    const handleNavigate = async () => {
        let prodName = data?.name
        const pathName = window.location.pathname.substr(1,).toLowerCase() || "home"

        //route code
        if (data?.variation_id) {

            //product name code
            // prodName = `${data?.name}(${attributes?.map(d => d?.option).join('-')})`
            setProductName(prodName)

            //final navigation
            //    window.open(`/productDetails/${data?.product_id}/${data?.variation_id}/${prodName.replace(' ', "-")?.replace('/', "-")}`)
            // navigate(`/productDetails/${data?.id}/${routePId}/${prodName.replace(' ', "-")}`)
            navigate(`/${encodeURIComponent(pathName)}/${prodName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${data?.product_id}&vid=${data?.variation_id}`)

        }
        else {
            setProductName(prodName)
            navigate(`/${encodeURIComponent(pathName)}/${prodName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${data?.product_id}&vid=0`)

            //  window.open(`/productDetails/${data?.product_id}/${0}/${prodName.replace(' ', "-")?.replace('/', "-")}`)
        }
    }

    let img = meta_data?.find(v => v?.key == data?.product_id)
    return (
        <>
            <div className="frame-1000002000 flex-col-hstart-vstart clip-contents" onClick={handleNavigate}>
                <div className="frame-1000001921 flex-row-vstart-hstart">
                    {/* <Link to={`/productDetails/${data?.product_id}`} target="_blank"> */}
                    <img
                        src={img?.value !== undefined ? img?.value : "https://firebasestorage.googleapis.com/v0/b/unify-bc2ad.appspot.com/o/G6hBvXFMP81M1WvQjWNcaZ-1%3A6?alt=media&token=d6723c20-9253-49d1-bd0d-92b559110dc4"}
                        alt="not found"
                        className="frame-1000001918"
                    />
                    {/* </Link> */}
                    <div className="frame-1000001912 flex-col-hstart-vsb clip-contents">
                        <div className="frame-1000002068 flex-col-hstart-vstart">
                            <div className="frame-10000019181 flex-row-vstart-hsb">
                                <div className="frame-1000002068 flex-col-hstart-vstart">
                                    {/* <p className="txt-4101">Mobile</p> */}
                                    {/* <div>
                                        <img src="/images/OrderConfirmation/Rating.png" alt='' />
                                        <p className="txt-714">4.9</p>
                                    </div> */}
                                </div>
                                <img src={isSelected ? "/images/repairs/like.jpg" : "/images/OrderConfirmation/Heart.png"} onClick={(e) => { e.stopPropagation(); addToFavourite() }} style={{ height: '40px', width: '40px', cursor: 'pointer', marginRight: '5px' }} alt='' />
                                {/* <img src="/images/OrderConfirmation/Arrow.png" alt='' /> */}
                            </div>
                            <br></br>
                            {/* <Link to={`/productDetails/${data?.product_id}`} target="_blank"> */}
                            <div className="frame-1000001920 flex-col-hstart-vstart">
                                <div className="frame-1000002074 flex-row-vstart-hstart">
                                    <div>
                                        <div className="frame-1000001919 flex-row-vstart-hstart">
                                            <p className="txt-954">
                                                {productName}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="frame-10000019182 flex-row-vcenter-hstart">
                                    <p className="txt-531">₹{Math.round(data.total)}</p>
                                </div>
                            </div>
                            {/* </Link> */}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


export default ProductDesign