// Login Watcher Actions

export const GET_ACCESSORIES_NEW_LAUNCHES_WATCHER = "GET_ACCESSORIES_NEW_LAUNCHES_WATCHER";
export const GET_CASE_AND_COVERS_WATCHER = "GET_CASE_AND_COVERS_WATCHER";
export const GET_MEMORY_CARDS_WATCHER = "GET_MEMORY_CARDS_WATCHER";
export const GET_POWER_BANKS_WATCHER = "GET_POWER_BANKS_WATCHER";
export const GET_SHOP_BY_BRAND_WATCHER = "GET_SHOP_BY_BRAND_WATCHER";
export const GET_SHOP_BY_PRICE_WATCHER = "GET_SHOP_BY_PRICE_WATCHER";
export const GET_CARD_TAG_WATCHER = "GET_CARD_TAG_WATCHER";
export const GET_ACCESSORIES_TAG_CARDS_WATCHER = "GET_ACCESSORIES_TAG_CARDS_WATCHER";

// Login Reducer Actions

export const ACCESSORIES_NEW_LAUNCHES_LIST = "ACCESSORIES_NEW_LAUNCHES_LIST";
export const CASE_AND_COVERS_LIST = "CASE_AND_COVERS_LIST";
export const MEMORY_CARDS_LIST = "MEMORY_CARDS_LIST";
export const POWER_BANKS_LIST = "POWER_BANKS_LIST";
export const SHOP_BY_BRAND_LIST = "SHOP_BY_BRAND_LIST";
export const SHOP_BY_PRICE_LIST = "SHOP_BY_PRICE_LIST";
export const CARD_TAG_LIST = "CARD_TAG_LIST";
export const ADD_ACCESSORIES_TAG_CARDS = "ADD_ACCESSORIES_TAG_CARDS"