import http from "../http-common"
import { WOOCOMMERCE_SERVICES } from "../http-common"

class wearablesService {

    getAllWearablesNewLaunchesList(data) {
        return http.get(`/wp-json/wc/v3/products?category=756&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getAllWearablesAppleWatchList(data) {
        return http.get(`/wp-json/wc/v3/products?tag=${496}&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getAllWearablesNoiseWatchList(data) {
        return http.get(`/wp-json/wc/v3/products?tag=${497}&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getAllWearablesSamsungWatchList(data) {
        return http.get(`/wp-json/wc/v3/products?tag=${498}&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getAllWearablesShopByBrandList(data) {
        return http.get(`/wp-json/wc/v3/brands?per_page=100&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getAllWearablesShopByPriceList(data) {
        return http.get(`/wp-json/wc/v3/brands?per_page=100&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }
}

export default new wearablesService();