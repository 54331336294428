import http from "../http-common";
import { http1 } from "../http-common";
import { WOOCOMMERCE_SERVICES } from "../http-common";

class commonService {

    getWishListProductId(id) {
        return http1.get(`/product/wishList/${id}`);
    }

    fetchRazorpayPayment(id) {
        return http1.get(`/product/fetchRazorpayPayment/${id}`);
    }

    addWishListProduct(data) {
        return http1.post(`/product/wishList`, data);
    }

    removeWishListProductId(userId, productId, variantId) {
        return http1.delete(`/product/wishList/${userId}/${productId}/${variantId}`);
    }

    getWishListProductData(data) {
        return http.get(`/wp-json/wc/v3/products?include=${data}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getMultipleProductData(data) {
        return http.get(`/wp-json/wc/v3/products?include=${data}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getProductDetail(id) {
        return http.get(`/wp-json/wc/v3/products/${id}?consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    addUserPincode(data) {
        return http1.get(`/product/checkpincode/${data}`);
    }

    getProductReview(id, data) {
        return http.get(`wp-json/wc/v3/products/reviews?product=${id}&page=${data?.page}&per_page=5&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getAccessoriesProductData(data) {
        return http.get(`/wp-json/wc/v3/products?include=${data}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    addProductReview(data) {
        return http.post(`wp-json/wc/v3/products/reviews?per_page=100&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`, data);
    }

    updateProductReview(id, data) {
        return http.put(`wp-json/wc/v3/products/reviews/${id}?per_page=100&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`, data);
    }

    productReviewById(data) {
        return http.get(`wp-json/wc/v3/products/reviews?product=${data?.product_id}&reviewer_email=${data?.reviewer_email}&per_page=100&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getProductVariants(id) {
        return http.get(`/wp-json/wc/v3/products/${id}/variations?per_page=100&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }
    
    getPaymentMethods() {
        return http1.get(`/product/paymentMethods`);
    }


}
export default new commonService();