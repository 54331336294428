import { CART_PRODUCT_ID_LIST,CART_PRODUCT_LIST, COUPON_CODE_DATA, ORDER_BY_ID_LIST, ADD_CART_WITHOUT_LOGIN_LIST, REMOVE_CART_WITHOUT_LOGIN_LIST, CART_PRODUCT_REQUEST, IS_CART_LOADED, CART_ADD_TOTAL, ADD_DELIVERY_CHARGE } from '../actionTypes/cart'

const intialState = {
  cartProductIdData: [],
  cartProductData: [],
  couponCodeData: [],
  orderByIdData: [],
  cartLoading: false,
  cartWithoutLoginData: JSON.parse(localStorage.getItem("cart")) || [],
  isCartLoaded: false,
  total: "",
  deliveryCharge: 0
};


export default function cartReducers(state = intialState, action) {
  switch (action.type) {

    case CART_PRODUCT_ID_LIST:
      return { ...state, cartProductIdData: action.payload };

    case CART_PRODUCT_LIST:
      return { ...state, cartProductData: action.payload,cartLoading: false };

    case COUPON_CODE_DATA:
      return { ...state, couponCodeData: action.payload };

    case ORDER_BY_ID_LIST:
      return { ...state, orderByIdData: action.payload };

    case CART_PRODUCT_REQUEST:
      return { ...state, cartLoading: true };

    case ADD_CART_WITHOUT_LOGIN_LIST:
      return { ...state, cartWithoutLoginData: [...state.cartWithoutLoginData, action.payload] };

    case REMOVE_CART_WITHOUT_LOGIN_LIST:
      return { ...state, cartWithoutLoginData: action.payload };

    case IS_CART_LOADED:
      return { ...state, isCartLoaded: action.payload };

    case CART_ADD_TOTAL:
      return { ...state, total: action.payload };

    case ADD_DELIVERY_CHARGE:
      return { ...state, deliveryCharge: action.payload };

    default:
      return state;
  }
}
