import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const CssTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: theme.palette.primaryColors.textWhite,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.primaryColors.textWhite,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.primaryColors.textWhite,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primaryColors.textWhite,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primaryColors.textWhite,
    },
    // width: 159,
    background:theme.palette.primaryColors.textWhite
  },
}));

export default CssTextField