import React from 'react'
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function SiteMap() {
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    // const { cateType, cateName } = location?.state || {}
    // const toPath = cateType === 'Home' ? '/' : `/${cateType?.toLowerCase()}`
    console.log(location,"topath");

  return (
    <div className='section page-nav'>
        <div>
        <ul>
        <li> <Link to="/">
            Home
            </Link> </li>
        <li> <Link to="/sitemap">
            sitemap
            </Link> </li>
        </ul>
        </div>
        <div style={{marginTop:"30px"}}>
            <div style={{marginBottom:"20px"}}> Home </div>
            <li style={{padding: "6px 0px"}}>
                <Link to="/20%25-Off-or-More"> 20% off or more </Link>
                 </li>
            <li style={{padding: "6px 0px"}}>
            <Link to="/Upto-50%25-Off"> upto 50% off </Link>
                 </li>
            <li style={{padding: "6px 0px"}}>
                <Link to="/30%25-Off-or-More"> 30% off or more </Link>
                 </li>
            <li style={{padding: "6px 0px"}}>
                <Link to="/Under-1500"> under 1500 </Link>
                 </li>
        </div>

        <div style={{marginTop:"30px"}}>
            <div style={{marginBottom:"20px"}}> Mobiles </div>
            <li style={{padding: "6px 0px"}}>
                <Link to="/Apple?search=Apple&category=15"> Iphones </Link>
                 </li>
            <li style={{padding: "6px 0px"}}>
            <Link to="/mi?search=mi&category=15"> MI </Link>
                 </li>
            <li style={{padding: "6px 0px"}}>
                <Link to="/NOKIA?search=NOKIA&category=15"> Nokia </Link>
                 </li>
            <li style={{padding: "6px 0px"}}>
            <Link to="/Oppo?search=Oppo&category=15"> Oppo </Link>
                 </li>
            <li style={{padding: "6px 0px"}}>
                <Link to="/Tecno?search=Tecno&category=15"> Tecno </Link>
                 </li>
            <li style={{padding: "6px 0px"}}>
            <Link to="/Vivo?search=Vivo&category=15"> Vivo </Link>
                 </li>
        </div>

        <div style={{marginTop:"30px"}}>
            <div style={{marginBottom:"20px"}}> Mobile-accessories brands </div>
            <li style={{padding: "6px 0px"}}>
                <Link to="/Apple?search=Apple&category=15"> Apple </Link>
                 </li>
            <li style={{padding: "6px 0px"}}>
            <Link to="/mi?search=mi&category=15"> MI </Link>
                 </li>
        </div>
        
        <div style={{marginTop:"30px"}}>
            <div style={{marginBottom:"20px"}}> Wearables brands </div>
            <li style={{padding: "6px 0px"}}>
                <Link to="/boAt?search=boAt&category=756"> Boat </Link>
                 </li>
            <li style={{padding: "6px 0px"}}>
            <Link to="/noise?search=noise&category=756"> Noise </Link>
                 </li>
             </div>

        <div style={{marginTop:"30px"}}>
            <div style={{marginBottom:"20px"}}> Headphones brands </div>
            <li style={{padding: "6px 0px"}}>
                <Link to="/Apple?search=Apple&category=377"> Apple </Link>
                 </li>
            <li style={{padding: "6px 0px"}}>
            <Link to="/mi?search=mi&category=377"> MI </Link>
                 </li>
        </div>

    </div>
  )
}
