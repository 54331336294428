import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    orange: {
      main: `#f26b23`,
      light: `#e4deda`,
    },
    filter: {
      orange: "#ffcc80",
      yellow: "#f7fb17",
      blue: "#55adff",
      grey: "#ced4da",
      green: "#41db0b",
      red: "#fc3232"
    },
    primary: {
      main: `#f8b602`
    },
    secondary: {
      main: `#f26b23`
    },

    primaryColors: {

      //white

      loginBg: `#E5E5E5`,
      loginWhite: `#FFFFFF`,
      textWhite: `#fff`,
      repairBrandBg: `#F4F4F5`,
      repairDesc: `rgba(255, 255, 255, 0.6)`,
      profileBg: `#F8F8F8`,

      //grey

      breadcrumbs: `#323232`,
      shopbybrand: "#f2f4f7",
      filterTextLight: `#c4c4c4`,
      filterLeftText: `#959597`,
      repairText: `#656565`,
      textLight4: "#888888",
      filterLeftInnerText: `#515151`,
      productListItemBg: `#F8F8F8`,
      textPath: "#666666",
      checkoutNumberCircle: "#F2F2F2",
      checkoutAccountbox: `#333333`,
      checkoutAccountdesc: `#939598`,
      checkoutPointText: `#4F4F4F`,
      checkoutOrderDesc: `rgba(0, 0, 0, 0.56)`,
      orderHeader: `rgba(0, 0, 0, 0.32)`,
      4: "#AEAEB2",
      orderConfirmationText: `#999999`,
      deliveryAddressText: `#BCBEC0`,
      repairFAQText: `rgba(25, 26, 26, 0.6)`,
      filterColor: `#eeeeee`,
      checkoutVisitStore: '#9b9b9b',
      CheckoutDeliveyTextfield: `#E6E7E8`,
      couponText: `#B3B3B3`,
      reviewText: `#21b869`,
      requiredText: `#e3101e`,
      navbarChoosed: `#FFFFFF`,
      navbarUnchoosed: `#808080`,

      //black

      cartText: `#000`,
      mobileCardText: `#000000`,
      checkoutPointerHeader: `#333333`,
      loginText: `#58595B`,
      checkoutSummaryText: `#404553`,
      repairFAQ: `#191A1A`,
      footerOurstore: `#403B3B`,

      //green 

      checkoutPriceSummary: `#F0FFEC`,
      priceSummaryText: `#4E6C46`,
      check: '#4caf50',

      //red

      checkoutCommonText: `#C71F3B`
    },

    grey: {
      main: `#323232`,
      shopbybrand: "#f2f4f7",
      light: `rgba(0, 0, 0, 0.2)`,
      textLight: `#c4c4c4`,
      textLight2: `#959597`,
      textLight3: "#656565",
      textLight4: "#888888",
      textDark: `#515151`,
      textField: "#F8F8F8",
      textPath: "#666666",
      circle: "#F2F2F2",
      grey1: "#333333",
      darkgrey: "#939598",
      2: "#4F4F4F",
      lightgrey: "rgba(0, 0, 0, 0.56)",
      3: "rgba(0, 0, 0, 0.32)",
      4: "#AEAEB2",
      5: "#999999",

      6: "#A6A6A6", //*

      7: "#BCBEC0",

      8: "rgba(0, 0, 0, 0.6)", //*

      9: "#757575", //*

      10: "rgba(25, 26, 26, 0.6)",

      11: "#f8f9fa", //*
      300: '#bdbdbd', //*
      200: '#eeeeee',
    },
    white: {
      main: "#fff",
      1: "#FFFFFF",
      2: "#E5E5E5",
      3: "#F4F4F5",
      4: "rgba(255, 255, 255, 0.6)",
      smoke: "#F8F8F8",
    },
    black: {
      main: `#000`,
      darkblack: "#000000",
      1: "#333333",
      lightblack: "#58595B",
      2: "#404553",
      3: "#191A1A",
      4: "#403B3B"
    },
    green: {
      lightgreen: "#F0FFEC",
      darkgreen: "#4E6C46",
      check: '#4caf50',
    },
    red: {
      1: "#C71F3B",
    },
  },
  typography: {
    p8_500: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "8px",
      lineHeight: "10px"
    },
    p9_400: {
      fontFamily: 'Roboto',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "9px",
      lineHeight: "12px",
    },
    p10: {
      fontSize: "10px",
      textTransform: "none",
      lineHeight: "12px",
    },
    p11_300: {
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "11px",
      lineHeight: "11px",
      textAlign: "center",
      fontFamily: "Roboto",
    },
    p11_400: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "11px",
      lineHeight: "13px"
    },
    p11_500: {
      fontFamily: 'Roboto',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "11px",
      lineHeight: "12px",
    },
    p11_600: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "11px",
      lineHeight: "19px",
      textTransform: "uppercase",
    },
    p12: {
      fontSize: "12px"
    },
    p12_400: {
      fontFamily: "'Roboto'",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "12px"
    },
    p12_500: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "18px"
    },
    p12_600: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "19px",
    },
    p13: {
      fontSize: "13px",
    },
    p13_500: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "13px",
      lineHeight: "19px",
    },
    p14_n: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
    },
    p14_400: {
      fontFamily: "'Roboto'",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px"
    },
    p14_400_15: {
      fontFamily: "'Roboto'",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px"
    },
    p14_500: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "21px",
      fontFamily: "Roboto",
    },
    p16_500_18: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "15px",
      lineHeight: "21px",
      fontFamily: "Roboto",
    },
    p14_700: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "17px"
    },
    p15_500: {
      lineHeight: "20px",
      fontWeight: 500,
      fontSize: "15px",
    },
    p16_b: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "19px",
    },
    p16_400: {
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "19px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    p16_500: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "19px"
    },
    p16_600: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "19px",
      letterSpacing: "-0.2px",
    },
    p16_700: {
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "19px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    p20_500: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "24px",
      textAlign: "center"
    },
    p20_600: {
      fontFamily: 'Inter',
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "32p",
    },
    p20_700: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px"
    },
    p22_600: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "22px",
      lineHeight: "32px",
    },
    p20_400: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "32px",
    },
    p22_700: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "22px",
      lineHeight: "27px"
    },
    p24_n: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "24px",
      lineHeight: "36px",
      textTransform: "capitalize",
    },
    p24_b: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "36px",
      textTransform: "capitalize",
    },
    p24_500: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "29px",
    },
    p24_600: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "29px"
    },
    p24_700: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "18px",
      letterSpacing: "-0.0008em"
    },
    p32_400: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "32px",
      lineHeight: "39px"
    },
    p32_600: {
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "32px",
      lineHeight: "32px"
    },
  },
});

export default theme;
