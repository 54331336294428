import { ACCESSORIES_NEW_LAUNCHES_LIST, CASE_AND_COVERS_LIST, GET_ACCESSORIES_NEW_LAUNCHES_WATCHER, GET_CASE_AND_COVERS_WATCHER, GET_MEMORY_CARDS_WATCHER, GET_POWER_BANKS_WATCHER, MEMORY_CARDS_LIST, POWER_BANKS_LIST, GET_SHOP_BY_PRICE_WATCHER, GET_SHOP_BY_BRAND_WATCHER, SHOP_BY_BRAND_LIST, SHOP_BY_PRICE_LIST, GET_CARD_TAG_WATCHER, CARD_TAG_LIST, GET_ACCESSORIES_TAG_CARDS_WATCHER, ADD_ACCESSORIES_TAG_CARDS } from "../actionTypes/accessories";

export function getAccessoriesNewLaunchesWatcher(data) {
  return { type: GET_ACCESSORIES_NEW_LAUNCHES_WATCHER, payload: data };
}

export function getCaseAndCoversWatcher(data) {
  return { type: GET_CASE_AND_COVERS_WATCHER, payload: data };
}

export function getMemoryCardsWatcher(data) {
  return { type: GET_MEMORY_CARDS_WATCHER, payload: data };
}

export function getPowerBanksWatcher(data) {
  return { type: GET_POWER_BANKS_WATCHER, payload: data };
}

export function getShopByBrandWatcher(data) {
  return { type: GET_SHOP_BY_BRAND_WATCHER, payload: data };
}

export function getShopByPriceWatcher(data) {
  return { type: GET_SHOP_BY_PRICE_WATCHER, payload: data };
}

export function getCardTagWatcher(data) {
  return { type: GET_CARD_TAG_WATCHER, payload: data };
}

export function getAccessoriesTagCardsWatcher(data) {
  return { type: GET_ACCESSORIES_TAG_CARDS_WATCHER, payload: data };
}

// Redux state changing actionCreators
export function accessoriesNewLaunchesList(data) {
  return { type: ACCESSORIES_NEW_LAUNCHES_LIST, payload: data };
}

export function caseAndCoversList(data) {
  return { type: CASE_AND_COVERS_LIST, payload: data };
}

export function memoryCardsList(data) {
  return { type: MEMORY_CARDS_LIST, payload: data };
}

export function powerBanksList(data) {
  return { type: POWER_BANKS_LIST, payload: data };
}

export function shopByBrandList(data) {
  return { type: SHOP_BY_BRAND_LIST, payload: data };
}

export function shopByPriceList(data) {
  return { type: SHOP_BY_PRICE_LIST, payload: data };
}

export function cardTagList(data) {
  return { type: CARD_TAG_LIST, payload: data };
}

export function addAccessoriesTagCards(data) {
  return { type: ADD_ACCESSORIES_TAG_CARDS, data: data };
}