import http from "../http-common";
import { WOOCOMMERCE_SERVICES } from "../http-common";

class orderService {

    getOrder(id) {
        return http.get(`/wp-json/wc/v3/orders?customer=${id}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getSingleOrder(id) {
        return http.get(`/wp-json/wc/v3/orders/${id}?consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    deleteOrder(id) {
        return http.delete(`/wp-json/wc/v3/orders/${id}?consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    taxDetail(data) {
        return http.get(`/wp-json/wc/v3/taxes?class=18-tax&per_page=100&consumer_key=ck_5c6f82c68695d9eee780c888bf9c539f8a015a61&consumer_secret=cs_b197e59eb82a2c04f238c274e92c9a07da551efb`);
    }
}

export default new orderService();