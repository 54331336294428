import { Typography, Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createOrderWatcher, createWooOrderWatcher, updateOrderWatcher } from '../../../store/actionCreators/cart'
import { updateAlert } from '../../../store/actionCreators/common'
import { deleteOrderWatcher } from '../../../store/actionCreators/order'
import theme from '../../../theme'
import ReactPixel from 'react-facebook-pixel';
import { addUserPincodeWatcher } from '../../../store/actionCreators/product'
// import axios from 'axios';

const Payment = ({ isAddressChange, setIsAddressChange }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { loginReducers: { userData, primaryAddress }, cartReducers: { cartProductData, cartWithoutLoginData, total, deliveryCharge }, productReducers: { userPincode } } = useSelector(state => state)
    const cartData = userData.length ? cartProductData : cartWithoutLoginData
    const { firstName, email, wooId } = userData[0]
    const { lastName, mobileNumber, house, area, pincode, district, state } = primaryAddress
    const [deliveryAmt, setdeliveryAmt] = useState(0)

    useEffect(() => {
        if (pincode) {
            dispatch(addUserPincodeWatcher(pincode))
        }
    }, [pincode])

    useEffect(() => {
        if (cartItemTotalFunc() < 2500) {
            let filterMobileCategoryProduct = cartData?.filter((v) => {
                console.log(v?.categories?.some((s) => s?.name === "Mobiles"), "deli amt", v)
                return v?.categories?.some((s) => s?.name === "Mobiles")
            })
            if (filterMobileCategoryProduct?.length > 0) {
                setdeliveryAmt(0)
            }
            else {
                setdeliveryAmt(deliveryCharge)
            }
        }
        else {
            setdeliveryAmt(0)
        }
    }, [cartData,deliveryCharge])

    const sumOfdisc = () => {
        return cartData?.reduce((a,b) => {
            let qty = b.quantity || 1
            let price = +b.regular_price -b.price
            return +a + +price * qty
             }, 0 )
    }

    // const createRazorPayOrder = async (wooOrderRes) => {
    //     const amount = +wooOrderRes.total * 100
    //     const baseData = "Basic cnpwX3Rlc3RfaFFPY21welNBZlhKalc6dFlDT3FiODczbGo2UkZ2RjlVN09kWUV4"
    //     const url = "https://api.razorpay.com/v1/orders"
    //     const obj = {
    //         "amount": amount,
    //         "currency": "INR",
    //         "receipt": `#${wooOrderRes.id}`,
    //         "notes": {}
    //     }
    //     try {
    //         const { data } = await axios.post(url, obj, {
    //             headers: {
    //                 'Content-type': 'application/json',
    //                 'Athorization': baseData
    //             }
    //         }
    //         )
    //         paymentFunction(data.id, wooOrderRes)
    //     }
    //     catch {
    //         alert('order failed')
    //     }

    // }

    const cartItemTotalFunc = () => {
        return cartData?.reduce((a, b) => {
            let qty = b.quantity || 1
            let price = +b.price * qty
            return +a + +price
        }, 0)
    }

    const razorPayPaymentFunction = (orderId, wooOrderRes) => {
        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
            amount: total.toString(),
            currency: "INR",
            name: firstName,
            description: "PHONEBUY",
            image: '',
            order_id: orderId,
            handler: function (response) {
                console.log(response)
                const data = {
                    ...wooOrderRes,
                    set_paid: true,
                    status: 'processing',
                    payment_method: response?.razorpay_order_id,
                    // payment_method_title: wooOrderRes.payment_method_title,
                    transaction_id: response?.razorpay_payment_id
                }
                console.log(data, "payment");


                dispatch(createWooOrderWatcher(data, res => {
                    navigate(`/orderConfirmation/${res.id}`)
                }))
            },
            prefill: {
                name: firstName,
                email: email,
                contact: mobileNumber,
            },
            theme: {
                color: theme.palette.orange.main,
            },
            modal: {
                ondismiss: () => {
                    // dispatch(deleteOrderWatcher(wooOrderRes.id))
                }
            }
        };
        console.log(JSON.stringify(options), "options");
        const paymentObject = new window.Razorpay(options);
        paymentObject.on("payment.failed", function (response) {
        });
        paymentObject.open();
    }

    const imageName = (imageArr) => {
        let imgName = (imageArr?.length && imageArr[0]?.src) || ""
        return imgName
    }

    const paymentClick = () => {
        if (!mobileNumber || !house || !area || !district || !state || !pincode) {
            setIsAddressChange(true)
            return dispatch(updateAlert('Enter your address to proceed', 'error'))
        }
        if (!userPincode) {
            setIsAddressChange(true)
            return dispatch(updateAlert('delivery not available', 'error'))
        }
        if (!cartProductData?.length)
            return dispatch(updateAlert('Your cart is empty!', 'warning'))

        let meta_data = []
        cartProductData?.map((v, i) => {
            meta_data.push({
                "key": v?.cartProductId?.toString(), "value":
                    imageName(v?.images)
            })
        })
        meta_data.push({
            "key" : "discount_price","value" : sumOfdisc()
        })
        const orderData = {
            "customer_id": +wooId,
            // "payment_method": "bacs",
            // "payment_method_title": "Direct Bank Transfer",
            "set_paid": false,
            "billing": {
                "first_name": firstName,
                "last_name": lastName,
                "address_1": house,
                "address_2": area,
                "city": district,
                "state": "TN",
                "postcode": pincode,
                "country": "IN",
                "email": email,
                "phone": mobileNumber
            },
            "shipping": {
                "first_name": firstName,
                "last_name": lastName,
                "address_1": house,
                "address_2": area,
                "city": district,
                "state": "TN",
                "postcode": pincode,
                "country": "IN"
            },
            "line_items": cartProductData.map(m => {
                if (+m.cartVariantId) {
                    return { "product_id": m.id, "variation_id": m.cartVariantId, "quantity": m.quantity }

                }
                else {
                    return { "product_id": m.id, "quantity": m.quantity }
                }
            }),
            "shipping_lines": parseInt(deliveryAmt) > 0 ? [{ "method_id": "flat_rate", "method_title": "Flat Rate", "total": parseInt(deliveryCharge).toFixed(2) }] : [], "meta_data": meta_data
        }
        if (!total) return
        dispatch(createOrderWatcher({ total }, (orderId) => {
            checkoutPixelHandler(orderData)
            razorPayPaymentFunction(orderId, orderData)
            // cashFreePaymentFunction(orderId, wooOrderRes)
            // createRazorPayOrder(res)

        }))
    }

    const checkoutPixelHandler = async (orderData) => {
        await ReactPixel.track('InitiateCheckout', {
            content_name: 'Checkout', content_category: 'Checkout', content_ids: orderData?.line_items?.map(v => v?.product_id), content_type: 'product', value: parseFloat(total), currency: 'INR', num_items: orderData?.line_items?.length, user_id: wooId
        });
        console.log({
            content_name: 'Checkout', content_category: 'Checkout', content_ids: orderData?.line_items?.map(v => v?.product_id), content_type: 'product', value: parseFloat(total), currency: 'INR', num_items: orderData?.line_items?.length, user_id: wooId
        }, "checkoutpixel")
    }

    return (
        <>
            <Box backgroundColor="primaryColors.loginWhite" boxshadow="0px 4px 20px rgba(0, 0, 0, 0.08)" marginLeft="20px" mt='5px' borderRadius="8px" >
                <Box display='flex'>
                    <Box border="1px solid" marginLeft="20px" borderColor="primaryColors.checkoutAccountdesc" borderRadius="50%" display="flex" justifyContent="center" alignItems="center" marginTop="15px" width='38px'>
                        <Typography variant="p24_n" color="black"> 3 </Typography>
                    </Box>

                    <Box paddingTop="24px">
                        <Typography variant='p24_700' color="primaryColors.checkoutPointerHeader" marginLeft="30px" marginBottom="30px"> Payment </Typography>
                    </Box>
                </Box>
                <Box pb='30px' pt='20px'>
                    <Button onClick={paymentClick} sx={{ cursor: 'pointer', backgroundColor: 'orange.main', ml: '87px', textTransform: 'none' }} variant='contained'>
                        <Typography color='primaryColors.textWhite'>Make Payment</Typography>
                    </Button>
                </Box>
            </Box>
        </>
    )
}
export default Payment
