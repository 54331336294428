import { ACCESSORIES_NEW_LAUNCHES_LIST, ADD_ACCESSORIES_TAG_CARDS, CARD_TAG_LIST, CASE_AND_COVERS_LIST, MEMORY_CARDS_LIST, POWER_BANKS_LIST, SHOP_BY_BRAND_LIST, SHOP_BY_PRICE_LIST } from '../actionTypes/accessories';

const intialState = {
  newLaunchesList: [],
  CaseAndCoversList: [],
  MemoryCardsList: [],
  PowerBanksList: [],
  ShopByBrandList: [],
  ShopByPriceList: [],
  CardTagList: [],
  AccessoriesTagCards: []
};


export default function accessoriesReducer(state = intialState, action) {
  switch (action.type) {

    case ACCESSORIES_NEW_LAUNCHES_LIST:
      return { ...state, newLaunchesList: action.payload };

    case CASE_AND_COVERS_LIST:
      return { ...state, CaseAndCoversList: action.payload };

    case MEMORY_CARDS_LIST:
      return { ...state, MemoryCardsList: action.payload };

    case POWER_BANKS_LIST:
      return { ...state, PowerBanksList: action.payload };

    case SHOP_BY_BRAND_LIST:
      return { ...state, ShopByBrandList: action.payload };

    case SHOP_BY_PRICE_LIST:
      return { ...state, ShopByPriceList: action.payload };

    case CARD_TAG_LIST:
      return { ...state, CardTagList: action.payload };
      
    case ADD_ACCESSORIES_TAG_CARDS:
      return { ...state, AccessoriesTagCards: action.data };

    default:
      return state;
  }
}
