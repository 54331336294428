import { GET_MOBILE_NEW_LAUNCHES_WATCHER, MOBILE_NEW_LAUNCHES_LIST, GET_MOBILE_RECOMMENDED_WATCHER, GET_MOBILE_BEST_BUDGET_WATCHER, MOBILE_RECOMMENDED_LIST, MOBILE_BEST_BUDGET_LIST, PRODUCT_BRAND_LIST, GET_PRODUCT_BRAND_WATCHER, SHOPBY_BRAND_LIST, GET_SHOPBY_BRAND_WATCHER, SHOPBY_PRICE_LIST, GET_SHOPBY_PRICE_WATCHER, ADD_MOBILES_LOADING, GET_MOBILES_TAG_CARDS_WATCHER, ADD_MOBILES_TAG_CARDS } from "../actionTypes/mobile"

// Worker triggering actionCreators
export function getMobileNewLaunchesWatcher(data) {
  return { type: GET_MOBILE_NEW_LAUNCHES_WATCHER, payload: data };
}

export function getMobileRecommendedWatcher(data) {
  return { type: GET_MOBILE_RECOMMENDED_WATCHER, payload: data };
}

export function getMobileBestBudgetWatcher(data) {
  return { type: GET_MOBILE_BEST_BUDGET_WATCHER, payload: data };
}

export function getProductBrandWatcher(data) {
  return { type: GET_PRODUCT_BRAND_WATCHER, payload: data };
}

export function getShopByBrandWatcher(data) {
  return { type: GET_SHOPBY_BRAND_WATCHER, payload: data };
}

export function getShopByPriceWatcher(data) {
  return { type: GET_SHOPBY_PRICE_WATCHER, payload: data };
}

export function getMobileTagCardsWatcher(data) {
  return { type: GET_MOBILES_TAG_CARDS_WATCHER, payload: data };
}


// Redux state changing actionCreators
export function mobileNewLaunchesList(data) {
  return { type: MOBILE_NEW_LAUNCHES_LIST, payload: data };
}

export function mobileRecommendedList(data) {
  return { type: MOBILE_RECOMMENDED_LIST, payload: data };
}

export function mobileBestBudgetList(data) {
  return { type: MOBILE_BEST_BUDGET_LIST, payload: data };
}

export function productBrandList(data) {
  return { type: PRODUCT_BRAND_LIST, payload: data };
}

export function shopByBrandList(data) {
  return { type: SHOPBY_BRAND_LIST, payload: data };
}

export function shopByPriceList(data) {
  return { type: SHOPBY_PRICE_LIST, payload: data };
}

export function addMobilesLoading(key,data) {
  return { type: ADD_MOBILES_LOADING, data: data,key };
}

export function addMobilesTagCards(data) {
  return { type: ADD_MOBILES_TAG_CARDS, data: data };
}

