import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';

export default function OurStores() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div style={{ margin: "110px 40px 40px 40px" }} className="section">
      <Grid container spacing={3}>
        <Grid item lg={4} md={12} sm={12} xs={12} >
          <Box height="50vh" position="relative" marginBottom="10px">
            <img src="/images/adambakkam.png" height="100%" width="100%" style={{ objectFit: "cover", borderRadius: "10px" }} alt='' />
            <div style={{ position: "absolute", bottom: 0, left: 0, width: "100%", zIndex: 100, textAlign: "center", fontSize: "xxx-large", color: "#fff", fontFamily: "serif" }}>ADAMBAKKAM</div>
          </Box>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <a href="https://www.google.com/maps/place/PhoneBuy/@12.985161,80.2028398,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x163e0b5734c57173!8m2!3d12.985161!4d80.2050285" style={{ fontSize: "15px", background: "#323232", padding: "6px 12px 6px 12px", margin: "10px 0 15px 0px", borderRadius: "15px", color: "#fff", display: "flex", flexDirection: "row", }}>
              <FmdGoodOutlinedIcon />
              <Typography style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#fff", paddingLeft: "5px" }}>Navigate</Typography>
            </a>
            <a href="tel:+91-8270050505" style={{ fontSize: "15px", background: "#323232", padding: "6px 12px 6px 12px", margin: "10px 0 15px 5px", borderRadius: "15px", color: "#fff", display: "flex", flexDirection: "row", width: "80px" }}>
              <CallOutlinedIcon style={{ paddingTop: "3px" }} />
              <Typography style={{ fontSize: "18px", fontFamily: "sans-serif", color: "#fff", paddingLeft: "5px" }}>call</Typography>
            </a>
          </div>
          <p style={{ fontSize: "16px", color: "primaryColors.footerOurstore", padding: "20px 20px 20px 0px" }}> 5, Brindavan Nagar Main Rd, Okkiayam, Thouraipakkam, Mahalakshmi Nagar, Adambakkam, Chennai, Tamil Nadu 600088 </p>
        </Grid>

        <Grid item lg={4} md={12} sm={12} xs={12} >
          <Box height="50vh" position="relative" marginBottom="10px">
            <img src="/images/pallikaranai.jpg" alt='' height="100%" width="100%" style={{ objectFit: "cover", borderRadius: "10px" }} />
            <div style={{ position: "absolute", bottom: 0, left: 0, width: "100%", zIndex: 100, textAlign: "center", fontSize: "xxx-large", color: "#fff", fontFamily: "serif" }}>PALLIKARANAI</div>
          </Box>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <a href="https://goo.gl/maps/48VR14dUTXKwL7bQ9" style={{ fontSize: "15px", background: "#323232", padding: "6px 12px 6px 12px", margin: "10px 0 15px 0", borderRadius: "15px", color: "#fff", display: "flex", flexDirection: "row" }}>
              <FmdGoodOutlinedIcon />
              <Typography style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#fff", paddingLeft: "5px" }}>Navigate</Typography>
            </a>
            <a href="tel:+91-8270050505" style={{ fontSize: "15px", background: "#323232", padding: "6px 12px 6px 12px", margin: "10px 0 15px 5px", borderRadius: "15px", color: "#fff", display: "flex", flexDirection: "row", width: "80px" }}>
              <CallOutlinedIcon style={{ paddingTop: "3px" }} />
              <Typography style={{ fontSize: "18px", fontFamily: "sans-serif", color: "#fff", paddingLeft: "5px" }}>call</Typography>
            </a>
          </div>
          <p style={{ fontSize: "16px", color: "primaryColors.footerOurstore", padding: "20px 20px 20px 0px" }}> No.87, 98, Velachery Rd, near Jeyachandran Textiles, Pallikaranai, Chennai, Tamil Nadu 600100 </p>
        </Grid>

        <Grid item lg={4} md={12} sm={12} xs={12}>
          <Box height="50vh" position="relative" marginBottom="10px">
            <img src="/images/madipakkam.jpg" alt='' height="100%" width="100%" style={{ objectFit: "cover", borderRadius: "10px" }} />
            <div style={{ position: "absolute", bottom: 0, left: 0, width: "100%", zIndex: 100, textAlign: "center", fontSize: "xxx-large", color: "#fff", fontFamily: "serif" }}>MADIPAKKAM</div>
          </Box>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <a href="https://goo.gl/maps/8mVAyksxJ2c8ToX68" style={{ fontSize: "15px", background: "#323232", padding: "6px 12px 6px 12px", margin: "10px 0 15px 0", borderRadius: "15px", color: "#fff", display: "flex", flexDirection: "row" }}>
              <FmdGoodOutlinedIcon />
              <Typography style={{ fontSize: "16px", fontFamily: "sans-serif", color: "#fff", paddingLeft: "5px" }}>Navigate</Typography>
            </a>
            <a href="tel:+91-8270050505" style={{ fontSize: "15px", background: "#323232", padding: "6px 12px 6px 12px", margin: "10px 0 15px 5px", borderRadius: "15px", color: "#fff", display: "flex", flexDirection: "row", width: "80px" }}>
              <CallOutlinedIcon style={{ paddingTop: "3px" }} />
              <Typography style={{ fontSize: "18px", fontFamily: "sans-serif", color: "#fff", paddingLeft: "5px" }}>call</Typography>
            </a>
          </div>
          <p style={{ fontSize: "16px", color: "primaryColors.footerOurstore", padding: "20px 20px 20px 0px" }}> Phonebuy, 7, Shanmugam Rd, Tambaram West, Tambaram, Chennai, Tamil Nadu 600045 </p>
        </Grid>

      </Grid>
    </div>
  )
}
