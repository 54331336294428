import { takeEvery, call, put, select } from "redux-saga/effects";
import { cartProductIdList, cartProductDataList, couponCodeDataList, orderByIdList, currentOrderList, isCartLoaded, cashfreePaymentStatus, addDeliveryCharge } from "../actionCreators/cart";
import { APPLY_COUPON_CODE_WATCHER, GET_CART_PRODUCT_ID_WATCHER, GET_CART_PRODUCT_DATA_WATCHER, REMOVE_FROM_CART_WATCHER, ADD_TO_CART_WATCHER, ORDER_BY_ID_WATCHER, UPDATE_CART_WATCHER, CREATE_ORDER_WATCHER, UPDATE_ORDER_WATCHER, DELETE_BULK_CART_WATCHER, ADD_BULK_CART_WATCHER, VERIFY_CASHFREE_PAYMENT_WATCHER, CREATE_WOO_ORDER_WATCHER, GET_DELIVERY_CHARGE_WATCHER } from "../actionTypes/cart"
import cartService from "../../services/cart"
import { updateOpenLoader, updateAlert, updateFalseLoader } from '../actionCreators/common'
import ReactPixel from 'react-facebook-pixel';
import axios from "axios";
import commonService from "../../services/product"

function* addtocart(data, action) {
    if (data?.length) {
        let productData = []
        let ind = 0
        let products = data.map(m => m.productId).join()
        let { data: data_1 } = yield call(cartService.getcartProductData, products);
        // yield data_1.map(async (m, i) => {
        //     const isQuantity = data?.find(d => +d.productId === m.id)
        //     console.log("isquantity", isQuantity)
        //     if (isQuantity !== undefined) {
        //         try {
        //             // action?.dispatch(updateOpenLoader())
        //             let { data: data2 } = m?.variations?.length > 0 ? await cartService.getProductVariants(m.id) : { data: [] }
        //             let prodData = data2?.length ? data2?.find(d => +d.id === +isQuantity.variantId) : m
        //             console.log("data2", data2, prodData)
        //             prodData.quantity = +isQuantity.quantity
        //             prodData.cartVariantId = +isQuantity.variantId
        //             prodData.cartProductId = +isQuantity.productId
        //             prodData.categories = m?.categories
        //             console.log("test", prodData)
        //             if (data2?.length) {
        //                 prodData.name = m?.name
        //                 prodData.images = prodData?.woo_variation_gallery_images
        //             }
        //             productData.push(prodData)
        //             // productData.splice(i, 0, prodData)
        //             // if (data.length - 1 === ind) {
        //             //     console.log("product", productData)
        //             //     action?.dispatch(cartProductDataList(productData));
        //             //     action?.dispatch(isCartLoaded(true))
        //             // }
        //             ind += 1
        //             // action?.dispatch(updateFalseLoader())
        //         }
        //         catch (e) {
        //             // action?.dispatch(updateFalseLoader())
        //         }
        //     }
        // })
        for (let m of data_1) {
            const isQuantity = data?.find(d => +d.productId === m.id)
            console.log("isquantity", isQuantity)
            if (isQuantity !== undefined) {
                try {
                    // action?.dispatch(updateOpenLoader())
                    let { data: data2 } = m?.variations?.length > 0 ? yield cartService.getProductVariants(m.id) : { data: [] }
                    let prodData = data2?.length ? data2?.find(d => +d.id === +isQuantity.variantId) : m
                    console.log("data2", data2, prodData)
                    prodData.quantity = +isQuantity.quantity
                    prodData.cartVariantId = +isQuantity.variantId
                    prodData.cartProductId = +isQuantity.productId
                    prodData.categories = m?.categories
                    console.log("test", prodData)
                    if (data2?.length) {
                        prodData.name = m?.name
                        prodData.images = prodData?.woo_variation_gallery_images
                        prodData.average_rating = m?.average_rating
                        prodData.meta_data = [...prodData?.woo_variation_gallery_images, ...m?.meta_data]
                    }
                    productData.push(prodData)
                    // productData.splice(i, 0, prodData)
                    // if (data.length - 1 === ind) {
                    //     console.log("product", productData)
                    //     action?.dispatch(cartProductDataList(productData));
                    //     action?.dispatch(isCartLoaded(true))
                    // }
                    ind += 1
                    // action?.dispatch(updateFalseLoader())
                }
                catch (e) {
                    // action?.dispatch(updateFalseLoader())
                }
            }
        }
        console.log("abc", JSON.stringify(productData))
        action?.dispatch(cartProductDataList(productData));
        action?.dispatch(isCartLoaded(true))

        // OLD CODE

        //     let products = data.map(m => m.productId).join()
        //     let { data: data1 } = yield call(cartService.getcartProductData, products);

        //     let productData = []
        //     data1.map(m => {
        //         const isQuantity = data?.find(d => +d.productId === m.id) || {}
        //         m.quantity = +isQuantity.quantity
        //         m.cartVariantId = +isQuantity.variantId
        //         productData.push(m)
        //         return true
        //     })


    }
    else {
        yield put(cartProductDataList([]));
    }
}
function* getCartProductIdEffect(action) {
    try {
        // yield put(updateOpenLoader())
        let { data } = yield call(cartService.getcartProductId, action.userId);
        yield put(cartProductIdList(data));
        yield addtocart(data, action)
        // yield put(updateFalseLoader())

    } catch (e) {
        // yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* AddCartProductEffect(action) {
    try {
        yield put(updateOpenLoader())
        let { data } = yield call(cartService.addCartProduct, action.payload?.req_payload);
        yield put(cartProductIdList(data));
        yield addtocart(data, action)
        yield put(updateFalseLoader())
        yield put(updateAlert('Product added to cart', 'success'));
        let pro_id = action.payload?.req_payload?.variantId === 0 ? action.payload?.req_payload?.productId : action.payload?.req_payload?.variantId
        ReactPixel.track('AddToCart', {
            content_name: action.payload?.detail?.name, content_category: 'Product Category', content_ids: [pro_id], content_type: 'product', value: parseFloat(action.payload?.detail?.price), currency: 'INR', num_items: 1, contents: [{ id: pro_id, quantity: action.payload?.req_payload?.quantity }], user_id: action.payload?.req_payload?.userId
        }
        );
        console.log({
            content_name: action.payload?.detail?.name, content_category: 'Product Category', content_ids: [pro_id], content_type: 'product', value: parseFloat(action.payload?.detail?.price), currency: 'INR', num_items: 1, contents: [{ id: pro_id, quantity: action.payload?.req_payload?.quantity }], user_id: action.payload?.req_payload?.userId
        }, "addtocart")
    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* updateCartProductEffect(action) {
    try {
        yield put(updateOpenLoader())
        let { data } = yield call(cartService.updateCartProduct, action.payload, action.userId, action.productId, action.variantProductId);
        yield put(cartProductIdList(data));
        yield addtocart(data, action)
        yield put(updateFalseLoader())

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* getCartProductDataEffect(cartIdData) {

    try {
        yield put(updateOpenLoader())

        let productData = []
        let ind = 0
        yield cartIdData?.payload?.map(async (m, i) => {
            try {
                cartIdData?.dispatch(updateOpenLoader())
                let { data: data1 } = await cartService.getSingleProductDetail(m.productId)
                let { data: data2 } = await cartService.getProductVariants(m.productId)
                let prodData = data2?.length ? data2?.find(d => +d.id === +m.variantId) : data1
                prodData.quantity = +m.quantity
                prodData.cartVariantId = +m.variantId
                prodData.cartProductId = +m.productId
                if (data2?.length) {
                    prodData.name = data1?.name
                    prodData.images = prodData?.woo_variation_gallery_images
                }
                productData.splice(i, 0, prodData)
                if (cartIdData?.payload?.length - 1 === ind) {
                    cartIdData?.dispatch(cartProductDataList(productData));
                    cartIdData?.dispatch(isCartLoaded(true))
                }
                ind += 1
                cartIdData?.dispatch(updateFalseLoader())
            }
            catch (e) {
                cartIdData?.dispatch(updateFalseLoader())
            }
        })

        //OLD CODE
        // let products = cartIdData.payload.map(m => m.productId).join()

        // let { data } = yield call(cartService.getcartProductData, products);
        // let productData = []
        // data.map(m => {
        //     const isQuantity = cartIdData.payload?.find(d => +d.productId === m.id) || {}
        //     m.quantity = +isQuantity.quantity
        //     m.cartVariantId = +isQuantity.variantId
        //     productData.push(m)
        //     return true
        // })

        // yield put(cartProductDataList(productData));
        yield put(updateFalseLoader())

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* removeFromCartEffect(action) {
    try {
        yield put(updateOpenLoader())

        let { data } = yield call(cartService.removecartProductId, action.userId, action.productId, action.cartVariantId);
        yield put(cartProductIdList(data));
        yield addtocart(data, action)
        yield put(updateFalseLoader())
        yield put(updateAlert('Product removed from cart', 'success'));

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* getOrderByIdEffect(action) {
    try {
        yield put(updateOpenLoader())

        let { data } = yield call(cartService.getOrderById, action.payload);
        yield put(orderByIdList(data));
        action?.res(data)
        yield put(updateFalseLoader())
        ReactPixel.track('Purchase', {
            value: parseFloat(data?.total),
            currency: 'INR',
            content_ids: data?.line_items?.map((v, i) => v?.product_id),
            content_type: 'product',
            num_items: data?.line_items.length,
            order_id: data?.id?.toString(),
        });
    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* applyCouponCodeEffect(action) {
    try {
        yield put(updateOpenLoader())

        let { data } = yield call(cartService.applyCouponCode, action.payload);
        yield put(couponCodeDataList(data));
        yield put(updateFalseLoader())

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert('Something Went Wrong!', 'error'));
    }
}

function* createOrderEffect(action) {
    try {
        yield put(updateOpenLoader())

        // let { data } = yield call(cartService.createOrder, action.payload);
        // yield put(currentOrderList(data));
        const obj = {
            amount: action.payload.total,
            // receipt: `#${data.id}`
        }
        let { data: data1 } = yield call(cartService.createRazorpayOrder, obj);

        action.res(data1.id)
        yield put(updateFalseLoader())

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* orderBookingCashfreeEffect(action) {
    try {
        yield put(updateOpenLoader());

        const NODE_ENV = process.env.NODE_ENV
        let base_url = 'http://localhost:3000'

        if (NODE_ENV === 'production') {
            base_url = 'https://phonebuy.com'
        }

        const { loginReducers: { userData } } = yield select((state) => state)

        const { firstName, email, mobileNumber, wooId } = userData[0] || {}

        let { data } = yield call(cartService.createOrder, action.payload);
        yield put(currentOrderList(data));
        const options = {
            method: 'POST',
            url: 'https://sandbox.cashfree.com/pg/orders',
            headers: {
                accept: 'application/json',
                'x-api-version': '2022-01-01',
                'content-type': 'application/json',
                'x-client-id': process.env.REACT_APP_CASHFREE_API_ID,
                'x-client-secret': process.env.REACT_APP_CASHFREE_SECRET_KEY
            },
            data: {
                order_id: `${data.id}`,
                order_amount: data.total,
                order_currency: 'INR',
                customer_details: {
                    customer_id: `${wooId}`,
                    customer_email: email,
                    customer_phone: mobileNumber,
                    customer_name: firstName
                },
                order_meta: {
                    return_url: `${base_url}/orderConfirmation/{order_id}`,
                    // notify_url: 'https://b8af79f41056.eu.ngrok.io/webhook.php'
                },
                order_note: 'Test order',
                //   order_tags: {OrderID: data.OrderID, OrderNID: `${data.OrderNID}`, cafeteriaId: `${data.cafeteriaId}`},
            }
        };

        //   const {data: data1} = yield axios.request(options)

        const { data: data1 } = yield call(cartService.createCashfreeOrder, options)
        // Getting the order details back
        const { payment_link } = data1;

        window.location.replace(payment_link)

        yield put(updateFalseLoader());

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e.response?.data.message, 'error'));
    }
}

function* verifyCashfreePaymentEffect(action) {
    try {
        yield put(updateOpenLoader())

        let { data } = yield call(cartService.verifyCashfreePayment, action.payload);
        yield put(cashfreePaymentStatus(data));
        action?.res(data)
        yield put(updateFalseLoader())

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* updateOrderEffect(action) {
    const { payload, orderId, msgData } = action
    const { userData } = yield select((state) => state.loginReducers)
    try {
        yield put(updateOpenLoader())

        let { data } = yield call(cartService.updateOrder, payload, orderId);
        yield put(currentOrderList(data));
        yield put(orderByIdList(data));
        yield call(cartService.confirmMsg, msgData);
        let { data: data1 } = yield call(cartService.removeBulkCart, userData[0].userId);
        yield put(cartProductDataList(data1));
        action.res(data)
        yield put(updateFalseLoader())

    } catch (e) {
        yield put(updateFalseLoader());
        console.log(e)
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* createWooOrderEffect(action) {
    const { payload, msgData } = action
    const { userData } = yield select((state) => state.loginReducers)
    try {
        yield put(updateOpenLoader())
        let { data : payData } = yield call(commonService.fetchRazorpayPayment,action.payload.transaction_id)
        action.payload.payment_method_title = payData?.method || ""
        let { data } = yield call(cartService.createOrder, action.payload);
        yield put(currentOrderList(data));
        yield put(orderByIdList(data));
        const msgData = {
            orderId: data.id,
            userName: userData[0].firstName,
            mobileNumber: data?.billing?.phone,
            amount: data.total.toString(),
            orderItems: data?.line_items.map(d => d.name)
        }
        console.log(msgData,"datamsg");
        yield call(cartService.confirmMsg, msgData);
        let { data: data1 } = yield call(cartService.removeBulkCart, userData[0].userId);
        yield put(cartProductDataList(data1));
        action.res(data)
        yield put(updateFalseLoader())

    } catch (e) {
        yield put(updateFalseLoader());
        console.log(e)
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* removeBulkCartEffect(action) {
    const { userId, payload, res } = action
    try {
        yield put(updateOpenLoader())

        let { data } = yield call(cartService.removeBulkCart, payload, userId);
        yield put(cartProductDataList(data));
        res(data)
        yield put(updateFalseLoader())

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* addBulkCartEffect(action) {
    const { userId, payload, res, dispatch } = action
    try {
        yield put(updateOpenLoader())
        let { data: cartIdData } = yield call(cartService.addBulkCart, payload, userId);
        yield put(cartProductIdList(cartIdData));
        yield addtocart(cartIdData, action)
        res(cartIdData)
        yield put(updateFalseLoader())

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* addDeliveryChargeEffect() {
    try {
        yield put(updateOpenLoader())
        let { data } = yield call(cartService.getDeliveryCharge);
        if(data?.length>0) {
            yield put(addDeliveryCharge(parseInt(data[0]?.settings?.cost?.value)));
        }
        yield put(updateFalseLoader())

    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

export const CartSaga = [
    takeEvery(GET_CART_PRODUCT_ID_WATCHER, getCartProductIdEffect),
    takeEvery(ADD_TO_CART_WATCHER, AddCartProductEffect),
    takeEvery(UPDATE_CART_WATCHER, updateCartProductEffect),
    takeEvery(GET_CART_PRODUCT_DATA_WATCHER, getCartProductDataEffect),
    takeEvery(APPLY_COUPON_CODE_WATCHER, applyCouponCodeEffect),
    takeEvery(REMOVE_FROM_CART_WATCHER, removeFromCartEffect),
    takeEvery(ORDER_BY_ID_WATCHER, getOrderByIdEffect),
    takeEvery(CREATE_ORDER_WATCHER, createOrderEffect),
    takeEvery(UPDATE_ORDER_WATCHER, updateOrderEffect),
    takeEvery(DELETE_BULK_CART_WATCHER, removeBulkCartEffect),
    takeEvery(ADD_BULK_CART_WATCHER, addBulkCartEffect),
    takeEvery(VERIFY_CASHFREE_PAYMENT_WATCHER, verifyCashfreePaymentEffect),
    takeEvery(CREATE_WOO_ORDER_WATCHER, createWooOrderEffect),
    takeEvery(GET_DELIVERY_CHARGE_WATCHER, addDeliveryChargeEffect)
]
