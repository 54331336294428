import React from "react";
import { Grid, Typography, Button, Box } from '@mui/material';
import ProductItemCard from "../../components/ProductListItemCard"
import { useDispatch, useSelector } from "react-redux";
import { addPrimarycategory, filterTagBasedProductWatcher } from "../../store/actionCreators/common";
import { Link } from "react-router-dom";


export default function BestBudget() {

    const dispatch = useDispatch()
    const { mobileReducers: { bestBudgetMobileList } } = useSelector(state => state)

    const mapData = bestBudgetMobileList.length ? bestBudgetMobileList : []
    const searchPath = '/search'

    const viewFunction = () => {
        // let data = {
        //     perPage: 50,
        //     tag: 387,
        //     category: 15,
        //     name: "BestBudget"
        // }
        // const pathName = window.location.pathname.substr(1,).toLowerCase()
        // dispatch(addPrimarycategory(pathName))
        // dispatch(filterTagBasedProductWatcher(data))
    }

    return (
        <>
            {Boolean(mapData.length) &&
                <>
                    <section className="container products-container padding-top-none clear">
                        <Grid container>
                            <Grid lg={8} md={8} sm={8} xs={12}>
                                <h2 className="left">
                                    Best Budget Smartphones
                                </h2>
                            </Grid>
                            <Grid lg={4} md={4} sm={4} xs={12}>
                                {Boolean(mapData.length >= 4) &&
                                    <Link to={"/BestBudget?name=BestBudget&tag=387&category15"} state={{ tag: 387 }}>
                                        <Box display='flex'>
                                            <Button variant='contained'
                                                sx={{
                                                    width: '130px', height: '40px', backgroundColor: 'orange.main',
                                                    '&:hover': { backgroundColor: 'orange.main' }, textTransform: 'none', m: '0px 25px 10px auto'
                                                }} onClick={viewFunction} width='100px' >
                                                <Typography color='white !important'>View All</Typography>
                                            </Button>
                                        </Box>
                                    </Link>
                                }
                            </Grid>
                        </Grid>
                    </section>

                    <Grid container spacing={3} sx={{ pr: '20px', pl: '20px', pb: '20px' }} >
                        {mapData?.map((m, i) => {
                            return <Grid item lg={3} xs={12} sm={6} md={4} key={m.id}>
                                <ProductItemCard productData={m} bgclr={'primaryColors.productListItemBg'} categoryName='Best Budget Smartphones' categoryType='Mobile' />
                            </Grid>
                        })}
                    </Grid>
                </>
            }
        </>
    )
}