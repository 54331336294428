import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MediaSlider from './mediaSlider';

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 600,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1
};

const BannerSlideindex = ({slideBasicArrow}) => {
  const images = ["/images/Slide1.png","/images/Slide2.png","/images/Slide3.png"]
  return (
      <MediaSlider>
        <Slider {...settings}>
          {images.map((slide, index) => (
            <Box
              key={index}
              sx={{
                position: 'relative',
                maxHeight: {xs: 200, sm: 390, md: 390, lg: 390},
                minHeight: {xs: 200, sm: 390, md: 390, lg: 390},
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                }}
              >
                <img src={slide} style={{ width: '100%', height:'95%' }} alt=''/>
              </Box>
            </Box>
          ))}
        </Slider>
      </MediaSlider>
  );
};

export default BannerSlideindex;

BannerSlideindex.propTypes = {
  BannerSlideindex: PropTypes.array,
};


