import { ORDER_LIST, TAX_DETAIL_WATCHER, VIEW_ORDER } from "../actionTypes/order";

const intialState = {
  orderData: [],
  orderDetail: {},
  taxDetail: {}
};


export default function orderReducers(state = intialState, action) {
  switch (action.type) {

    case ORDER_LIST:
      return { ...state, orderData: action.payload };

    case VIEW_ORDER:
      return { ...state, orderDetail: action.payload };

    case TAX_DETAIL_WATCHER:
      return { ...state, taxDetail: action.payload };

    default:
      return state;
  }
}
