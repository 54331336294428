import { takeEvery, call, put } from "redux-saga/effects";
import headPhonesService from "../../services/headPhones"
import { updateOpenLoader, updateAlert, updateFalseLoader } from '../actionCreators/common'
import { addHeadphonesTagCards, headphonesNewLaunchesList } from "../actionCreators/headPhones";
import { GET_HEADPHONES_NEW_LAUNCHES_WATCHER, GET_HEADPHONES_TAG_CARDS_WATCHER } from "../actionTypes/headPhones";
import commonService from '../../services/common'


function* newLaunchesListEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(
      headPhonesService.getAllHeadPhonesNewLaunchesList,
      action.payload
    );

    yield put(headphonesNewLaunchesList(data));
    yield put(updateFalseLoader())
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* getHeadphonesCategoryTagEffect(action) {
  try {
    yield put(updateOpenLoader())
    let { data } = yield call(commonService.getCategoryByTagIdData, action.payload);
    yield put(addHeadphonesTagCards(data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

export const HeadPhonesProductSaga = [
  takeEvery(GET_HEADPHONES_NEW_LAUNCHES_WATCHER, newLaunchesListEffect),
  takeEvery(GET_HEADPHONES_TAG_CARDS_WATCHER, getHeadphonesCategoryTagEffect)
];