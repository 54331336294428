import { WEARABLES_NEW_LAUNCHES_LIST, GET_WEARABLES_NEW_LAUNCHES_WATCHER, GET_APPLE_WATCH_WATCHER, APPLE_WATCH_LIST, GET_NOISE_WATCH_WATCHER, NOISE_WATCH_LIST, GET_SAMSUNG_WATCH_WATCHER, SAMSUNG_WATCH_LIST, GET_SHOP_BY_BRAND_WATCHER, GET_SHOP_BY_PRICE_WATCHER, SHOP_BY_BRAND_LIST, SHOP_BY_PRICE_LIST, GET_WEARABLES_TAG_CARDS_WATCHER, ADD_WEARABLES_TAG_CARDS } from "../actionTypes/wearables";

export function getWearablesNewLaunchesWatcher(data) {
    return { type: GET_WEARABLES_NEW_LAUNCHES_WATCHER, payload: data };
}

export function getAppleWatchWatcher(data) {
    return { type: GET_APPLE_WATCH_WATCHER, payload: data };
}

export function getNoiseWatchWatcher(data) {
    return { type: GET_NOISE_WATCH_WATCHER, payload: data };
}

export function getSamsungWatchWatcher(data) {
    return { type: GET_SAMSUNG_WATCH_WATCHER, payload: data };
}

export function getShopByBrandWatcher(data) {
    return { type: GET_SHOP_BY_BRAND_WATCHER, payload: data };
  }
  
export function getShopByPriceWatcher(data) {
    return { type: GET_SHOP_BY_PRICE_WATCHER, payload: data };
  }
 
export function getWearablesTagCardsWatcher(data) {
    return { type: GET_WEARABLES_TAG_CARDS_WATCHER, payload: data };
  }

// Redux state changing actionCreators
export function wearablesNewLaunchesList(data) {
    return { type: WEARABLES_NEW_LAUNCHES_LIST, payload: data};
}

export function appleWatchList(data) {
    return { type: APPLE_WATCH_LIST, payload: data};
}

export function noiseWatchList(data) {
    return { type: NOISE_WATCH_LIST, payload: data};
}

export function samsungWatchList(data) {
    return { type: SAMSUNG_WATCH_LIST, payload: data};
}

export function shopByBrandList(data) {
    return { type: SHOP_BY_BRAND_LIST, payload: data };
  }
  
export function shopByPriceList(data) {
    return { type: SHOP_BY_PRICE_LIST, payload: data };
  }
 
export function addWearablesTagCards(data) {
    return { type: ADD_WEARABLES_TAG_CARDS, data: data };
  }