// Login Watcher Actions

export const GET_WEARABLES_NEW_LAUNCHES_WATCHER = "GET_WEARABLES_NEW_LAUNCHES_WATCHER";
export const GET_APPLE_WATCH_WATCHER = "GET_APPLE_WATCH_WATCHER";
export const GET_NOISE_WATCH_WATCHER = "GET_NOISE_WATCH_WATCHER";
export const GET_SAMSUNG_WATCH_WATCHER = "GET_SAMSUNG_WATCH_WATCHER";
export const GET_SHOP_BY_BRAND_WATCHER = "GET_SHOP_BY_BRAND_WATCHER";
export const GET_SHOP_BY_PRICE_WATCHER = "GET_SHOP_BY_PRICE_WATCHER";
export const GET_WEARABLES_TAG_CARDS_WATCHER = "GET_WEARABLES_TAG_CARDS_WATCHER";

// Login Reducer Actions

export const WEARABLES_NEW_LAUNCHES_LIST = "WEARABLES_NEW_LAUNCHES_LIST";
export const APPLE_WATCH_LIST = "APPLE_WATCH_LIST";
export const NOISE_WATCH_LIST = "NOISE_WATCH_LIST";
export const SAMSUNG_WATCH_LIST = "SAMSUNG_WATCH_LIST";
export const SHOP_BY_BRAND_LIST = "SHOP_BY_BRAND_LIST";
export const SHOP_BY_PRICE_LIST = "SHOP_BY_PRICE_LIST";
export const ADD_WEARABLES_TAG_CARDS = "ADD_WEARABLES_TAG_CARDS"