// import axios from "axios";
import http from "../http-common";
import { WOOCOMMERCE_SERVICES } from "../http-common";
import { http1 } from "../http-common";

class commonService {

    getTagCardList(data) {
        return http.get(`/wp-json/wc/v3/products?tag=${data.tagId}&category=${data.cateId}&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    filterBasedList(data) {
        return http.get(`/wp-json/wc/v3/products?search=${data.searchVal}&min_price=${data.minPrice}&max_price=${data.maxPrice}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getCategoryData(id) {
        return http.get(`/wp-json/wc/v3/products/categories/${id}?consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getBrandData(id) {
        return http.get(`/wp-json/wc/v3/products/brands/${id}?consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getTagData(id) {
        return http.get(`/wp-json/wc/v3/products/tags/${id}?consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    AddRepairAndService(data) {
        return http1.post('/repair/addRepair', data)
    }

    contactUs(data) {
        return http1.post('/repair/contactUs', data)
    }

    filterTagBasedList(data) {
        let value= "min_price=1&"
        if(data.min_price !== undefined) {
            value = `min_price=${data.min_price}&`
        } 
        if(data.tag !== undefined) {
            value += `tag=${data.tag}&`
        } 
        if(data.category !== undefined) {
            value += `category=${data.category}&`
        }
        if(data.search !== undefined) {
            value += `search=${data.search}&`
        }          
        if(data.max_price !== undefined) {
            value += `max_price=${data.max_price}&`
        }  
        if(data.orderby !== undefined) {
            value += `orderby=${data.orderby}&`
        }
        if(data.offset !== undefined) {
            value += `offset=${data.offset}&`
        }
        if(data.page !== undefined) {
            value += `page=${data.page}&`
        }
        if(data.brand !== undefined) {
            value += `brand=${data.brand}&`
        }
        return http.get(`/wp-json/wc/v3/products?${value}per_page=${data.perPage||50}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }
    
    getCategoryByTagIdData(id) {
        return http.get(`/wp-json/wc/v3/products/categories?parent=${id}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }


}

export default new commonService();