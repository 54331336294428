import React, { useEffect, useRef } from 'react'
import { Typography, Grid, Box, TextField, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { addAddress, deleteAddress, getAddress, updateAddress, updateUserWatcher } from '../../../store/actionCreators/login'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { updateAlert } from '../../../store/actionCreators/common'


export default function AddressessDesign() {
    const formref = useRef(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    let mobileref = useRef()
    let houseref = useRef()
    let arearef = useRef()
    let districtref = useRef()
    let stateref = useRef()
    let pincoderef = useRef()

    const Schema = Yup.object().shape({
        addressType: Yup.string().required('Type is required'),
        mobileNumber: Yup.string().required('Phone Number is required').min(10, 'enter valid Phone number').max(10, 'enter valid Phone number'),
        house: Yup.string().required('House is required'),
        area: Yup.string().required('Area is required'),
        district: Yup.string().required('District is required'),
        state: Yup.string().required('State is required'),
        pincode: Yup.string().required('Pincode is required').min(6, 'enter valid pincode').max(6, 'enter valid pincode'),
    });

    const { loginReducers: { userData, address } } = useSelector(state => (state))
    console.log(address, "address");
    const { addressType, mobileNumber, house, area, pincode, district, state, userId } = userData[0] || {}
    const [click, setclick] = useState(false)
    const [form, setForm] = useState()
    const [mode, setMode] = useState("initial")

    const setValues = () => {
        let timeout = setTimeout(() => {
            if (userData?.length > 0 && formref.current !== undefined) {
                formref.current?.setFieldValue("addressType", userData[0]?.addressType)
                formref.current?.setFieldValue("mobileNumber", userData[0]?.mobileNumber)
                formref.current?.setFieldValue("house", userData[0]?.house)
                formref.current?.setFieldValue("area", userData[0]?.area)
                formref.current?.setFieldValue("pincode", userData[0]?.pincode)
                formref.current?.setFieldValue("district", userData[0]?.district)
                formref.current?.setFieldValue("state", userData[0]?.state)
            }
            clearTimeout(timeout)
            console.log(formref.current, "formref");
        }, 200);
    }

    useEffect(() => {
        if (!userData.length) navigate('/login')
        if (userData?.length > 0 && formref.current !== undefined) {
            formref.current?.setFieldValue("addressType", userData[0]?.addressType)
            formref.current?.setFieldValue("mobileNumber", userData[0]?.mobileNumber)
            formref.current?.setFieldValue("house", userData[0]?.house)
            formref.current?.setFieldValue("area", userData[0]?.area)
            formref.current?.setFieldValue("pincode", userData[0]?.pincode)
            formref.current?.setFieldValue("district", userData[0]?.district)
            formref.current?.setFieldValue("state", userData[0]?.state)
        }
        console.log(!userData[0]?.pincode, userData[0]?.pincode, "name");
        if (!userData[0]?.pincode) {
            setclick(true)
        }
    }, [userData, navigate, click])

    useEffect(() => {
        if (userData?.length > 0) {
            dispatch(getAddress(userData[0]?.userId))
        }
    }, [userData])

    const updatefunction = (values) => {
        console.log(userData, "val");
        const ischeck = Object.values(values).filter((x) => Object.values(userData[0]).some((y) => x === y))
        console.log(values, formref.current?.errors, "addressess");
        if (Object.values(values).length !== ischeck.length) {
            dispatch(updateUserWatcher(values, userData[0]?.userId, () => {
                dispatch(updateAlert('address updated successfully!', 'success'))
            }))
        }
    }

    const handleCancel = () => {
        setMode("initial")
    };

    const addAddressHandler = (val) => {
        let data = {
            "userId": userId,
            "firstName": "",
            "lastName": "",
            "addressType": val?.addressType,
            "house": val?.house,
            "area": val?.area,
            "district": val?.district,
            "state": val?.state,
            "pincode": val?.pincode,
            "mobileNumber": val?.mobileNumber
        }
        dispatch(addAddress(data, () => { setMode("initial") }))
    }

    return (
        <div>
            {mode === "initial" && (<Box p="15px" backgroundColor="#FFFFFF" borderRadius="8px" marginTop="20px">
                <Box backgroundColor="#F8F8F8" borderRadius="8px" marginTop="20px">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8} md={8} lg={9} margin="10px">
                            <Typography variant="p16_700"> Add your address </Typography> <br />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={2} padding="20px" justifyContent="right" display="flex">
                            <Button onClick={() => { setMode("new") }} style={{ width: "92px", height: "48px", backgroundColor: "#F26B23", color: "white", textTransform: "none", borderRadius: "5px", "&:hover": { color: "#F26B23", backgroundColor: "#F26B23" } }}>Add</Button>
                            {/* <Button onClick={() => { setMode("edit"); setValues() }} sx={{ width: "92px", margin:"5px", height: "48px", backgroundColor: "#F26B23", borderRadius: "5px", "&:hover": { color: "#F26B23", backgroundColor: "#F26B23" } }}>
                                <Typography sx={{ color: "primaryColors.loginWhite", textTransform: "none" }}> Edit </Typography>
                            </Button> */}
                        </Grid>
                    </Grid>

                </Box>
            </Box>)}

            {mode === "new" &&
                <Box p="15px" backgroundColor="#FFFFFF" borderRadius="8px" marginTop="20px">
                    <Box backgroundColor="#F8F8F8" borderRadius="8px" marginTop="20px">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={7} margin="10px">
                                <Typography variant="p22_600"> Add Address </Typography> <br />
                                {/* <Typography variant="p16_700"> Primary - Home</Typography> */}
                            </Grid>
                        </Grid>
                    </Box>
                    <Formik
                        initialValues={{
                            addressType: "", mobileNumber: "", house: "", area: "", district: "", state: "", pincode: ""
                        }}
                        innerRef={formref}
                        validationSchema={Schema}
                        onSubmit={(values) => {
                            if (mode === "new") {
                                addAddressHandler(values)
                            }
                            else {
                                updatefunction(values)
                            }
                        }}
                    >
                        {({ errors, touched, handleSubmit, values, setFieldValue }) => (
                            <Form>
                                <Grid container padding="15px" spacing={3}>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField fullWidth label="Address type" variant='outlined' type='text' value={values.addressType} placeholder="address Type" onChange={(e) => setFieldValue("addressType", e.target.value)}
                                            error={Boolean(touched.addressType && errors.addressType)}
                                            helperText={touched.addressType && errors.addressType}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    mobileref.current?.focus()
                                                }
                                            }} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField fullWidth label="Phone number" variant='outlined' type='number' value={values.mobileNumber} placeholder="Phone Number" onChange={(e) => setFieldValue("mobileNumber", e.target.value)}
                                            error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                                            helperText={touched.mobileNumber && errors.mobileNumber}
                                            inputRef={mobileref}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    houseref.current?.focus()
                                                }
                                            }} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField fullWidth label="House" variant='outlined' value={values.house} placeholder="House" onChange={(e) => setFieldValue("house", e.target.value)}
                                            error={Boolean(touched.house && errors.house)}
                                            helperText={touched.house && errors.house}
                                            inputRef={houseref}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    arearef.current?.focus()
                                                }
                                            }} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField fullWidth label="Area" variant='outlined' value={values.area} placeholder="Area" onChange={(e) => setFieldValue("area", e.target.value)}
                                            error={Boolean(touched.area && errors.area)}
                                            helperText={touched.area && errors.area}
                                            inputRef={arearef}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    districtref.current?.focus()
                                                }
                                            }} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField fullWidth label="District" variant='outlined' value={values.district} placeholder="District" onChange={(e) => setFieldValue("district", e.target.value)}
                                            error={Boolean(touched.district && errors.district)}
                                            helperText={touched.district && errors.district}
                                            inputRef={districtref}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    stateref.current?.focus()
                                                }
                                            }} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField fullWidth label="State" variant='outlined' value={values.state} placeholder="State" onChange={(e) => setFieldValue("state", e.target.value)}
                                            error={Boolean(touched.state && errors.state)}
                                            helperText={touched.state && errors.state}
                                            inputRef={stateref}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    pincoderef.current?.focus()
                                                }
                                            }} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField fullWidth label="Pincode" variant='outlined' type='number' value={values.pincode} placeholder="Pincode" onChange={(e) => setFieldValue("pincode", e.target.value)}
                                            error={Boolean(touched.pincode && errors.pincode)}
                                            helperText={touched.pincode && errors.pincode}
                                            inputRef={pincoderef}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    formref.current?.handleSubmit()
                                                }
                                            }} />
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                    <Grid item xs={12} sm={12} md={12} lg={12} gap={2} padding="20px" >
                        <Grid container gap={2} justifyContent="right" display="flex" >
                            <Grid>
                                <Button onClick={handleCancel} sx={{ color: "primaryColors.checkoutAccountdesc", width: "92px", height: "48px", borderRadius: "5px", backgroundColor: "primaryColors.checkoutAccountdesc", "&:hover": { color: "primaryColors.checkoutAccountdesc", backgroundColor: "primaryColors.checkoutAccountdesc" } }}>
                                    <Typography sx={{ color: "primaryColors.loginWhite", textTransform: "none" }} > cancel </Typography>
                                </Button>
                            </Grid>
                            <Grid>
                                <Button onClick={() => { formref.current?.handleSubmit() }} sx={{ width: "92px", height: "48px", backgroundColor: "orange.main", borderRadius: "5px", "&:hover": { color: "orange.main", backgroundColor: "orange.main" } }}>
                                    <Typography sx={{ color: "primaryColors.loginWhite", textTransform: "none" }}> save </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>}
            {address?.map((v, i) => {
                return <AddressCard item={v} />
            })}
        </div>
    )
}
const AddressCard = ({ item }) => {
    console.log(item, "itemadd");
    const { loginReducers: { userData } } = useSelector(state => (state))
    const [mode, setMode] = useState("initial")
    const formref = useRef(null)
    const dispatch = useDispatch()
    let mobileref = useRef()
    let houseref = useRef()
    let arearef = useRef()
    let districtref = useRef()
    let stateref = useRef()
    let pincoderef = useRef()

    const Schema = Yup.object().shape({
        addressType: Yup.string().required('Type is required'),
        mobileNumber: Yup.string().required('Phone Number is required').min(10, 'enter valid Phone number').max(10, 'enter valid Phone number'),
        house: Yup.string().required('House is required'),
        area: Yup.string().required('Area is required'),
        district: Yup.string().required('District is required'),
        state: Yup.string().required('State is required'),
        pincode: Yup.string().required('Pincode is required').min(6, 'enter valid pincode').max(6, 'enter valid pincode'),
    });

    const setValues = () => {
        let timeout = setTimeout(() => {
            if (userData?.length > 0 && formref.current !== undefined) {
                formref.current?.setFieldValue("addressType", item?.addressType)
                formref.current?.setFieldValue("mobileNumber", item?.mobileNumber)
                formref.current?.setFieldValue("house", item?.house)
                formref.current?.setFieldValue("area", item?.area)
                formref.current?.setFieldValue("pincode", item?.pincode)
                formref.current?.setFieldValue("district", item?.district)
                formref.current?.setFieldValue("state", item?.state)
            }
            clearTimeout(timeout)
            console.log(formref.current, "formref");
        }, 200);
    }

    const updatefunction = (values) => {
        const ischeck = Object.values(values).filter((x) => Object.values(item).some((y) => x === y))
        console.log(values, formref.current?.errors, "addressess");
        if (Object.values(values).length !== ischeck.length) {
            dispatch(updateAddress({ ...values, userId: userData[0]?.userId }, item?.id, () => {
                dispatch(updateAlert('address updated successfully!', 'success'))
                handleCancel()
            }))
        }
    }

    const deleteHandler = () => {
        dispatch(deleteAddress(item?.id, userData[0]?.userId, () => {
            dispatch(updateAlert('address deleted successfully!', 'success'))
            handleCancel()
        }))
    }

    const handleCancel = () => {
        setMode("initial")
    };

    return (
        <div>
            {mode === "initial" && (<Box p="15px" backgroundColor="#FFFFFF" borderRadius="8px" marginTop="20px">
                <Box backgroundColor="#F8F8F8" borderRadius="8px" marginTop="20px">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} margin="10px">
                            <Typography variant="p16_700"> Address type </Typography> <br />
                            <Typography variant="p16_500" paddingTop="10px"> {item?.addressType}</Typography>
                        </Grid>

                    </Grid>

                </Box>
                <Grid container padding="15px">
                    <Grid item xs={6} sm={6} md={6} lg={3}>
                        <Typography variant="p16_500" color="primaryColors.loginText" paddingLeft="25px"> Phone Number </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={9}>
                        <Typography variant="p16_500" color="primaryColors.loginText"> Address </Typography>
                    </Grid>
                </Grid>
                <Grid container padding="15px">
                    <Grid item xs={6} sm={6} md={6} lg={3}>
                        <Typography variant="p14_400" color="primaryColors.checkoutAccountdesc" paddingLeft="25px"> {item?.mobileNumber} </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={9}>
                        <Typography variant="p14_400" color="primaryColors.checkoutAccountdesc">
                            {item?.house}, {item?.area}, {item?.district}, {item?.state}, {item?.pincode}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} justifyContent="end" display="flex">
                    <Button onClick={() => { deleteHandler() }} style={{ width: "92px", margin: "5px", height: "48px", backgroundColor: "#F26B23", color: "white", textTransform: "none", borderRadius: "5px", "&:hover": { color: "#F26B23", backgroundColor: "#F26B23" } }}> Delete </Button>
                    <Button onClick={() => { setMode("edit"); setValues() }} sx={{ width: "92px", margin: "5px", height: "48px", backgroundColor: "#F26B23", borderRadius: "5px", "&:hover": { color: "#F26B23", backgroundColor: "#F26B23" } }}>
                        <Typography sx={{ color: "primaryColors.loginWhite", textTransform: "none" }}> Edit </Typography>
                    </Button>
                </Grid>
            </Box>)}
            {mode === "edit" && (<Box p="15px" backgroundColor="#FFFFFF" borderRadius="8px" marginTop="20px">
                <Box backgroundColor="#F8F8F8" borderRadius="8px" marginTop="20px">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={7} margin="10px">
                            <Typography variant="p22_600"> Modify Address </Typography> <br />
                        </Grid>
                    </Grid>

                </Box>
                <Formik
                    initialValues={{
                        addressType: "", mobileNumber: "", house: "", area: "", district: "", state: "", pincode: ""
                    }}
                    innerRef={formref}
                    validationSchema={Schema}
                    onSubmit={(values) => {
                        updatefunction(values)
                    }}
                >
                    {({ errors, touched, handleSubmit, values, setFieldValue }) => (
                        <Form>
                            <Grid container padding="15px" spacing={3}>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField fullWidth label="AddressType" variant='outlined' type='text' value={values.addressType} placeholder="Address Type" onChange={(e) => setFieldValue("addressType", e.target.value)}
                                        error={Boolean(touched.addressType && errors.addressType)}
                                        helperText={touched.addressType && errors.addressType}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                mobileref.current?.focus()
                                            }
                                        }} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField fullWidth label="PhoneNumber" variant='outlined' type='number' value={values.mobileNumber} placeholder="Phone Number" onChange={(e) => setFieldValue("mobileNumber", e.target.value)}
                                        error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                                        helperText={touched.mobileNumber && errors.mobileNumber}
                                        inputRef={mobileref}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                houseref.current?.focus()
                                            }
                                        }} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField fullWidth label="House" variant='outlined' value={values.house} placeholder="House" onChange={(e) => setFieldValue("house", e.target.value)}
                                        error={Boolean(touched.house && errors.house)}
                                        helperText={touched.house && errors.house}
                                        inputRef={houseref}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                arearef.current?.focus()
                                            }
                                        }} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField fullWidth label="Area" variant='outlined' value={values.area} placeholder="Area" onChange={(e) => setFieldValue("area", e.target.value)}
                                        error={Boolean(touched.area && errors.area)}
                                        helperText={touched.area && errors.area}
                                        inputRef={arearef}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                districtref.current?.focus()
                                            }
                                        }} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField fullWidth label="District" variant='outlined' value={values.district} placeholder="District" onChange={(e) => setFieldValue("district", e.target.value)}
                                        error={Boolean(touched.district && errors.district)}
                                        helperText={touched.district && errors.district}
                                        inputRef={districtref}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                stateref.current?.focus()
                                            }
                                        }} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField fullWidth label="State" variant='outlined' value={values.state} placeholder="State" onChange={(e) => setFieldValue("state", e.target.value)}
                                        error={Boolean(touched.state && errors.state)}
                                        helperText={touched.state && errors.state}
                                        inputRef={stateref}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                pincoderef.current?.focus()
                                            }
                                        }} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField fullWidth label="Pincode" variant='outlined' type='number' value={values.pincode} placeholder="Pincode" onChange={(e) => setFieldValue("pincode", e.target.value)}
                                        error={Boolean(touched.pincode && errors.pincode)}
                                        helperText={touched.pincode && errors.pincode}
                                        inputRef={pincoderef}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                formref.current?.handleSubmit()
                                            }
                                        }} />
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
                <Grid item xs={12} sm={12} md={12} lg={12} gap={2} padding="20px" >
                    <Grid container gap={2} justifyContent="right" display="flex" >
                        <Grid>
                            <Button onClick={handleCancel} sx={{ color: "primaryColors.checkoutAccountdesc", width: "92px", height: "48px", borderRadius: "5px", backgroundColor: "primaryColors.checkoutAccountdesc", "&:hover": { color: "primaryColors.checkoutAccountdesc", backgroundColor: "primaryColors.checkoutAccountdesc" } }}>
                                <Typography sx={{ color: "primaryColors.loginWhite", textTransform: "none" }} > cancel </Typography>
                            </Button>
                        </Grid>
                        <Grid>
                            <Button onClick={() => { formref.current?.handleSubmit() }} sx={{ width: "92px", height: "48px", backgroundColor: "orange.main", borderRadius: "5px", "&:hover": { color: "orange.main", backgroundColor: "orange.main" } }}>
                                <Typography sx={{ color: "primaryColors.loginWhite", textTransform: "none" }}> update </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            )}
        </div>
    )
}