import React, { useEffect } from 'react'
import { Typography, Grid, Box, useMediaQuery, Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import OrderDesign from './orderDesign'
import { getOrderWatcher } from '../../../store/actionCreators/order'
import SideBar from '../sideBar'
import Screenwise from '../../../components/ProductListItemCard/screenwise'
import SmallSideBar from '../smallSideBar'
import { addBulkCartWatcher } from '../../../store/actionCreators/cart'
import { getMultipleProductDataWatcher } from '../../../store/actionCreators/product'
import SEO from '../../../utils/SEO'
import ReactToPrint from 'react-to-print'
import Invoice from '../../OrderConfirmation/invoice'
import { useState } from 'react'
import FileCopyIcon from '@mui/icons-material/FileCopy';

export default function Orders() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const match = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const dispatch = useDispatch()
  const navigate = useNavigate()
  let componentRef;

  const { loginReducers: { userData }, orderReducers: { orderData }, cartReducers: { cartProductData } } = useSelector(state => state)
  console.log(orderData, "orderData");
  console.log(cartProductData, "cartproductdata");

  useEffect(() => {
    if (userData.length) {
      dispatch(getOrderWatcher(userData[0]?.wooId))
    }
  }, [dispatch, userData])

  useEffect(() => {
    if (!userData.length) navigate('/login')
  }, [userData, navigate])

  const reOrderFunc = (products) => {
    if (products.length) {
      let addProdID = products.map(d => {
        return { userId: userData[0].userId, productId: d.product_id, quantity: 1, variantId: d.variation_id }
      })
      // dispatch(getMultipleProductDataWatcher(addProdID, (res) => {
      //   dispatch(addBulkCartWatcher(res, userData[0].userId, (res) => {
      //     navigate('/cart')
      //   }, dispatch))
      // }))
      dispatch(addBulkCartWatcher(addProdID, userData[0].userId, (res) => {
        navigate('/cart')
      }, dispatch))
    }
    else {
      navigate('/cart')
    }
  }

  return (
    <>
      <SEO title='Orders' description='Your Orders' />
      <section className="sectionOrder">
        <Box backgroundColor="primaryColors.loginBg">
          <Box display='flex' backgroundColor="primaryColors.loginWhite" color="primaryColors.textPath">
            <Typography sx={{ color: 'primaryColors.breadcrumbs', '&:hover': { textDecoration: 'underline' }, paddingRight: '10px' }} to='/' component={Link} variant="p14_500"> Home </Typography>
            <Typography variant="p13_500" sx={{ color: 'primaryColors.cartText', paddingRight: '10px' }}> &gt; </Typography>
            <Typography variant="p14_500" sx={{ color: 'grey[200]' }}> Orders </Typography>
          </Box>
          <Grid container>
            <Grid item xs={12} sm={12} md={2.8} lg={2.6} padding="27px 20px 0px 32px">
              {match ? <SideBar /> : <SmallSideBar />}
            </Grid>
            <Grid item xs={12} sm={12} md={9.2} lg={9.4} padding="40px 40px 40px 35px">
              <Typography variant="p24_700"> Orders </Typography>
              <br />
              <br />
              <Typography variant="p14_500" color="primaryColors.checkoutOrderDesc" paddingTop="18px"> see how your orders are managed and check the latest status on your order </Typography>
              <Grid container gap={2}>
              { orderData?.length ?
                  orderData?.map((v, i) => {
                    return <Grid lg={5.5} md={12} sm={12} xs={12} display="flex" flexDirection="column">
                      <OrderCard v={v} reOrderFunc={reOrderFunc} />
                    </Grid>
                  })
                  :
                  <Box justifyContent='center' alignItems='center' pt='30px'>
                    <Typography variant="p14_500">Recently you haven't purchased anything !</Typography>
                    <Box display='flex'>
                      <Typography variant="p14_500" sx={{ textDecoration: 'underline', color: 'secondary.main' }} to='/' component={Link}>Click here</Typography>
                      <Typography sx={{ pl: '7px' }} variant="p14_500">to make your first order !</Typography>
                    </Box>
                  </Box>
              }
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </section>
    </>
  )
}

const OrderCard = ({ v, reOrderFunc }) => {

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const match = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <>
      <Invoice data={v} open={open} handleClose={handleClose} />
      <Box
        p="35px"
        backgroundColor="primaryColors.loginWhite"
        borderRadius="8px"
        marginTop="20px"
        width='100%'
      >
        <Grid container>
          <Grid lg={9} md={9} sm={9} xs={9}>
            {v?.line_items?.length > 0 &&
              v?.line_items?.map((val) => {
                return (
                  <div>
                    {matches ? <OrderDesign value={val} meta_data={v?.meta_data} /> : <Screenwise productData={val} pageType='profileOrder' />}
                  </div>)
              })}
          </Grid>
          <Grid lg={3} md={3} sm={3} xs={3}>
            <Box
              mb="10px"
              height='100%'
              backgroundColor="primaryColors.profileBg"
              marginRight="50px"
              borderRadius="8px"
              display="flex" justifyContent="space-between"
              width='100%'>

              <Grid container display="flex" flexDirection="row">
                <Grid lg={12} md={12} sm={12} xs={12} paddingTop="10px" justifyContent="center" display="flex">
                  <Box >

                    <Typography variant='p14_500' color="primaryColors.cartText"> Order Placed </Typography> <br />
                    <Typography variant='p12_700' color="primaryColors.checkoutOrderDesc"> {v?.date_paid?.slice(0, 10)} </Typography>

                  </Box>
                </Grid>
                <Grid lg={12} md={12} sm={12} xs={12} justifyContent="center" display="flex">
                  <Box >

                    <Typography variant='p14_500' color="primaryColors.cartText"> Order ID </Typography> <br />
                    <Typography variant='p12_700' color="primaryColors.checkoutOrderDesc"> {v?.id} </Typography>

                  </Box>
                </Grid>
                <Grid lg={12} md={12} sm={12} xs={12} justifyContent="center" display="flex">
                  <Box >
                    <Typography variant='p14_500' color="primaryColors.cartText"> Order Status </Typography> <br />
                    <Typography variant='p12_700' color="primaryColors.checkoutOrderDesc"> {v?.status} </Typography>
                  </Box>
                </Grid>
                <Grid lg={12} md={12} sm={12} xs={12} display="flex" alignItems="center" justifyContent="center">
                  {v?.status === "completed" && <Box style={{ cursor: "pointer" }}>
                    <FileCopyIcon onClick={() => { setOpen(true) }} />
                  </Box>}
                </Grid>
                <Grid lg={12} md={12} sm={12} xs={12} paddingTop="10px">
                  <Box justifyContent="center" display="flex">
                    {v?.status === "completed" &&
                      <Button sx={{ color: "white !important", border: '1px solid #F26B23' }} border='1px solid #F26B23' borderRadius="5px" onClick={() => reOrderFunc(v.line_items)}>
                        <Typography variant='p15_500' textTransform="none" sx={{ color: "secondary.main" }}> ReOrder </Typography>
                      </Button>
                    }
                  </Box>
                </Grid>

                <Grid lg={12} md={12} sm={12} xs={12} paddingTop="10px">
                  <Box justifyContent="center" display="flex">
                    <Link to={`/ViewDetails/${v?.id}`}>
                      <Button sx={{ width: '80px', height: '40px',backgroundColor: "secondary.main", "&:hover": { backgroundColor: "#F26B23" } }} borderRadius="5px" >
                        <Typography variant='p15_500' color="primaryColors.loginWhite" textTransform="none"> Details </Typography>
                      </Button>
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
