import { ACCESSORIES_BRANDS_LIST, ADD_HOME_LOADING, BEST_BUDGET_LIST, CASE_AND_COVERS_LIST, DEALS_LIST, GET_ACCESSORIES_BRANDS_WATCHER, GET_BEST_BUDGET_WATCHER, GET_BRAND_NEW_LAUNCHES_WATCHER, GET_CASE_AND_COVERS_WATCHER, GET_DEALS_WATCHER, GET_HEADPHONES_BRANDS_WATCHER, GET_HOME_NEW_LAUNCHES_WATCHER, GET_MOBILES_BRANDS_WATCHER, GET_PRODUCT_CARDS_WATCHER, GET_SAMSUNG_MOBILES_WATCHER, GET_SHOP_BY_BRAND_WATCHER, GET_SHOP_BY_PRICE_WATCHER, GET_SMART_WATCH_WATCHER, GET_TOP_RATED_PRODUCT_WATCHER, GET_WEARABLES_BRANDS_WATCHER, HEADPHONES_BRANDS_LIST, HOME_NEW_LAUNCHES_LIST, MOBILES_BRANDS_LIST, PRODUCT_CARDS_LIST, SAMSUNG_MOBILES_LIST, SHOP_BY_BRAND_LIST, SHOP_BY_PRICE_LIST, SMART_WATCH_LIST, TOP_RATED_PRODUCT_LIST, WEARABLES_BRANDS_LIST } from "../actionTypes/home";

export function getHomeNewLaunchesWatcher(data) {
    return { type: GET_HOME_NEW_LAUNCHES_WATCHER, payload: data };
  }
  
  export function getTopRatedProductWatcher(data) {
    return { type: GET_TOP_RATED_PRODUCT_WATCHER, payload: data };
  }
  
  export function getSmartWatchWatcher(data) {
    return { type: GET_SMART_WATCH_WATCHER, payload: data };
  }
  
  export function getSamsungMobilesWatcher(data) {
    return { type: GET_SAMSUNG_MOBILES_WATCHER, payload: data };
  }
  
  export function getShopByBrandWatcher(data) {
    return { type: GET_SHOP_BY_BRAND_WATCHER, payload: data };
  }
  
  export function getShopByPriceWatcher(data) {
    return { type: GET_SHOP_BY_PRICE_WATCHER, payload: data };
  }
  
  export function getBestBudgetWatcher(data) {
    return { type: GET_BEST_BUDGET_WATCHER, payload: data };
  }
  
  export function getDealsWatcher(data) {
    return { type: GET_DEALS_WATCHER, payload: data };
  }
  
  export function getProductCardsWatcher(data) {
    return { type: GET_PRODUCT_CARDS_WATCHER, payload: data };
  }
  
  export function getMobilesBrandsWatcher(data) {
    return { type: GET_MOBILES_BRANDS_WATCHER, payload: data };
  }
  
  export function getAccessoriesBrandsWatcher(data) {
    return { type: GET_ACCESSORIES_BRANDS_WATCHER, payload: data };
  }
  
  export function getWearablesBrandsWatcher(data) {
    return { type: GET_WEARABLES_BRANDS_WATCHER, payload: data };
  }
  
  export function getHeadphonesBrandsWatcher(data) {
    return { type: GET_HEADPHONES_BRANDS_WATCHER, payload: data };
  }
  
  // Redux state changing actionCreators
  export function homeNewLaunchesList(data) {
    return { type: HOME_NEW_LAUNCHES_LIST, payload: data };
  }
  
  export function topRatedProductList(data) {
    return { type: TOP_RATED_PRODUCT_LIST, payload: data };
  }
  
  export function smartWatchList(data) {
    return { type: SMART_WATCH_LIST, payload: data };
  }
  
  export function samsungMobilesList(data) {
    return { type: SAMSUNG_MOBILES_LIST, payload: data };
  }
  
  export function shopByBrandList(data) {
    return { type: SHOP_BY_BRAND_LIST, payload: data };
  }
  
  export function shopByPriceList(data) {
    return { type: SHOP_BY_PRICE_LIST, payload: data };
  }
  
  export function bestBudgetList(data) {
    return { type: BEST_BUDGET_LIST, payload: data };
  }
  
  export function dealsList(data) {
    return { type: DEALS_LIST, payload: data };
  }
  
  export function productCardsList(data) {
    return { type: PRODUCT_CARDS_LIST, payload: data };
  }

  export function addHomeLoading(key,data) {
    return { type: ADD_HOME_LOADING, data: data,key };
  }  
  
  export function mobilesBrandsList(data) {
    return { type: MOBILES_BRANDS_LIST, payload: data };
  }
  
  export function accessoriesBrandsList(data) {
    return { type: ACCESSORIES_BRANDS_LIST, payload: data };
  }
  
  export function wearablesBrandsList(data) {
    return { type: WEARABLES_BRANDS_LIST, payload: data };
  }
  
  export function headphonesBrandsList(data) {
    return { type: HEADPHONES_BRANDS_LIST, payload: data };
  }

