import { Grid, Typography } from '@mui/material'
import React from 'react'
import "./OrderConfirmation.css"
import { Box } from "@mui/system"
import Stepper from './stepper'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { orderByIdWatcher, updateOrderWatcher, verifyCashfreePaymentWatcher } from '../../store/actionCreators/cart'
import { useNavigate, useParams } from 'react-router-dom'
import ProductDesign from './productDesign'
// import { getMultipleProductDataWatcher } from '../../store/actionCreators/product'
import { Link } from "react-router-dom"
import { updateAlert } from '../../store/actionCreators/common'

export default function Index() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let { orderId } = useParams()
  const { cartReducers: { orderByIdData, cartProductData, cartWithoutLoginData }, loginReducers: { userData } } = useSelector(state => state)
  const cartData = userData.length ? cartProductData : cartWithoutLoginData
  console.log(cartData, "123456");

  useEffect(() => {
    if (userData.length) {

      dispatch(orderByIdWatcher(orderId, async (res) => {
        if (res?.status === 'pending') {
          const options = {
            method: 'GET',
            url: `https://sandbox.cashfree.com/pg/orders/${orderId}`,
            headers: {
              accept: 'application/json',
              'x-api-version': '2022-01-01',
              'content-type': 'application/json',
              'x-client-id': process.env.REACT_APP_CASHFREE_API_ID,
              'x-client-secret': process.env.REACT_APP_CASHFREE_SECRET_KEY
            }
          };
          dispatch(verifyCashfreePaymentWatcher(options, (res1) => {
            if (res1?.order_status === 'PAID') {
              const updateData = {
                set_paid: true,
                status: 'processing',
                payment_method: res1?.payment_method,
                payment_method_title: res1?.payment_method_title,
                transaction_id: res1?.transaction_id
              }

              const msgData = {
                orderId,
                userName: userData[0].firstName,
                mobileNumber: userData[0].mobileNumber,
                amount: res1.order_amount,
                orderItems: res?.line_items.map(d => d.name)
              }

              dispatch(updateOrderWatcher(updateData, orderId, msgData, res => {
                navigate(`/orderConfirmation/${orderId}`)
                //  setPayStatus(true)
              },))
            }
            else {
              dispatch(updateAlert('Payment failed!', 'error'));
              navigate(`/checkout`)
              //  setPayStatus(false)
            }
          }))
        }

      }))
    }
    else {
      navigate('/')
    }
  }, [dispatch, navigate, orderId, userData])

  // useEffect(() => {
  //   const productId = orderByIdData?.line_items?.map(m => m.product_id).join()
  //   dispatch(getMultipleProductDataWatcher(productId))
  // }, [])

  const cartItemTotalFunc = () => {
    return orderByIdData?.line_items?.reduce((a, b) => {
      let price = +b.price * b.quantity
      return +a + +price
    }, 0)
  }

  const sumOfdisc = () => {
    return cartData?.reduce((a, b) => {
      let qty = b.quantity || 1
      let price = +b.regular_price - b.price
      return +a + +price * qty
    }, 0)
  }
  let discountPrice = orderByIdData?.meta_data?.find(v => v?.key == "discount_price")?.value || 0
  let cartTotal = parseFloat(orderByIdData?.total) - parseFloat(orderByIdData?.total_tax)
  return (
    <>
      <div className="Bg">

        <Box display='flex' backgroundColor="primaryColors.loginWhite" color="primaryColors.textPath" p='80px 0 10px 50px'>
          <Typography sx={{ color: 'primaryColors.breadcrumbs', '&:hover': { textDecoration: 'underline' }, paddingRight: '10px' }} to='/' component={Link} variant="p14_500"> Home </Typography>
          <Typography variant="p13_500" sx={{ color: 'primaryColors.cartText', paddingRight: '10px' }}> &gt; </Typography>
          <Typography variant="p14_500" sx={{ color: 'grey[200]' }}> Checkout </Typography>
        </Box>

        <section style={{ font: "bold 22px/63px Georgia", style: "normal", paddingTop: "10px", paddingLeft: "43px" }}>
          Order Summary
        </section>

        <section className="Tabular">
          <div style={{ textAlign: 'center', font: "bold 22px/63px Georgia" }}> Congratulations, {orderByIdData?.billing?.first_name} </div>
          <div style={{ textAlign: 'center' }}> Your Order has been placed. An email confirmation has </div>
          <div style={{ paddingTop: "3px", position: "static", textAlign: 'center' }}> been sent to {orderByIdData?.billing?.email} </div>
          <div style={{ textAlign: 'center', font: "bold 22px/63px Georgia" }}> Order Status </div>

          <Box width='100%' display='flex' justifyContent='center' mb='20px'>
            <Box sx={{ width: '1000px' }}>
              <Stepper />
            </Box>
          </Box>

        </section>
        <br />

        <section>
          <div style={{ padding: "25px 50px" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={7} md={7} sm={12}>
                  {orderByIdData?.line_items?.map(m => {
                    console.log(orderByIdData, "iddata");
                    return <Box mt='10px'>
                      <ProductDesign pageType='placeOrder' data={m} meta_data={orderByIdData?.meta_data} />
                    </Box>

                  })}
                </Grid>
                <Grid item xs={12} lg={5} md={5} sm={12}>
                  <div className='Tab'>
                    <Box sx={{ flexGrow: 1 }}>
                      <div style={{ fontSize: 22, fontWeight: "bolder", paddingTop: "17px", paddingLeft: "15px" }}> Order Summary </div>
                      <br />
                      <br></br>
                      <Grid container spacing={2}>
                        <Grid item xs={7}>
                          <div style={{ paddingLeft: "15px", textAlign: "left" }}>Order Id</div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ paddingLeft: "15px", textAlign: "right" }}>#{orderByIdData?.id}</div>
                        </Grid>
                        <Grid item xs={7}>
                          <div style={{ paddingLeft: "15px", textAlign: "left" }}>Cart Total</div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ paddingLeft: "15px", textAlign: "right" }}>₹ {Math.round(parseFloat(cartTotal).toFixed(2))}</div>
                        </Grid>
                        <Grid item xs={7}>
                          <div style={{ paddingLeft: "15px", textAlign: "left" }}>Delivery Charges</div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ paddingLeft: "15px", textAlign: "right" }}>₹ {orderByIdData?.shipping_total}</div>
                        </Grid>
                        <Grid item xs={7}>
                          <div style={{ paddingLeft: "15px", textAlign: "left" }}>Discounts</div>
                        </Grid>
                        <Grid item xs={4}>
                          <a href="#/" >
                            <div style={{ paddingLeft: "15px", textAlign: "right" }}>-₹ {discountPrice}</div>
                          </a>
                        </Grid>
                        <Grid item xs={7}>
                          <div style={{ paddingLeft: "15px", textAlign: "left" }}>Tax</div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ paddingLeft: "15px", textAlign: "right" }}>{orderByIdData?.total_tax}</div>
                        </Grid>
                        <section className='Ln'></section>
                        <Grid item xs={7}>
                          <div style={{ paddingLeft: "15px", textAlign: "left", fontSize: 23, fontWeight: "bolder" }}>Grand Total</div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ textAlign: "right", fontSize: 23, fontWeight: "bolder" }}>₹ {orderByIdData?.total}</div>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                  <>
                    <section className='Bx' style={{ marginTop: '10px' }}>
                      <div style={{ fontSize: 22, fontWeight: "bolder", paddingTop: "17px" }}>Shipping Address</div>
                      <br />
                      <br />
                      <div>Home</div>
                      <br />
                      <div sx={{ color: "primaryColors.checkoutVisitStore", fontSize: 14 }}>
                        <div>{orderByIdData?.shipping?.address_1}, {orderByIdData?.shipping?.address_2},</div>
                        <div style={{ paddingTop: "3px" }}>{orderByIdData?.shipping?.city}, {orderByIdData?.shipping?.state} -  {orderByIdData?.shipping?.postcode}</div>
                        <div style={{ paddingTop: "3px" }}>{orderByIdData?.billing?.email}</div>
                      </div>
                    </section>
                  </>
                </Grid>
              </Grid>
            </Box>
          </div>
          <br />
        </section>
      </div>
    </>
  )
}
