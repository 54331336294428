import { Grid } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CartItemDesign from './cartItemDesign'
import { removeFromCartWatcher, removeCartWithoutLoginList } from '../../store/actionCreators/cart'
import Screenwise from '../ProductListItemCard/screenwise'
import useMediaQuery from '@mui/material/useMediaQuery';


const CartItemIndex = ({ pageType }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const dispatch = useDispatch()
  const { loginReducers: { userData }, cartReducers: { cartProductData, cartWithoutLoginData } } = useSelector((state) => state)

  const cartData = userData.length ? cartProductData : cartWithoutLoginData

  const removeFunction = (productData) => {
    console.log(userData,"itemindex");
    if (userData.length) {
      dispatch(removeFromCartWatcher(userData[0]?.userId, productData?.cartProductId, productData?.cartVariantId, dispatch))
    }
    else {
      const data = cartWithoutLoginData.filter(m => {
        // return +productData?.cartVariantId ? +m.cartVariantId !== +productData?.cartVariantId : +m.cartProductId !== +productData?.cartProductId
        return m?.id !== productData?.id
      })
      localStorage.setItem("cart", JSON.stringify(data))
      dispatch(removeCartWithoutLoginList(data))
      console.log(data,"datacart");

    }
  }

  return (
    <>
      <Grid container spacing={3} sx={{ pr: '20px', pb: '20px' }} >
        {cartData?.map((m, i) => {
          console.log("cartdata", cartData)
          return <Grid item lg={12} xs={12} key={m.id}>
            {matches ? <CartItemDesign productData={m} removeFunction={removeFunction} pageType={pageType} /> : <Screenwise productData={m} removeFunction={removeFunction} pageType={pageType} />}
          </Grid>
        })}
      </Grid>
    </>
  )
}
export default CartItemIndex
