import { Grid, Typography } from '@mui/material'
import React from 'react'
import "../../OrderConfirmation/OrderConfirmation.css"
import { Box } from "@mui/system"
import Stepper from '../../OrderConfirmation/stepper'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { singleOrderWatcher } from '../../../store/actionCreators/order'
import OrderDesign from './orderDesign'
import { Link } from 'react-router-dom'

export default function ViewDetails() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const dispatch = useDispatch()
  const { orderReducers: { orderDetail },cartReducers: { cartProductData, cartWithoutLoginData }, loginReducers: { userData } } = useSelector(state => state)
  let { id } = useParams()

  const cartData = userData.length ? cartProductData : cartWithoutLoginData
  console.log(cartData,"viewdata");

  useEffect(() => {
    if (id !== undefined) {
      dispatch(singleOrderWatcher(id))
    }
  }, [id, dispatch])

  const cartItemTotalFunc = () => {
    return orderDetail?.line_items?.reduce((a, b) => {
      let price = +b.price * b.quantity
      return +a + +price
    }, 0)
  }
  let discountPrice = orderDetail?.meta_data?.find(v => v?.key == "discount_price")?.value || 0 
  let cartTotal = parseFloat(orderDetail?.total)-parseFloat(orderDetail?.total_tax)
console.log(orderDetail,"orderDetail");
  return (
    <>
      <div className="Bg">
        <section>
        <Box display='flex' backgroundColor="primaryColors.loginWhite" color="primaryColors.textPath" p='80px 0 10px 50px'>
          <Typography sx={{ color: 'primaryColors.breadcrumbs', '&:hover': { textDecoration: 'underline' }, paddingRight: '10px' }} to='/' component={Link} variant="p14_500"> Home </Typography>
          <Typography variant="p13_500" sx={{ color: 'primaryColors.cartText', paddingRight: '10px' }}> &gt; </Typography>
          <Typography variant="p14_500" sx={{ color: 'grey[200]' }}> Orders </Typography>
        </Box>
        </section>
        <section style={{ font: "bold 22px/63px Georgia", style: "normal", paddingTop: "30px", paddingLeft: "43px" }}>
          Order Summary
        </section>

        <section className="Tabular">
          {/* <div style={{ textAlign:'center', font: "bold 22px/63px Georgia" }}> Congratulations, Refai </div>
          <div style={{ textAlign:'center' }}> Your Order has been placed. An email confirmation has </div>
          <div style={{ paddingTop: "3px", position: "static", textAlign:'center' }}> been sent to {orderDetail?.billing?.email} </div> */}
          <div style={{ textAlign: 'center', font: "bold 22px/63px Georgia" }}> Order Status </div>

          <Box width='100%' display='flex' justifyContent='center' mb='20px'>
            <Box sx={{ width: '1000px' }}>
              <Stepper />
            </Box>
          </Box>
        </section>
        <br />

        <section>
          <div style={{ padding: "25px 50px" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={7} md={7} sm={12}>
                  <>
                    {orderDetail?.line_items?.length > 0 &&
                      orderDetail?.line_items?.map((v, i) => {
                        return (<Box mt='10px'>
                          <OrderDesign value={v} meta_data={orderDetail?.meta_data} />
                        </Box>
                        )
                      })
                    }
                  </>
                </Grid>

                <Grid item xs={12} lg={5} md={5} sm={12}>
                  <div className='Tab'>
                    <Box sx={{ flexGrow: 1 }}>
                      <div style={{ fontSize: 22, fontWeight: "bolder", paddingTop: "17px", paddingLeft: "15px" }}> Order Summary </div>
                      <br />
                      <br></br>
                      <Grid container spacing={2}>
                        <Grid item xs={7}>
                          <div style={{ paddingLeft: "15px", textAlign: "left" }}>Order Id</div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ paddingLeft: "15px", textAlign: "right" }}>#{orderDetail?.id}</div>
                        </Grid>
                        <Grid item xs={7}>
                          <div style={{ paddingLeft: "15px", textAlign: "left" }}>Cart Total</div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ paddingLeft: "15px", textAlign: "right" }}>₹ {Math.round(parseFloat(cartTotal).toFixed(2))}</div>
                        </Grid>
                        <Grid item xs={7}>
                          <div style={{ paddingLeft: "15px", textAlign: "left" }}> GST </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ paddingLeft: "15px", textAlign: "right" }}>₹ {Math.round(orderDetail?.total_tax)}</div>
                        </Grid>
                        <Grid item xs={7}>
                          <div style={{ paddingLeft: "15px", textAlign: "left" }}>Delivery Charges</div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ paddingLeft: "15px", textAlign: "right" }}>₹ {orderDetail?.shipping_total}</div>
                        </Grid>
                        <Grid item xs={7}>
                          <div style={{ paddingLeft: "15px", textAlign: "left" }}>Discounts</div>
                        </Grid>
                        <Grid item xs={4}>
                          <a href="#/" >
                            <div style={{ paddingLeft: "15px", textAlign: "right" }}>-₹ {discountPrice}</div>
                          </a>
                        </Grid>
                        <section className='Ln'></section>
                        <Grid item xs={7}>
                          <div style={{ paddingLeft: "15px", textAlign: "left", fontSize: 23, fontWeight: "bolder" }}>Grand Total</div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ textAlign: "right", fontSize: 23, fontWeight: "bolder" }}>₹ {orderDetail?.total}</div>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                  <>
                    <section className='Bx' style={{ marginTop: '10px' }}>
                      <div style={{ fontSize: 22, fontWeight: "bolder", paddingTop: "17px" }}>Shipping Address</div>
                      <br />
                      <br />
                      <div>Home</div>
                      <br />
                      <div style={{ color: "primaryColors.checkoutVisitStore", fontSize: 14 }}>
                        <div>{orderDetail?.shipping?.address_1}, {orderDetail?.shipping?.address_2},</div>
                        <div style={{ paddingTop: "3px" }}>{orderDetail?.shipping?.city}, {orderDetail?.shipping?.state} -  {orderDetail?.shipping?.postcode}</div>
                        <div style={{ paddingTop: "3px" }}>{orderDetail?.billing?.email}</div>
                      </div>
                    </section>
                  </>
                </Grid>


                {/* <Grid item xs={12} lg={5} md={5} sm={12}>
                  <div className='Tab'>
                    <Box sx={{ flexGrow: 1 }}>
                      <div style={{ fontSize: 22, fontWeight: "bolder", paddingTop: "17px", paddingLeft: "15px" }}> Order Summary </div>
                      <br />
                      <br></br>
                      <Grid container spacing={2}>
                        <Grid item xs={7}>
                          <div style={{ paddingLeft: "15px", textAlign: "left" }}>Order Id</div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ paddingLeft: "15px", textAlign: "right" }}>#{orderDetail?.id}</div>
                        </Grid>
                        <Grid item xs={7}>
                          <div style={{ paddingLeft: "15px", textAlign: "left" }}>Cart Total</div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ paddingLeft: "15px", textAlign: "right" }}>₹ {cartItemTotalFunc()}</div>
                        </Grid>
                        <Grid item xs={7}>
                          <div style={{ paddingLeft: "15px", textAlign: "left" }}>Delivery Charges</div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ paddingLeft: "15px", textAlign: "right" }}>₹ {orderDetail?.shipping_total}</div>
                        </Grid>
                        <Grid item xs={7}>
                          <div style={{ paddingLeft: "15px", textAlign: "left" }}>Discounts</div>
                        </Grid>
                        <Grid item xs={4}>
                          <a href="#" >
                            <div style={{ paddingLeft: "15px", textAlign: "right" }}>-₹ {orderDetail.discount_total}</div>
                          </a>
                        </Grid>
                        <section className='Ln'></section>
                        <Grid item xs={7}>
                          <div style={{ paddingLeft: "15px", textAlign: "left", fontSize: 23, fontWeight: "bolder" }}>Grand Total</div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{  textAlign: "right", fontSize: 23, fontWeight: "bolder" }}>₹ {orderDetail?.total}</div>
                        </Grid>
                      </Grid>
                    </Box>

                  </div>
               
                  <>
                    <section className='Bx'>
                      <div style={{ fontSize: 22, fontWeight: "bolder", paddingTop: "17px" }}>Shipping Address</div>
                      <br />
                      <br />
                      <div>Home</div>
                      <br />
                      <div style={{ color: "#9b9b9b", fontSize: 14 }}>
                        <div>{orderDetail?.shipping?.address_1}, {orderDetail?.shipping?.address_2}</div>
                        <div style={{ paddingTop: "3px" }}>{orderDetail?.shipping?.city}, {orderDetail?.shipping?.state} - {orderDetail?.shipping?.postcode}</div>
                        <div style={{ paddingTop: "3px" }}>{orderDetail?.billing?.email}</div>
                      </div>
                    </section>
                  </>
                </Grid> */}
              </Grid>
            </Box>

          </div>
          <br />
        </section>

      </div>
    </>
  )
}
