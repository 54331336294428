import { Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Footer = () => {

  const { loginReducers: { userData } } = useSelector((state) => state)

  return (
    <footer className="footer clear">
      <aside className="footer-text left">
        <h3>Contact Information</h3>
        <p>
          <strong>Head Office</strong>
        </p>
        <p>
          No.87, 98, Velachery Rd,
          <br />
          Pallikaranai, Chennai – 600100.
          <br />
          Tamil Nadu, India.
          <br />
          support@phonebuy.com
        </p>
      </aside>
      <aside className="footer-nav left">
        <h3>Support</h3>
        <Link to={"/contactus"}>Contact Us</Link>
        <Link to="/siteMap">Site Map</Link>
      </aside>
      <aside className="footer-nav left">
        <h3>Policies</h3>
        <Link to="/termsCondition">T&C</Link>
        <Link to="/policy">Privacy Policy</Link>
        <Link to="/refund">
          Return, Replacement &<br />
          Refund Policy
        </Link>
      </aside>
      <aside className="footer-nav left">
        <h3>Policies</h3>
        <Link to="/aboutUs">About Us</Link>
        <Link to="/ourStores">Our Stores</Link>
      </aside>
      <aside className="right">
        <div className="social-media clear">
          <h3>Follow Us</h3>
          <a href="https://www.facebook.com/phonebuyindia" target="_blank" rel="noreferrer">
            <i className="fa fa-facebook"></i>
          </a>
          <a href="https://www.instagram.com/phonebuyindia/" target="_blank" rel="noreferrer">
            <i className="fa fa-instagram"></i>
          </a>
          <a href="https://twitter.com/phonebuyindia" target="_blank" rel="noreferrer">
            <i className="fa fa-twitter"></i>
          </a>
          {/* <a href="#/">
            <i className="fa fa-youtube"></i>
          </a> */}
        </div>
        <div className="applinks clear">
          <h3>Download our app</h3>

          <Grid container spacing={2}>

            <Grid item xs={12} lg={6} md={6} sm={12}>
              <a href="https://play.google.com/store/apps/details?id=com.phonebuy" target="_blank" rel="noreferrer">
                <img src="/images/Google-Play.svg" alt='' />
              </a>
            </Grid>

            <Grid item xs={12} lg={6} md={6} sm={12}>
              <a href="https://apps.apple.com/us/app/phonebuy/id1590150559" target="_blank" rel="noreferrer">
                <img src="/images/App-Store.svg" alt='' />
              </a>
            </Grid>

          </Grid>

        </div>
        <aside className="footer-nav">
          <h3> Payment Options </h3>
        <div style={{height:"100px", width:"500px"}}>
        <img src="/images/payment.png"/>
        </div>
      </aside>
      </aside>
      
      <div className="clear"></div>
      <footer className="footer-bottom clear">
        <p>
          Copyright © <span id="year"></span> PhoneBuy. All rights reserved.
        </p>
      </footer>
    </footer>
  );
};

export default Footer;
