// Login Watcher Actions

export const GET_TABLETS_NEW_LAUNCHES_WATCHER = "GET_TABLETS_NEW_LAUNCHES_WATCHER";
export const GET_SHOP_BY_BRAND_WATCHER = "GET_SHOP_BY_BRAND_WATCHER";
export const GET_SHOP_BY_PRICE_WATCHER = "GET_SHOP_BY_PRICE_WATCHER";

// Login Reducer Actions

export const TABLETS_NEW_LAUNCHES_LIST = "TABLETS_NEW_LAUNCHES_LIST";
export const SHOP_BY_BRAND_LIST = "SHOP_BY_BRAND_LIST";
export const SHOP_BY_PRICE_LIST = "SHOP_BY_PRICE_LIST";