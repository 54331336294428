import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ReactImageZoom from "react-image-zoom";
import "./style.css";



function ZoomingImage({ src }) {
  const match = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const props = {
    // width: match ? 300 : 250,
    // height: match ? 480 : 330 ,
    zoomWidth: 400,
    // zoomPosition: 'bottom',
  //   offset: [0,50], //{"vertical": 0, "horizontal": 50}
  //   img: "http://malaman.github.io/react-image-zoom/example/1.jpg"
  }
  return <Box><ReactImageZoom {...props} img={src} /></Box>
}
export default ZoomingImage;
