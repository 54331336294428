import { takeEvery, call, put } from "redux-saga/effects";
import accessoriesService from "../../services/accessories";
import {
  GET_ACCESSORIES_NEW_LAUNCHES_WATCHER,
  GET_ACCESSORIES_TAG_CARDS_WATCHER,
  GET_CARD_TAG_WATCHER,
  GET_CASE_AND_COVERS_WATCHER,
  GET_MEMORY_CARDS_WATCHER,
  GET_POWER_BANKS_WATCHER,
} from "../actionTypes/accessories";
import {
  accessoriesNewLaunchesList,
  addAccessoriesTagCards,
  cardTagList,
  caseAndCoversList,
  memoryCardsList,
  powerBanksList,
} from "../actionCreators/accessorires";
import { updateOpenLoader, updateAlert, updateFalseLoader } from '../actionCreators/common'
import commonService from '../../services/common'

function* newLaunchesListEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(
      accessoriesService.getAllAccessoriesNewLaunchesList,
      action.payload
    );

    yield put(accessoriesNewLaunchesList(data));
    yield put(updateFalseLoader())
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* caseAndCoversEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(
      accessoriesService.getAllAccessoriesCaseAndCoversList,
      action.payload
    );

    yield put(caseAndCoversList(data));
    yield put(updateFalseLoader())
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* memoryCardsEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(
      accessoriesService.getAllAccessoriesMemoryCardsList,
      action.payload
    );

    yield put(memoryCardsList(data));
    yield put(updateFalseLoader())
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* powerBanksEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(
      accessoriesService.getAllAccessoriesPowerBanksList,
      action.payload
    );

    yield put(powerBanksList(data));
    yield put(updateFalseLoader())
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* cardTagEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(
      accessoriesService.getCardTagList,
      action.payload
    );

    yield put(cardTagList(data));
    yield put(updateFalseLoader())
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* getAccessoriesCategoryTagEffect(action) {
  try {
    yield put(updateOpenLoader())
    let { data } = yield call(commonService.getCategoryByTagIdData, action.payload);
    yield put(addAccessoriesTagCards(data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

export const AccessoriesProductSaga = [
  takeEvery(GET_ACCESSORIES_NEW_LAUNCHES_WATCHER, newLaunchesListEffect),
  takeEvery(GET_CASE_AND_COVERS_WATCHER, caseAndCoversEffect),
  takeEvery(GET_MEMORY_CARDS_WATCHER, memoryCardsEffect),
  takeEvery(GET_POWER_BANKS_WATCHER, powerBanksEffect),
  takeEvery(GET_CARD_TAG_WATCHER, cardTagEffect),
  takeEvery(GET_ACCESSORIES_TAG_CARDS_WATCHER, getAccessoriesCategoryTagEffect),
];
