import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Stack, Box, Card } from '@mui/material';
import ProductItemCard from "../../components/ProductListItemCard"
import { useDispatch, useSelector } from "react-redux";
import { getProductBrandWatcher, getShopByBrandWatcher, shopByBrandList } from "../../store/actionCreators/mobile"
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Link, useLocation } from "react-router-dom";
import { addMetaTagData, addPrimarycategory, filterTagBasedProductWatcher } from "../../store/actionCreators/common";
import { useInView } from 'react-intersection-observer';
import ProductBrandListCard from "../ProductBrandListCard";


export default function ShopByBrand({ categoryName = 'Home', categoryId, shopByBrandApi = getShopByBrandWatcher, page }) {
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const { mobileReducers: { shopByBrandMobileData, productBrandList } } = useSelector(state => state)
    console.log(productBrandList, "1234");
    const [selectedBrand] = useState({})
    const [filteredBrand, setFilteredBrand] = useState([])
    const mapData = shopByBrandMobileData.length ? shopByBrandMobileData : []
    const searchPath = '/search'

    const { ref, inView, entry } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    useEffect(() => {
        let data = {
            pageNO: 1,
            perPage: 4
        }
        if (inView) {
            dispatch(getProductBrandWatcher(data))
        }
    }, [inView])

    useEffect(() => {
        if (categoryName === 'Home') {
            setFilteredBrand(productBrandList)
        }
        else {
            setFilteredBrand(productBrandList.filter(m => {
                let productCatName = m?.categories?.toLowerCase().split(',')
                return productCatName.includes(categoryName?.toLowerCase())

            }))
        }
    }, [productBrandList, dispatch, categoryName])

    const viewFunction = (search) => {
        let data = {
            perPage: 100,
            search: search
        }
        dispatch(filterTagBasedProductWatcher(data))
    }

    const brandClick = (val) => {
        // let data = {
        //     perPage: 50,
        //     search: val?.name,
        //     name: val?.name
        // }
        // if (categoryId !== undefined) {
        //     data = { ...data, category: categoryId }
        // }
        // const pathName = window.location.pathname.substr(1,).toLowerCase()
        // dispatch(addPrimarycategory(pathName))
        // dispatch(filterTagBasedProductWatcher(data))
        // let meta_data = {title: val?.meta_title, description: val?.meta_description}
        // dispatch(addMetaTagData(meta_data))
    }

    return (
        <section className="container shop-by-brand padding-top-none clear" ref={ref}>
            {Boolean(productBrandList.length) &&
                <>
                    <h2 className="left">
                        Shop By Brand<small>Choose your favourite brand</small>
                    </h2>

                    <Grid item container spacing={3} ml='-7px'>
                        {filteredBrand?.map((m, i) => {
                            console.log(filteredBrand, "filterrr");
                            let brandName = m?.name?.toUpperCase()
                            let url = `/brand/${m?.name + "?brand=" + m?.slug || "ShopByBrand"}`
                            if (categoryId) {
                                url += "&category=" + categoryId
                            }
                            return <Grid item xs={12} sm={3} md={3} lg={2} >
                                <Link to={url} onClick={() => brandClick(m)} state={{ searchVal: m?.name }}>
                                    <Card sx={{ borderRadius: '6px', height: '100px', alignItems: 'center', cursor: 'pointer', justifyContent: 'center', backgroundColor: 'primaryColors.shopbybrand' }}>
                                        <Box display='flex' justifyContent='center' padding='25px'>
                                            <h3>{brandName}</h3>
                                        </Box>
                                    </Card>
                                </Link>
                            </Grid>
                        })}
                    </Grid>

                    {Boolean(mapData.length >= 4) &&
                        <Box display='flex'>
                            <Button variant='contained'
                                sx={{
                                    width: '130px', height: '40px', backgroundColor: 'orange.main',
                                    '&:hover': { backgroundColor: 'orange.main' }, textTransform: 'none', m: '20px 25px 10px auto'
                                }} width='100px' onClick={viewFunction}>
                                <Typography color='white !important'>View All</Typography>
                            </Button>
                        </Box>
                    }

                    {Object.values(selectedBrand).length ? mapData.length ?
                        <Grid item container spacing={3} sx={{ p: '20px' }} >
                            {mapData?.map((m, i) => {
                                return <Grid item lg={3} xs={12} sm={6} md={4} key={m.id}>
                                    <ProductBrandListCard productData={m} bgclr={'primaryColors.productListItemBg'} categoryName='Shop By Brand' categoryType={page} />
                                </Grid>
                            })}
                        </Grid>
                        :
                        <Box textAlign='center' pt='20px'>
                            <Typography variant='p20_500' >No records found</Typography>
                        </Box>
                        :
                        <br />
                    }
                </>
            }
        </section >
    )
}

