import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";


const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.orange.main,
  //   backgroundColor: purple[500],
  "&:hover": {
    backgroundColor: theme.palette.primaryColors.textWhite,
  },
  backgroundColor: theme.palette.primaryColors.textWhite,
  border: "2px solid",
  //   borderColor:theme.palette.orange,
  boxSizing: "border-box",
  borderRadius: "4px",
  textTransform: "none",
  width: "160px",
}));

export default ColorButton;
