// import { DataArrayRounded } from "@mui/icons-material";
import { GET_CART_PRODUCT_ID_WATCHER, GET_CART_PRODUCT_DATA_WATCHER, REMOVE_FROM_CART_WATCHER, APPLY_COUPON_CODE_WATCHER, CART_PRODUCT_ID_LIST, CART_PRODUCT_LIST, COUPON_CODE_DATA, ADD_TO_CART_WATCHER, ORDER_BY_ID_WATCHER, ORDER_BY_ID_LIST, UPDATE_CART_WATCHER, CREATE_ORDER_WATCHER, UPDATE_ORDER_WATCHER, CURRENT_ORDER_LIST, DELETE_BULK_CART_WATCHER, ADD_CART_WITHOUT_LOGIN_LIST, REMOVE_CART_WITHOUT_LOGIN_LIST, CART_PRODUCT_REQUEST, IS_CART_LOADED, ADD_BULK_CART_WATCHER, VERIFY_CASHFREE_PAYMENT_WATCHER, CASHFREE_PAYMENT_STATUS, CART_ADD_TOTAL, CREATE_WOO_ORDER_WATCHER, GET_DELIVERY_CHARGE_WATCHER, ADD_DELIVERY_CHARGE } from "../actionTypes/cart"

// Worker triggering actionCreators

export function getCartProductIdWatcher(userId, dispatch) {
  return { type: GET_CART_PRODUCT_ID_WATCHER, userId, dispatch };
}

export function AddCartProductWatcher(data, dispatch) {
  return { type: ADD_TO_CART_WATCHER, payload: data, dispatch };
}

export function getCartProductDataWatcher(data, dispatch) {
  return { type: GET_CART_PRODUCT_DATA_WATCHER, payload: data, dispatch };
}

export function updateCartProductWatcher(data, userId, productId, variantProductId, dispatch) {
  return { type: UPDATE_CART_WATCHER, payload: data, productId, userId, variantProductId, dispatch };
}

export function removeFromCartWatcher(userId, productId, cartVariantId, dispatch) {
  return { type: REMOVE_FROM_CART_WATCHER, userId, productId, dispatch, cartVariantId };
}

export function applyCouponCodeWatcher(data) {
  return { type: APPLY_COUPON_CODE_WATCHER, payload: data };
}

export function orderByIdWatcher(orderId, res) {
  return { type: ORDER_BY_ID_WATCHER, payload: orderId, res };
}

export function createOrderWatcher(data, res) {
  return { type: CREATE_ORDER_WATCHER, payload: data, res};
}

export function removeBulkCartWatcher(data, userId, res) {
  return { type: DELETE_BULK_CART_WATCHER, payload: data, userId, res};
}

export function updateOrderWatcher(data, orderId, msgData, res ) {
  return { type: UPDATE_ORDER_WATCHER, payload: data, orderId, msgData, res};
}

export function createWooOrderWatcher(data, res ) {
  return { type: CREATE_WOO_ORDER_WATCHER, payload: data, res};
}

export function addBulkCartWatcher(data, userId, res, dispatch ) {
  return { type: ADD_BULK_CART_WATCHER, payload: data, userId, res, dispatch };
}

export function verifyCashfreePaymentWatcher(data, res ) {
  return { type: VERIFY_CASHFREE_PAYMENT_WATCHER, payload: data, res };
}

export function getDeliveryChargeWatcher() {
  return { type: GET_DELIVERY_CHARGE_WATCHER };
}


// Redux state changing actionCreators

export function cartProductIdList(data) {
  return { type: CART_PRODUCT_ID_LIST, payload: data };
}

export function cartProductDataList(data) {
  return { type: CART_PRODUCT_LIST, payload: data };
}

export function cartProductRequest() {
  return { type: CART_PRODUCT_REQUEST };
}

export function couponCodeDataList(data) {
  return { type: COUPON_CODE_DATA, payload: data };
}

export function orderByIdList(data) {
  return { type: ORDER_BY_ID_LIST, payload: data };
}

export function currentOrderList(data) {
  return { type: CURRENT_ORDER_LIST, payload: data };
}

export function addCartWithoutLoginList(data ) {
  return { type: ADD_CART_WITHOUT_LOGIN_LIST, payload: data };
}

export function removeCartWithoutLoginList(data ) {
  return { type: REMOVE_CART_WITHOUT_LOGIN_LIST, payload: data };
}

export function isCartLoaded(data) {
  return { type: IS_CART_LOADED, payload: data };
}

export function cashfreePaymentStatus(data) {
  return { type: CASHFREE_PAYMENT_STATUS, payload: data };
}

export function addTotal(data) {
  return { type: CART_ADD_TOTAL, payload: data };
}

export function addDeliveryCharge(data) {
  return { type: ADD_DELIVERY_CHARGE, payload: data };
}