import { Box, Button, Grid, Radio, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addAddress, addPrimaryAddress, updateAddress, updateUserWatcher } from '../../../store/actionCreators/login'
import service from '../../../services/login'
import { addUserPincodeWatcher } from '../../../store/actionCreators/product'
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { updateAlert } from '../../../store/actionCreators/common'

const AfterLogin = ({ isAddressChange, setIsAddressChange }) => {
    const dispatch = useDispatch()
    const { loginReducers: { userData, address }, productReducers: { userPincode } } = useSelector(state => state)
    const { firstName, mobileNumber, house, area, pincode, district, state, email, userId, wooId } = userData[0]

    // const [isAddressChange, setIsAddressChange] = useState(false)
    const [addressVal, setAddressval] = useState({})
    const [pincodeError, setPincodeError] = useState(false)
    const [invalidPincodeError, setInvalidPincodeError] = useState(false)

    let phoneNumberref = useRef()
    let houseref = useRef()
    let arearef = useRef()
    let districtref = useRef()
    let stateref = useRef()
    let pincoderef = useRef()

    useEffect(() => {
        if (addressVal?.pincode?.length === 6) {
            setInvalidPincodeError(false)
            dispatch(addUserPincodeWatcher(addressVal?.pincode))
        }
    }, [addressVal?.pincode, dispatch])

    const addressValChange = (e) => {
        const { name, value } = e.target
        setAddressval({ ...addressVal, [name]: value })

    }

    const Schema = Yup.object().shape({
        addressType: Yup.string().required('Address Type is required'),
        mobileNumber: Yup.string().required('Phone Number is required').min(10, 'enter valid Phone number').max(10, 'enter valid Phone number'),
        houseNo: Yup.string().required('House Number is required'),
        area: Yup.string().required('Area is required'),
        district: Yup.string().required('District is required'),
        state: Yup.string().required('State is required'),
        pincode: Yup.string().min(6, 'enter valid pincode').max(6, 'enter valid pincode').required('Pincode is required')
    });

    const formik = useFormik({
        initialValues: {
            addressType: '',
            mobileNumber: '',
            houseNo: '',
            area: '',
            district: '',
            state: '',
            pincode: '',
        },

        validationSchema: Schema,

        onSubmit: (v) => {
            addAddressHandler(v)
        }
    });

    // useEffect(() => {
    //     if (userData.length) {
    //         formik.setFieldValue("houseNo", house)
    //         formik.setFieldValue("area", area)
    //         formik.setFieldValue("state", state)
    //         formik.setFieldValue("district", district)
    //         formik.setFieldValue("pincode", pincode)
    //     }
    // }, [userData, isAddressChange])

    useEffect(() => {
        if (formik.values.pincode.length === 6) {
            dispatch(addUserPincodeWatcher(formik.values.pincode))
        }
    }, [formik.values.pincode, dispatch])

    const addressUpdate = async () => {
        const localData = {
            "house": formik.values.houseNo,
            "area": formik.values.area,
            "district": formik.values.district,
            "state": formik.values.state,
            "pincode": formik.values.pincode,
        }

        const shipping = {
            "address_1": formik.values.houseNo,
            "address_2": formik.values.area,
            "city": formik.values.district,
            "state": formik.values.state,
            "postcode": formik.values.pincode,
        }

        const wooData = {
            // "email": addressVal.email,
            // "username": addressVal.email,
            "shipping": shipping
        }

        if (formik.values.pincode.length === 6) {
            if (!userPincode) return
            try {
                await service.updateWooUser(wooData, wooId)
                dispatch(updateUserWatcher(localData, userId, (res) => {
                    if (res) {
                        setIsAddressChange(false)
                    }
                }))
            }
            catch {
                alert('error')
            }
        }
        else {
            setInvalidPincodeError(true)
        }
    }
    const addAddressHandler = (val) => {
        let data = {
            "userId": userId,
            "firstName": "",
            "lastName": "",
            "addressType": val?.addressType,
            "house": val?.houseNo,
            "area": val?.area,
            "district": val?.district,
            "state": val?.state,
            "pincode": val?.pincode,
            "mobileNumber": val?.mobileNumber
        }
        console.log(val,"valll");
        dispatch(addAddress(data,(v) => {
            dispatch(addPrimaryAddress(v))
        }))
    }  
    return (
        <>
        {address.length === 0 && <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                <Box width="100%" backgroundColor="primaryColors.loginWhite" boxshadow="0px 4px 20px rgba(0, 0, 0, 0.08)" borderRadius="8px" mt='5px'>
                                <Box display='flex'>
                                    <Box m='20px'>
                                        <img src="/images/OrderConfirmation/Ellipse17.png" style={{ width: '35px', height: '35px' }} alt='' />
                                    </Box>
                                    <Box paddingTop="20px" marginBottom="20px" ml='15px'>

                                        <Typography variant='p24_700' color="primaryColors.checkoutPointerHeader" > Delivery address </Typography> <br />
                                        <Typography variant='p14_400' color="primaryColors.checkoutAccountdesc" > Add your new location </Typography>
                                    </Box>
                                </Box>
                                <Grid container spacing={3} pl='90px' pr='80px'>
                                    <Grid item xs={6}>
                                        <TextField fullWidth size='small' placeholder='addressType' label='AddressType' padding="14px" value={formik.values.addressType} name='addressType'
                                            {...formik.getFieldProps("addressType")}
                                            error={Boolean(formik.touched.addressType && formik.errors.addressType)}
                                            helperText={formik.touched.addressType && formik.errors.addressType} 
                                            onKeyPress={(e)=>{
                                                if(e.key === "Enter"){
                                                    phoneNumberref.current?.focus()
                                                }
                                            }}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField fullWidth size='small' placeholder='Mobile Number' label='MobileNumber' padding="14px" value={formik.values.mobileNumber} name='mobileNumber'
                                            {...formik.getFieldProps("mobileNumber")}
                                            error={Boolean(formik.touched.mobileNumber && formik.errors.mobileNumber)}
                                            helperText={formik.touched.mobileNumber && formik.errors.mobileNumber} 
                                            inputRef={phoneNumberref}
                                            onKeyPress={(e)=>{
                                                if(e.key === "Enter"){
                                                    houseref.current?.focus()
                                                }
                                            }}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField fullWidth size='small' placeholder='House / Flat no' label='House/Flat no' padding="14px" value={formik.values.houseNo} name='houseNo'
                                            {...formik.getFieldProps("houseNo")}
                                            error={Boolean(formik.touched.houseNo && formik.errors.houseNo)}
                                            helperText={formik.touched.houseNo && formik.errors.houseNo}
                                            inputRef={houseref}
                                            onKeyPress={(e)=>{
                                                if(e.key === "Enter"){
                                                    arearef.current?.focus()
                                                }
                                            }} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField fullWidth size='small' placeholder='Area Name' label='Area' padding="14px" value={formik.values.area} name='area'
                                            {...formik.getFieldProps("area")}
                                            error={Boolean(formik.touched.area && formik.errors.area)}
                                            helperText={formik.touched.area && formik.errors.area} 
                                            inputRef={arearef}
                                            onKeyPress={(e)=>{
                                                if(e.key === "Enter"){
                                                    districtref.current?.focus()
                                                }
                                            }}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField fullWidth size='small' placeholder='District' label='District' padding="14px" value={formik.values.district} name='district'
                                            {...formik.getFieldProps("district")}
                                            error={Boolean(formik.touched.district && formik.errors.district)}
                                            helperText={formik.touched.district && formik.errors.district} 
                                            inputRef={districtref}
                                            onKeyPress={(e)=>{
                                                if(e.key === "Enter"){
                                                    stateref.current?.focus()
                                                }
                                            }}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField fullWidth size='small' placeholder='State' label='State' padding="14px" value={formik.values.state} name='state'
                                            {...formik.getFieldProps("state")}
                                            error={Boolean(formik.touched.state && formik.errors.state)}
                                            helperText={formik.touched.state && formik.errors.state} 
                                            inputRef={stateref}
                                            onKeyPress={(e)=>{
                                                if(e.key === "Enter"){
                                                    pincoderef.current?.focus()
                                                }
                                            }}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField fullWidth size='small' placeholder='Pincode' label='Pincode' padding="14px" value={formik.values.pincode} name='pincode'
                                            {...formik.getFieldProps("pincode")}
                                            error={Boolean(formik.touched.pincode && formik.errors.pincode)}
                                            helperText={formik.touched.pincode && formik.errors.pincode} 
                                            inputRef={pincoderef}
                                            onKeyPress={(e)=>{
                                                if(e.key === "Enter"){
                                                    formik.handleSubmit()
                                                }
                                            }}/>
                                        {(!userPincode && formik.values?.pincode?.length === 6) && <Typography sx={{ fontSize: "14px", color: (theme) => `${theme.palette.primaryColors.requiredText} !important` }}> Delivery not Available </Typography>}
                                        {invalidPincodeError && <Typography sx={{ color: (theme) => `${theme.palette.primaryColors.requiredText} !important` }}> Invalid pincode </Typography>}
                                    </Grid>
                                    {/* <Grid item xs={6}>
                                        <TextField fullWidth size='small' placeholder='Enter Email Id' label='EmailId' padding="14px"  value={addressVal.email} name='email' />
                                    </Grid> */}
                                </Grid>

                                <Box marginLeft="30px" marginTop="15px" m='20px auto 20px 90px'>
                                    <Box display='flex' paddingBottom="20px">
                                        <Box height="38px" padding="0px 15px" sx={{ cursor: 'pointer', backgroundColor: "primaryColors.checkoutAccountdesc", color: "primaryColors.loginWhite", borderRadius: '4px', }}>

                                            <Button height="48px" m='20px' padding="14px 55px" marginTop="14px" onClick={() => setIsAddressChange(false)} sx={{ cursor: 'pointer', backgroundColor: "primaryColors.checkoutAccountdesc", color: "primaryColors.loginWhite", borderRadius: '4px', textTransform: 'none' }}>
                                                <Typography color='primaryColors.textWhite'>Cancel</Typography>  </Button>
                                        </Box>

                                        <Box height="38px" padding="0px 10px" sx={{ cursor: 'pointer', backgroundColor: "secondary.main", color: "primaryColors.loginWhite", borderRadius: '4px', ml: '10px' }}>

                                            <Button height="48px" padding="14px 25px" marginTop="14px" onClick={formik.handleSubmit} sx={{ cursor: 'pointer', backgroundColor: "secondary.main", color: "primaryColors.loginWhite", ml: '10px', borderRadius: '4px', textTransform: 'none' }}>
                                                <Typography color='primaryColors.textWhite'>Save Address </Typography></Button>
                                        </Box>

                                    </Box>

                                </Box>
                            </Box >
                </Form>
                </FormikProvider>}

                <Box width="100%" backgroundColor="primaryColors.loginWhite" boxshadow="0px 4px 20px rgba(0, 0, 0, 0.08)" borderRadius="8px" display='flex' mb='20px'>
                         <Box marginTop="20px" ml='15px' mb='20px'>
                            <Typography variant='p24_700' > Logged In </Typography> <br /> <br />
                            <Typography variant='p16_400' color="primaryColors.loginText"> {userData[0]?.firstName}  |  {userData[0]?.mobileNumber} </Typography>
                        </Box>
                        </Box>
                        <Grid container>
                <Grid item xs={12} lg={12} md={12} sm={12} marginTop="10px" ml='15px' mb='10px'>
                <Typography variant='p24_700'> Delivery address </Typography>
                </Grid>
                </Grid>
            {address?.map((v, i) => {
                return <AddressCard item={v} />
            })}
        </>
    )
}

const AddressCard = ({ item }) => {
    const dispatch = useDispatch()
    const { loginReducers: { userData, primaryAddress }, productReducers: { userPincode } } = useSelector(state => state)

    const [isAddressChange, setIsAddressChange] = useState(false)
    const [invalidPincodeError, setInvalidPincodeError] = useState(false)
    const [isAvailable, setIsAvailable] = useState(false)

    const Schema = Yup.object().shape({
        addressType: Yup.string().required('Address Type is requied'),
        mobileNumber: Yup.string().required('Phone Number is required').min(10, 'enter valid Phone number').max(10, 'enter valid Phone number'),
        house: Yup.string().required('House Number is required'),
        area: Yup.string().required('Area is required'),
        district: Yup.string().required('District is required'),
        state: Yup.string().required('State is required'),
        pincode: Yup.string().min(6, 'enter valid pincode').max(6, 'enter valid pincode').required('Pincode is required')
    });

    let phoneNumberref = useRef()
    let houseref = useRef()
    let arearef = useRef()
    let districtref = useRef()
    let stateref = useRef()
    let pincoderef = useRef()

    const formik = useFormik({
        initialValues: {
            addressType: '',
            mobileNumber: '',
            house: '',
            area: '',
            district: '',
            state: '',
            pincode: '',
        },

        validationSchema: Schema,

        onSubmit: (values) => {
            console.log(isAvailable,"isAvailable");
            if(isAvailable){
                dispatch(updateAddress({...values,userId:userData[0]?.userId}, item?.id, () => {
                    dispatch(updateAlert('address updated successfully!', 'success'))
                    setIsAddressChange(false)
                }))
            } 
        }
    });

    useEffect(() => {
        if (userData.length) {
            formik.setFieldValue("addressType", item?.addressType)
            formik.setFieldValue("mobileNumber", item?.mobileNumber)
            formik.setFieldValue("house", item?.house)
            formik.setFieldValue("area", item?.area)
            formik.setFieldValue("state", item?.state)
            formik.setFieldValue("district", item?.district)
            formik.setFieldValue("pincode", item?.pincode)
            console.log(item,"itemhouse");
        }
    }, [isAddressChange])

    useEffect(() => {
        if (formik.values.pincode.length === 6) {
            dispatch(addUserPincodeWatcher(formik.values.pincode, (v) =>{setIsAvailable(v)}))
        }
    }, [formik.values.pincode, dispatch])

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                    {!isAddressChange ?
                        <>
                        
                            <Box width="100%" backgroundColor="primaryColors.loginWhite" boxshadow="0px 4px 20px rgba(0, 0, 0, 0.08)" borderRadius="8px" display='flex'>
                                
                                <Grid container>
                                    <Grid item xs={12} lg={10} md={9} sm={10}>
                                        <Box paddingTop="20px" marginBottom="20px" ml='15px'>
                                             <Radio
                                        checked={item?.id === primaryAddress?.id}
                                        onChange={null}
                                        onClick={() => {
                                            if(item?.id === primaryAddress?.id) {
                                                dispatch(addPrimaryAddress({}))
                                            }
                                            else {
                                                dispatch(addPrimaryAddress(item))
                                            }
                                        }}
                                        value={item?.id}
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                            <Typography variant='p14_400' color="primaryColors.checkoutAccountdesc"> {item?.addressType}: {item?.house}, {item?.area}, {item?.district}, {item?.state}, {item?.pincode} </Typography><br />
                                            <Typography variant='p14_400_15' color="primaryColors.checkoutAccountdesc"> {item?.email} </Typography>

                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={2} md={3} sm={2}>
                                        <Button onClick={() => setIsAddressChange(true)} justifyContent='right' sx={{ cursor: 'pointer', mt: '10px' }}>
                                            <Typography variant='p16_500' color="primaryColors.checkoutCommonText"> CHANGE </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                        :
                        <>
                            <Box width="100%" backgroundColor="primaryColors.loginWhite" boxshadow="0px 4px 20px rgba(0, 0, 0, 0.08)" borderRadius="8px" mt='5px'>
                                <Box display='flex'>
                                    <Box m='20px'>
                                        <img src="/images/OrderConfirmation/Ellipse17.png" style={{ width: '35px', height: '35px' }} alt='' />
                                    </Box>
                                    <Box paddingTop="20px" marginBottom="20px" ml='15px'>

                                        <Typography variant='p24_700' color="primaryColors.checkoutPointerHeader" > Delivery address </Typography> <br />
                                        <Typography variant='p14_400' color="primaryColors.checkoutAccountdesc" > Add your new location </Typography>
                                    </Box>
                                </Box>
                                <Grid container spacing={3} pl='90px' pr='80px'>
                                    <Grid item xs={6}>
                                        <TextField fullWidth size='small' placeholder='AddressType' label='AddressType' padding="14px" value={formik.values.addressType} name='addressType'
                                            {...formik.getFieldProps("addressType")}
                                            error={Boolean(formik.touched.addressType && formik.errors.addressType)}
                                            helperText={formik.touched.addressType && formik.errors.addressType} 
                                            onKeyPress={(e)=>{
                                                if(e.key === "Enter"){
                                                    phoneNumberref.current?.focus()
                                                }
                                            }}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField fullWidth size='small' placeholder='MobileNumber' label='MobileNumber' padding="14px" value={formik.values.mobileNumber} name='mobileNumber'
                                            {...formik.getFieldProps("mobileNumber")}
                                            error={Boolean(formik.touched.mobileNumber && formik.errors.mobileNumber)}
                                            helperText={formik.touched.mobileNumber && formik.errors.mobileNumber} 
                                            inputRef={phoneNumberref}
                                            onKeyPress={(e)=>{
                                                if(e.key === "Enter"){
                                                    houseref.current?.focus()
                                                }
                                            }}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField fullWidth size='small' placeholder='House / Flat no' label='House/Flat no' padding="14px" value={formik.values.house} name='house'
                                            {...formik.getFieldProps("house")}
                                            error={Boolean(formik.touched.house && formik.errors.house)}
                                            helperText={formik.touched.house && formik.errors.house} 
                                            inputRef={houseref}
                                            onKeyPress={(e)=>{
                                                if(e.key === "Enter"){
                                                    arearef.current?.focus()
                                                }
                                            }}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField fullWidth size='small' placeholder='Area Name' label='Area' padding="14px" value={formik.values.area} name='area'
                                            {...formik.getFieldProps("area")}
                                            error={Boolean(formik.touched.area && formik.errors.area)}
                                            helperText={formik.touched.area && formik.errors.area} 
                                            inputRef={arearef}
                                            onKeyPress={(e)=>{
                                                if(e.key === "Enter"){
                                                    districtref.current?.focus()
                                                }
                                            }}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField fullWidth size='small' placeholder='District' label='District' padding="14px" value={formik.values.district} name='district'
                                            {...formik.getFieldProps("district")}
                                            error={Boolean(formik.touched.district && formik.errors.district)}
                                            helperText={formik.touched.district && formik.errors.district} 
                                            inputRef={districtref}
                                            onKeyPress={(e)=>{
                                                if(e.key === "Enter"){
                                                    stateref.current?.focus()
                                                }
                                            }}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField fullWidth size='small' placeholder='State' label='State' padding="14px" value={formik.values.state} name='state'
                                            {...formik.getFieldProps("state")}
                                            error={Boolean(formik.touched.state && formik.errors.state)}
                                            helperText={formik.touched.state && formik.errors.state} 
                                            inputRef={stateref}
                                            onKeyPress={(e)=>{
                                                if(e.key === "Enter"){
                                                    pincoderef.current?.focus()
                                                }
                                            }}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField fullWidth size='small' placeholder='Pincode' label='Pincode' padding="14px" value={formik.values.pincode} name='pincode'
                                            {...formik.getFieldProps("pincode")}
                                            error={Boolean(formik.touched.pincode && formik.errors.pincode)}
                                            helperText={formik.touched.pincode && formik.errors.pincode} 
                                            inputRef={pincoderef}
                                            onKeyPress={(e)=>{
                                                if(e.key === "Enter"){
                                                    formik.handleSubmit()
                                                }
                                            }}/>
                                        {(!isAvailable && formik.values?.pincode?.length === 6) && <Typography sx={{ fontSize: "14px", color: (theme) => `${theme.palette.primaryColors.requiredText} !important` }}> Delivery not Available </Typography>}
                                        {invalidPincodeError && <Typography sx={{ color: (theme) => `${theme.palette.primaryColors.requiredText} !important` }}> Invalid pincode </Typography>}
                                    </Grid>
                                    {/* <Grid item xs={6}>
                                        <TextField fullWidth size='small' placeholder='Enter Email Id' label='EmailId' padding="14px"  value={addressVal.email} name='email' />
                                    </Grid> */}
                                </Grid>

                                <Box marginLeft="30px" marginTop="15px" m='20px auto 20px 90px'>
                                    <Box display='flex' paddingBottom="20px">
                                        <Box height="38px" padding="0px 15px" sx={{ cursor: 'pointer', backgroundColor: "primaryColors.checkoutAccountdesc", color: "primaryColors.loginWhite", borderRadius: '4px', }}>

                                            <Button height="48px" m='20px' padding="14px 55px" marginTop="14px" onClick={() => setIsAddressChange(false)} sx={{ cursor: 'pointer', backgroundColor: "primaryColors.checkoutAccountdesc", color: "primaryColors.loginWhite", borderRadius: '4px', textTransform: 'none' }}>
                                                <Typography color='primaryColors.textWhite'>Cancel</Typography>  </Button>
                                        </Box>

                                        <Box height="38px" padding="0px 10px" sx={{ cursor: 'pointer', backgroundColor: "secondary.main", color: "primaryColors.loginWhite", borderRadius: '4px', ml: '10px' }}>

                                            <Button height="48px" padding="14px 25px" marginTop="14px" onClick={() => {formik.handleSubmit()
                                                 console.log(formik.errors,"formik");}} sx={{ cursor: 'pointer', backgroundColor: "secondary.main", color: "primaryColors.loginWhite", ml: '10px', borderRadius: '4px', textTransform: 'none' }}>
                                                <Typography color='primaryColors.textWhite'>Save Address </Typography></Button>
                                        </Box>

                                    </Box>

                                </Box>
                            </Box >

                        </>

                    }
                </Form>
            </FormikProvider>
        </>
    )
}

export default AfterLogin