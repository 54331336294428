import React, { useRef, useState } from 'react'
import { Box } from '@mui/system'
import { Typography, Grid, TextField, Button } from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { addPage, checkUserNumberWatcher, forgotPasswordWatcher, updatePasswordWatcher, userPhNumber } from '../../store/actionCreators/login';
import { updateAlert } from '../../store/actionCreators/common';
import { useEffect } from 'react';
import SEO from '../../utils/SEO';

export default function ForgetPassword() {
    const { loginReducers: { page, userId } } = useSelector(state => state)
    console.log("forget", page);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    let rpasswordref = useRef()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])

    const [phoneNumber, setPhoneNumber] = useState('')
    const [isPhErr, setIsPhErr] = useState(false)
    const [pages, setPages] = useState(1)
    const [password, setPassword] = useState("")
    const [isPsErr, setIsPsErr] = useState(false)
    const [newPassword, setNewPassword] = useState("")
    const [isNpsErr, setIsNpsErr] = useState(false)
    const [isMatchErr, setIsMatchErr] = useState(false)

    const continueFunction = () => {
        if (phoneNumber) {
            let data = {
                mobileNumber: phoneNumber,
                isResend: false
            }
            dispatch(forgotPasswordWatcher(data, (isExistUser) => {
                navigate('/ForgotPasswordVerify')
            }))
        }
        else {
            setIsPhErr(true)
        }
    }

    const updatePassword = () => {
        if (password == "") {
            setIsPsErr(true)
            setIsMatchErr(false)
            return
        }
        if (newPassword == "") {
            setIsNpsErr(true)
            setIsMatchErr(false)
            return
        }
        if (password !== newPassword) {
            setIsNpsErr(false)
            setIsMatchErr(true)
            setIsPsErr(false)
            return
        }
        let data = {
            userId: userId,
            password: password
        }
        dispatch(updatePasswordWatcher(data, (isExistUser) => {
            navigate('/login')
        }))
    }

    return (
        <>
            <SEO title='Login' description='Login Your account' />
            <section className="Login" style={{ backgroundColor: "#E5E5E5", paddingBottom: "150px" }}>
                <Grid container height="450px" width="100%" backgroundColor="primaryColors.loginBg" justifyContent='center'>
                    <Grid item xs={11} lg={4} md={6} sm={8} >
                        <Box
                            className='loginBox'
                            width='90%'
                            height="322px"
                            backgroundColor="primaryColors.loginWhite"
                            borderRadius="16px"
                            padding="25px">
                            <Grid container rowSpacing={1} >
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <Typography variant='p24_700' color="orange.main"> Reset password </Typography>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <Typography variant='p14_400' color="primaryColors.loginText" paddingTop="50px"> Enter your registered Mobile number </Typography>
                                </Grid>
                                {page === "login" && <> <Grid item xs={2.8} lg={2} md={2} sm={2}>
                                    <Box width="59px" height="56px" border='1px solid rgba(230, 231, 232, 1)' borderRadius="5px" padding="10px" color="primaryColors.checkoutPointerHeader" >
                                        <Typography variant='p14_400'> +91 </Typography>
                                    </Box>
                                </Grid>
                                    <Grid item xs={9.2} lg={10} md={10} sm={10}>
                                        <TextField
                                            required={true}
                                            // sx={{ mt: "20px" }}
                                            size='medium'
                                            fullWidth={true}
                                            placeholder='Enter your Phone Number'
                                            label='Phone Number'
                                            color='primary'
                                            type='text'
                                            variant='outlined'
                                            value={phoneNumber}
                                            onChange={(e) => { setPhoneNumber(e.target.value); setIsPhErr(false) }}
                                            onBlur={(e) => { setPhoneNumber(e.target.value); setIsPhErr(false) }}
                                            error={isPhErr}
                                            helperText={isPhErr ? 'Please enter Phone Number' : ''}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    if (page === "login") {
                                                        continueFunction()
                                                    } else {
                                                        updatePassword()
                                                    }
                                                }
                                            }}
                                        />
                                    </Grid> </>}
                                {page !== "login" && <> <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <TextField
                                        required={true}
                                        // sx={{ mt: "20px" }}
                                        size='medium'
                                        fullWidth={true}
                                        placeholder='Enter your password'
                                        label='New password'
                                        color='primary'
                                        type='text'
                                        variant='outlined'
                                        value={password}
                                        onChange={(e) => { setPassword(e.target.value); }}
                                        onBlur={(e) => { setPassword(e.target.value); setIsPsErr(false) }}
                                        error={isPsErr}
                                        helperText={isPsErr ? 'Please enter New Password' : ''}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                rpasswordref.current?.focus()
                                            }
                                        }}
                                    />
                                </Grid>
                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        <TextField
                                            required={true}
                                            // sx={{ mt: "20px" }}
                                            size='medium'
                                            fullWidth={true}
                                            placeholder='Enter your new password'
                                            label='Re-enter password'
                                            color='primary'
                                            type='text'
                                            variant='outlined'
                                            value={newPassword}
                                            onChange={(e) => { setNewPassword(e.target.value); setIsNpsErr(false) }}
                                            onBlur={(e) => { setNewPassword(e.target.value); setIsNpsErr(false) }}
                                            error={isNpsErr}
                                            helperText={isNpsErr ? 'Please Re-enter your New Password' : ''}
                                            inputRef={rpasswordref}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    if (page === "login") {
                                                        continueFunction()
                                                    } else {
                                                        updatePassword()
                                                    }
                                                }
                                            }}
                                        />
                                    </Grid> </>}
                                {
                                    isMatchErr && <div>Pasword does not match</div>
                                }
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <Box height="48px" backgroundColor="orange.main" borderRadius="5px" marginTop="20px" paddingTop="13px" onClick={() => {
                                        if (page === "login") {
                                            continueFunction()
                                        } else {
                                            updatePassword()
                                        }
                                    }} sx={{ cursor: 'pointer' }} style={{ textAlign: 'center' }}>
                                        <Typography variant='p15_500' color="primaryColors.loginWhite" style={{ textAlign: 'center' }}> Continue </Typography>
                                    </Box>
                                    <Grid container marginTop="10px">
                                        <Grid item xs={12} lg={12} md={12} sm={12} display="flex" justifyContent="end">
                                            <Link to="/signUp"> <Button sx={{ textTransform: 'none' }}>SignUp</Button> </Link>
                                        </Grid>
                                    </Grid>
                                    <Box paddingTop="10px" style={{ textAlign: 'center' }}>
                                        <Typography variant='p14_400' color="primaryColors.checkoutAccountdesc"> By signing in you agree to our </Typography>
                                        <Link to="/termsCondition"> <Typography variant='p14_400' color="orange.main"> terms and conditions</Typography> </Link>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </section>
        </>
    )
} 