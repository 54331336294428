import { CHECK_USER_NUMBER, USER_DATA, IS_USER, ADD_USER, PHONE_NUMBER, VERIFY_OTP, UPDATE_USER, ADD_PAGE, LOGIN, FORGOT_PASSWORD, FORGOT_PASSWORD_VERIFY_OTP, UPDATE_PASSWORD, ADD_USER_ID, CHECK_USER_EXIST, ADD_USER_ADDRESS, DELETE_USER_ADDRESS, GET_USER_ADDRESS, UPDATE_USER_ADDRESS, ADD_ADDRESS, UPDATE_ADDRESS, DELETE_ADDRESS, GET_ADDRESS, ADD_PRIMARY_ADDRESS, SHOW_USERDATA_ADDRESS } from "../actionTypes/login"

// Worker triggering actionCreators

export function checkUserNumberWatcher(data, response) {
  return { type: CHECK_USER_NUMBER, payload: data, response };
}

export function checkIsExist(data, response) {
  return { type: CHECK_USER_EXIST, payload: data, response };
}

export function addUserWatcher(data, response) {
  return { type: ADD_USER, payload: data, response };
}

export function updateUserWatcher(data, userId, response) {
  return { type: UPDATE_USER, payload: data, response, userId };
}

export function verifyOtpWatcher(data, response) {
  return { type: VERIFY_OTP, payload: data, response };
}

export function loginWatcher(data, response) {
  return { type: LOGIN, payload: data, response };
}

export function forgotPasswordWatcher(data, response) {
  return { type: FORGOT_PASSWORD, payload: data, response };
}

export function forgotPasswordVerifyOtpWatcher(data, response) {
  return { type: FORGOT_PASSWORD_VERIFY_OTP, payload: data, response };
}

export function updatePasswordWatcher(data, response) {
  return { type: UPDATE_PASSWORD, payload: data, response };
}

export function addUserId(data) {
  return { type: ADD_USER_ID, payload: data };
}

export function addAddress(data, res) {
  return { type: ADD_ADDRESS, payload: data, res };
}

export function getAddress(userId) {
  return { type: GET_ADDRESS, userId };
}

export function updateAddress(data, id, res) {
  return { type: UPDATE_ADDRESS, payload: data, id, res };
}

export function deleteAddress(id, userId, res) {
  return { type: DELETE_ADDRESS, id, userId, res };
}

// Redux state changing actionCreators

export function isUser(data) {
  return { type: IS_USER, payload: data };
}

export function userDetails(data) {
  return { type: USER_DATA, payload: data };
}

export function userPhNumber(data) {
  return { type: PHONE_NUMBER, payload: data };
}

export function addPage(data) {
  return { type: ADD_PAGE, payload: data };
}

export function addUserAddress(data) {
  return { type: ADD_USER_ADDRESS, payload: data };
}

export function addPrimaryAddress(data) {
  return { type: ADD_PRIMARY_ADDRESS, payload: data };
}

export function showUserDataAddress(data) {
  return { type: SHOW_USERDATA_ADDRESS, payload: data };
}
