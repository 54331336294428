import { Typography, Grid, Box } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'


export default function SmallSideBar() {
  const location = useLocation()
  const { loginReducers: { userData } } = useSelector((state) => state)
  const selectedTab = location.pathname

  return (
    <div>

      <Box>
        <Box paddingBottom="20px">
          <Typography variant="p22_600" color="primaryColors.cartText"> Hello, {userData[0]?.firstName} </Typography>
        </Box>

        <Grid container display="flex" flexDirection="row" spacing={2}>
          <Grid item sm={4} xs={6}>

            <Link to="/orders" >
              <Typography backgroundColor={(selectedTab === "/orders" || selectedTab === "") ? "secondary.main" : "white !important"} borderRadius= "5px" paddingTop="10px" paddingBottom="10px" color={(selectedTab === "/orders" || selectedTab === "") ? "primaryColors.textWhite" : "primaryColors.cartText"} paddingLeft="15px" id="defaultOpen"> Orders </Typography>
            </Link>

          </Grid>

          <Grid item sm={4} xs={6}>

            <Link to="/mobile-repair-and-services" >
              <Typography backgroundColor={(selectedTab === "/mobile-repair-and-services" || selectedTab === "") ? "secondary.main" : "white !important"} borderRadius= "5px" paddingTop="10px" paddingBottom="10px" color={selectedTab === "/mobile-repair-and-services" ? "primaryColors.textWhite" : "primaryColors.cartText"} paddingLeft="15px"> Repair & Services </Typography>
            </Link>

          </Grid>

          <Grid item sm={4} xs={6}>

            <Link to="/addresses" >
              <Typography backgroundColor={(selectedTab === "/addresses" || selectedTab === "") ? "secondary.main" : "white !important"} borderRadius= "5px" paddingTop="10px" paddingBottom="10px" color={selectedTab === "/addresses" ? "primaryColors.textWhite" : "primaryColors.cartText"} paddingLeft="15px"> Addresses </Typography>
            </Link>

          </Grid>

          <Grid item sm={4} xs={6}>

            <Link to="/wishlist" >
              <Typography backgroundColor={(selectedTab === "/wishlist" || selectedTab === "") ? "secondary.main" : "white !important"} borderRadius= "5px" paddingTop="10px" paddingBottom="10px" color={selectedTab === "/wishlist" ? "primaryColors.textWhite" : "primaryColors.cartText"} paddingLeft="15px"> Wishlist </Typography>
            </Link>

          </Grid>

          <Grid item sm={4} xs={6}>

            <Link to="/profilepage" >
              <Typography backgroundColor={(selectedTab === "/profilepage" || selectedTab === "") ? "secondary.main" : "white !important"} borderRadius= "5px" paddingTop="10px" paddingBottom="10px" color={selectedTab === "/profilepage" ? "primaryColors.textWhite" : "primaryColors.cartText"} paddingLeft="15px"> Profile </Typography>
            </Link>

          </Grid>

        </Grid>

      </Box>
    </div>
  )
}
