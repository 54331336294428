import React from 'react'
import { Typography, Grid, Box, useMediaQuery } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom';
import SideBar from '../sideBar';
import WishListDesign from './wishListDesign';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWishlistProductIdWatcher } from '../../../store/actionCreators/product';
import Screenwise from '../../../components/ProductListItemCard/screenwise';
import SmallSideBar from '../smallSideBar';
import SEO from '../../../utils/SEO';

export default function Wishlist() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const match = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loginReducers: { userData }, productReducers: { wishListProductData } } = useSelector((state) => state)
  console.log(wishListProductData,"wishlistproductdata");

  useEffect(() => {
    if (userData.length) {
      dispatch(getWishlistProductIdWatcher(userData[0]?.userId))
    }
  }, [dispatch, userData])

  useEffect(() => {
    if (!userData.length) navigate('/login')
  }, [userData, navigate])

  return (
    <>
      <SEO title='Wishlist' description='Your Wishlist' />
      <section className='sectionOrderMenu'>
        <Box backgroundColor="primaryColors.loginBg">
          <Box display='flex' backgroundColor="primaryColors.loginWhite" color="primaryColors.textPath" p='80px 0 10px 50px'>
            <Typography sx={{ color: 'primaryColors.breadcrumbs', '&:hover': { textDecoration: 'underline' }, paddingRight: '10px' }} to='/' component={Link} variant="p14_500"> Home </Typography>
            <Typography variant="p13_500" sx={{ color: 'primaryColors.cartText', paddingRight: '10px' }}> &gt; </Typography>
            <Typography variant="p14_500" sx={{ color: 'grey[200]' }}> Wishlist </Typography>
          </Box>

          <Grid container >
            <Grid item xs={12} sm={12} md={2.8} lg={2.6} padding="27px 20px 0px 32px">
              <Box>
                {match ? <SideBar /> : <SmallSideBar />}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9.2} lg={9.4} padding="40px 40px 40px 35px">

              <Typography variant="p24_700"> Wishlist </Typography>
              <br />
              <br />
              <Grid container gap={2}>
              {wishListProductData.length ?
                wishListProductData?.map((v, i) => {
                  return ( <Grid lg={5.8} md={12} sm={12} xs={12}>
                    <Box p="4px"
                      backgroundColor="primaryColors.loginWhite"
                      borderRadius="8px"
                      marginTop="20px">
                      {matches ? <WishListDesign value={v} /> : <Screenwise productData={v} pageType='wishList' />}
                    </Box>
                    </Grid>
                  )
                })
                :
                <Box>
                  <Typography variant="p20_400" color='secondary.main'>Your wishlist is empty !</Typography>
                </Box>
              }
              </Grid>

            </Grid>
          </Grid>
        </Box>
      </section>
    </>
  )
}