import React from "react";
import "./styles.css";
import FilterSection from "./FilterSection";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import GridViewIcon from "@mui/icons-material/GridView";
import ProductListItemCard from "../../components/ProductListItemCard";
import ProductListItemGrid from "../../components/ProductListItemGrid";
import { Box, Typography, Grid, Button, Slide, Dialog, IconButton } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux'
import { addPrimarycategory, addProductCategory, filterAttributeProductList, filterBasedProductWatcher, filterTagBasedProductWatcher } from "../../store/actionCreators/common";
import SplitscreenIcon from '@mui/icons-material/Splitscreen'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SEO from "../../utils/SEO";
import { productArr } from "../../utils/common";

const minDistance = 10;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid filter.grey",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 11,
    // lineHeight: 19,
    /* identical to box height, or 173% */
    textTransform: "uppercase",
    color: "primaryColors.mobileCardText",
    // "&:focus": {
    //   borderRadius: 4,
    //   borderColor: "#80bdff",
    //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    // },
  },
}));

const Category = () => {
  const location = useLocation()
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(searchParams.get("tag"), params, "location");
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const smlfilter = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const topview = useMediaQuery((theme) => theme.breakpoints.down('480'));
  const dispatch = useDispatch()
  const { state } = useLocation()
  const { commonReducers: { filterBasedProductData, searchProductData, searchData, meta_title, meta_description, searchText } } = useSelector(state => state)
  console.log(searchData, "searchData")
  const [filterModal, setFilterModal] = useState(false)
  const [value1, setValue1] = React.useState([0, 60000]);
  const [doubleView, setDoubleView] = React.useState(true);
  const [dataCount, setDataCount] = React.useState(50);

  const [formValues, setformValues] = React.useState({
    sort_by: "RECOMMENDED",
    display: "50",
  });

  const [formFilter, setFormFilter] = React.useState({
    sort_by: "RECOMMENDED",
    display: "50",
    color: [], Ram: [], Storage: [], Brand: []
  });

  useEffect(() => {
    window.scrollTo(0, 0)
    let path = location.pathname.substr(1,)
    let m = productArr.find(v => v.name == decodeURI(path))
    if (m !== undefined) {
      let data = {
        perPage: 100,
        category: m.category,
        name: m.name
      }
      if (m.perc !== undefined && m.condition !== undefined) {
        data = {
          ...data, perc: m.perc,
          condition: m.condition
        }
      }
      if (m.min_price !== undefined && m.max_price !== undefined) {
        data = {
          ...data, min_price: m.min_price,
          max_price: m.max_price
        }
      }

      dispatch(addPrimarycategory(m.productName.toLowerCase()))
      dispatch(addProductCategory(m.productName.toLowerCase()))
      dispatch(filterTagBasedProductWatcher(data))
      console.log(data, "tesdata");
    }
    if (m !== undefined) return
    let data = {
      perPage: 50,
      name: searchParams.get("name") || "",
    }
    if (searchParams.get("min_price") !== null) {
      data = { ...data, min_price: searchParams.get("min_price") }
    }
    if (searchParams.get("tag") !== null) {
      data = { ...data, tag: searchParams.get("tag") }
    }
    if (searchParams.get("category") !== null) {
      data = { ...data, category: searchParams.get("category") }
    }
    if (searchParams.get("search") !== null) {
      data = { ...data, search: searchParams.get("search") }
    }
    if (searchParams.get("max_price") !== null) {
      data = { ...data, max_price: searchParams.get("max_price") }
    }
    if (searchParams.get("order_by") !== null) {
      data = { ...data, orderby: searchParams.get("order_by") }
    }
    if (searchParams.get("offset") !== null) {
      data = { ...data, offset: searchParams.get("offset") }
    }
    if (searchParams.get("page") !== null) {
      data = { ...data, page: searchParams.get("page") }
    }
    if (searchParams.get("brand") !== null) {
      data = { ...data, brand: searchParams.get("brand") }
    }
    dispatch(filterTagBasedProductWatcher(data))
    console.log(data, "condata");
  }, [location.pathname, searchText])

  useEffect(() => {
    let search_session = sessionStorage.getItem("search");
    if (search_session !== undefined) {
      let search = JSON.parse(search_session)
      dispatch(filterTagBasedProductWatcher(search))
    }
  }, [location.pathname])

  const requestData = () => {
    let data = {
      perPage: 50,
      name: searchParams.get("name") || "",
    }
    if (searchParams.get("min_price") !== null) {
      data = { ...data, min_price: searchParams.get("min_price") }
    }
    if (searchParams.get("tag") !== null) {
      data = { ...data, tag: searchParams.get("tag") }
    }
    if (searchParams.get("category") !== null) {
      data = { ...data, category: searchParams.get("category") }
    }
    if (searchParams.get("search") !== null) {
      data = { ...data, search: searchParams.get("search") }
    }
    if (searchParams.get("max_price") !== null) {
      data = { ...data, max_price: searchParams.get("max_price") }
    }
    if (searchParams.get("order_by") !== null) {
      data = { ...data, orderby: searchParams.get("order_by") }
    }
    if (searchParams.get("offset") !== null) {
      data = { ...data, offset: searchParams.get("offset") }
    }
    if (searchParams.get("brand") !== null) {
      data = { ...data, brand: searchParams.get("brand") }
    }
    return data
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setformValues({ ...formValues, [name]: value });
    console.log({ ...formValues, [name]: value }, "category")
    let data = { ...searchData }
    data.page = 1
    data.perPage = 50
    delete data.pagination
    delete data.offset
    if (event.target.value == "AVERAGE") {
      data.orderby = "rating"
    }
    if (event.target.value == "LATEST") {
      data.orderby = "date"
    }
    dispatch(filterTagBasedProductWatcher(data))
  };

  const handleFilterChange = (attr_name, option) => {
    // const { name, value } = event.target;
    // if (name !== 'sort_by' && name !== 'display') {
    let attr_value = formFilter[attr_name];
    console.log(attr_name, "nme")
    if (attr_value) {
      if (attr_value?.some(v => v == option)) {
        setFormFilter({ ...formFilter, [attr_name]: attr_value?.filter(x => x !== option) })
      } else {
        setFormFilter({ ...formFilter, [attr_name]: [...attr_value, option] })
      }
    }
    // if (prevVal.includes(curVal)) {
    //   setFormFilter({ ...formFilter, [name]: prevVal.filter(e => e !== curVal) })
    // }
    // else {
    //   setFormFilter({ ...formFilter, [name]: [...prevVal, curVal] })
    // }
    //}
    // else {
    //   setFormFilter({ ...formFilter, [name]: value });
    // }
  };

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return setValue1(newValue);
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  const applyFunction = () => {
    console.log(value1, "value1");
    setFilterModal(false)
    let attributes = []
    for (var key in formFilter) {
      if (key !== "sort_by" && key !== "display" && key !== "Brand") {

        attributes = [...attributes, ...formFilter[key]]
      }
    }


    if (attributes.length === 0 && formFilter?.Brand?.length == 0) {
      dispatch(filterAttributeProductList(searchProductData?.filter((v) => parseInt(v?.price) >= value1[0] && parseInt(v?.price) <= value1[1])))
      return
    }

    if (formFilter?.Brand?.length > 0) {
      let FilterResult = searchProductData?.filter(x => {
        return x?.brands?.some(b => formFilter?.Brand?.some(s => s?.includes(b?.name)))

      })?.filter(x => {
        if (attributes?.length == 0) return true;
        return x?.attributes?.some(y => attributes.some(s => y?.options?.some(oo => oo?.includes(s))))

      })?.filter((v) => {
        return parseInt(v?.price) >= value1[0] && parseInt(v?.price) <= value1[1]
      })

      dispatch(filterAttributeProductList(FilterResult))
    } else {

      let FilterResult = searchProductData?.filter(x => {

        return x?.attributes?.some(y => attributes.some(s => y?.options?.some(oo => oo?.includes(s))))

      })?.filter((v) => {
        return parseInt(v?.price) >= value1[0] && parseInt(v?.price) <= value1[1]
      })
      console.log(FilterResult, "filterres");
      dispatch(filterAttributeProductList(FilterResult))
    }


    // if (attributes.length === 0 && formFilter.Brand.length === 0) {
    //   let filterResult = searchProductData?.filter((v) => {
    //     return parseInt(v?.price) >= value1[0] && parseInt(v?.price) <= value1[1]
    //   })
    //   dispatch(filterAttributeProductList(filterResult))
    // }
    // else if (formFilter.Brand.length > 0) {
    //   let BrandFilterResult = searchProductData?.filter(x => {
    //     return x?.brands?.some(b => formFilter.Brand?.some(a => a?.includes(b?.name)))
    //   })

    //   let FilterResult = BrandFilterResult?.filter(x => {
    //     return attributes.length > 0 ? x?.attributes?.some(y => attributes?.some(s => y?.options?.some(oo => oo?.includes(s)))) : true

    //   }).filter((v) => {
    //     return parseInt(v?.price) >= value1[0] && parseInt(v?.price) <= value1[1]
    //   })
    //   dispatch(filterAttributeProductList(FilterResult))
    // }
    // else {
    //   let FilterResult = searchProductData?.filter(x => {
    //     return x?.attributes?.some(y => attributes?.some(s => y?.options?.some(oo => oo?.includes(s))))

    //   }).filter((v) => {
    //     return parseInt(v?.price) >= value1[0] && parseInt(v?.price) <= value1[1]
    //   })
    //   dispatch(filterAttributeProductList(FilterResult))
    // }
    // console.log(attributes,formFilter,"attri")
    // let data = {
    //   minPrice: value1[0],
    //   maxPrice: value1[1],
    //   perPage: formFilter.display,
    //   searchVal: state.searchVal || null
    // }
    // dispatch(filterBasedProductWatcher(data))
  }

  const dataCountFunction = (e) => {
    setDataCount(e.target.value)

    // let data = {
    //   minPrice: value1[0],
    //   maxPrice: value1[1],
    //   perPage: e.target.value,
    //   searchVal: ''
    // }
    let data = { ...requestData() }
    delete data.orderby
    if (e.target.value == 50) {
      data.page = 1
      data.perPage = 50
      delete data.pagination
      delete data.offset
    }
    else {
      data.perPage = e.target.value == 100 ? 50 : 25
      data.offset = searchProductData.length
      data.pagination = true
    }
    dispatch(filterTagBasedProductWatcher(data))
    // dispatch(filterBasedProductWatcher(data))
    console.log(data, "data")
  }

  const Transition = React.forwardRef(function Transition(props, ref) { return <Slide direction="up" ref={ref} {...props} />; });

  const handleClose = () => {
    setFilterModal(false)
  }

  const handleClickOpen = () => {
    setFilterModal(true)
  }
  const useStyles = makeStyles((theme) => { const appbarHeight = 64; return { root: { top: `${appbarHeight}px !important` } }; });
  const classes = useStyles()
  console.log("fltr", filterModal, smlfilter)
  return (
    <>
      {(meta_title || meta_description) &&
        <SEO title={meta_title} description={meta_description} />}
      <section style={{ paddingTop: "30px" }}>

        <Dialog
          className={[classes.root]}
          fullScreen
          open={filterModal}
          onClose={handleClose}
        >
          {/* <Button onClick={handleClose}>Close</Button> */}
          <Box marginTop="100px" display="flex" justifyContent="end"><IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close"><CloseIcon /></IconButton></Box>
          <FilterSection handleChange1={handleChange1} value1={value1} setValue1={setValue1} applyFunction={applyFunction} handleFilterChange={handleFilterChange} formFilter={formFilter} />
        </Dialog>

        <Grid container spacing={3} sx={{ pb: '20px', mt: '70px', p: { xs: '30px', sm: '30px', md: '0' } }} >
          <Grid item xs={12} lg={3} md={4} sm={4} style={{ backgroundColor: "#F8F8F8" }}>
            {smlfilter && <FilterSection handleChange1={handleChange1} value1={value1} setValue1={setValue1} applyFunction={applyFunction} handleFilterChange={handleFilterChange} formFilter={formFilter} />}
          </Grid>
          <Grid item lg={9} xs={12} md={8} sm={12} className="category_right_main" >
            <Grid container spacing={3} pb='20px' width='100%' >
              {matches ? <Grid item lg={5.5} md={6} sm={12} xs={12} display='flex' alignItems='center' justifyContent='center' ml={{ xs: '20px', md: '0' }} >
                <Typography variant="p16_400" color="primaryColors.repairText">
                  {filterBasedProductData.length} Results
                  {/* {searchData?.name !== undefined && */}
                  <Typography variant="p16_700"> for "{searchParams.get("name") || searchData?.name || params?.tag_name || ""}" </Typography>
                  {/* } */}
                </Typography>
                {!smlfilter && <Button variant="outlined" color="black" onClick={handleClickOpen} style={{ marginLeft: "20px" }}> <FilterListIcon /> Filter</Button>}
              </Grid> : <Grid item lg={5.5} md={6} sm={12} xs={12} display='flex' alignItems='center' justifyContent='center' ml={{ xs: '20px', md: '0' }} >
                <Typography variant="p16_400" color="primaryColors.repairText">
                  {filterBasedProductData.length} Results
                </Typography>
                {topview && <Button variant="outlined" color="black" style={{ marginLeft: "20px" }} onClick={handleClickOpen}>
                  <FilterAltIcon />
                </Button>}
              </Grid>}
              <Grid item lg={2.5} md={6} sm={4} xs={matches ? 3.5 : 4} display='flex' alignItems='center' justifyContent='center' ml={{ xs: '20px', md: '0' }}>
                <Typography variant="p11_600" color="primaryColors.filterTextLight" pr='10px'>
                  Sort By
                </Typography>

                <FormControl sx={{ m: 1, width: 129 }} variant="standard">
                  <Select
                    fullWidth
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    name="sort_by"
                    value={formValues.sort_by}
                    onChange={handleChange}
                    input={<BootstrapInput />}
                  >
                    <MenuItem value={"RECOMMENDED"}>RECOMMENDED</MenuItem>
                    <MenuItem value={"AVERAGE"}>AVERAGE</MenuItem>
                    <MenuItem value={"LATEST"}>LATEST</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={2} md={6} sm={3.5} xs={matches ? 3.5 : 4} display='flex' alignItems='center' justifyContent='center'>
                <Typography ml="24px" variant="p11_600" color="primaryColors.filterTextLight" pr='10px'>
                  Display
                </Typography>

                <FormControl sx={{ m: 1, width: 55 }} variant="standard">
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    name="display"
                    value={dataCount}
                    onChange={dataCountFunction}
                    input={<BootstrapInput />}
                  >
                    <MenuItem value={"50"}>50</MenuItem>
                    <MenuItem value={"75"}>75</MenuItem>
                    <MenuItem value={"100"}>100</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {matches ? <Grid item lg={2} md={6} sm={3.5} xs={6} display='flex' alignItems='center' justifyContent='center'>
                <Typography
                  ml="24px"
                  mr="8px"
                  variant="p11_600"
                  color="primaryColors.filterTextLight"
                  pr='10px'
                >
                  Grid
                </Typography>
                <Box
                  className="category_right_grid_icon"
                  borderColor="grey.dark"
                  color="grey.dark"
                  onClick={() => setDoubleView(!doubleView)}
                  sx={{ cursor: 'pointer' }}
                >
                  {doubleView ?
                    <SplitscreenIcon color="inherit" sx={{ fontSize: "2rem" }} /> :
                    <GridViewIcon color="inherit" sx={{ fontSize: "2rem" }} />
                  }
                </Box>
              </Grid>
                : <Grid xs={3} display='flex' alignItems='center' justifyContent='end' marginTop='20px'>
                  {!topview && <Button variant="outlined" color="black" onClick={handleClickOpen}> <FilterAltIcon /> </Button>}
                </Grid>}
            </Grid>

            <Grid container spacing={3}>
              {filterBasedProductData?.map((m, i) => {
                return <Grid item lg={doubleView ? 4 : 12} md={doubleView ? 6 : 12} sx={12} sm={doubleView ? 6 : 12} >
                  <div key={m.id}>
                    <Box className="products-list clear" sx={{ width: '100%', paddingRight: '20px' }} ml={{ xs: '20px', md: '0' }}>
                      {matches ? (!doubleView) ?
                        <ProductListItemGrid productData={m} />
                        :
                        <ProductListItemCard productData={m} />
                        :
                        <ProductListItemCard productData={m} />
                      }

                    </Box>
                  </div>
                </Grid>
              })}
            </Grid>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default Category;
