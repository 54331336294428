import { Box, Typography } from "@mui/material";
import React from 'react'
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { AddWishlistProductWatcher, removeFromWishlistWatcher } from "../../store/actionCreators/product";
import { useState } from "react";
import { useEffect } from "react";
import productService from "../../services/product"
import { updateFalseLoader, updateOpenLoader } from "../../store/actionCreators/common";
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import { getcardprimarycolor, getfreetagcolor, getprimarycolor } from "../../utils/common";
import { useNavigate } from "react-router-dom";

const ProductItemCard = ({ cl, name, productData, bgclr, categoryType, categoryName }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { loginReducers: { userData }, cartReducers: { cartProductData, cartWithoutLoginData }, productReducers: { wishListProductData } } = useSelector((state) => state)
  const [price, setPrice] = useState({ sale_price: "", regular_price: "", discount: "" })
  const [productName, setProductName] = useState('')
  const [selectedVariant, setSelectedVariant] = useState('')
  let salePrice = isNaN(parseInt(productData?.sale_price)) ? 0 : parseInt(productData?.sale_price)
  let discountpercent = 100 - (salePrice) * 100 / parseInt(productData?.regular_price);
  const variantId = productData?.variations?.length ? productData?.variations[0] : 0

  const isSelected = wishListProductData.some(d => {
    return +d.wishListVariantId ? +d.wishListVariantId === +variantId : +d.wishListProductId === +productData?.id
  })
  const isCart = userData.length ? cartProductData.some(d => +d.id === +productData.id) : cartWithoutLoginData.some(d => +d.id === +productData.id)
  const userId = userData[0]?.userId
  const productId = productData?.id

  useEffect(() => {
    if (productData?.attributes?.length && productData?.attributes[0]?.options?.length) {
      let attribute = productData?.attributes?.filter(s => s?.variation) || []
      let getName = attribute?.length >= 2 ? attribute[0]?.options[0] + ' - ' + attribute[1]?.options[0] : attribute[0]?.options[0]
      const prodName = `${productData?.name}(${getName})`
      setProductName(prodName)

      let chooseColor = attribute[0]
      chooseColor = { ...chooseColor, "selected": chooseColor?.options[0] }
      attribute[0] = chooseColor
      if (attribute?.length >= 2) {
        let chooseGB = attribute[1]
        chooseGB = { ...chooseGB, "selected": chooseGB?.options[0] }
        attribute[1] = chooseGB
      }
      setSelectedVariant(attribute)
      console.log(attribute, "attri");
    }
    else {
      setProductName(productData?.name)
    }
    let div = document.createElement('div')
    div.innerHTML = productData?.price_html
    console.log("sample", div.getElementsByClassName("woocommerce-Price-amount amount"))
    let price1 = ""
    let price2 = ""
    price1 = div.getElementsByClassName("woocommerce-Price-amount amount")[0]?.innerText?.split(".")[0]?.replace(/,/g, '')?.match(/(\d+)/)[0] || ""
    price2 = div.getElementsByClassName("woocommerce-Price-amount amount")[1]?.innerText?.split(".")[0]?.replace(/,/g, '')?.match(/(\d+)/)[0] || ""
    console.log("amount", div.getElementsByClassName("woocommerce-Price-amount amount")[1]?.innerText.split(".")[0])

    setPrice({
      regular_price: parseInt(price1) > parseInt(price2) ? price1 : price2,
      sale_price: parseInt(price1) > parseInt(price2) ? price2 : price1,
      discount: (100 - parseInt(parseInt(price1) > parseInt(price2) ? parseInt(price2) : price1) * 100 / parseInt(parseInt(price1) > parseInt(price2) ? price1 : price2))?.toFixed(0) || ""
    })

    console.log("priz", price1, price2)
  }, [productData?.attributes, productData?.name])

  const imageName = (imageArr) => {
    let imgName = imageArr?.length ? imageArr[0]?.src : "/images/Phone1.png"
    return imgName
  }

  const findFreeProduct = () => {
    let freeproductkey = productData?.meta_data?.find(m => m.key === '_accessory_ids') || {}
    return freeproductkey.value?.length > 0
  }

  const addToFavourite = () => {
    if (!isSelected) {
      let data = {
        userId,
        productId,
        variantId
      }
      dispatch(AddWishlistProductWatcher(data, dispatch))
    }
    else {
      dispatch(removeFromWishlistWatcher(userId, productId, variantId, dispatch))
    }
  }

  let cat_name = productData.categories[0]?.name || ''
  let cat_id = productData?.meta_data?.find(m => m.key === 'epc_primary_product_cat')?.value
  let primaryCategory = cat_id !== undefined ? (productData?.categories?.find(v => v?.id == cat_id)?.name || cat_name) : cat_name

  const handleNavigate = async () => {
    console.log(selectedVariant, "selectedvariant")
    let prodName = productData?.name
    const pathName = primaryCategory?.toLowerCase() || "home"
    if (selectedVariant?.length) {
      //route code
      if (productData?.variations?.length > 0) {
        dispatch(updateOpenLoader())
        let { data: data1 } = await productService.getProductVariants(productData?.id)
        console.log(data1, "data1");
        let selectedProdVariant = selectedVariant?.filter(s => s?.selected !== undefined)?.map(d => d?.selected).join('-')
        console.log(selectedProdVariant, "selec");
        let routeProdVariant = data1?.map(d => d.attributes?.map(f => f.option).join('-'))
        console.log(routeProdVariant, "route");
        let findInd = routeProdVariant?.findIndex(v => selectedProdVariant === v) || 0
        console.log(findInd, "findInd");
        let routePId = data1.length && data1[findInd]?.id
        console.log(routePId, "routepid");
        //product name code
        prodName = `${productData?.name?.replace(/ /g, "-")?.trim()}(${selectedProdVariant?.replace(/ /g, "-")?.trim()})`
        setProductName(prodName)
        console.log("selectedVariant", selectedVariant)
        //final navigation
        navigate(`/${encodeURIComponent(pathName)}/${prodName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${productData?.id}&vid=${routePId}`)
        dispatch(updateFalseLoader());
      }
    }
    else {
      setProductName(prodName)
      navigate(`/${encodeURIComponent(pathName)}/${prodName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${productData?.id}&vid=0`)
    }
  }

  return (

    <div className={`products-list-item ${cl?.products_list_nwidth}`} onClick={handleNavigate} style={{ cursor: 'pointer' }}>
      <article>
        <Box bgcolor={bgclr || 'white'} borderRadius='8px'>
          <div className="products-buttons">

            {Boolean(userData.length) &&
              <a href="#/" onClick={(e) => { e.stopPropagation(); addToFavourite() }} style={{ cursor: 'pointer' }}>
                <img src={isSelected ? "/images/repairs/like.jpg" : "/images/Heart.svg"} alt='' />
              </a>
            }

          </div>
          {findFreeProduct() &&
            <div className="products-free-items" style={{ background: "#F26B23" }}>
              <h5 style={{ backgroundColor: "#000" }}>Free</h5>
              <img src="/images/Free-Case.svg" alt='' />
              <img src="/images/Free-Case2.svg" alt='' />
            </div>
          }
          <Box textAlign='left' className="figure">
            <img src={imageName(productData?.images)} alt='' style={{ objectFit: "contain" }} />
          </Box>
        </Box>
        <figcaption>
          <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
            <Typography variant='p12_400' color="primaryColors.loginText">{primaryCategory}</Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <StarRateRoundedIcon sx={{ fontSize: 20, color: "#F26B23" }} /><span style={{ fontSize: "14px", color: "#888888" }}>{parseInt(productData?.average_rating)}</span>
            </div>
          </div>
          <div>
            <Box mt='6px'>
              <Typography variant='p16_500_18' color="primaryColors.mobileCardText" fontFamily='Inter' className="break"> {productName} </Typography>
            </Box>

            <div className="products-specs clear">
              {
                productData?.attributes?.filter(v => !v?.variation)?.map((v, i) => {
                  console.log("sss", productData)
                  return (
                    <aside key={v.name}><img src={`https://dev.phonebuy.com/wp-content/uploads/2022/11/${v?.name}.png`} alt='' /><span className="attribute_break">{v?.options?.toString()}</span></aside>
                  )
                })
              }
            </div>
          </div>
          <br />
          {productData?.type === "variable" ?
            <div>
              <Typography variant='p24_700' color="primaryColors.mobileCardText" marginTop='10px'> ₹{price?.sale_price} </Typography>
              <br />
              {price?.sale_price !== "" && !isNaN(price.discount) &&
                <Typography className="mrp-price-off" marginTop='10px'> <del style={{ paddingRight: "10px" }}>₹{price?.regular_price}</del> <strong style={{ zIndex: -1 }}>{price.discount}% Off</strong> </Typography>}
            </div>
            :
            <div>
              <Typography variant='p24_700' color="primaryColors.mobileCardText" marginTop='10px'> ₹{productData?.price} </Typography>
              <br />
              {productData?.sale_price !== "" &&
                <Typography className="mrp-price-off" marginTop='10px'> <del style={{ paddingRight: "10px" }}>₹{productData?.regular_price}</del> <strong style={{ zIndex: -1 }}>{discountpercent?.toFixed(0)}% Off</strong> </Typography>}
            </div>}

        </figcaption>
      </article>
    </div>
  );
};

export default ProductItemCard;
