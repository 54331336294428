import { takeEvery, call, put } from "redux-saga/effects";
import { tagCardList, repairAndServiceList, setSearchData, paginationView, addMetaTagData } from "../actionCreators/common";
import { TAG_CARD_WATCHER, ADD_SERVICE_AND_REPAIR_WATCHER, FILTER_BASED_PRODUCT_WATCHER, CONTACT_US_WATCHER, FILTER_TAG_BASED_PRODUCT_WATCHER, GET_CATEGORY_DATA_WATCHER, GET_TAG_DATA_WATCHER } from "../actionTypes/common"
import commonService from "../../services/common"
import { updateOpenLoader, updateAlert, updateFalseLoader, filterBasedProductList, contactUsResponse } from '../actionCreators/common'


function* tagCardEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(commonService.getTagCardList, action.payload);
    yield put(tagCardList(data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* repairAndServiceEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(commonService.AddRepairAndService, action.payload);
    yield put(repairAndServiceList(data));
    yield put(updateFalseLoader())
    yield put(updateAlert('Saved your repair!', 'success'));
    action.res()
  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* filterBasedProductEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(commonService.filterBasedList, action.payload);
    yield put(filterBasedProductList(data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* filterTagBasedProductEffect(action) {
  try {
    sessionStorage.setItem("search", JSON.stringify(action.payload));
    yield put(setSearchData(action.payload))
    yield put(updateOpenLoader())

    let { data } = yield call(commonService.filterTagBasedList, action.payload);
    let result = data
    console.log("action", action.payload, result.length)
    if (action.payload.perc !== undefined && action.payload.condition !== undefined) {
      result = data?.filter((value) => {
        let div = document.createElement('div')
        div.innerHTML = value?.price_html
        console.log("sample", div.getElementsByClassName("woocommerce-Price-amount amount"))
        let price1 = ""
        let price2 = ""
        price1 = div.getElementsByClassName("woocommerce-Price-amount amount")[0]?.innerText?.split(".")[0]?.replace(/,/g, '')?.match(/(\d+)/)[0] || ""
        price2 = div.getElementsByClassName("woocommerce-Price-amount amount")[1]?.innerText?.split(".")[0]?.replace(/,/g, '')?.match(/(\d+)/)[0] || ""
        let discountpercent = (100 - parseInt(parseInt(price1) > parseInt(price2) ? parseInt(price2) : price1) * 100 / parseInt(parseInt(price1) > parseInt(price2) ? price1 : price2))?.toFixed(0) || ""
        console.log("disc", discountpercent, price1, price2)
        if (!discountpercent) return false;
        // let discountpercent = 100 - (parseInt(value?.sale_price) * 100 / parseInt(value?.regular_price));
        console.log("discountpercent", discountpercent)
        if (action.payload.condition === "above") {
          return discountpercent >= action.payload.perc
        }
        else {
          return discountpercent <= action.payload.perc
        }
      })?.map((v) => {
        let div = document.createElement('div')
        div.innerHTML = v?.price_html
        console.log("sample", div.getElementsByClassName("woocommerce-Price-amount amount"))
        let price1 = ""
        let price2 = ""
        price1 = div.getElementsByClassName("woocommerce-Price-amount amount")[0]?.innerText?.split(".")[0]?.replace(/,/g, '')?.match(/(\d+)/)[0] || ""
        price2 = div.getElementsByClassName("woocommerce-Price-amount amount")[1]?.innerText?.split(".")[0]?.replace(/,/g, '')?.match(/(\d+)/)[0] || ""
        let discountpercent = (100 - parseInt(parseInt(price1) > parseInt(price2) ? parseInt(price2) : price1) * 100 / parseInt(parseInt(price1) > parseInt(price2) ? price1 : price2))?.toFixed(0) || ""
        return { ...v, discountpercent }
      }).sort((a, b) => {
        if (a?.discountpercent < b?.discountpercent) {
          return 1;
        }
        if (a?.discountpercent > b?.discountpercent) {
          return -1;
        }
        return 0;
      })
      console.log("resut", result.length)
    }
    if (action.payload.pagination === true) {
      yield put(paginationView(result));
    }
    else {
      yield put(filterBasedProductList(result));
    }
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* contactUsEffect(action) {
  try {
    yield put(updateOpenLoader())

    let data = yield call(commonService.contactUs, action.payload);
    yield put(contactUsResponse(data));
    action?.response(data)
    yield put(updateFalseLoader())
    // yield put(updateAlert('We will reach you soon!', 'success'));
    yield put(updateAlert('Your request successfully sent!', 'success'));

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* getCategoryDataEffect(action) {
  try {
    let { data } = yield call(commonService.getCategoryData, action.payload);
    if (data?.id !== undefined) {
      try {
        let { title, description } = JSON.parse(data?.description);
        yield put(addMetaTagData({ title: title, description }))
      }
      catch (e) {
      }
    }

  } catch (e) {
    console.log(e, "cons")
  }
}

function* gettAGDataEffect(action) {
  try {
    let { data } = yield call(commonService.getTagData, action.payload);
    if (data?.id !== undefined) {
      try {
        let { title, description } = JSON.parse(data?.description);
        yield put(addMetaTagData({ title: title, description }))
      }
      catch (e) {
      }
    }

  } catch (e) {
  }
}

export const CommonSaga = [
  takeEvery(TAG_CARD_WATCHER, tagCardEffect),
  takeEvery(ADD_SERVICE_AND_REPAIR_WATCHER, repairAndServiceEffect),
  takeEvery(FILTER_BASED_PRODUCT_WATCHER, filterBasedProductEffect),
  takeEvery(CONTACT_US_WATCHER, contactUsEffect),
  takeEvery(FILTER_TAG_BASED_PRODUCT_WATCHER, filterTagBasedProductEffect),
  takeEvery(GET_CATEGORY_DATA_WATCHER, getCategoryDataEffect),
  takeEvery(GET_TAG_DATA_WATCHER, gettAGDataEffect)
]
