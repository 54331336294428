// Login Watcher Actions

export const GET_ORDER_WATCHER = "GET_ORDER_WATCHER";
export const VIEW_ORDER = "VIEW_ORDER"
export const GET_SINGLE_ORDER_WATCHER = "GET_SINGLE_ORDER_WATCHER"
export const DELETE_ORDER_WATCHER = "DELETE_ORDER_WATCHER"
export const TAX_DETAIL_WATCHER = "TAX_DETAIL_WATCHER"

// Login Reducer Actions

export const ORDER_LIST = "ORDER_LIST";
export const TAX_LIST = "TAX_LIST"