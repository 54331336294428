import Routes from './routes/Routes';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'
import './theme/fonts/styles.css'
import store from "./store";
import { Provider } from "react-redux";
import { loadScript } from "./utils/common";
import { useEffect } from 'react';
import Backdrop from './components/common/backdrop'
import Alert from './components/common/alert'
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

function App() {

  ReactGA.initialize("UA-259781520-1")
  ReactPixel.init('964106811256660')
 

  return (
    <>
    <Provider store={store}>
        <ThemeProvider theme={theme} >
          <Backdrop />
          <Alert />
          <div className="wrapper">
            <Routes />
          </div>
          <a href="#/" className="scrollup"><i className="fa fa-angle-up"></i></a>
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default App;