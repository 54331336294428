// import axios from "axios";
import http from "../http-common";
import { http1 } from "../http-common";
import { WOOCOMMERCE_SERVICES } from "../http-common";

class cartService {

    getcartProductId(id) {
        return http1.get(`/product/cart/${id}`);
    }

    addCartProduct(data) {
        return http1.post(`/product/cart`, data);
    }

    updateCartProduct(data, userId, productId, variantProductId) {
        return http1.put(`/product/cart/${userId}/${productId}/${variantProductId}`, data);
    }

    removecartProductId(userId, productId, cartVariantId) {
        return http1.delete(`/product/cart/${userId}/${productId}/${cartVariantId}`);
    }

    getcartProductData(data) {
        return http.get(`/wp-json/wc/v3/products?include=${data}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    applyCouponCode(code) {
        return http.get(`/wp-json/wc/v3/coupons?code=${code}&consumer_key=ck_5c6f82c68695d9eee780c888bf9c539f8a015a61&consumer_secret=cs_b197e59eb82a2c04f238c274e92c9a07da551efb`)
    }

    getOrderById(orderId) {
        return http.get(`/wp-json/wc/v3/orders/${orderId}?consumer_key=ck_5c6f82c68695d9eee780c888bf9c539f8a015a61&consumer_secret=cs_b197e59eb82a2c04f238c274e92c9a07da551efb`)
    }

    createOrder(data) {
        return http.post('/wp-json/wc/v3/orders?consumer_key=ck_5c6f82c68695d9eee780c888bf9c539f8a015a61&consumer_secret=cs_b197e59eb82a2c04f238c274e92c9a07da551efb', data)
    }

    updateOrder(data, orderId) {
        return http.put(`/wp-json/wc/v3/orders/${orderId}?consumer_key=ck_5c6f82c68695d9eee780c888bf9c539f8a015a61&consumer_secret=cs_b197e59eb82a2c04f238c274e92c9a07da551efb`, data)
    }

    createRazorpayOrder(data) {
        return http1.post(`/product/createRazorpayOrder`, data);
    }

    createCashfreeOrder(data) {
        return http1.post(`/product/createCashfreeOrder`, data);
    }

    verifyCashfreePayment(data) {
        return http1.post(`/product/verifyCashfreePayment`, data);
    }

    confirmMsg(data) {
        return http1.post(`/product/confirmMsg`, data);
    }

    removeBulkCart(userId) {
        return http1.delete(`/product/removeBulkCart/${userId}`);
    }

    addBulkCart(data, userId) {
        return http1.post(`/product/addBulkCart/${userId}`, data);
    }

    getSingleProductDetail(id) {
        return http.get(`/wp-json/wc/v3/products/${id}?consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getProductVariants(id) {
        return http.get(`/wp-json/wc/v3/products/${id}/variations?consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getDeliveryCharge() {
        return http.get(`/wp-json/wc/v3/shipping/zones/1/methods?per_page=100&consumer_key=ck_5c6f82c68695d9eee780c888bf9c539f8a015a61&consumer_secret=cs_b197e59eb82a2c04f238c274e92c9a07da551efb`);
    }

}
export default new cartService();