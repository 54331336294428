import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import NewLaunches from '../../components/Wearables/newLaunches'
import ShopByBrand from '../../components/mobiles/shopByBrand'
import ShopByPrice from '../../components/mobiles/shopByPrice'
import NoiseSmartWatches from '../../components/Wearables/noiseSmartWatches'
import SamsungSmartWatches from '../../components/Wearables/samsungSmartWatches'
import TagCardDesign from "../../components/common/tagCards"
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAppleWatchWatcher, getNoiseWatchWatcher, getSamsungWatchWatcher, getWearablesNewLaunchesWatcher, getWearablesTagCardsWatcher } from '../../store/actionCreators/wearables'
import { getProductBrandWatcher } from '../../store/actionCreators/mobile'
import { getProductCardsWatcher } from '../../store/actionCreators/home'
import TopDeals from '../../components/Accessories/topDeals'
import { useLocation } from 'react-router-dom'
import BannerSlideindex from '../../components/Slider'
import SEO from '../../utils/SEO'

export default function Index() {
  const { wearablesReducer: { WearablesTagCards } } = useSelector(state => state)
  const matches = useMediaQuery((theme) => theme.breakpoints.up('1550'));
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    let data = {
      pageNO: 1,
      perPage: 4
    }
    let Dealdata = {
      pageNO: 1,
      perPage: 3
    }
    dispatch(getWearablesTagCardsWatcher(756))
    dispatch(getWearablesNewLaunchesWatcher(data))
    dispatch(getAppleWatchWatcher(data))
    dispatch(getNoiseWatchWatcher(data))
    dispatch(getSamsungWatchWatcher(data))
    dispatch(getProductBrandWatcher(data))
    dispatch(getProductCardsWatcher(Dealdata))
  }, [dispatch])

  // const tagCardArr = [{ categoryId: 790, urlname: 'Top-Selling', tagName: 'Top Selling', imgSrc: '/images/wearables/mobilestore.png' },
  // { categoryId: 791, urlname: `Today's-Deals`, tagName: `Today's Deals`, imgSrc: '/images/wearables/badge.png' },
  // { categoryId: 792, urlname: 'Budget-Friendly', tagName: 'Budget Friendly', imgSrc: '/images/wearables/notifications.png' },
  // { categoryId: 793, urlname: 'Latest-Models', tagName: 'Latest Models', imgSrc: '/images/wearables/rating.png' },
  // ]

  const priceArr = [
    // { id: 1, min: '100', max: '1,000', type: 'between' },
    { id: 2, min: '1,000', max: '3,000', type: 'between' },
    { id: 3, min: '3,000', max: '6,000', type: 'between' },
    // { id: 4, min: '6,000', max: '10,000', type: 'between' },
    // { id: 5, min: '10,000', max: '50,000', type: 'between' },
    // { id: 6, min: '50,000', max: '1,00,000', type: 'between' }
  ]

  return (
    <>
      <SEO title='Buy Wearable Accessories Online at Best Prices In Chennai' description='Shop for Wearable Devices online at Phonebuy.com. Checkout wide range selection of Wearable Devices at affordable prices.' />
      <Box m='30px 0' style={{ margin: matches && "0px 100px" }}>
        <Box m='100px 30px 0 30px'>
          <TagCardDesign tagCardArr={WearablesTagCards} categoryId={756} />
        </Box>

        <Box>
          <BannerSlideindex />
        </Box>

        <Box m='0 30px 20px 30px'>
          <NewLaunches />
        </Box>

        <Box m='0px 30px 0 30px'>
          <TopDeals category_id={756} />
        </Box>

        <Box m='0px 30px 0 30px'>
          <ShopByBrand categoryName={'wearables'} categoryId={756} page='wearables' />
        </Box>

        <Box m='0px 30px 0 30px'>
          <ShopByPrice priceArr={priceArr} categoryId={756} page='wearables' />
        </Box>

        <Box m='0px 30px 0 30px'>
          <NoiseSmartWatches />
        </Box>


        <Box m='25px 30px 0 30px'>
          <SamsungSmartWatches />
        </Box>
      </Box>
    </>
  )
}
