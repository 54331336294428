import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { removeFromWishlistWatcher } from '../../../store/actionCreators/product'
import { useDispatch, useSelector } from 'react-redux'
import { AddCartProductWatcher } from '../../../store/actionCreators/cart'
import { useEffect } from 'react'
import { useState } from 'react'
import { getcardprimarycolor } from '../../../utils/common'
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function WishListDesign({ value }) {
  console.log(value, "valuee");
  let dispatch = useDispatch()
  const navigate = useNavigate()

  const { loginReducers: { userData }, cartReducers: { cartProductData } } = useSelector((state) => state)
  const isCart = cartProductData.some(d => d?.id === value?.id)
  const productId = value?.wishListProductId !== undefined ? value?.wishListProductId : value?.id
  const [productName, setProductName] = useState('')
  const [selectedVariant, setSelectedVariant] = useState('')

  const imageName = (imageArr) => {
    let imgName = (imageArr?.length && imageArr[0]?.src) || "/images/Phone1.png"
    return imgName
  }

  useEffect(() => {
    let prodName = value?.name
    //route code
    if (value?.attributes?.length && value?.attributes[0]?.options?.length) {
      let attribute = value?.attributes?.filter(s => s?.variation) || []
      let getName = attribute?.length >= 2 ? attribute[0]?.options[0] + ' - ' + attribute[1]?.options[0] : attribute[0]?.options[0]
      const prodName = `${value?.name}(${getName})`
      setProductName(prodName)

      let chooseColor = attribute[0]
      chooseColor.selected = chooseColor?.options[0]
      if (attribute?.length >= 2) {
        let chooseGB = attribute[1]
        chooseGB.selected = chooseGB?.options[0]
      }
      setSelectedVariant(attribute)
    }
    else {
      setProductName(value?.name)
    }
  }, [value])

  const removefunction = (e) => {
    e.stopPropagation()
    let userId = userData[0]?.userId
    let productId = value?.wishListProductId
    dispatch(removeFromWishlistWatcher(userId, productId, value?.wishListVariantId, dispatch))
    console.log(userId, productId, value?.wishListVariantId, "consolelog");
  }
  console.log("value", value)

  const addToCart = (e) => {
    e.stopPropagation()
    let userId = userData[0]?.userId
    let productId = value?.id
    if (userData.length) {
      if (!isCart) {
        let data =
        {
          req_payload: {
            userId,
            productId: value?.wishListProductId,
            quantity: "1",
            variantId: +value?.wishListVariantId,
          },
          detail: {
            name: productName,
            price: value?.price
          }
        }
        console.log(data, "datacart")
        dispatch(AddCartProductWatcher(data, dispatch))
        navigate('/cart')
      }
      else {
        navigate('/cart')
      }
    }
  }

  const handleNavigate = async () => {
    let prodName = value?.name
    const pathName = window.location.pathname.substr(1,).toLowerCase() || "home"

    //route code
    if (value?.wishListVariantId !== 0) {
      navigate(`/${encodeURIComponent(pathName)}/${prodName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${value?.wishListProductId !== undefined ? value?.wishListProductId : productId}&vid=${value?.wishListVariantId !== undefined ? value?.wishListVariantId : value?.cartVariantId}`, { replace: true })
    }

    else {
      navigate(`/${encodeURIComponent(pathName)}/${prodName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${value?.wishListProductId !== undefined ? value?.wishListProductId : productId}&vid=0`, { replace: true })
    }
  }
  let cat_name = value.categories?.length > 0 ? value.categories[0]?.name : ''
  let cat_id = value?.primary_category?.find(m => m.key === 'epc_primary_product_cat')?.value || value?.meta_data?.find(m => m.key === 'epc_primary_product_cat')?.value
  let primaryCategory = cat_id !== undefined ? (value?.categories?.find(v => v?.id == cat_id)?.name || cat_name) : cat_name

  return (
    <div className="frame-1000002000 flex-col-hstart-vstart clip-contents" onClick={handleNavigate} style={{ cursor: "pointer" }}>
      <div className="frame-1000001921 flex-row-vstart-hstart">
        {/* <Link to={`/productDetails/${value?.id}`} target="_blank"> */}
        <img
          src={imageName(value?.images)}
          alt=""
          className="frame-1000001918"
        />
        {/* </Link> */}
        <div className="frame-1000001912 flex-col-hstart-vsb clip-contents">
          <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
            <Typography variant='p12_400' color="primaryColors.loginText">{primaryCategory}</Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center", paddingTop: "10px" }}>
            <StarRateRoundedIcon sx={{ fontSize: 16, color: "#F26B23" }} /><span style={{ fontSize: "14px", color: "#888888" }}>{parseInt(value?.average_rating)}</span>
          </div>
          <div className="frame-1000002068 flex-col-hstart-vstart">
            <div className="frame-10000019181 flex-row-vstart-hsb">
              <div className="frame-1000002068 flex-col-hstart-vstart">
              </div>
              {/* <img src="/images/OrderConfirmation/Arrow.png" alt='' /> */}
            </div>
            <br></br>
            <div className="frame-1000001920 flex-col-hstart-vstart">
              {/* <Link to={`/productDetails/${value?.id}`} target="_blank"> */}
              <div className="frame-1000002074 flex-row-vstart-hstart">
                <div >
                  <div className="frame-1000001919 flex-row-vstart-hstart">
                    <p className="txt-954" style={{ paddingBottom: "0px", height:"30px" }}>
                      {productName}
                    </p>
                  </div>
                </div>
              </div>
              <div className="products-specs clear" style={{ padding: "10px 0px" }}>
                {
                  value?.product_attributes?.filter(v => !v?.variation)?.map((v, i) => {
                    return (
                      <aside key={v.name}><img src={`https://dev.phonebuy.com/wp-content/uploads/2022/11/${v?.name}.png`} alt='' /><span className="attribute_break">{v?.options?.toString()}</span></aside>
                    )
                  })
                }
              </div>
              <div className="frame-10000019182 flex-row-vcenter-hstart">
                <p className="txt-531">₹{value.price}</p>
              </div>
              {/* </Link> */}
              <Box className="frame-1000002023 flex-row-vend" mt='80px'>
                <Button className="frame-1000002071 flex-row-vcenter-hcenter" sx={{ cursor: 'pointer', height: "40px", width: "130px", marginRight:"10px" , backgroundColor: "orange.main", "&:hover": { color: "orange.main", backgroundColor: "orange.main"} }} onClick={addToCart}>
                    <LocalMallIcon color='white' className='trash'/>
                  <h1 className="txt-278" style={{color:"white"}}> Movetocart </h1>
                </Button>
                <Button className="frame-1000002071 flex-row-vcenter-hcenter" sx={{ cursor: 'pointer', height: "40px", width: "130px", backgroundColor: "orange.main", "&:hover": { color: "orange.main", backgroundColor: "orange.main"} }} onClick={removefunction}>
                    <DeleteForeverIcon color='white' className='trash'/>
                  <h1 className="txt-278" style={{color:"white"}}> RemoveItem </h1>
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
