import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "../components/Navbar";
import HomePage from "../pages/HomePage";
import ProductDetails from "../pages/ProductDetails";
import Mobile from "../pages/Mobile";
import Cart from "../pages/Cart";
import OrderConfirmation from "../pages/OrderConfirmation"
import Category from '../pages/Category'
import Checkout from "../pages/Checkout/PlaceOrder"
import Payment from "../pages/Checkout/PlaceOrder"
import CartEmpty from "../pages/Profile/CartEmpty";
import Orders from "../pages/Profile/Orders"
import ViewDetails from "../pages/Profile/Orders/viewDetails";
import RepairServices from "../pages/Profile/RepairServices"
import Addressess from "../pages/Profile/Addressess"
import Wishlist from "../pages/Profile/Wishlist"
import ProfilePage from "../pages/Profile/ProfilePage"
import Otp from "../pages/loginAndSignin/otp"
import Accessories from "../pages/Accessories"
import Wearables from "../pages/Wearables"
import RepairAndServices from "../pages/RepairAndServices/repairPage"
import Login from "../pages/loginAndSignin/login"
import SignUpDesign from "../pages/loginAndSignin/signUp"
import HeadPhones from "../pages/HeadPhones";
import Tablets from "../pages/Tablets"
import Policy from "../components/Footer/policy";
import TermsCondition from "../components/Footer/termsCondition";
import Refund from "../components/Footer/refund";
import OurStores from "../components/Footer/ourStores";
import ContactUs from "../components/Footer/contactUs";
import LoginRedirect from "../components/Footer/loginRedirect";
import ForgetPassword from "../pages/loginAndSignin/forgetPassword";
import ForgotPasswordVerify from "../pages/loginAndSignin/forgotPasswordVerify";
import AboutUs from "../components/Footer/aboutUs";
import SiteMap from "../components/Footer/siteMap";
import ProductBrandListCard from "../components/ProductBrandListCard";

const RoutesComp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index element={<HomePage />} />
          <Route path='productDetails/:productId/:variantProductId/:productContent' element={<ProductDetails />} />
          <Route path='mobiles' element={<Mobile />} />
          <Route path='cart' element={<Cart />} />
          <Route path='orderConfirmation/:orderId' element={<OrderConfirmation />} />
          <Route path='search' element={<Category />} />
          <Route path="/:tag_name" element={<Category />} />
          <Route path="/brand/:tag_name" element={<ProductBrandListCard />} />
          <Route path='checkout' element={<Checkout />} />
          <Route path='payment' element={<Payment />} />
          <Route path='cartEmpty' element={<CartEmpty />} />
          <Route path='orders' element={<Orders />} />
          <Route path='mobile-repair-and-services' element={<RepairServices />} />
          <Route path='addresses' element={<Addressess />} />
          <Route path='wishlist' element={<Wishlist />} />
          <Route path='profilePage' element={<ProfilePage />} />
          <Route path='login' element={<Login />} />
          <Route path='forgetPassword' element={<ForgetPassword />} />
          <Route path='otp' element={<Otp />} />
          <Route path='mobile-accessories' element={<Accessories />} />
          <Route path='wearables' element={<Wearables />} />
          <Route path='mobile-repair-and-services' element={<RepairAndServices />} />
          <Route path='signUp' element={<SignUpDesign />} />
          <Route path='viewDetails/:id' element={<ViewDetails />} />
          <Route path='mobile-headphones-headsets' element={<HeadPhones />} />
          <Route path='tablets' element={<Tablets />} />
          <Route path='policy' element={<Policy />} />
          <Route path='termsCondition' element={<TermsCondition />} />
          <Route path='refund' element={<Refund />} />
          <Route path='ourStores' element={<OurStores />} />
          <Route path='contactus' element={<ContactUs />} />
          <Route path='loginRedirect' element={<LoginRedirect />} />
          <Route path='/:path/:product_name' element={<ProductDetails />} />
          <Route path='/ForgotPasswordVerify' element={<ForgotPasswordVerify />} />
          <Route path='aboutUs' element={<AboutUs/>} />
          <Route path='siteMap' element={<SiteMap/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComp;
