import { GET_WISH_LIST_PRODUCT_ID_WATCHER, GET_WISH_LIST_PRODUCT_DATA_WATCHER, REMOVE_FROM_WISH_LIST_WATCHER, WISH_LIST_PRODUCT_ID_LIST, WISH_LIST_PRODUCT_LIST, ADD_TO_WISH_LIST_WATCHER, GET_PRODUCT_DETAIL_WATCHER, PRODUCT_DETAIL_DATA_LIST, ADD_USER_PINCODE_WATCHER, USER_PINCODE_LIST, GET_PRODUCT_REVIEWS_WATCHER, PRODUCT_REVIEWS_LIST, GET_MULTIPLE_PRODUCT_DATA, MULTIPLE_PRODUCT_LIST, ADD_SELECTED_TAB, GET_PRODUCT_ACCESSORIES_WATCHER, ACCESSORIES_SINGLE_PRODUCT, ADD_PRODUCT_REVIEW, ADD_PRODUCT_VARIANTS, ADD_VARIANT_DETAIL, PRODUCTREVIEW_EMPTY, MODIFY_PRODUCT_DETAIL, ADD_PRODUCT_BREADCRUMB } from "../actionTypes/product"

// Worker triggering actionCreators

export function getWishlistProductIdWatcher(userId, dispatch) {
  return { type: GET_WISH_LIST_PRODUCT_ID_WATCHER, userId, dispatch };
}

export function AddWishlistProductWatcher(data, dispatch) {
  return { type: ADD_TO_WISH_LIST_WATCHER, payload: data, dispatch };
}

export function getWishlistProductDataWatcher(data) {
  return { type: GET_WISH_LIST_PRODUCT_DATA_WATCHER, payload: data };
}

export function removeFromWishlistWatcher(userId, productId, variantId, dispatch) {
  return { type: REMOVE_FROM_WISH_LIST_WATCHER, userId, productId, variantId, dispatch };
}

export function getProductDetailWatcher(productId) {
  return { type: GET_PRODUCT_DETAIL_WATCHER, productId };
}

export function addUserPincodeWatcher(data, res) {
  return { type: ADD_USER_PINCODE_WATCHER, payload: data, res };
}

export function getProductReviewsWatcher(productId, data) {
  return { type: GET_PRODUCT_REVIEWS_WATCHER, productId, data };
}

export function getMultipleProductDataWatcher(data, res) {
  return { type: GET_MULTIPLE_PRODUCT_DATA, payload: data, res };
}

export function removeWishListWatcher(userId, productId) {
  return { type: REMOVE_FROM_WISH_LIST_WATCHER, userId, productId };
}

export function getProductAccessoriesWatcher(id) {
  return { type: GET_PRODUCT_ACCESSORIES_WATCHER, id };
}

export function addProductReviewWatcher(data) {
  return { type: ADD_PRODUCT_REVIEW, data };
}



// Redux state changing actionCreators
export function productReviewEmptyList() {
  return { type: PRODUCTREVIEW_EMPTY };
}

export function wishListProductIdList(data) {
  return { type: WISH_LIST_PRODUCT_ID_LIST, payload: data };
}

export function wishListProductDataList(data) {
  return { type: WISH_LIST_PRODUCT_LIST, payload: data };
}

export function productDetailDataList(data) {
  return { type: PRODUCT_DETAIL_DATA_LIST, payload: data };
}

export function userPincodeList(data) {
  return { type: USER_PINCODE_LIST, payload: data };
}

export function productReviewsList(data) {
  return { type: PRODUCT_REVIEWS_LIST, payload: data };
}

export function multipleProductList(data) {
  return { type: MULTIPLE_PRODUCT_LIST, payload: data };
}

export function addSelectedTab(data) {
  return { type: ADD_SELECTED_TAB, payload: data };
}

export function accessoriesSingleProduct(data) {
  return { type: ACCESSORIES_SINGLE_PRODUCT, payload: data };
}

export function productVariants(data) {
  return { type: ADD_PRODUCT_VARIANTS, payload: data };
}

export function addVariantDetail(data) {
  return { type: ADD_VARIANT_DETAIL, payload: data };
}

export function modifyProductDetail(data) {
  return { type: MODIFY_PRODUCT_DETAIL, payload: data };
}

export function addProductBreadCrumbs(data) {
  return { type: ADD_PRODUCT_BREADCRUMB, payload: data };
}



