import { http1 } from "../http-common";
import http from "../http-common";

class loginService {

    checkUserNumber(data) {
        return http1.post('/login/isExist', data);
    }

    checkIsExist(data) {
        return http1.post('/login/CheckIsExist', data);
    }

    addUser(data) {
        return http1.post('/login/addUser', data);
    }

    verifyOtp(data) {
        return http1.post('/login/verifyOtp', data);
    }

    updateUser(data, id) {
        return http1.put(`/login/${id}`, data);
    }

    updateAddress(data, id) {
        return http1.put(`/address/${id}`, data);
    }

    getAddress(id) {
        return http1.get(`/address/${id}`);
    }

    deleteAddress(id, userId) {
        return http1.delete(`/address/${id}/${userId}`);
    }

    addAddress(data) {
        return http1.post(`/address`, data);
    }

    updateWooUser(data, id) {
        return http.put(`/wp-json/wc/v3/customers/${id}?consumer_key=ck_5c6f82c68695d9eee780c888bf9c539f8a015a61&consumer_secret=cs_b197e59eb82a2c04f238c274e92c9a07da551efb`, data);
    }

    addWooUser(data) {
        return http.post('/wp-json/wc/v3/customers?consumer_key=ck_5c6f82c68695d9eee780c888bf9c539f8a015a61&consumer_secret=cs_b197e59eb82a2c04f238c274e92c9a07da551efb', data);
    }

    checkWooUserEmail(data) {
        return http.get(`/wp-json/wc/v3/customers?email=${data}&consumer_key=ck_5c6f82c68695d9eee780c888bf9c539f8a015a61&consumer_secret=cs_b197e59eb82a2c04f238c274e92c9a07da551efb`);
    }

    login(data) {
        return http1.post('/login', data);
    }

    forgotpassword(data) {
        return http1.post('/login/forgotpassword', data);
    }

    updatepassword(data) {
        return http1.put(`/login/password/${data.userId}`, data);
    }

}
export default new loginService();