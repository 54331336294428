import React from 'react'
import { Typography, Box, Button, Grid } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import DealsCard from '../ProductListItemCard/dealsCard';
import { addPrimarycategory, filterTagBasedProductWatcher } from '../../store/actionCreators/common';
import { Link, useLocation } from 'react-router-dom';
import ScrollLoader from '../Loader/scrollLoader';
import { getDealsWatcher } from '../../store/actionCreators/home';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { getprimarycolor } from '../../utils/common';


const DiscountCard = ({ pageType, category_id }) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { homeReducer: { DealsList, DealsListLoader } } = useSelector((state) => state)

    const mapData = DealsList.length ? DealsList : []
    const searchPath = '/search'

    const { ref, inView, entry } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    useEffect(() => {
        let Dealdata = {
            pageNO: 1,
            perPage: 3,
            category_id
        }
        if (inView) {
            dispatch(getDealsWatcher(Dealdata))
        }
    }, [inView, location.pathname])

    const viewFunction = () => {
        // let data = {
        //     perPage: 50,
        //     name: "TopDeals",
        //     tag: 499,
        //     category: category_id
        // }
        // const pathName = window.location.pathname.substr(1,).toLowerCase()
        // dispatch(addPrimarycategory(pathName))
        // dispatch(filterTagBasedProductWatcher(data))
    }
    let url = "/TopDeals?name=TopDeals&tag=499"
    if(category_id) {
        url += "&category="+category_id
    }

    return (
        <Box className={pageType === "mobile" ? "container clear" : "container deals clear"} ref={ref}>
            <>
                <Box className="clear"></Box>
                <Grid item container>
                    <Grid lg={8} md={8} sm={8} xs={12}>
                        <h2 className="left">Deals You Dont want to miss</h2>
                    </Grid>
                    <Grid lg={4} md={4} sm={4} xs={12}>
                        {Boolean(mapData.length >= 3) &&
                            <Link to={url} state={{ tag: 499 }}>
                                <Box display='flex'>
                                    <Button variant='contained'
                                        sx={{
                                            width: '130px', height: '40px', backgroundColor: "#F26B23",
                                            '&:hover': { backgroundColor: "#F26B23" }, textTransform: 'none', m: '0px 25px 10px auto'
                                        }} onClick={viewFunction} width='100px' >
                                        <Typography color='white !important'>View All</Typography>
                                    </Button>
                                </Box>
                            </Link>
                        }
                    </Grid>
                </Grid>

                {DealsListLoader ? <ScrollLoader /> :
                    mapData.map((v, i) => {
                        return <Box flexDirection="row" key={v.id}>
                            <DealsCard productData={v} categoryName='Deals You Dont want to miss' categoryType='Mobile' />
                        </Box>
                    })
                }
            </>
        </Box>
    );
};

export default DiscountCard;
