import { takeEvery, call, put } from "redux-saga/effects";
import tabletsService from "../../services/tablets"
import { updateOpenLoader, updateAlert, updateFalseLoader } from '../actionCreators/common'
import { tabletsNewLaunchesList } from "../actionCreators/tablets";
import { GET_TABLETS_NEW_LAUNCHES_WATCHER } from "../actionTypes/tablets";


function* newLaunchesListEffect(action) {
    try {
      yield put(updateOpenLoader())
  
      let { data } = yield call(
        tabletsService.getAllTabletsNewLaunchesList,
        action.payload
      );
  
      yield put(tabletsNewLaunchesList(data));
      yield put(updateFalseLoader())
    } catch (e) {
      yield put(updateFalseLoader());
      yield put(updateAlert('Something Went Wrong!', 'error'));
    }
  }
  
  // function* shopByPriceEffect(action) {
  //   try {
  //     yield put(updateOpenLoader())
  
  //     let { data } = yield call(
  //       tabletsService.getAllTabletsShopByPriceList,
  //       action.payload
  //     );
  
  //     yield put(shopByPriceList(data));
  //     yield put(updateFalseLoader())
  //   } catch (e) {
  //     yield put(updateFalseLoader());
  //     yield put(updateAlert('Something Went Wrong!', 'error'));
  //   }
  // }

  export const tABLETSProductSaga = [
    takeEvery(GET_TABLETS_NEW_LAUNCHES_WATCHER, newLaunchesListEffect),
    // takeEvery(GET_SHOP_BY_BRAND_WATCHER, shopByBrandEffect),
    // takeEvery(GET_SHOP_BY_PRICE_WATCHER, shopByPriceEffect),
  ];