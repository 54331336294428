import { Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';

export default function InvoiceDetail({ data }) {
    console.log(data, "ddddd");
    return (
        <>
            <Grid container>
                <Grid lg={6} md={6} sm={6} xs={6} >
                    <div>
                        <img src="/images/InvoiceLogo.png" alt="Phone Buy" title="Phone Buy" style={{ height: "200px", width: "200px" }} />
                    </div>
                </Grid>
                <Grid lg={6} md={6} sm={6} xs={6} >
                    <Typography variant='p16_700'>Tax Invoice/Bill of Supply/Cash Memo
                        (Original for Recipient)</Typography>
                </Grid>
            </Grid>

            <Grid container>
                <Grid lg={6} md={6} sm={6} xs={6}>
                    <Typography variant='p22_700'> From </Typography> <br />
                    <Typography variant='p14_700'> No.87, 98, Velachery Rd,
                        Pallikaranai,
                    </Typography> <br />
                    <Typography variant='p14_700'>  Chennai – 600100.
                        Tamil Nadu, India. </Typography> <br />
                    <Typography variant='p14_700'> E-mail: sales@phonebuy.com </Typography> <br />
                    <Typography variant='p14_700'> Contact No: 8270050505 </Typography>
                </Grid>

                <Grid lg={6} md={6} sm={6} xs={6}>
                    <Typography variant='p22_700'> Shipping Address </Typography> <br />
                    <Typography variant='p14_700'> {data?.billing?.address_1} </Typography>
                    <Typography variant='p14_700'> {data?.billing?.address_2} </Typography>
                    <Typography variant='p14_700'> {data?.billing?.city}- {data?.billing?.postcode} </Typography> <br />
                    <Typography variant='p14_700'> E-mail: {data?.billing?.email} </Typography> <br />
                    <Typography variant='p14_700'> Contact No: {data?.billing?.phone} </Typography>
                </Grid>

                <Grid lg={6} md={6} sm={6} xs={6} paddingTop="20px">
                    <Typography variant='p14_700'> GST Registration No : </Typography>
                    <Typography variant='p14_700'> </Typography>
                </Grid>

                <Grid lg={6} md={6} sm={6} xs={6} paddingTop="20px">
                    <Typography variant='p14_700'> State/UT Code: 33 </Typography>
                </Grid>

                <Grid lg={6} md={6} sm={6} xs={6} paddingTop="20px">
                    <Typography variant='p14_700'> Order Number: </Typography>
                    <Typography variant='p14_700'> {data?.id} </Typography> <br />
                    <Typography variant='p14_700'> Order Date:  </Typography>
                    <Typography variant='p14_700'> {data?.date_paid?.slice(0, 10)}  </Typography>
                </Grid>
                <Grid lg={6} md={6} sm={6} xs={6} paddingTop="20px">
                    <Typography variant='p14_700'> Invoice Date:   </Typography>
                    <Typography variant='p14_700'> {data?.date_completed?.slice(0, 10)}   </Typography>
                </Grid>

                <Grid container paddingTop="20px">
                    <Grid lg={1} md={1} sm={1} xs={1} border="1px solid black">
                        <Typography> S.no </Typography>
                    </Grid>
                    <Grid lg={3} md={3} sm={3} xs={3} border="1px solid black">
                        <Typography> Description of Goods </Typography>
                    </Grid>
                    <Grid lg={1} md={1} sm={1} xs={1} border="1px solid black">
                        <Typography> Unit Price </Typography>
                    </Grid>
                    <Grid lg={1} md={1} sm={1} xs={1} border="1px solid black">
                        <Typography> Quantity </Typography>
                    </Grid>
                    <Grid lg={1} md={1} sm={1} xs={1} border="1px solid black">
                        <Typography> Tax Rate </Typography>
                    </Grid>
                    <Grid lg={2} md={2} sm={2} xs={2} border="1px solid black">
                        <Typography> Tax Type </Typography>
                    </Grid>
                    <Grid lg={1} md={1} sm={1} xs={1} border="1px solid black">
                        <Typography> Tax Amount </Typography>
                    </Grid>
                    <Grid lg={1} md={1} sm={1} xs={1} border="1px solid black">
                        <Typography> Delivery Charge </Typography>
                    </Grid>
                    <Grid lg={1} md={1} sm={1} xs={1} border="1px solid black">
                        <Typography> Total Amount </Typography>
                    </Grid>
                    <Grid lg={1} md={1} sm={1} xs={1}>
                        {data?.line_items?.map((v, i) => {
                            return <Typography border="1px solid black"> {i + 1} </Typography>
                        })}
                    </Grid>
                    <Grid lg={3} md={3} sm={3} xs={3}>
                        {data?.line_items?.map((v) => {
                            console.log(data?.line_items.length, "length");
                            return <Typography border="1px solid black"> {v?.name} </Typography>
                        })}
                    </Grid>
                    <Grid lg={1} md={1} sm={1} xs={1}>
                        {data?.line_items?.map((p) => {
                            return <Typography border="1px solid black"> {p?.subtotal} </Typography>
                        })}
                    </Grid>
                    <Grid lg={1} md={1} sm={1} xs={1}>
                        {data?.line_items?.map((q) => {
                            return <Typography border="1px solid black"> {1} </Typography>
                        })}
                    </Grid>
                    <Grid lg={1} md={1} sm={1} xs={1}>
                        {data?.line_items?.map((p) => {
                            return <Typography border="1px solid black"> {p?.subtotal_tax} </Typography>
                        })}
                    </Grid>
                    <Grid lg={2} md={2} sm={2} xs={2}>
                        <Grid container height="100%">
                            {data?.tax_lines?.map((g) => {
                                return <Grid lg={6} md={6} sm={6} xs={6}>
                                    <Typography border="1px solid black" height="100%"> {g?.label} </Typography>
                                </Grid>
                            })}
                        </Grid>
                    </Grid>

                    {/* <Grid lg={1} md={1} sm={1} xs={1}>
                        {data?.tax_lines?.map((q) => {
                            return <Typography border="1px solid black"> {q?.label} </Typography>
                        })}
                    </Grid>
                    <Grid lg={1} md={1} sm={1} xs={1}>
                        {data?.tax_lines?.map((p) => {
                            return <Typography border="1px solid black"> {p?.tax_total} </Typography>
                        })}
                    </Grid> */}
                    <Grid lg={1} md={1} sm={1} xs={1} border="1px solid black">
                        <Typography > {data?.total_tax} </Typography>
                    </Grid>
                    <Grid lg={1} md={1} sm={1} xs={1} border="1px solid black">
                        <Typography > {data?.shipping_total} </Typography>
                    </Grid>
                    <Grid lg={1} md={1} sm={1} xs={1} border="1px solid black">
                        {data?.line_items?.map((p) => {
                            return <Typography> {!NaN & p?.price < 1 ? 1 : Math?.round(p?.price)} </Typography>
                        })}
                    </Grid>
                    <Grid lg={10} md={10} sm={10} xs={10} border="1px solid black">
                        <Typography> Total </Typography>
                    </Grid>
                    <Grid lg={2} md={2} sm={2} xs={2} border="1px solid black">
                        <Typography> {Math?.round(data?.total)} </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container>
                <Grid lg={12} md={12} sm={12} xs={12} padding="20px" display="flex" justifyContent="end" >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <img src="/images/signature.png" style={{ height: "120px", width: "200px" }} />
                        <Typography> Authorized Signatory </Typography>
                    </div>
                </Grid>
                <Grid lg={12} md={12} sm={12} xs={12}>
                    <Typography style={{ marginLeft: "5px" }}> Declaration : </Typography>
                    <Divider style={{ width: "80px", border: "0.5px solid black", marginLeft: "5px" }} />
                    <Typography style={{ display: "flex", justifyContent: "center" }}> We declare that this invoice shows the actual price of the goods described and that all particular are true and correct. </Typography>
                </Grid>
            </Grid>
        </>
    )
}
