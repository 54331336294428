import { Typography } from '@mui/material'
import React, { useEffect } from 'react'

export default function Refund() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="section" style={{ margin: "80px" }}>
            <div style={{ display: "flex", justifyContent: "center", fontSize: "40px", fontFamily: "serif", padding: "20px" }}> Refund Policy </div>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black" }}> Returning products purchased online </Typography>
            <p style={{ paddingTop: "10px" }}>
                The fastest and easiest way to return an item is by bringing it to a PhoneBuy.com Store Near You.
                However, in some cases, certain items that have been purchased online can be returned by mail.
                If you don't live near a PhoneBuy.com Store location and are eligible to ship your product back.
                Customer shall intimate request to Company the within the 24 Hours from the receipt of goods in case of damage, DOA, Return, missing products, delivered products not as per the standards/specification mentioned.
            </p>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black", paddingTop: "10px" }}> RETURN POLICY </Typography>
            <p style={{ paddingTop: "10px" }}>
                • Reasons for Return-Return Policy makes it possible for You to return a product if You receive a damaged or defective product or if the product is significantly different from what was purchased or when the package delivered is missing one or more products or accessories. Return Policy here should be read in conjunction with the any specific terms and conditions applicable to a product which can be found on product descriptions page for returning a product.
            </p>
            <p>
                • Conditions for Return-In order to return any product sold through the App, you are required to comply with the below mentioned conditions:
            </p>
            <div style={{ padding: "10px 30px" }}>
                <p>
                    o	The return has to be in compliance with the Return Policy associated with the product category.
                </p>
                <p>
                    o	Products should be returned unused, in their original packaging along with the original price tags, labels, packing, barcodes, user manual, warranty card and invoices, accessories, freebies and original boxes defined as essentials. If any product is returned without the essentials, the product shall not be accepted for return and shall be sent back to You.
                </p>
                <p>
                    o	The return packages should be strongly and adequately packaged so that there is no damage of products during transit.
                </p>
                <p>
                    o	The returned products are subject to verification and quality checks by the Company in order to determine the legitimacy of the complaint or return.
                </p>
            </div>
            <p>
                • You should fill the Return Form provided by Company with the originally delivered package or make available to You over email or account and include it in the returned package. Return requests are not processed if the Return Form is incomplete or absent in the returned package received. The reason for return should be stated in the Return form. You should additionally fill out the delivery sheet used by the dealer and note down any damaged or tampered condition of the delivered package.
            </p>
            <p>
                • The return has to be in compliance with the return policy of the dealer or the manufacturer of the product where applicable.
            </p>
            <p>
                • Products shall not be considered under Return Policy or shall not be returned
            </p>
            <div style={{ padding: "10px 30px" }}>
                <p>
                    o	Products that have been used or installed.
                </p>
                <p>
                    o	Products damaged due to misuse of product or products having incidental damages due to malfunctioning.
                </p>
                <p>
                    o	A product with defects not covered under manufacturer’s warranty.
                </p>
                <p>
                    o	Products with tampered or missing serial numbers.
                </p>
                <p>
                    o	Personalised or engraved products or resized products.
                </p>
                <p>
                    o	Product sold in sets or combination cannot be returned individually.
                </p>
                <p>
                    o	Any other product depending upon the Return Policy in force.
                </p>
            </div>
            <p>
                •	If You are unable to inform Company of the receipt of a damaged or defective product or the product is not as per specifications of Your order or the package delivered is missing one or more products or accessories as per the returns policy and procedure, Company shall not be obliged to accept the returned product or be liable in this regard. In the event, such product is sent to Company, the product will be returned to the Customer. Company will not be responsible if the product is not accepted and no refund will be issued.
            </p>
            <p>
                •	Damaged, Defective or Other Non-Conforming Products
            </p>
            <p>
                •	Returns of damaged, defective or other products eligible for return are accepted as under:
            </p>
            <div style={{ padding: "10px 30px" }}>
                <p>
                    o	In the event You receive a damaged or defective product or a product that does not comply with the specifications as per Your original or the product is delivered after the expected delivery period or the package delivered is missing one or more products or accessories and eligible for return as described by Return Policy, You are required to get in touch with the Customer Care Team through any of the below mentioned channels or submit a return request online within the 24 hours from the receipt of products (if such facility is available) as per the Returns Policy.
                </p>
                <p>
                    o	Upon receiving Your return request, Company shall verify the authenticity and the nature of the request and if the request is genuine, and upon the return request Company for a refund to be processed upon the receipt and quality check of the returned product. It will take a minimum of [4-6] business days to process Your request for return of products.
                </p>
                <p>
                    o	Upon receipt of the returned product by Company and successful completion of the quality check, you will receive an email or SMS confirmation at the email address or mobile number provided to Company.
                </p>
                <p>
                    o	You will be refunded the product cost along with taxes, if any.
                </p>
            </div>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black", paddingTop: "10px" }}> DEAD ON ARRIVAL PRODUCTS </Typography>
            <p style={{ paddingTop: "10px" }}>
                In the event, you receive an electronic or technological product that is has manufacturing defect or is dead on arrival, you should inform Company the period defined in the returns policy and place a request for return by following the procedure defined in the Returns Policy.
                You may be asked to obtain a manufacturer defect or Dead on Arrival certificate from the manufacturer or an authorised service centre of the manufacturer.
                This certificate will have to be produced to Company for processing Your return request at its own discretion to help Customers obtain this certificate and cannot be held responsible for any delay or mishandling of products.
                If the products are found to be working or the defect is caused to any mishandling, product will be returned back to You.
                Customers are required to accept the return.
            </p>
            <p>
                In case a dead-on arrival certificate cannot be issued by an authorised service centre of the manufacturer, You will be required to get the product serviced or repaired as per warranty provided by the manufacturer and Company will not be liable in any manner.
                In the event the certificate provided as dead on arrival certificate is disputed by the manufacturer or deemed as invalid, the product will be returned to You and Company will not be liable in any manner.
            </p>
            <p>
                Refusal of Return Request- Company reserves the right to refuse or cancel any return request.
                If the request for returns is not allowed by the Returns Policy, you will not be refunded the payment made or any costs and will not be able to raise a second request for return for the same product.
                You agree not to dispute the decision made by Company and accept Company decision regarding the refusal or cancellation and further agree not hold Company liable for any refusal or cancellation
            </p>
            <p>
                Frivolous Complaints- In the event of frivolous or baseless complaints or requests regarding the quality or content of the products, Company reserves the right to take necessary legal actions against You and You will be solely liable for all costs incurred by Company in this regard.
            </p>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black", paddingTop: "10px" }}> Promotional pricing </Typography>
            <p style={{ paddingTop: "10px" }}>
                All of Company’s price changes take effect as of midnight ISD on the advertised date.
                This also applies to special promotional events (such as Online Only Events), unless otherwise indicated.
            </p>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black", paddingTop: "10px" }}> Purchasing by minors </Typography>
            <p style={{ paddingTop: "10px" }}>
                For the protection of our shoppers, it is our policy to not sell to minors.
                If you are under the legal age of majority and wish to purchase from our online store, please have a parent or legal guardian make the purchase for you.
            </p>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", color: "black", paddingTop: "10px" }}> Errors or misprints </Typography>
            <p style={{ paddingTop: "10px" }}>
                We strive to prevent errors.
                How we fix them depends on the error.
                If we find that a product description is incorrect, for example, we'll correct it.
            </p>
            <p>
                In general, you should know that prices and availability are subject to change without notice.
                Errors will be corrected where discovered.
                Company reserves the right to revoke any stated offer and to correct any errors, inaccuracies or omissions, including after an order has been submitted, whether or not the order has been confirmed and your credit card charged, as permitted by law.
                If your credit card has already been charged for the purchase and your order is cancelled, Company will issue a credit to your credit card account in the amount of the charge.
                Individual bank policies will dictate when this amount is credited to your account.
            </p>
        </div>
    )
}
