import React, { useEffect, useState } from 'react'
import { Typography, Grid } from '@mui/material'
import { Box } from '@mui/system'
import OrderSummary from '../../../components/cart/orderSummary'
import CartItemIndex from '../../../components/cart/cartItemIndex'
import { useDispatch, useSelector } from 'react-redux'
import AfterLogin from './afterLogin'
import BeforeLogin from './beforeLogin'
import Payment from './payment'
import { Link, useNavigate } from "react-router-dom"
import { updateAlert } from '../../../store/actionCreators/common'
import SEO from '../../../utils/SEO'
import { loadScript } from '../../../utils/common'
import { getAddress } from '../../../store/actionCreators/login'

export default function PaymentIndex() {
  
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loginReducers: { userData }, cartReducers: { cartProductData, cartWithoutLoginData, isCartLoaded } } = useSelector(state => state)
  const [isAddressChange, setIsAddressChange] = useState(false)
  const cartCount = userData.length ? cartProductData.length : cartWithoutLoginData.length
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []) 

  useEffect(() => {
    if(userData?.length>0){
      dispatch(getAddress(userData[0]?.userId))
      loadScript("https://checkout.razorpay.com/v1/checkout.js");
    }
  }, [])

  useEffect(() => {
    const pathName = window.location.pathname.substr(1,).toLowerCase()
    console.log(pathName,"pathname");
    // let timeout = setTimeout(() => {
      if (cartCount == 0 ) {
        console.log("cartempty");
        navigate("/CartEmpty")
      }
    // }, 2000)
  }, [cartCount])
  
  // useEffect(() => {
  //   if (isCartLoaded && !cartProductData.length && !cartWithoutLoginData.length) {
  //     dispatch(updateAlert('Your cart is empty!', 'warning'));
  //     navigate('/')
  //   }
  // }, [cartProductData, cartWithoutLoginData, navigate, dispatch, isCartLoaded])

  return (
    <>
    <SEO title='Checkout' description='Checkout'/>
      <Box backgroundColor="primaryColors.loginBg" paddingBottom="20px" className="sectionCheck">
        <Box marginTop="80px" boxShadow="0px 4px 12px rgba(0, 0, 0, 0.08)" marginBottom="30px">
          <Box display='flex' backgroundColor="primaryColors.loginWhite" color="primaryColors.textPath" p='0px 0 10px 50px'>
            <Typography sx={{ color: 'primaryColors.breadcrumbs', '&:hover': { textDecoration: 'underline' }, paddingRight: '10px' }} to='/' component={Link} variant="p14_500"> Home </Typography>
            <Typography variant="p13_500" sx={{ color: 'primaryColors.cartText', paddingRight: '10px' }}> &gt; </Typography>
            <Typography variant="p14_500" sx={{ color: 'grey[200]' }}> Checkout </Typography>
          </Box>
        </Box>

        <Typography variant='p22_600' color="primaryColors.mobileCardText" paddingLeft="50px" > Checkout </Typography>

        <Grid container spacing={3} pr='20px' pl='30px' >
          <Grid item xs={12} md={7} lg={7}>
            {userData.length ?
              <>
                <Box width='100%' display='flex' flexDirection='column' pl='20px' pt='15px'>
                  <AfterLogin isAddressChange={isAddressChange} setIsAddressChange={setIsAddressChange} />
                </Box>

                <Payment isAddressChange={isAddressChange} setIsAddressChange={setIsAddressChange} />
              </>
              :
              <Box width='100%' display='flex' flexDirection='column' pl='50px' pt='15px'>
                <BeforeLogin />
              </Box>

            }

          </Grid>

          <Grid item xs={12} md={5} lg={5} pl='30px'>
            <Box sx={{ backgroundColor: "primaryColors.loginWhite", borderRadius: "8px" }}>

              <Box sx={{ fontSize: 22, fontWeight: "bolder" }} m='15px' p='20px'>
                <Typography variant='p24_700'>
                  Order Summary
                </Typography>
              </Box>
              <Box>
                <CartItemIndex pageType='placeOrder' />
              </Box>

              <Box justifyContent='center' >
                <OrderSummary pageType='placeOrder' />
              </Box>

            </Box>
          </Grid>

        </Grid>


      </Box>
</>

  )
}
