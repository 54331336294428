import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import OrangeButton from "../../../components/Customs/Buttons/Orange";
import { Link, useLocation } from "react-router-dom";
import { prevPathName } from "../../../store/actionCreators/common";
import { useDispatch } from "react-redux";

const BeforeLogin = () => {
    const { pathname } = useLocation()
    const dispatch = useDispatch()

    const savePath = () => {
        dispatch(prevPathName(pathname))
    }

    return (
        <>
            <Box bgcolor="primaryColors.productListItemBg" padding="38px 60px 60px 60px">
                <Grid container mt="26px">
                    <Grid item xs>
                        <Box
                            minwidth="812px"
                            p="24px"
                            mb="10px"
                            bgcolor="primaryColors.loginWhite"
                            boxShadow="0px 4px 20px rgba(0, 0, 0, 0.08)"
                            borderRadius="8px"
                        >
                            <Box display="flex">
                                <div>
                                    <Box
                                        border="1px solid"
                                        width="40px"
                                        height="40px"
                                        mr="24px"
                                        borderColor="primaryColors.checkoutNumberCircle"
                                        borderRadius="50%"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography variant="p24_n" color="black">
                                            1
                                        </Typography>
                                    </Box>
                                </div>

                                <Box display="flex" flexDirection="column">
                                    <Typography variant="p24_b" color="primaryColors.checkoutPointerHeader">
                                        Account
                                    </Typography>
                                    <Typography variant="p14_n" color="primaryColors.checkoutAccountdesc">
                                        To place your order now, log in to your existing account or
                                        sign up.
                                    </Typography>
                                    <Link to="/login" onClick={savePath}>
                                        <Box mt="16px" display="flex" >
                                            <Grid container>
                                                <Grid padding={1}>
                                                    <Box mr="34px">
                                                        <OrangeButton variant="contained">
                                                            <Box display="flex" flexDirection="column">
                                                                <Typography
                                                                    mt="4px"
                                                                    variant="p11_300"
                                                                    color="primaryColors.checkoutAccountbox"
                                                                >
                                                                    Have an account
                                                                </Typography>
                                                                <Typography variant="p14_500" color="primaryColors.checkoutAccountbox">
                                                                    LOGIN
                                                                </Typography>
                                                            </Box>
                                                        </OrangeButton>
                                                    </Box>
                                                </Grid>
                                                <Grid padding={1}>
                                                    <OrangeButton variant="contained">
                                                        <Box display="flex" flexDirection="column">
                                                            <Typography
                                                                mt="4px"
                                                                variant="p11_300"
                                                                color="primaryColors.checkoutAccountbox"
                                                            >
                                                                New to Phonebuy
                                                            </Typography>
                                                            <Typography variant="p14_500" color="primaryColors.checkoutAccountbox">
                                                                SIGNUP
                                                            </Typography>
                                                        </Box>
                                                    </OrangeButton>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Link>
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            minwidth="812px"
                            p="24px"
                            mb="10px"
                            bgcolor="primaryColors.loginWhite"
                            boxShadow="0px 4px 20px rgba(0, 0, 0, 0.08)"
                            borderRadius="8px"
                        >
                            <Box display="flex">
                                <div>
                                    <Box
                                        border="1px solid"
                                        width="40px"
                                        height="40px"
                                        mr="24px"
                                        borderColor="primaryColors.checkoutNumberCircle"
                                        borderRadius="50%"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography variant="p24_n" color="primaryColors.checkoutAccountdesc">
                                            2
                                        </Typography>
                                    </Box>
                                </div>

                                <Box display="flex" flexDirection="column">
                                    <Typography variant="p24_n" color="primaryColors.checkoutPointText">
                                        Delivery address
                                    </Typography>
                                    <Typography variant="p14_n" color="primaryColors.checkoutAccountdesc">
                                        Login to choose address
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            minwidth="812px"
                            p="24px"
                            mb="10px"
                            bgcolor="primaryColors.loginWhite"
                            boxShadow="0px 4px 20px rgba(0, 0, 0, 0.08)"
                            borderRadius="8px"
                        >
                            <Box display="flex">
                                <div>
                                    <Box
                                        border="1px solid"
                                        width="40px"
                                        height="40px"
                                        mr="24px"
                                        borderColor="primaryColors.checkoutNumberCircle"
                                        borderRadius="50%"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography variant="p24_n" color="primaryColors.checkoutAccountdesc">
                                            3
                                        </Typography>
                                    </Box>
                                </div>

                                <Box display="flex" flexDirection="column">
                                    <Typography variant="p24_n" color="primaryColors.checkoutPointText">
                                        Payment
                                    </Typography>
                                    <Typography variant="p14_n" color="primaryColors.checkoutAccountdesc">
                                        Login and select delivery address to select payment methods
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default BeforeLogin;
