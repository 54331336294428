import React, { useState, useEffect } from 'react'
import { Grid, Typography, Box, Button, Stack } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { getSamsungMobilesWatcher } from '../../store/actionCreators/home';
import ProductItemCard from '../ProductListItemCard';

export default function SamsungMobiles() {
    const dispatch = useDispatch()
    const { homeReducer: { SamsungMobilesList } } = useSelector((state) => state)

    const [pageCount, setPageCount] = useState(1)
    const [perPageData, setPerPageData] = useState(4)
    const [previousEnable, setPreviousEnable] = useState(false)
    const [nextEnable, setNextEnable] = useState(true)
    const [prevData, setPrevData] = useState([])
    const [paginationEnable, setPaginationEnable] = useState(false)
    const [viewAllEnable, setViewAllEnable] = useState(true)
    const mapData = SamsungMobilesList.length ? SamsungMobilesList : prevData

    useEffect(() => {
        if (pageCount === 1) setPreviousEnable(false)
        if (!SamsungMobilesList?.length) {
            setNextEnable(false)
            // dispatch(SamsungMobilesList(prevData))
        }
        else {
            setNextEnable(true)
        }
    }, [pageCount, SamsungMobilesList])


    const viewFunction = () => {
        setPageCount(1)
        setPerPageData(8)
        setPaginationEnable(true)
        setViewAllEnable(false)
        let data = {
            pageNO: pageCount,
            perPage: 8
        }
        dispatch(getSamsungMobilesWatcher(data))
    }

    const previousClick = () => {
        setPageCount(pageCount - 1)
        let data = {
            pageNO: pageCount - 1,
            perPage: perPageData
        }
        dispatch(getSamsungMobilesWatcher(data))
    }

    const nextClick = () => {
        setPrevData(SamsungMobilesList)
        setPreviousEnable(true)
        setPageCount(pageCount + 1)
        let data = {
            pageNO: pageCount + 1,
            perPage: perPageData
        }
        dispatch(getSamsungMobilesWatcher(data))
    }
    return (
        <div>
            {Boolean(mapData.length) &&
                <>
                    <Box >
                        <section className="container products-container padding-top-none clear">
                            <Grid item container>
                                <Grid lg={8} md={8} sm={8} xs={12}>
                                    <h2 className="left">
                                        Samsung Mobiles
                                    </h2>
                                </Grid>
                                <Grid lg={4} md={4} sm={4} xs={12}>
                                    {Boolean(SamsungMobilesList.length >= 4) &&
                                        <Box display='flex'>
                                            <Button variant='contained'
                                                sx={{
                                                    width: '130px', height: '40px', backgroundColor: 'orange.main',
                                                    '&:hover': { backgroundColor: 'orange.main' }, textTransform: 'none', m: '0px 0px 10px auto'
                                                }} onClick={viewFunction} width='100px' disabled={!viewAllEnable} >
                                                <Typography color='white !important'>View All</Typography>
                                            </Button>
                                        </Box>
                                    }
                                </Grid>
                            </Grid>
                        </section>

                        <Grid item container spacing={3} sx={{ pr: '20px', pl: '20px', pb: '20px' }} >
                            {
                                SamsungMobilesList.map((v, i) => {
                                    return <Grid item lg={3} xs={12} sm={6} md={4} key={v.id}>
                                        <ProductItemCard productData={v} bgclr={'primaryColors.productListItemBg'} categoryName='Samsung Mobiles' categoryType='Home' />
                                    </Grid>
                                })
                            }
                        </Grid>
                    </Box>

                    {paginationEnable &&
                        <Stack direction='row' justifyContent='right' spacing={3} pr='45px' pb='20px' marginTop="30px">
                            <Button variant='contained' startIcon={<KeyboardDoubleArrowLeftIcon color='white' />}
                                sx={{
                                    width: '130px', height: '38px', backgroundColor: 'orange.main',
                                    '&:hover': { backgroundColor: 'orange.main' }, textTransform: 'none'
                                }} onClick={previousClick} width='100px' disabled={!previousEnable}>
                                <Typography color='white !important'>Previous</Typography>
                            </Button>

                            <Button variant='contained' endIcon={<KeyboardDoubleArrowRightIcon color='white' />}
                                sx={{
                                    width: '130px', height: '38px', backgroundColor: 'orange.main',
                                    '&:hover': { backgroundColor: 'orange.main' }, textTransform: 'none'
                                }} onClick={nextClick} width='100px' disabled={!nextEnable}>
                                <Typography color='white !important'>Next</Typography>
                            </Button>
                        </Stack>
                    }
                </>
            }
        </div>
    )
}
