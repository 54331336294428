import { TAG_CARD_LIST, REPAIR_AND_SERVICE_LIST, PREV_PATH_NAME, OPEN_LOADER, FALSE_LOADER, UPDATE_ALERT, UPDATE_ALERT_CLOSE, FILTER_BASED_PRODUCT_LIST, CONTACT_US_RES, FILTER_ATTRIBUTE_VARIATIONS, FILTER_ATTRIBUTE_VARIATIONS_LIST, SET_SEARCH_DATA, PAGINATION_VIEW, ADD_PRODUCT_CATEGORY, ADD_PRIMARY_CATEGORY, ADD_META_TAG_DATA, ADD_SEARCH_TEXT } from '../actionTypes/common'

const intialState = {
  tagCardData: [],
  reapirAndServiceData: [],
  prevPath: '/',
  isLoader: false,
  isAlert: false,
  message: '',
  color: '',
  spinnerCount: 0,
  filterBasedProductData: [],
  searchProductData: [],
  searchData: {},
  contactUsRes: '',
  category: '',
  primaryCategory: '',
  meta_title: '',
  meta_description: '',
  searchText: ''
};

export default function commonReducers(state = intialState, action) {

  switch (action.type) {

    case TAG_CARD_LIST:
      return { ...state, tagCardData: action.payload };

    case REPAIR_AND_SERVICE_LIST:
      return { ...state, reapirAndServiceData: action.payload };

    case PREV_PATH_NAME:
      return { ...state, prevPath: action.payload };

    case OPEN_LOADER:
      return { ...state, isLoader: action.payload, spinnerCount: state.spinnerCount + 1 };

    case FALSE_LOADER:
      return { ...state, isLoader: action.payload, spinnerCount: state.spinnerCount - 1 };

    case UPDATE_ALERT:
      return { ...state, isAlert: true, message: action.message, color: action.color };

    case UPDATE_ALERT_CLOSE:
      return { ...state, isAlert: false };

    case FILTER_BASED_PRODUCT_LIST:
      return { ...state, filterBasedProductData: action.payload, searchProductData: action.payload };

    case PAGINATION_VIEW:
      let products = [...state.searchProductData, ...action.payload]
      return { ...state, filterBasedProductData: products, searchProductData: products };

    case FILTER_ATTRIBUTE_VARIATIONS_LIST:
      return { ...state, filterBasedProductData: action.payload };

    case SET_SEARCH_DATA:
      return { ...state, searchData: action.payload };

    case CONTACT_US_RES:
      return { ...state, contactUsRes: action.payload };

    case ADD_PRODUCT_CATEGORY:
      return { ...state, category: action.payload };

    case ADD_PRIMARY_CATEGORY:
      return { ...state, primaryCategory: action.payload };

    case ADD_META_TAG_DATA:
      let {title,description} = action.payload
      return { ...state,meta_title: title, meta_description:description };
      
    case ADD_SEARCH_TEXT:
      return { ...state, searchText: action.payload };

    default:
      return state;
  }
}
