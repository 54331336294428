import React, { useEffect } from 'react'

export default function Policy() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div style={{ margin: "80px" }} className="section">
            <div style={{ display: "flex", justifyContent: "center", fontSize: "40px", fontFamily: "serif", padding: "20px" }}> Privacy Policy </div>
            <p>
                Penta Phones Private Limited (Phonebuy) distinguishes the importance of protecting the privacy of certain information collected about the customers and visitors of our website. This Privacy Policy is to disclose and describe with the manner in which Pentaphones collects and uses your information.
            </p>
            <p>
                By using our website, you agree the terms and conditions of this privacy policy. IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY, DO NOT PROCEED FURTHER AND STOP USEING THIS WEBSITE.
            </p>
            <p>
                This policy covers how Pentaphones treats personal and non-personal information that Pentaphones collects and receives, including information related to your past use of our products and services. Personal information is information about you that is personally identifiable like your name, address, e-mail address, Telephone numbers etc.
            </p>
            <p>
                Pentaphones collects only such Personal Information to be relevant and is required to support, follow up after purchase or service, exciting offers and services, keep you informed about the content available on the website,.
            </p>
            <p>
                Pentaphones DOES NOT trade or sell your personal information in any manner, except as specified herein or if express consent is sought from you.
            </p>

            <div style={{ fontSize: "34px", fontFamily: "serif", padding: "20px" }}> Non-Personal Information </div>

            <p>
                When you visit our site, we collect some basic information that does not identify individual users. This includes pages visited, types of products purchased, and feedback from our visitors, as well as location data, logs and other communication data, and information about your computer or personal devices ( like mobile, phablet, tablet etc.) and internet connection, including your IP address, operating system and browser type. We then combine this information with other pieces of information to improve our site and make your experience on the site as appreciated and well-organized as possible. Pages of the site may use devices that permit us to count users who have visited those pages and for other related website statistics.
            </p>
            <p>
                Pentaphones may use all your personal and non-personal information, including your name, address, email address and any transactions you conduct on our website with us in order to deliver tailored advertising to you that match your interests when you visit our website as well as other websites.
            </p>
            <p>
                When you register at our website, we ask for information such as your name, e-mail address, address, telephone. Once you register with Pentaphones and sign in to our services, you are not anonymous to Pentaphones.
            </p>
            <p>
                Pentaphones will collect your contact and payment information (including name, postal address, email address, credit card number, or other financial transaction information) for Purchase or Sales of Product or Services. We also use this information to create/update your account, verify your identity, authorize payments, and to send you a confirmation of your payment. We will share your financial information via a secure Internet connection with our order-processing center for the sole purpose of processing your order or refund.
            </p>
            <p>
                You have right to refrain from giving us certain information, but then you might not be able to take advantage of all of our features. We use the information that you provide for to enhance the service, such as responding to your requests. However, you may not mask your identity by providing false or misleading information. For example, you may not use IP masking to mask your Internet access location, including without limitation, proxy servers, masking software or the like.
            </p>
            <p>
                Pentaphones automatically receives and records information on our server logs from your browser, including your IP address, Pentaphones cookie information, and the page you request. Most web browsers have the ability to accept cookies, reject cookies, or to prompt the user before accepting them. The help portion of the toolbar of your browser will tell you how to select the option that suits you best. However, disabling the acceptance of cookies does not allow you to take full advantage of some of the best and most advanced features that we have to offer, and we recommend that you leave them turned on. We do not normally link IP addresses to anything personally identifiable, which means that a user’s session will be logged, but the user will remain anonymous to us.
            </p>
            <p>
                During some visits we may use software tools such as JavaScript to measure and collect session information, including page response times, download errors, length of visits to certain pages, page interaction information (like scrolling, clicks, and mouse-overs), and methods used to browse away from the page.
            </p>
            <p>
                Pentaphones may uses information for the following general purposes to customize the advertising and content you see, fulfil your requests for products and services, improve our services, contact you, conduct research, system administration, diagnose problems with our main computers, and provide anonymous reporting for internal and external clients.
            </p>

            <div style={{ fontSize: "34px", fontFamily: "serif", padding: "20px" }}> Information Sharing and Disclosure </div>

            <p>
                Pentaphones may share the collected personally identifiable information with a sponsor or other third party that may be interested in serving you, or under the following circumstances:
            </p>
            <p>
                To Communicate with you regarding your purchase of our products or services and provide you with related customer service.
                We provide the information to trusted partners who work on behalf of or with Pentaphones. These companies may use your personal information to help Pentaphones communicate with you about offers from Pentaphones and our marketing partners. However, these companies do not have any independent right to share this information.
                Send important information regarding the website, changes to Pentaphones’ terms, conditions, and policies and/or other administrative information.
                Send you surveys and marketing communications that Pentaphones considers may be of interest to you.
                Personalize your experience on the website and Pentaphones’ other websites by presenting advertising, products and offers tailored to you.
                We respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims.
                We believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of Pentaphones’ terms of use, or as otherwise required by law.
                We transfer information about you if Pentaphones is acquired by or merged with another company. In this event, Pentaphones will provide notice before such information about you is transferred and becomes subject to a different privacy policy.
            </p>
            <p>
                You acknowledge that apart from your personal information, if you upload any content on the website, such content may contain information including personal information and the same may be available to the other users of the website. Pentaphones will not be liable for the disclosure and dissemination of such personal information to any third parties.
            </p>
            <p>
                pentaphones may disclose combined information about the use of the website, but it will never contain personal information. Pentaphones does not disclose personal information to third parties in the normal course of operations. However, in situations when Pentaphones is legally obligated to disclose information to the government or other third parties, Pentaphones will do so.
            </p>
            <p>
                Pentaphones implements standard measures to protect against unauthorized access to and unlawful interception of Personal Information. However, no internet site can fully eliminate security risks.
            </p>

            <div style={{ fontSize: "34px", fontFamily: "serif", padding: "20px" }}> Children Under Age 18 </div>

            <p>
                Pentaphones does not knowingly collect or solicit personal information from anyone under the age of 18. If you are under 18, please do not register for Pentaphones or send any information about you to us, including your name, address, telephone number, or email address. No one under age 18 may provide any personal information to or on Pentaphones. In the event that we learn that we have collected personal information from a child under age 18 without verification of parental consent, we will promptly delete that information. If you believe that we might have any information from or about a child under 18, you may also contact us at privacy@pentaphones.com
            </p>

            <div style={{ fontSize: "34px", fontFamily: "serif", padding: "20px" }}> Account Information And Preferences </div>

            <p>
                You can edit your Pentaphones account information, including your marketing preferences, at any time. We reserve the right to send you certain communications relating to the Pentaphones products and services, such as service announcements and administrative messages that are considered part of your Pentaphones account, without offering you the opportunity to opt-out of receiving them.
            </p>

            <div style={{ fontSize: "34px", fontFamily: "serif", padding: "20px" }}> Confidentiality And Security </div>

            <p>
                We limit access to personal information about you to employees who we believe reasonably need to come into contact with that information to provide products or services to you or in order to do their jobs.
            </p>
            <p>
                We have physical, electronic, and procedural safeguards that comply with national regulations to protect personal information about you.
                As a user of Pentaphones goods and/or services, you have the responsibility to maintain the secrecy and/or integrity of any passwords assigned to you and to take reasonable measures to ensure that your password is not misused. You must notify Pentaphones immediately should you suspect that your password secrecy and/or integrity have been compromised.
            </p>

            <div style={{ fontSize: "34px", fontFamily: "serif", padding: "20px" }}> Changes To This Privacy Policy </div>

            <p>
                Pentaphones may update this policy. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary e-mail address specified in your Pentaphones account or by placing a prominent notice on our site. If you have questions or recommendations, please feel free to write us at privacy@pentaphones.com
            </p>

        </div>
    )
}
