import http from "../http-common"
import { WOOCOMMERCE_SERVICES } from "../http-common"

class accessoriesService {

    getAllAccessoriesNewLaunchesList(data) {
        return http.get(`/wp-json/wc/v3/products?category=${249}&min_price=1&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getAllAccessoriesCaseAndCoversList(data) {
        return http.get(`/wp-json/wc/v3/products?tag=${491}&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getAllAccessoriesMemoryCardsList(data) {
        return http.get(`/wp-json/wc/v3/products?tag=${493}&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getAllAccessoriesPowerBanksList(data) {
        return http.get(`/wp-json/wc/v3/products?tag=${492}&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getAllAccessoriesShopByBrandList(data) {
        return http.get(`/wp-json/wc/v3/brands?per_page=100&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getAllAccessoriesShopByPriceList(data) {
        return http.get(`/wp-json/wc/v3/brands?per_page=100&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getCardTagList(data) {
        return http.get(`/wp-json/wc/v3/products?tag=${data.tagId}&category=${data.cateId}&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }
}

export default new accessoriesService();