import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Stack, Box } from '@mui/material';
import ProductItemCard from "../../components/ProductListItemCard"
import { useDispatch, useSelector } from "react-redux";
import { getShopByPriceWatcher, shopByPriceList } from "../../store/actionCreators/mobile"
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Link, useLocation } from "react-router-dom";
import { addPrimarycategory, filterTagBasedProductWatcher, getTagData } from "../../store/actionCreators/common";

export default function ShopByBrand({ priceArr, categoryId, shopByPriceApi = getShopByPriceWatcher, page }) {
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const { mobileReducers: { shopByPriceMobileData } } = useSelector(state => state)

    const [selectedPrice, setSelectedPrice] = useState({})
    const searchPath = '/search'

    const viewFunction = () => {
        let minPrice = selectedPrice?.min?.replaceAll(',', '')?.trim() || 0
        let maxPrice = selectedPrice?.max?.replaceAll(',', '')?.trim() || 0
    }

    const priceClick = (val) => {
        let minPrice = val?.min?.replaceAll(',', '')?.trim() || 0
        let maxPrice = val?.max?.replaceAll(',', '')?.trim() || 0
        let data = { min_price: minPrice, max_price: maxPrice, category: categoryId, perPage: 50,name: `₹${val?.min}-₹${val?.max}` }
        const pathName = window.location.pathname.substr(1,).toLowerCase()
        dispatch(addPrimarycategory(pathName))
        dispatch(filterTagBasedProductWatcher(data))
        dispatch(getTagData(857))
    }

    return (
        <section className="container shop-by-price padding-top-none clear">
            <h2 className="left">
                Shop By Price<small>Choose your preffered price range</small>
            </h2>
            <Box>
                <Grid item container spacing={3} sx={{ m: '20px -25px' }} >
                    {priceArr?.map((m, i) => {
                        let minPrice = m?.min?.replaceAll(',', '')?.trim() || 0
                        let maxPrice = m?.max?.replaceAll(',', '')?.trim() || 0
                        let url = `/ShopByPrice?name=₹${m?.min}-₹${m?.max}&min_price=${minPrice}&max_price=${maxPrice}`
                        if(categoryId) {
                            url += "&category="+categoryId
                        }
                        return <Grid item lg={2} xs={6} sm={4} md={4} key={m.id}>
                            <Link to={url} state={{}} style={{ color: "black" }}>
                                <Box onClick={() => priceClick(m)} sx={{ cursor: 'pointer', backgroundColor: m.id === selectedPrice?.id ? `orange.main` : "primaryColors.shopbybrand", p: '15px 5px', textAlign: 'center', borderRadius: '5px' }}>
                                    {m.type === 'between' ? <Typography variant='p16_700' sx={{ color: m.id === selectedPrice?.id ? 'white.main' : 'white' }} >₹{m.min} to ₹{m.max}</Typography>
                                        : m.type === 'below' ? <Typography variant='p16_700' sx={{ color: m.id === selectedPrice?.id ? 'white.main' : 'white' }}>Below ₹{m.min}</Typography>
                                            : <Typography variant='p16_400' sx={{ color: m.id === selectedPrice?.id ? 'white.main' : 'white' }} >Above ₹{m.min}</Typography>
                                    }
                                </Box>
                            </Link>
                        </Grid>
                    })}
                </Grid>
            </Box>

            {Boolean(shopByPriceMobileData.length >= 4) &&
                <Box display='flex' mb='20px'>
                    <Button variant='contained'
                        sx={{
                            width: '130px', height: '40px', backgroundColor: 'orange.main',
                            '&:hover': { backgroundColor: 'orange.main' }, textTransform: 'none', m: '20px 25px 10px auto'
                        }} width='100px' onClick={viewFunction}>
                        <Typography color='white !important'>View All</Typography>
                    </Button>
                </Box>
            }

            {Object.values(selectedPrice).length ? shopByPriceMobileData.length ?
                <Grid item container spacing={3} sx={{ pr: '20px', pl: '20px', pb: '20px' }} >
                    {shopByPriceMobileData?.map((m, i) => {
                        return <Grid item lg={3} xs={12} sm={6} md={4} key={m.id}>
                            <ProductItemCard productData={m} bgclr={'primaryColors.productListItemBg'} categoryName='Shop By Price' categoryType={page} />
                        </Grid>
                    })}
                </Grid>
                :
                <Box textAlign='center' pt='50px'>
                    <Typography variant='p20_500' >No records found</Typography>

                </Box>
                :
                <br />
            }
        </section>
    )
}