import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import orderService from "../../services/orders"
import { orderList, taxList, viewOrder } from "../actionCreators/order";
import { DELETE_ORDER_WATCHER, GET_ORDER_WATCHER, GET_SINGLE_ORDER_WATCHER, TAX_DETAIL_WATCHER } from "../actionTypes/order";
import { updateOpenLoader, updateAlert, updateFalseLoader } from '../actionCreators/common'


function* getOrderEffect(action) {
    try {
        yield put(updateOpenLoader())
        let { data } = yield call(orderService.getOrder, action.userId);
        yield put(orderList(data));
        yield put(updateFalseLoader())
    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* getSingleOrderEffect(action) {
    try {
        yield put(updateOpenLoader())
        let { data } = yield call(orderService.getSingleOrder, action.orderId);
        yield put(viewOrder(data));
        yield put(updateFalseLoader())
    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* deleteOrderEffect(action) {
    try {
        yield put(updateOpenLoader())
        let { data } = yield call(orderService.deleteOrder, action.orderId);
        yield put(updateFalseLoader())
    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

function* taxDetailEffect(action) {
    try {
        yield put(updateOpenLoader())
        let { data } = yield call(orderService.taxDetail, action.userId);
        yield put(taxList(data));
        yield put(updateFalseLoader())
    } catch (e) {
        yield put(updateFalseLoader());
        yield put(updateAlert(e?.message || "something went wrong", 'error'));
    }
}

export const OrderSaga = [
    takeEvery(GET_ORDER_WATCHER, getOrderEffect),
    takeLatest(GET_SINGLE_ORDER_WATCHER, getSingleOrderEffect),
    takeLatest(DELETE_ORDER_WATCHER, deleteOrderEffect),
    takeEvery(TAX_DETAIL_WATCHER, taxDetailEffect)
]