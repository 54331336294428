import { MOBILE_NEW_LAUNCHES_LIST, MOBILE_RECOMMENDED_LIST, MOBILE_BEST_BUDGET_LIST, PRODUCT_BRAND_LIST, SHOPBY_BRAND_LIST, SHOPBY_PRICE_LIST, ADD_MOBILES_LOADING, ADD_MOBILES_TAG_CARDS } from '../actionTypes/mobile'

const intialState = {
  newLaunchesMobileList: [],
  recommendedMobileList: [],
  recommendedMobileListLoading: false,
  bestBudgetMobileList: [],
  productBrandList: [],
  shopByBrandMobileData: [],
  shopByPriceMobileData: [],
  tagCards: []
};


export default function mobileReducers(state = intialState, action) {
  switch (action.type) {

    case MOBILE_NEW_LAUNCHES_LIST:
      return { ...state, newLaunchesMobileList: action.payload };

    case MOBILE_RECOMMENDED_LIST:
      return { ...state, recommendedMobileList: action.payload };

    case MOBILE_BEST_BUDGET_LIST:
      return { ...state, bestBudgetMobileList: action.payload };

    case PRODUCT_BRAND_LIST:
      return { ...state, productBrandList: action.payload };

    case SHOPBY_BRAND_LIST:
      return { ...state, shopByBrandMobileData: action.payload };

    case SHOPBY_PRICE_LIST:
      return { ...state, shopByPriceMobileData: action.payload };
  
    case ADD_MOBILES_LOADING:
      return { ...state, [action.key]: action.data };
  
    case ADD_MOBILES_TAG_CARDS:
      return { ...state, tagCards: action.data };

    default:
      return state;
  }
}
