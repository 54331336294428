// Login Watcher Actions

export const TAG_CARD_WATCHER = "TAG_CARD_WATCHER";
export const ADD_SERVICE_AND_REPAIR_WATCHER = "ADD_SERVICE_AND_REPAIR_WATCHER";
export const CONTACT_US_WATCHER = "CONTACT_US_WATCHER";
export const FILTER_TAG_BASED_PRODUCT_WATCHER = "FILTER_TAG_BASED_PRODUCT_WATCHER"


// Login Reducer Actions

export const TAG_CARD_LIST = "TAG_CARD_LIST";
export const REPAIR_AND_SERVICE_LIST = "REPAIR_AND_SERVICE_LIST";
export const PREV_PATH_NAME = "PREV_PATH_NAME";
export const FILTER_BASED_PRODUCT_WATCHER = "FILTER_BASED_PRODUCT_WATCHER";
export const CONTACT_US_RES = "CONTACT_US_RES";
export const FILTER_ATTRIBUTE_VARIATIONS_LIST = "FILTER_ATTRIBUTE_VARIATIONS_LIST";
export const SET_SEARCH_DATA = "SET_SEARCH_DATA"
export const PAGINATION_VIEW = "PAGINATION_VIEW"
export const ADD_META_TAG_DATA = "ADD_META_TAG_DATA"
export const GET_CATEGORY_DATA_WATCHER = "GET_CATEGORY_DATA_WATCHER"
export const GET_TAG_DATA_WATCHER = "GET_TAG_DATA_WATCHER"
export const GET_BRAND_DATA_WATCHER = "GET_BRAND_DATA_WATCHER"
export const ADD_SEARCH_TEXT = "ADD_SEARCH_TEXT"

// loader and alert
export const OPEN_LOADER = "OPEN_LOADER";
export const FALSE_LOADER = "FALSE_LOADER";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const UPDATE_ALERT_CLOSE = "UPDATE_ALERT_CLOSE";
export const FILTER_BASED_PRODUCT_LIST= "FILTER_BASED_PRODUCT_LIST";
export const ADD_PRODUCT_CATEGORY = "ADD_PRODUCT_CATEGORY"
export const ADD_PRIMARY_CATEGORY = "ADD_PRIMARY_CATEGORY"
