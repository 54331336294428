import { PRODUCT_DETAIL_DATA_LIST, PRODUCT_REVIEWS_LIST, USER_PINCODE_LIST, WISH_LIST_PRODUCT_ID_LIST, WISH_LIST_PRODUCT_LIST, MULTIPLE_PRODUCT_LIST, ADD_SELECTED_TAB, ACCESSORIES_SINGLE_PRODUCT, ADD_PRODUCT_VARIANTS, ADD_VARIANT_DETAIL, PRODUCTREVIEW_EMPTY, MODIFY_PRODUCT_DETAIL, ADD_PRODUCT_BREADCRUMB } from '../actionTypes/product'

const intialState = {
  wishListProductIdData: [],
  wishListProductData: [],
  productDetailData: [],
  userPincode: false,
  productReviews: [],
  multipleProductData: [],
  selectedTab: "",
  accessories: [],
  userPincodeMessage: "",
  productVariants: [],
  variantData: {},
  reviewButton: true,
  productBreadCrumb: []
};


export default function productReducers(state = intialState, action) {
  switch (action.type) {

    case WISH_LIST_PRODUCT_ID_LIST:
      return { ...state, wishListProductIdData: action.payload };

    case WISH_LIST_PRODUCT_LIST:
      return { ...state, wishListProductData: action.payload };

    case PRODUCT_DETAIL_DATA_LIST:
      console.log("productDetailData");
      return { ...state, productDetailData: action.payload, userPincode: false, reviewButton: true, userPincodeMessage: "", variantData: action.payload };

    case USER_PINCODE_LIST:
      return { ...state, userPincode: action.payload.available, userPincodeMessage: action.payload.message };

    case PRODUCT_REVIEWS_LIST:
      return { ...state, productReviews: [...state.productReviews, ...action.payload], reviewButton: !(action.payload?.length === 0) };

    case MULTIPLE_PRODUCT_LIST:
      return { ...state, multipleProductData: action.payload };

    case ADD_SELECTED_TAB:
      return { ...state, selectedTab: action.payload };

    case ACCESSORIES_SINGLE_PRODUCT:
      return { ...state, accessories: action.payload };

    case ADD_PRODUCT_VARIANTS:
      return { ...state, productVariants: action.payload };

    case ADD_VARIANT_DETAIL:
      console.log("variantDetail");
      return { ...state, productDetailData: { ...state.productDetailData, ...action.payload } };

    case PRODUCTREVIEW_EMPTY:
      return { ...state, productReviews: [] };

    case MODIFY_PRODUCT_DETAIL:
      console.log("modifyproduct");
      return { ...state, productDetailData: { ...state.productDetailData, ...action.payload } };

    case ADD_PRODUCT_BREADCRUMB:
      return { ...state, productBreadCrumb: action.payload };

    default:
      return state;
  }
}
