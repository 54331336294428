// Login Watcher Actions

export const GET_CART_PRODUCT_ID_WATCHER = "GET_CART_PRODUCT_ID_WATCHER";
export const GET_CART_PRODUCT_DATA_WATCHER = "GET_CART_PRODUCT_DATA_WATCHER";
export const REMOVE_FROM_CART_WATCHER = "REMOVE_FROM_CART_WATCHER";
export const APPLY_COUPON_CODE_WATCHER = "APPLY_COUPON_CODE_WATCHER";
export const ADD_TO_CART_WATCHER = "ADD_TO_CART_WATCHER";
export const ORDER_BY_ID_WATCHER = "ORDER_BY_ID_WATCHER";
export const UPDATE_CART_WATCHER = "UPDATE_CART_WATCHER";
export const CREATE_ORDER_WATCHER = "CREATE_ORDER_WATCHER";
export const UPDATE_ORDER_WATCHER = "UPDATE_ORDER_WATCHER";
export const DELETE_BULK_CART_WATCHER = "DELETE_BULK_CART_WATCHER";
export const ADD_BULK_CART_WATCHER = "ADD_BULK_CART_WATCHER";
export const VERIFY_CASHFREE_PAYMENT_WATCHER = "VERIFY_CASHFREE_PAYMENT_WATCHER";
export const CREATE_WOO_ORDER_WATCHER = "CREATE_WOO_ORDER_WATCHER";
export const GET_DELIVERY_CHARGE_WATCHER = "GET_DELIVERY_CHARGE_WATCHER";


// Login Reducer Actions

export const CART_PRODUCT_ID_LIST = "CART_PRODUCT_ID_LIST";
export const CART_PRODUCT_LIST = "CART_PRODUCT_LIST";
export const COUPON_CODE_DATA = "COUPON_CODE_DATA";
export const ORDER_BY_ID_LIST = "ORDER_BY_ID_LIST";
export const CART_PRODUCT_REQUEST = "CART_PRODUCT_REQUEST"
export const CURRENT_ORDER_LIST = "CURRENT_ORDER_LIST";
export const ADD_CART_WITHOUT_LOGIN_LIST = "ADD_CART_WITHOUT_LOGIN_LIST";
export const REMOVE_CART_WITHOUT_LOGIN_LIST = "REMOVE_CART_WITHOUT_LOGIN_LIST";
export const IS_CART_LOADED = "IS_CART_LOADED";
export const CASHFREE_PAYMENT_STATUS = "CASHFREE_PAYMENT_STATUS";
export const CART_ADD_TOTAL = "CART_ADD_TOTAL"
export const ADD_DELIVERY_CHARGE = "ADD_DELIVERY_CHARGE"

