// import { Box, Button, Grid, Rating, Typography } from '@mui/material';
// import React, { useState, useEffect } from 'react'
// import { useDispatch, useSelector } from 'react-redux';
// import { getProductReviewsWatcher } from '../../store/actionCreators/product';


// export default function TabContent() {
//     const dispatch = useDispatch()
//     const { productReducers: { productDetailData, productReviews } } = useSelector((state) => state)

//     const [tab, settab] = useState("Highlights")
//     return (
//         <div>
//             <section class="container product-spec-desc clear">
//                 <div class="tab">
//                     <button class="tablinks" onClick={() => settab("Highlights")} style={{ backgroundColor: tab == "Highlights" ? `#e37438` : "#f2f4f7" }} id="defaultOpen">Overview</button>
//                     <button class="tablinks" onClick={() => settab("Specifications")} style={{ backgroundColor: tab == "Specifications" ? `#e37438` : "#f2f4f7" }}>Specifications</button>
//                     <button class="tablinks" onClick={() => {
//                         dispatch(getProductReviewsWatcher(productDetailData?.id))
//                         settab("Reviews")
//                     }} style={{ backgroundColor: tab == "Reviews" ? `#e37438` : "#f2f4f7" }}>Reviews</button>
//                 </div>
//                 {tab === "Highlights" &&
//                     <div id="Overview" class="tabcontent">
//                         <div>
//                             <h3>Highlights</h3>
//                             <ul>
//                                 <li style={{ marginLeft: "50px" }} >
//                                 <div dangerouslySetInnerHTML={{__html: productDetailData?.description}} />
//                                 </li>
//                             </ul>
//                         </div>
//                     </div>}
//                 {tab === "Specifications" &&
//                     <div id="Specifications" class="tabcontent">
//                         <h3>Specifications</h3>
//                         <Grid container spacing={5}>
//                             {productDetailData?.attributes?.map((v, i) => {
//                                 return <Grid item xs={12} lg={5.5} sx={{ backgroundColor: "orange.light", display: 'flex', marginRight: "20px", marginLeft: "38px", padding: "30px", borderRadius: "8px", height: "30px", marginTop: "30px", alignItems: "center" }} key={i}>
//                                     <Box>
//                                         <Typography variant='p16_400'>{v?.name}</Typography>
//                                     </Box>
//                                     <Box justifyContent="right" ml="auto">
//                                         <Typography variant='p16_400'>{v?.options?.map((d, n) => d).join()}</Typography>
//                                     </Box>
//                                 </Grid>
//                             })}
//                         </Grid>



//                     </div>}
//                 {tab === "Reviews" &&
//                     <div id="Reviews" class="tabcontent">
//                         <h3>Reviews</h3>
//                         {
//                             productReviews?.length > 0 &&
//                             productReviews?.map((value, index) => {
//                                 return (
//                                     <div style={{ marginLeft: "50px" }}>
//                                         <h4>{value?.name}</h4>
//                                         <Rating name='read-only' value={value?.rating} readOnly />
//                                         <div>{value?.review}</div>
//                                     </div>
//                                 )
//                             })
//                         }
//                     </div>}
//             </section>
//         </div>
//     )
// }

import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Grid, Rating, TextField } from '@mui/material';
import { useState } from 'react';
import { addProductReviewWatcher, getProductReviewsWatcher } from '../../store/actionCreators/product';
import StarIcon from '@mui/icons-material/Star';
import moment from 'moment';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs({ myRef, value, setValue }) {
    let dispatch = useDispatch()
    const { productReducers: { productDetailData, productReviews, reviewButton }, loginReducers: { userData } } = useSelector((state) => state)

    const [review, setReview] = React.useState(0)
    const [reviewText, setReviewText] = useState("")
    const [error, setError] = useState(false)
    const [reviewPage, setReviewPage] = useState(1)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // const cancel = () => {
    //     setReview("")
    //     setReviewText("")
    // }

    const SubmitReviewHandler = () => {
        if (reviewText === "") {
            setError(true)
        }
        else {
            setError(false)

            let data =
            {
                "product_id": productDetailData?.id,
                "review": reviewText,
                "reviewer": userData[0]?.firstName,
                "reviewer_email": userData[0]?.email,
                "rating": review,
                "verified": true

            }
            dispatch(addProductReviewWatcher(data))
            setReview("")
            setReviewText("")
        }
    }

    const reviewFunc = (e) => {
        if (e.target.value.length > 0) {
            setError(false)
        }
        else {
            setError(true)
        }
        setReviewText(e.target.value)
    }
    console.log("prdtrvw", productReviews?.length, productDetailData, productReviews, reviewButton)
    return (
        <Box sx={{ width: '100%' }}>
            <section className="container product-spec-desc clear">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" textColor="secondary" indicatorColor="secondary" >
                        <Tab label="Overview" {...a11yProps(0)} style={{ minWidth: "20%" }} />
                        <Tab label="Specifications" {...a11yProps(1)} style={{ minWidth: "20%" }} />
                        <Tab label="Reviews" {...a11yProps(2)} onClick={() => {
                            dispatch(getProductReviewsWatcher(productDetailData?.id, { page: 1, per_page: 1 }))
                        }} style={{ minWidth: "20%" }} />
                    </Tabs>
                </Box>

                <TabPanel value={value} index={0}>
                    <div id="Overview" className="tabcontent">
                        <div>
                            <div className='Highlight'>Highlights</div>
                            <ul style={{ marginLeft: "50px" }} >
                                <div dangerouslySetInnerHTML={{ __html: productDetailData?.description }} />
                            </ul>
                        </div>
                    </div>
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <div id="Specifications" className="tabcontent">
                        <div className='highlight'>Specifications</div>
                        <Grid container >
                            {productDetailData?.meta_data?.find((s) => s?.key === "_dwps_specification_table")?.value?.map((g, i) => {
                                return g?.attributes.length !== 0 && <div style={{ width: "100%", paddingTop: "20px", fontSize: "22px", fontWeight: "bold" }}>
                                    <div>{g?.group_name}</div>
                                    {
                                        g?.attributes?.map((v, i) => {
                                            return v?.value !== "" && <Grid item sx={{ display: 'flex', marginRight: "20px", marginLeft: "38px", borderRadius: "8px", marginTop: "10px", alignItems: "center" }} key={i}>
                                                <Box>
                                                    <Typography variant='p16_400'>{v?.attr_name}</Typography>
                                                </Box>
                                                <Box justifyContent="right" ml="auto" width="70%">
                                                    <Typography variant='p16_400'>{v?.value}</Typography>
                                                </Box>
                                            </Grid>
                                        })
                                    }
                                </div>
                            })}
                        </Grid>
                    </div>
                </TabPanel>

                <TabPanel value={value} index={2}>
                    <div id="Reviews" className="tabcontent" ref={myRef}>
                        {Boolean(userData.length) && <div style={{ marginLeft: "30px" }}>
                            <Typography component="legend">Give Your Reviews</Typography>
                            <Rating sx={{ mt: '10px', color: 'secondary.main' }} name="simple-controlled" value={review} onChange={(event, newValue) => { setReview(newValue) }} />
                            <Box sx={{ display: { sm: 'flex' }, mt: '10px', pr: { xs: '20px', md: '170px', lg: '400px' } }}>
                                <TextField value={reviewText} fullWidth placeholder="Write a Review" onChange={(e) => reviewFunc(e)}
                                    error={error} helperText={error ? 'Review Required' : ''} onBlur={(e) => setError(false)} multiline minRows={2} />
                                <Box>
                                    <Button onClick={SubmitReviewHandler} sx={{ backgroundColor: "orange.main", color: "white !important", marginLeft: "20px", marginTop: "37px", height: '40px', width: '90px', "&:hover": { backgroundColor: "orange.main" } }}> Submit </Button>
                                </Box>
                            </Box>
                        </div>}

                        <Box sx={{ marginLeft: "30px", mt: '10px' }}>
                            {/* <div className='highlight'>Reviews</div> */}
                            <div style={{ paddingLeft: "20px", fontWeight: "600", fontSize: "30px", paddingBottom: "25px" }}>Customer Reviews</div>
                            <Grid item container>
                                <Grid style={{ width: "15%" }}>
                                    <StarIcon style={{ color: "orange", fontSize: "50px", paddingTop: "3px" }} /> <span style={{ zIndex: -1, fontSize: "50px", color: "#ff7a00" }}>{productReviews?.length == 1 ? productReviews[0]?.rating : parseFloat(productDetailData?.average_rating).toFixed(1)}</span>
                                    <Typography style={{ paddingLeft: "10px" }}>{productReviews?.length == 1 ? 1 : (productDetailData?.rating_count > 0 ? productDetailData?.rating_count + 1 : productDetailData?.rating_count)} Reviews</Typography>
                                </Grid>
                                <Grid style={{ width: "1%" }}>
                                    <Divider orientation="vertical" style={{ border: "1px solid", marginLeft: "40px", height: "150px" }} /> </Grid>
                                <Grid style={{ width: "80%" }}>
                                    {
                                        productReviews?.length > 0 &&
                                        productReviews?.map((value, index) => {

                                            return (<>

                                                <div style={{ marginLeft: "85px" }} key={value.id}>
                                                    <div style={{ fontSize: "20px", fontWeight: "bold" }}>{value?.reviewer}</div>
                                                    <div>
                                                        <Typography variant='' color='primaryColors.checkoutAccountdesc' style={{ paddingLeft: '20px' }}>{moment(value?.date_created).format("DD.MM.YYYY")}</Typography>
                                                        <Typography variant='' color='primaryColors.checkoutAccountdesc' style={{ paddingLeft: '20px' }}>{moment(value?.date_created).format("hh:mm A")}</Typography>
                                                    </div>
                                                    <Rating name='read-only' value={value?.rating} readOnly style={{ paddingLeft: '20px' }} /><br />
                                                    <div dangerouslySetInnerHTML={{ __html: value?.review }} variant='' color='primaryColors.checkoutAccountdesc' style={{ paddingLeft: '20px' }} />
                                                </div>

                                            </>

                                            )
                                        })
                                    }
                                    {productReviews?.length >= 5 && reviewButton &&
                                        <Button onClick={() => {
                                            dispatch(getProductReviewsWatcher(productDetailData?.id, { page: reviewPage + 1, per_page: 1 }))
                                            setReviewPage(reviewPage + 1)
                                        }} sx={{ backgroundColor: "white", color: "#ff7a00 !important", border: "1px solid #ff7a00", borderRadius: "5px", marginLeft: "180px", marginTop: "37px", height: '40px', width: '150px', "&:hover": { backgroundColor: "orange" } }}> Show More </Button>}

                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </TabPanel>
            </section>
        </Box>
    );
}
