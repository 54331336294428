import http from "../http-common";
import { WOOCOMMERCE_SERVICES } from "../http-common";
class mobileService {

  getNewLaunchesList(data) {
    return http.get(`/wp-json/wc/v3/products?category=15&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
  }

  getRecommendedList(data) {
    let value = ""
    if (data.category !== undefined) {
      value += `category=${data.category}&`
    }
    return http.get(`/wp-json/wc/v3/products?${value}tag=${392}&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
  }

  getBestBudgetList(data) {
    return http.get(`/wp-json/wc/v3/products?tag=${387}&category=15&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
  }

  getProductBrandList(data) {
    return http.get(`/wp-json/wc/v3/brands?per_page=100&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
  }

  getShopByBrandList(data) {
    return http.get(`/wp-json/wc/v3/products?&search=${data.brand}&category=${data.categoryId}&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
  }

  getShopByPriceList(data) {
    return http.get(`/wp-json/wc/v3/products?category=${data.categoryId}&min_price=${data.minPrice}&max_price=${data.maxPrice}&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
  }

}

export default new mobileService();