import React from 'react'

export default function ScrollLoader() {
    let arr = Array.from(Array(4).keys())
  return (
    <div style={{display:"flex", flexDirection:"row"}}>
    {
        arr.map((v,i)=> {
            return <div class="card">
            <div class="shimmerBG media"></div>
            <div class="p-32">
              <div class="shimmerBG title-line"></div>
              <div class="shimmerBG title-line end"></div>
    
              <div class="shimmerBG content-line m-t-24"></div>
              <div class="shimmerBG content-line"></div>
              <div class="shimmerBG content-line"></div>
              <div class="shimmerBG content-line"></div>
              <div class="shimmerBG content-line end"></div>
            </div>
          </div>
        })
    }
      </div>
  )
}
