import { ACCESSORIES_BRANDS_LIST, ADD_HOME_LOADING, BEST_BUDGET_LIST, DEALS_LIST, HEADPHONES_BRANDS_LIST, HOME_NEW_LAUNCHES_LIST, MOBILES_BRANDS_LIST, PRODUCT_CARDS_LIST, SAMSUNG_MOBILES_LIST, SHOP_BY_BRAND_LIST, SHOP_BY_PRICE_LIST, SMART_WATCH_LIST, TOP_RATED_PRODUCT_LIST, WEARABLES_BRANDS_LIST } from "../actionTypes/home";

const intialState = {
  HomeNewLaunchesList: [],
  HomeNewLaunchesListLoader: false,
  TopRatedProductList: [],
  SmartWatchList: [],
  SamsungMobilesList: [],
  ShopByBrandList: [],
  ShopByPriceList: [],
  BestBudgetList: [],
  BestBudgetListLoader: false,
  DealsList: [],
  DealsListLoader: false,
  ProductCardsList: [],
  MobilesBrandsList: [],
  AccessoriesBrandsList: [],
  WearablesBrandsList: [],
  HeadphonesBrandsList: []
};


export default function homeReducer(state = intialState, action) {
  switch (action.type) {

    case HOME_NEW_LAUNCHES_LIST:
      return { ...state, HomeNewLaunchesList: action.payload };

    case TOP_RATED_PRODUCT_LIST:
      return { ...state, TopRatedProductList: action.payload };

    case SMART_WATCH_LIST:
      return { ...state, SmartWatchList: action.payload };

    case SAMSUNG_MOBILES_LIST:
      return { ...state, SamsungMobilesList: action.payload };

    case SHOP_BY_BRAND_LIST:
      return { ...state, ShopByBrandList: action.payload };

    case SHOP_BY_PRICE_LIST:
      return { ...state, ShopByPriceList: action.payload };

    case BEST_BUDGET_LIST:
      return { ...state, BestBudgetList: action.payload };

    case DEALS_LIST:
      return { ...state, DealsList: action.payload };

    case PRODUCT_CARDS_LIST:
      return { ...state, ProductCardsList: action.payload };

    case ADD_HOME_LOADING:
      return { ...state, [action.key]: action.data };
      
    case MOBILES_BRANDS_LIST:
      return { ...state, MobilesBrandsList: action.payload };
      
    case ACCESSORIES_BRANDS_LIST:
      return { ...state, AccessoriesBrandsList: action.payload };
      
    case WEARABLES_BRANDS_LIST:
      return { ...state, WearablesBrandsList: action.payload };
      
    case HEADPHONES_BRANDS_LIST:
      return { ...state, HeadphonesBrandsList: action.payload };

    default:
      return state;
  }
}
