import React, { useEffect } from 'react'
import { Grid, Typography, Box, Button } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import ProductItemCard from '../ProductListItemCard';
import { Link } from 'react-router-dom';
import { addPrimarycategory, filterTagBasedProductWatcher } from '../../store/actionCreators/common';
import ScrollLoader from '../Loader/scrollLoader';
import { useInView } from 'react-intersection-observer';
import { getBestBudgetWatcher } from '../../store/actionCreators/home';
import { getprimarycolor } from '../../utils/common';

export default function BestBudget() {
    const dispatch = useDispatch()
    const { homeReducer: { BestBudgetList, BestBudgetListLoader } } = useSelector((state) => state)

    const mapData = BestBudgetList.length ? BestBudgetList : []
    const searchPath = '/search'

    const { ref, inView, entry } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    useEffect(() => {
        let data = {
            pageNO: 1,
            perPage: 4
        }
        if (inView) {
            dispatch(getBestBudgetWatcher(data))
        }
    }, [inView])

    const viewFunction = () => {
        // let data = {
        //     perPage: 50,
        //     name: "BestBudget",
        //     tag: 387,
        //     category: 15
        // }
        // const pathName = window.location.pathname.substr(1,).toLowerCase()
        // dispatch(addPrimarycategory(pathName))
        // dispatch(filterTagBasedProductWatcher(data))
    }

    return (
        <div ref={ref}>
            <>
                <Box>
                    <section className="container products-container padding-top-none clear">
                        <Grid item container>
                            <Grid lg={8} md={8} sm={8} xs={12}>
                                <h2 className="left">
                                    Best Budget Smartphones
                                </h2>
                            </Grid>
                            <Grid lg={4} md={4} sm={4} xs={12}>
                                {Boolean(mapData.length >= 4) &&
                                    <Link to={"/BestBudget?name=BestBudget&tag=387&category=15"} state={{ tag: 387 }}>
                                        <Box display='flex'>
                                            <Button variant='contained'
                                                sx={{
                                                    width: '130px', height: '40px', backgroundColor: "#F26B23",
                                                    '&:hover': { backgroundColor: "#F26B23" }, textTransform: 'none', m: '0px 0px 10px auto'
                                                }} onClick={viewFunction} width='100px'>
                                                <Typography color='white !important'>View All</Typography>
                                            </Button>
                                        </Box>
                                    </Link>
                                }
                            </Grid>
                        </Grid>
                    </section>

                    <Grid item container spacing={3} sx={{ pr: '20px', pl: '20px', pb: '20px' }} >
                        {BestBudgetListLoader ? <ScrollLoader /> :
                            mapData.map((v, i) => {
                                return <Grid item lg={3} xs={12} sm={6} md={4} key={v.id}>
                                    <ProductItemCard productData={v} bgclr={'primaryColors.productListItemBg'} categoryName='Best Budget Smartphones' categoryType='Home' />
                                </Grid>
                            })
                        }
                    </Grid>
                </Box>
            </>
        </div>
    )
}
