import React, { useState, useEffect } from 'react'
import { Grid, Typography, Box, Button, Stack } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { getHomeNewLaunchesWatcher } from '../../store/actionCreators/home';
import ProductItemCard from '../ProductListItemCard';
import { addPrimarycategory, filterTagBasedProductWatcher } from '../../store/actionCreators/common';
import { Link } from 'react-router-dom';
import ScrollLoader from '../Loader/scrollLoader';
import { useInView } from 'react-intersection-observer'; 
import { getprimarycolor } from '../../utils/common';


export default function NewLaunches() {
    const dispatch = useDispatch()
    const { homeReducer: { HomeNewLaunchesList, HomeNewLaunchesListLoader } } = useSelector((state) => state)

    const mapData = HomeNewLaunchesList.length ? HomeNewLaunchesList : []
    const searchPath = '/search'

    const { ref, inView, entry } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    useEffect(() => {
        let data = {
            pageNO: 1,
            perPage: 4
        }
        if (inView) {
            dispatch(getHomeNewLaunchesWatcher(data))
        }
    }, [inView])

    const viewFunction = () => {
        // let data = {
        //     perPage: 50,
        //     name: "NewLaunches"
        // }
        // const pathName= window.location.pathname.substr(1,).toLowerCase()
        // dispatch(addPrimarycategory(pathName))
        // dispatch(filterTagBasedProductWatcher(data))
    }


    return (
        <div ref={ref}>
            <>
                <Box >
                    <Box >
                        <section className="container products-container padding-top-none clear">
                            <Grid item container>
                                <Grid lg={8} md={8} sm={8} xs={12}>
                                    <h2 className="left">
                                        New Launches
                                    </h2>
                                </Grid>
                                <Grid lg={4} md={4} sm={4} xs={12}>
                                    {Boolean(mapData.length >= 4) &&
                                        <Link to={"/NewLaunches?name=NewLaunches"} state={{ tag: 391 }}>
                                            <Box display='flex'>
                                                <Button variant='contained'
                                                    sx={{
                                                        width: '130px', height: '40px', backgroundColor: "#F26B23",
                                                        '&:hover': { backgroundColor: "#F26B23" }, textTransform: 'none', m: '0px 0px 10px auto'
                                                    }} onClick={viewFunction} width='100px' >
                                                    <Typography color='white !important'>View All</Typography>
                                                </Button>
                                            </Box>
                                        </Link>
                                    }
                                </Grid>
                            </Grid>


                        </section>

                    </Box>
                    <Grid item container spacing={3} sx={{ pr: '20px', pl: '20px', pb: '20px' }} >
                        {HomeNewLaunchesListLoader ? <ScrollLoader /> :
                            mapData.map((v, i) => {
                                return <Grid item lg={3} xs={12} sm={6} md={4} key={v.id}>
                                    <ProductItemCard productData={v} bgclr={'primaryColors.productListItemBg'} categoryName='New Launches' categoryType='Home' />
                                </Grid>
                            })
                        }
                    </Grid>
                </Box>               
            </>
        </div>
    )
}
