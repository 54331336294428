import React from 'react'
import { Grid, Typography, Box, Button } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import ProductItemCard from '../ProductListItemCard/index'
import { Link } from 'react-router-dom'
import { addPrimarycategory, filterTagBasedProductWatcher } from '../../store/actionCreators/common'
import { getprimarycolor } from '../../utils/common'


export default function NewLaunches() {
  const dispatch = useDispatch()
  const { accessoriesReducer: { newLaunchesList } } = useSelector((state) => state)

  const mapData = newLaunchesList.length ? newLaunchesList : []
  const searchPath = '/search'

  const viewFunction = () => {
    // let data = {
    //   perPage: 50,
    //   category: 249,
    //   name: "NewLaunches"
    // }
    // dispatch(filterTagBasedProductWatcher(data))
    // const pathName = window.location.pathname.substr(1,).toLowerCase()
    // dispatch(addPrimarycategory(pathName))
  }

  return (
    <div>
      {Boolean(mapData.length) &&
        <>
          <Box >
            <section className="container products-container padding-top-none clear">
              <Grid container>
                <Grid lg={8} md={8} sm={8} xs={12}>
                  <h2 className="left">
                    New Launches
                  </h2>
                </Grid>
                <Grid lg={4} md={4} sm={4} xs={12}>
                  {Boolean(mapData.length >= 4) &&
                    <Link to={"/NewLaunches?name=NewLaunches&category=249"} state={{ tag: 489 }}>
                      <Box display='flex'>
                        <Button variant='contained'
                          sx={{
                            width: '130px', height: '40px', backgroundColor: "#F26B23",
                            '&:hover': { backgroundColor: "#F26B23" }, textTransform: 'none', m: '0px 0px 10px auto'
                          }} onClick={viewFunction} width='100px' >
                          <Typography color='white !important'>View All</Typography>
                        </Button>
                      </Box>
                    </Link>
                  }
                </Grid>
              </Grid>
            </section>

            <Grid container spacing={3} sx={{ pr: '20px', pl: '20px', pb: '20px' }} >
              {
                mapData.map((v, i) => {
                  return <Grid item lg={3} xs={12} sm={6} md={4} key={v.id}>
                    <ProductItemCard productData={v} bgclr={'primaryColors.productListItemBg'} categoryName='NewLaunches' categoryType='Accessories' />
                  </Grid>
                })
              }
            </Grid>
          </Box>

        </>
      }
    </div>
  )
}
