import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { updateFalseLoader, updateOpenLoader } from '../../store/actionCreators/common'
import { useDispatch } from 'react-redux'
import productService from "../../services/product"
import { getprimarycolor } from '../../utils/common'
import { useNavigate } from "react-router-dom";


export default function DealsCard({ productData, categoryType, categoryName }) {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [selectedVariant, setSelectedVariant] = useState('')
    const [productName, setProductName] = useState('')
    const [price, setPrice] = useState({ sale_price: "", regular_price: "", discount: "" })


    const imageName = (imageArr) => {
        let imgName = (imageArr?.length && imageArr[0]?.src) || "/images/Phone1.png"
        return imgName
    }

    useEffect(() => {
        if (productData?.attributes?.length && productData?.attributes[0]?.options?.length) {
            let attribute = productData?.attributes?.filter(s => s?.variation) || []
            let getName = attribute?.length >= 2 ? attribute[0]?.options[0] + ' - ' + attribute[1]?.options[0] : attribute[0]?.options[0]
            const prodName = `${productData?.name}(${getName})`
            setProductName(prodName)

            let chooseColor = attribute[0]
            chooseColor.selected = chooseColor?.options[0]
            if (attribute?.length >= 2) {
                let chooseGB = attribute[1]
                chooseGB.selected = chooseGB?.options[0]
            }
            setSelectedVariant(attribute)
        }
        else {
            setProductName(productData?.name)
        }
        let div = document.createElement('div')
        div.innerHTML = productData?.price_html
        console.log("sample", div.getElementsByClassName("woocommerce-Price-amount amount"))
        let price1 = ""
        let price2 = ""
        price1 = div.getElementsByClassName("woocommerce-Price-amount amount")[0]?.innerText?.split(".")[0]?.replace(/,/g, '')?.match(/(\d+)/)[0] || ""
        price2 = div.getElementsByClassName("woocommerce-Price-amount amount")[1]?.innerText?.split(".")[0]?.replace(/,/g, '')?.match(/(\d+)/)[0] || ""
        console.log("amount", div.getElementsByClassName("woocommerce-Price-amount amount")[1]?.innerText.split(".")[0])

        setPrice({
            regular_price: parseInt(price1) > parseInt(price2) ? price1 : price2,
            sale_price: parseInt(price1) > parseInt(price2) ? price2 : price1,
            discount: (100 - parseInt(parseInt(price1) > parseInt(price2) ? parseInt(price2) : price1) * 100 / parseInt(parseInt(price1) > parseInt(price2) ? price1 : price2))?.toFixed(0) || ""
        })

        console.log("priz", price1, price2)
    }, [productData?.attributes, productData?.name])

    const handleNavigate = async () => {
        let prodName = productData?.name
        const pathName = window.location.pathname.substr(1,).toLowerCase() || "home"
        if (selectedVariant?.length) {
            //route code
            if (productData?.variations?.length > 0) {
                dispatch(updateOpenLoader())
                let { data: data1 } = await productService.getProductVariants(productData?.id)

                let selectedProdVariant = selectedVariant?.filter(s => s?.selected !== undefined)?.map(d => d?.selected).join('-')
                let routeProdVariant = data1?.map(d => d.attributes?.map(f => f.option).join('-'))
                let findInd = routeProdVariant?.findIndex(v => selectedProdVariant === v) || 0
                let routePId = data1.length && data1[findInd]?.id
                //product name code
                prodName = `${productData?.name?.replace(/ /g, "-")?.trim()}(${selectedProdVariant?.replace(/ /g, "-")?.trim()})`
                setProductName(prodName)
                console.log("selectedVariant", selectedVariant, selectedProdVariant, data1, findInd, routePId, routeProdVariant)
                //final navigation
                navigate(`/${pathName}/${prodName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${productData?.id}&vid=${routePId}`)
                dispatch(updateFalseLoader());
            }
        }
        else {
            setProductName(prodName)
            navigate(`/${pathName}/${prodName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${productData?.id}&vid=0`)
        }
    }
    console.log("typr", price)
    return (
        <Box className="product-deals" onClick={handleNavigate}>
            <a href="#/">
                <aside>
                    <Box className="topalign">
                        <h2>{productData?.name}</h2>
                    </Box>
                    {productData?.type === "variable" ?
                        <Box className="bottomalign">
                            <p>Discount</p>
                            <h1 style={{ color: "#F26B23" }}>{
                                price?.sale_price === "" ? price?.price :
                                    (<>{price?.sale_price} <del>{price?.regular_price}</del> </>)
                            } </h1>
                        </Box> :
                        <Box className="bottomalign">
                            <p>Discount</p>
                            <h1 style={{ color: "#F26B23" }}>{
                                productData?.sale_price === "" ? productData?.price :
                                    (<>{productData?.price} <del>{productData?.regular_price}</del> </>)
                            } </h1>
                        </Box>}
                </aside>
                <div style={{ paddingTop: "40px", display: "flex", justifyContent: "end" }}>
                    <img src={imageName(productData?.images)} style={{ height: "100%", marginRight: "10px" }} alt='' />
                </div>
            </a>
        </Box>
    )
}
