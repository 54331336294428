import { ADD_HEADPHONES_TAG_CARDS, HEADPHONES_NEW_LAUNCHES_LIST, SHOP_BY_BRAND_LIST, SHOP_BY_PRICE_LIST } from "../actionTypes/headPhones";

const intialState = {
    newLaunchesList: [],
    ShopByBrandList: [],
    ShopByPriceList: [],
    HeadphonesTagCards: []
};


export default function headPhonesReducers(state = intialState, action) {
    switch (action.type) {

        case HEADPHONES_NEW_LAUNCHES_LIST:
            return { ...state, newLaunchesList: action.payload };

        case SHOP_BY_BRAND_LIST:
            return { ...state, ShopByBrandList: action.payload };

        case SHOP_BY_PRICE_LIST:
            return { ...state, ShopByPriceList: action.payload };

        case ADD_HEADPHONES_TAG_CARDS:
            return { ...state, HeadphonesTagCards: action.data };

        default:
            return state;
    }
}
