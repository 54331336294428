import http from "../http-common"
import { WOOCOMMERCE_SERVICES } from "../http-common"

class homeService {

    getAllHomeNewLaunchesList(data) {
        return http.get(`/wp-json/wc/v3/products?min_price=1&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }
    
    getAllHomeSmartWatchList(data) {
        return http.get(`/wp-json/wc/v3/products?tag=${496}&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getTopRatedProductList(data) {
        let value = ""
        if (data.category_id !== undefined) {
            value += `category=${data.category_id}&`
        }
        return http.get(`/wp-json/wc/v3/products?min_price=1&${value}orderby=rating&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getAllHomeSamsungMobilesList(data) {
        return http.get(`/wp-json/wc/v3/products?category=${450}&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getAllHomeShopByBrandList(data) {
        return http.get(`/wp-json/wc/v3/products?search=${data.brand}&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getShopByPriceList(data) {
        return http.get(`/wp-json/wc/v3/products?min_price=${data.minPrice}&max_price=${data.maxPrice}&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getAllBestBudgetList(data) {
        return http.get(`/wp-json/wc/v3/products?tag=${387}&category=15&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getDealsList(data) {
        let value = ""
        if (data.category_id !== undefined) {
            value += `category=${data.category_id}&`
        }
        return http.get(`/wp-json/wc/v3/products?${value}tag=${499}&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getProductCardsList(data) {
        return http.get(`/wp-json/wc/v3/products?tag=${499}&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }
    
    getMobilesBrandsList(data) {
        return http.get(`/wp-json/wc/v3/products?brand=${data.brand}&category=${data.category}&min_price=1&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getAccessoriesBrandsList(data) {
        return http.get(`/wp-json/wc/v3/products?brand=${data.brand}&category=${data.category}&min_price=1&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getWearablesBrandsList(data) {
        return http.get(`/wp-json/wc/v3/products?brand=${data.brand}&category=${data.category}&min_price=1&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

    getHeadphonesBrandsList(data) {
        return http.get(`/wp-json/wc/v3/products?brand=${data.brand}&category=${data.category}&min_price=1&page=${data.pageNO}&per_page=${data.perPage}&consumer_key=${WOOCOMMERCE_SERVICES.CONSUMER_KEY}&consumer_secret=${WOOCOMMERCE_SERVICES.CONSUMER_SECRET}`);
    }

}

export default new homeService();