export const getConvertedDate = (val) => {
    if(!val) return null
    let dt = val;
    let localTime = dt.getTime();
    let localOffset = dt.getTimezoneOffset() * 60000;
    let utc = localTime + localOffset;
    let offset = 5.5;
    let IST = utc + 3600000 * offset;
    let new_dt = new Date(IST);
    let current_timezone_time = `${new_dt
      .getDate()
      .toString()
      .padStart(2, "0")}-${(new_dt.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${new_dt.getFullYear().toString().padStart(4, "0")}`;
  
    return current_timezone_time;
  };

  export const productArr = [{ id: 1, name: "20%-Off-or-More", imgPath: "/images/Phone4.png", productName: "Mobiles", offer: "20% Off or More", category: "15", perc: 20, condition: "above" },
  { id: 2, name: "Upto-50%-Off", imgPath: "/images/Accessories.png", productName: "Accessories", offer: "Upto 50% Off", category: "249", perc: 50, condition: "below" },
  { id: 3, name: "30%-Off-or-More", imgPath: "/images/SmartWatches2.png", productName: "Wearables", offer: "30% Off or More", category: "756", perc: 30, condition: "above" },
  { id: 4, name: "Under-1500", imgPath: "/images/Headphones.png", productName: "Headphones", offer: "Under 1500", category: "377", max_price: 1500, min_price: 0 }]

  export const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  let themecolors = {
    "" : {
      primary: "#000"
    },
    "mobiles" : {
      primary: "#F26B23"
    },
    "mobile-accessories" : {
      primary: "#238FF2"
    },
    "wearables": {
      primary: "#ED3348"
    },
    "mobile-headphones-headsets": {
      primary: "#34A853"
    }
  }

  let starcolors = {
    "" : {
      primary: "#000"
    },
    "mobiles" : {
      primary: "#F26B23"
    },
    "accessories" : {
      primary: "#238FF2"
    },
    "wearables": {
      primary: "#ED3348"
    },
    "headphones": {
      primary: "#34A853"
    }
  }

  export const getprimarycolor = () => {
    const pathName= window.location.pathname.substr(1,).toLowerCase()
    const color = themecolors[pathName]?.primary || "#F26B23"
    return color
  }

  export const getcardprimarycolor = (category) => {
    const color = starcolors[category]?.primary || "#F26B23"
    return color
  }

  export const getfreetagcolor = () => {
    const pathName= window.location.pathname.substr(1,).toLowerCase()
    const color = window.location.pathname === "/" ? "#F26B23" : "#000"
    return color
  }