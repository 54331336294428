import React, { useEffect, useState } from 'react'
import { AddWishlistProductWatcher, removeFromWishlistWatcher } from '../../../store/actionCreators/product';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function OrderDesign({ value, meta_data }) {
  console.log(value,"valueee");
  let dispatch = useDispatch()
  const { loginReducers: { userData }, productReducers: { wishListProductData } } = useSelector((state) => state)

  const isSelected = wishListProductData.some(d => {
    return +d.wishListVariantId ? +d.wishListVariantId === +value?.variation_id : +d.wishListProductId === +value?.product_id
  })
  const [productName, setProductName] = useState('')
  const userId = userData[0]?.userId
    const productId = value?.product_id
    
  const navigate = useNavigate()

  useEffect(() => {
    let prodName = value?.name
    //route code
    if (value?.meta_data?.length) {
        let attributes = value?.meta_data
        //product name code
        prodName = `${value?.name}(${attributes?.map(d => d?.display_value).join('-')})`
        setProductName(prodName)
    }
    else {
        setProductName(prodName)
    }
}, [])

  const addToFavourite = () => {
    if (!isSelected) {
      let data =
      {
          userId,
          productId,
          variantId : value?.variation_id
      }
      dispatch(AddWishlistProductWatcher(data, dispatch))
  }
  else {
      dispatch(removeFromWishlistWatcher(userId, productId, value?.variation_id, dispatch))
  }
}

const handleNavigate = async () => {
  let prodName = value?.name
  const pathName = window.location.pathname.substr(1,).toLowerCase() || "home"
     
  //route code
  if (value?.variation_id) {

      //product name code
      setProductName(prodName)

      //final navigation
  //    window.open(`/productDetails/${value?.product_id}/${value?.variation_id}/${prodName.replace(' ', "-")?.replace('/', "-")}`)
  navigate(`/${encodeURIComponent(pathName)}/${prodName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${value?.product_id}&vid=${value?.variation_id}`)
           
}
  else {
      setProductName(prodName)
  //    window.open(`/productDetails/${value?.product_id}/${0}/${prodName.replace(' ', "-")?.replace('/', "-")}`)
  navigate(`/${encodeURIComponent(pathName)}/${prodName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${value?.product_id}&vid=0`)
           
}
}

let img = meta_data?.find(v => v?.key == value?.product_id)

  return (
    <div className="frame-1000002000 flex-col-hstart-vstart clip-contents" onClick={handleNavigate} style={{cursor:"pointer"}}>
      <div className="frame-1000001921 flex-row-vstart-hstart">
        <img style={{width:"180px"}}
          src={img?.value}
          alt=""
          className="frame-1000001918"
        />
        <div className="frame-1000001912 flex-col-hstart-vsb clip-contents">
          <div className="frame-1000002068 flex-col-hstart-vstart">
            <div className="frame-10000019181 flex-row-vstart-hsb">
              <div className="frame-1000002068 flex-col-hstart-vstart">
              </div>
              <img src={isSelected ? "/images/repairs/like.jpg" : "/images/OrderConfirmation/Heart.png"} onClick={(e)=> {e.stopPropagation(); addToFavourite()}} onChange={(e) => { e.stopPropagation(); }} style={{ height: '40px', width: '40px', cursor: 'pointer', marginRight: '5px' }} alt=""/>
            </div>
            <br></br>
            <div className="frame-1000001920 flex-col-hstart-vstart">
              <div className="frame-1000002074 flex-row-vstart-hstart">
                <div >
                  <div className="frame-1000001919 flex-row-vstart-hstart">
                    <p className="txt-954">
                    {productName}
                    </p>
                  </div>
                </div>
              </div>
              <div className="frame-10000019182 flex-row-vcenter-hstart">
                <p className="txt-531">₹{Math.round(value.total)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
