import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import BestBudget from '../../components/Home/bestBudget';
import SamsungMobiles from '../../components/Home/samsungMobiles';
import { useSelector, useDispatch } from 'react-redux';
import { getBestBudgetWatcher, getSamsungMobilesWatcher } from '../../store/actionCreators/home';
import { AddCartProductWatcher, updateCartProductWatcher, addCartWithoutLoginList } from '../../store/actionCreators/cart';
import { addUserPincodeWatcher, AddWishlistProductWatcher, removeFromWishlistWatcher, getProductReviewsWatcher, getProductDetailWatcher, addVariantDetail } from '../../store/actionCreators/product';
import TabContent from './tabContent';
import OrderSummary from '../../components/cart/orderSummary';
import ProductCart from '../../components/ProductListItemCard/cartCard';
import './style.css';
import ReviewDialog from './reviewDialog';
import ZoomingImage from './zoomingImage';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { addProductCategory, filterTagBasedProductWatcher, updateFalseLoader, updateOpenLoader } from '../../store/actionCreators/common';
import SEO from '../../utils/SEO';
import { useSearchParams } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import MediaSlider from '../../components/Slider/mediaSlider';
import Slider from 'react-slick';
import { Helmet } from 'react-helmet';


const Index = () => {
    const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const view = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const match = useMediaQuery((theme) => theme.breakpoints.up('576'));

    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const { productId: pId, variantProductId } = useParams()
    const [searchParams] = useSearchParams();
    const pId = searchParams.get("pid");
    const variantProductId = searchParams.get("vid");
    const { loginReducers: { userData }, cartReducers: { cartProductData, cartWithoutLoginData }, productReducers: { productDetailData, wishListProductData, accessories, userPincode, userPincodeMessage, productVariants, productReviews, productBreadCrumb } } = useSelector((state) => state)
    console.log("prdt", productDetailData, productReviews)
    const [breadcrumb, setbreadcrumb] = useState([])
    const location = useLocation()
    const { cateType, cateName } = location?.state || {}

    let imgName = (productDetailData?.images?.length && productDetailData?.images[0]?.src) || "images/Phone1.png"
    const isSelected = wishListProductData.some(d => {
        if (+variantProductId) {
            return (+d.wishListProductId === +pId) && (+d.wishListVariantId === +variantProductId)
        }
        else {
            return +d.id === +productDetailData.id
        }
    })

    const cartData = userData.length ? cartProductData : cartWithoutLoginData

    const isCart = cartData.some(d => {
        if (+variantProductId) {
            return (+d.cartProductId === +pId) && (+d.cartVariantId === +variantProductId)
        }
        else {
            return +d.id === +productDetailData.id
        }
    })

    const cartItem = useMemo(() => {
        return cartProductData.find(d => {
            if (+variantProductId) {
                return (+d.cartProductId === +pId) && (+d.cartVariantId === +variantProductId)
            }
            else {
                return +d.id === +productDetailData.id
            }
        }) || {}
    }, [cartProductData, productDetailData])

    const userId = userData[0]?.userId
    const productId = productDetailData?.id
    const cartCount = cartData.length
    let discountpercent = 100 - (parseInt(productDetailData?.sale_price) * 100 / parseInt(productDetailData?.regular_price));
    const toPath = cateType === 'Home' ? '/' : `/${cateType?.toLowerCase()}`

    const cartAmount = cartData?.reduce((a, b) => {
        let qty = b.quantity || 1
        let price = +b.price * qty
        return +a + +price
    }, 0)

    const [image, setImage] = useState(imgName)
    const [pincode, setpincode] = useState("")
    // const [tab, settab] = useState("Highlights")
    const [qty, setQty] = useState(1)
    const [toggle, setToggle] = useState(false)
    const [reviewModel, setReviewModel] = useState(false)
    const [value, setValue] = useState(0)
    const [pincodeError, setPincodeError] = useState(false)
    const [invalidPincodeError, setInvalidPincodeError] = useState(false)
    const [selectedVariant, setSelectedVariant] = useState([])
    const [productName, setProductName] = useState('')
    const [deliveryAmt, setdeliveryAmt] = useState(0)
    const [keyword, setkeyword] = useState("")
    const [stock, setStock] = useState(true);

    // General scroll to element function
    const myRef = useRef(null)

    useEffect(() => {
        let product_name = ""
        if (productDetailData?.attributes?.length && productVariants?.length && productVariants[0]?.attributes?.length) {
            let attribute = productVariants.find(d => +d.id === +variantProductId)?.attributes || []

            let getName = attribute?.length >= 2 ? attribute[0]?.option + ' - ' + attribute[1]?.option : attribute[0]?.option
            const prodName = `${productDetailData?.name}(${getName})`
            setProductName(prodName)
            product_name = prodName
            let chooseColor = attribute[0]
            // chooseColor.selected = chooseColor?.option
            chooseColor = { ...chooseColor, "selected": chooseColor?.option }
            attribute[0] = chooseColor
            if (attribute?.length >= 2) {
                let chooseGB = attribute[1]
                // chooseGB.selected = chooseGB?.option
                chooseGB = { ...chooseGB, "selected": chooseGB?.option }
                attribute[1] = chooseGB
            }
            setSelectedVariant(attribute)
            let stock_ = productVariants.find(d => +d.id === +variantProductId)?.stock_status

            if (stock_ == "outofstock") {
                setStock(false);
            }
        }
        else {
            setProductName(productDetailData?.name)
            product_name = productDetailData?.name
            if (productDetailData?.stock_status == "outofstock") {
                setStock(false);
            }
        }
        if (parseInt(productDetailData?.price) < 2500) {
            if (productDetailData?.categories?.some((s) => s?.name === "Mobiles")) {
                setdeliveryAmt(0)
            }
            else {
                setdeliveryAmt(40)
            }
        }
        else {
            setdeliveryAmt(0)
        }
        let cat_name = productDetailData?.categories?.length > 0 ? productDetailData?.categories[0]?.name : ''
        let cat_id = productDetailData?.meta_data?.find(m => m.key === 'epc_primary_product_cat')?.value
        let primaryCategory = cat_id !== undefined ? (productDetailData?.categories?.find(v => v?.id == cat_id)?.name || cat_name) : cat_name
        console.log(primaryCategory, "primary")
        if (primaryCategory) {
            dispatch(addProductCategory(primaryCategory))
            let arr = []
            arr.push({
                "name": primaryCategory,
                "link": "/" + primaryCategory?.toLowerCase()
            })
            if (productDetailData?.brands?.length > 0) {
                arr.push({
                    "name": productDetailData?.brands[0]?.name,
                    "link": "/" + productDetailData?.brands[0]?.name?.toLowerCase() + "?search=" + productDetailData?.brands[0]?.name?.toLowerCase() + "&name=" + productDetailData?.brands[0]?.name?.toLowerCase(),
                    "brand": true
                })
            }
            arr.push({
                "name": product_name,
                // "link" : "/"+window.location.pathname
            })
            setbreadcrumb(arr)
        }
        let keywords = productDetailData?.meta_data?.find(v => v?.key == "keywords")
        if (keywords !== undefined) {
            setkeyword(keywords?.value)
        }
    }, [productDetailData, productVariants])

    const executeScroll = (ref) => {
        setValue(2)
        window.scrollTo({
            top: ref.current.offsetTop - 60,
            left: 0,
            behavior: "smooth",
        })
        dispatch(getProductReviewsWatcher(productDetailData?.id, { page: 1, per_page: 1 }))
    }

    const HandleClose = () => {
        setReviewModel(false)
    }

    useEffect(() => {
        let isallvariantselected = selectedVariant.filter(v => v?.selected !== "")
        if (selectedVariant?.length !== 0) {
            if (selectedVariant.length === isallvariantselected.length) {
                let selectedvalue = productVariants?.find(o => {
                    let res = 0;
                    o?.attributes?.forEach(tag => res += selectedVariant?.some(y => y?.selected?.includes(tag?.option)));
                    //  return res == checked?.length && o;
                    return res === selectedVariant?.length && o;
                })
                //   console.log("selectedVariant", selectedvalue)
                //  console.log("selectedvalue", selectedvalue, productVariants?.find(x  => x?.attributes?.some(y => y?.option == "Black")))
                if (selectedvalue !== undefined) {
                    let data = {
                        "price": selectedvalue?.price,
                        "regular_price": selectedvalue?.regular_price,
                        "sale_price": selectedvalue?.sale_price, "images": selectedvalue?.woo_variation_gallery_images?.length > 0 ? selectedvalue?.woo_variation_gallery_images : productDetailData?.images
                    }
                    console.log(data, "image");
                    dispatch(addVariantDetail(data))
                    // console.log("tessttt", { ...productDetailData, ...data })
                }
            }
        }
    }, [dispatch, selectedVariant, productDetailData?.images, productVariants])

    // const handleOpen = () => {
    //     setReviewModel(true)
    // }

    useEffect(() => {
        if (imgName) {
            setImage(imgName)
        }
    }, [imgName])

    useEffect(() => {
        setSelectedVariant([])
        window.scrollTo(0, 0)
        dispatch(getProductDetailWatcher(pId))
        dispatch(getProductReviewsWatcher(pId, { page: 1, per_page: 1 }))
    }, [dispatch, pId])

    // useEffect(() => {
    //     let variation = productDetailData?.attributes?.filter(v => v?.variation)
    //     if (variation?.length > 0) {
    //         let formatvalue = variation?.map((v, i) => {
    //             return { ...v, selected: "" }
    //         })
    //         setSelectedVariant(formatvalue)
    //     }
    // }, [dispatch, productDetailData?.id, productDetailData?.attributes])

    useEffect(() => {
        if (Object.values(cartItem)?.length) {
            setQty(cartItem.quantity)
        }
        else {
            setQty(1)
        }
    }, [cartItem])

    useEffect(() => {
        let data = {
            pageNO: 1,
            perPage: 4
        }
        dispatch(getBestBudgetWatcher(data))
        dispatch(getSamsungMobilesWatcher(data))
    }, [dispatch])

    const addToCart = () => {
        if (pincode === "" || userPincodeMessage === "") {
            setPincodeError(true)
            window.scrollTo(0, 0)
        }
        else {
            setPincodeError(false)
            if (userData.length) {
                if (!isCart) {
                    let data =
                    {
                        req_payload: {
                            userId,
                            productId,
                            quantity: qty,
                            variantId: +variantProductId,
                        },
                        detail: {
                            name: productName,
                            price: productDetailData?.price
                        }
                    }
                    if (userPincode) {
                        dispatch(AddCartProductWatcher(data, dispatch))
                    }
                    // dispatch(addUserPincodeWatcher(pincodeData))
                }
                else {
                    if (userPincode) {
                        navigate('/cart')
                    }
                }
            } else {
                if (!isCart) {
                    let localCart = JSON.parse(localStorage.getItem("cart")) || []
                    if (+variantProductId) {
                        let data = productVariants.find(d => +d.id === +variantProductId)
                        data.name = productDetailData?.name
                        data.cartVariantId = data?.id
                        data.cartProductId = pId
                        data.images = data?.woo_variation_gallery_images
                        if (userPincode) {
                            console.log(data, "prddet");
                            dispatch(addCartWithoutLoginList(data))
                            if (!localCart.some(v => v?.id == data?.id)) {
                                localCart.push({ ...data })
                                localStorage.setItem("cart", JSON.stringify(localCart))
                            }
                        }
                    }
                    else {
                        if (userPincode) {
                            console.log(productDetailData, "pddlog");
                            dispatch(addCartWithoutLoginList(productDetailData))
                            if (!localCart.some(v => v?.id == productDetailData?.id)) {
                                localCart.push({ ...productDetailData })
                                localStorage.setItem("cart", JSON.stringify(localCart))
                            }
                        }
                    }
                }
                else {
                    if (userPincode) {
                        navigate('/cart')
                    }
                }
            }
        }
    }

    const addToFavourite = () => {
        if (!isSelected) {
            let data = {
                userId,
                productId,
                variantId: +variantProductId
            }
            dispatch(AddWishlistProductWatcher(data, dispatch))
        }
        else {
            dispatch(removeFromWishlistWatcher(userId, productId, +variantProductId, dispatch))
        }
    }

    const qtyChange = (value) => {
        if (isCart) {
            setQty(value)
            let data = {
                quantity: value
            }
            dispatch(updateCartProductWatcher(data, userId, pId, variantProductId, dispatch))
        } else {
            setQty(value)
        }
    }

    const imagesrc = (imageArr) => {
        let img_Name = (imageArr?.length && imageArr[0]?.src) || "/images/Phone1.png"
        return img_Name
    }

    const selectedVarianthandler = (id, name) => {
        setStock(true);
        const routeName = window.location.pathname.toLowerCase() || "home"
        const pageName = routeName.split('/')
        let variantindex = selectedVariant.findIndex((v) => v?.id === id)
        if (variantindex > -1) {
            dispatch(updateOpenLoader())
            let variantarr = [...selectedVariant]
            variantarr[variantindex].selected = name
            setSelectedVariant(variantarr)
            //route code
            let selectedProdVariant = variantarr?.map(d => d.selected).join('-')
            let routeProdVariant = productVariants?.map(d => d.attributes?.map(f => f.option).join('-'))
            let findInd = routeProdVariant?.findIndex(v => selectedProdVariant === v) || 0
            let routePId = productVariants.length && productVariants[findInd]?.id
            if (!routePId) {
                dispatch(updateFalseLoader());
                setStock(false);
                return;
            }
            //product name code
            const prodName = `${productDetailData?.name}(${selectedProdVariant})`
            setProductName(prodName)
            //final navigation
            navigate(`/${pageName[1]}/${prodName.replace(/ /g, "-")?.replace('/', "-")?.trim()}?pid=${productDetailData?.id}&vid=${routePId}`)
            setTimeout(() => {
                dispatch(updateFalseLoader());
            }, 500);
        }
    }

    const meta = {
        title: productName,
        description: productName,
        // canonical: 'https://example.com/path/to/page',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: keyword
            }
        }
    };

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 600,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    let schemajson = {
        "@context": "https://schema.org/",
        "@type": "BreadcrumbList",
        "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://phonebuy.com/"
        }, ...breadcrumb?.filter(v => !v?.brand)?.map((e, i) => {
            return {
                "@type": "ListItem",
                "position": i + 2,
                "name": "Home",
                "item": e?.link ? "https://phonebuy.com" + e?.link : window.location.href
            }
        })]
    }
    console.log(userPincodeMessage,"usermsg");
    console.log(!isNaN(parseInt(productDetailData?.regular_price) - parseInt(productDetailData?.price)) && (parseInt(productDetailData?.regular_price) - parseInt(productDetailData?.price)), "saving");
    return <>
        <DocumentMeta {...meta}>
            {/* <SEO title={productName} description='Product Detail'/> */}
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(schemajson)}
                </script>

            </Helmet>
            <ReviewDialog open={reviewModel} HandleClose={HandleClose} />
            <section>
                <nav className="page-nav clear">
                    <ul>
                        {/* <li><Link to={toPath}>{cateType}</Link></li>
                    <li>{cateName}</li>
                    <li>{productName}</li> */}
                        {breadcrumb?.map((v, i) => {
                            console.log("breadcrumbsbrand", breadcrumb)
                            return <li>{v?.link !== undefined ? <Link to={v?.link} onClick={() => {
                                if (v?.brand) {
                                    let data = {
                                        perPage: 50,
                                        search: v?.name,
                                        name: v?.name
                                    }
                                    // dispatch(filterTagBasedProductWatcher(data))
                                }
                            }}>{v?.name}</Link> : v?.name}</li>
                        })}
                    </ul>
                </nav>
                <section className="container padding-top-none clear">
                    {/* <Box className="product-Box"> */}
                    <aside className={matches ? "product-slider left" : "product-slider-responsive left"}>
                        <div className="products-buttons">
                            {Boolean(userData.length) &&
                                <a href="#/" onClick={() => addToFavourite()}>
                                    <img src={isSelected ? "/images/repairs/like.jpg" : "/images/Heart.svg"} alt='' />
                                </a>
                            }
                            {/* <a href="#/"><img src="/images/Compare.svg" alt='' /></a> */}
                        </div>
                        {view ? <div className="simpleLens-gallery-container" id="demo-1">
                            <div className="simpleLens-container">
                                <div className="simpleLens-big-image-container">
                                    <a href="#/" className="simpleLens-lens-image" style={{ paddingLeft: matches ? "0px" : "120px" }} data-lens-image={image}>
                                        <ZoomingImage src={image} />
                                        {/* <img src={image} className="simpleLens-big-image" /> */}
                                    </a>
                                </div>
                            </div>


                            <div className="simpleLens-thumbnails-container" style={{ display: "flex", flexDirection: "column" }}>
                                {productDetailData?.images?.map((v, i) => {
                                    console.log("imgs", productDetailData?.images)
                                    return <Button key={i} onClick={() => setImage(v?.src)}><a href="#/" className="simpleLens-thumbnail-wrapper" data-lens-image={image} data-big-image={image}><img src={v?.src} alt='' /></a></Button>
                                })
                                }
                            </div>
                        </div> :
                            <MediaSlider>
                                <Slider {...settings}>
                                    {productDetailData?.images?.map((slide, index) => (
                                        <div className="simpleLens-gallery-container" id="demo-1">
                                            <div className="simpleLens-container" >
                                                <div className="simpleLens-big-image-container" >
                                                    <a href="#/" className="simpleLens-lens-image" data-lens-image={image}>
                                                        <img src={slide?.src} />
                                                        {/* <img src={image} className="simpleLens-big-image" /> */}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </MediaSlider>}
                    </aside>
                    <aside className={matches ? "product-details right" : "product-details product-details-responsive right"}>
                        {accessories?.length > 0 &&
                            <div>
                                <div className="freebies clear">Freebies Included</div>
                                {/* <p>Xiaomi</p> */}
                            </div>}
                        <h1>{productName}</h1>
                        {productReviews?.length > 0 ?
                            <div onClick={() => executeScroll(myRef)} style={{ cursor: "pointer" }} className="viewrating"><span style={{ zIndex: -1 }}>{productReviews?.length == 1 ? productReviews[0]?.rating : parseFloat(productDetailData?.average_rating).toFixed(1)}</span>
                                {productReviews?.length == 1 ? 1 : (productDetailData?.rating_count > 0 ? productDetailData?.rating_count + 1 : productDetailData?.rating_count)} Ratings
                            </div>
                            :
                            <div onClick={() => executeScroll(myRef)} style={{ cursor: "pointer" }} >
                                <Typography color='primaryColors.reviewText' variant="p14_500" style={{ textDecoration: "underline" }}>Be the first to review</Typography>
                            </div>

                        }
                        {/* {productDetailData?.sale_price !== "" &&
                            <div className="mrp-price-off"><span>MRP:</span> <del>₹{productDetailData?.regular_price}</del></div>} */}
                        <div className="mrp-price-off"><span>{productDetailData?.sale_price === "" ? "MRP:" : "Offer Price:"}</span> <h1 style={{ zIndex: -1 }}>{productDetailData?.price}</h1>
                            {productDetailData?.sale_price !== "" &&
                                <span className="mrp-price-off"> <Typography paddingLeft="20px"> <del>₹{productDetailData?.regular_price}</del> </Typography> </span>}
                            <Typography padding="10px"> (Included GST) </Typography>
                        </div>
                        {productDetailData?.sale_price !== "" &&
                            <div className="mrp-price-off"><span>Savings :</span> <p style={{ zIndex: -1 }}> {!isNaN(parseInt(productDetailData?.regular_price) - parseInt(productDetailData?.price)) && (parseInt(productDetailData?.regular_price) - parseInt(productDetailData?.price))} <strong style={{ zIndex: -1 }}>{!isNaN(discountpercent.toFixed(0)) && (discountpercent.toFixed(0))}% Off</strong></p></div>}
                        <div className="products-specs clear">
                            {
                                productDetailData?.attributes?.filter(v => !v?.variation)?.map((v, i) => {
                                    return (<div key={i}>
                                        <aside><img src={`https://dev.phonebuy.com/wp-content/uploads/2022/11/${v?.name}.png`} alt='' /><span>{v?.options?.toString()}</span></aside>
                                    </div>)
                                })
                            }
                            {/* <aside><img src="/images/smartphone.svg" /><span>6.5 inch</span></aside>
                    <aside><img src="/images/camera.svg" /><span>12 MP</span></aside>
                    <aside><img src="/images/battery.svg" /><span>4373 mAh</span></aside>
                    <aside><img src="/images/signal.svg" /><span>5G</span></aside>
                    <aside><img src="/images/sim-card.svg" /><span>Single SIM + eSIM</span></aside> */}
                        </div>
                        <div className="mrp-price-off" style={{ paddingBottom: "5px", textTransform: "capitalize", color: !stock ? "red" : "#404553" }}><span style={{ color: "#404553" }}>Stock :</span>  {!stock ? "out of stock" : "in stock"} </div>
                        {productDetailData?.categories?.length > 0 &&
                            <div className="mrp-price-off"><span>Delivery :</span> {deliveryAmt === 0 ? <span> <del>₹40</del> Free </span> : "₹40"}</div>}
                        <fieldset className="clear">
                            {/* <form> */}
                            <label style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", paddingBottom: "5px" }}>
                                <input name="phone" type="number" placeholder="Pin Code" value={pincode}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            if (pincode === "") {
                                                setPincodeError(true)
                                                setInvalidPincodeError(false)
                                            }
                                            else if (pincode.length === 6) {
                                                setPincodeError(false)
                                                setInvalidPincodeError(false)
                                                dispatch(addUserPincodeWatcher(pincode))
                                            }
                                            else {
                                                setInvalidPincodeError(true)
                                            }
                                        }
                                    }}
                                    style={{ zIndex: 1, width: "95%" }} onChange={(e) => setpincode(e.target.value)} />
                                <input onClick={() => {
                                    if (pincode === "") {
                                        setPincodeError(true)
                                        setInvalidPincodeError(false)
                                    }
                                    else if (pincode.length === 6) {
                                        setPincodeError(false)
                                        setInvalidPincodeError(false)
                                        dispatch(addUserPincodeWatcher(pincode))
                                    }
                                    else {
                                        setInvalidPincodeError(true)
                                    }
                                }} name="reset" type="reset" value="Apply" style={{ zIndex: 1, marginRight: "10px" }} />
                            </label>
                            {invalidPincodeError && <Typography sx={{ color: (theme) => `${theme.palette.primaryColors.requiredText} !important` }} > Enter valid pincode </Typography>}
                            {pincodeError && !invalidPincodeError && <Typography sx={{ color: (theme) => `${theme.palette.primaryColors.requiredText} !important` }}> Pincode is required</Typography>}
                            {(userPincodeMessage !== "" && !pincodeError && !invalidPincodeError) && <p style={{ paddingBottom: "10px" }}>{userPincodeMessage === "not available" ? "Sorry delivery is" : "Delivery"} <a href="#/">{userPincodeMessage}</a></p>}

                            {
                                productDetailData?.attributes?.filter(v => v?.variation)?.map((v, i) => {
                                    return (<div key={i}>
                                        <h4> {v?.name}: <span></span></h4>
                                        {
                                            v?.options?.map((d, n) => {
                                                let isSelected = selectedVariant.some((s) => s?.selected === d)

                                                return <button onClick={() => selectedVarianthandler(v?.id, d)} style={{ border: `1px solid ${isSelected ? '#f8b602' : '#999999'}` }} key={n}>{d}</button>
                                            })
                                        }
                                    </div>)
                                })
                            }
                            {accessories?.length > 0 && <Box padding="20px" marginBottom="10px">
                                <div className={view ? "freebie-content-parent free offer freebie clear" : "freebie-content-parent-mobile free offer freebieMobile clear"} >
                                    <h3 style={{ color: "#ffffff" }}>Your freebie for this products</h3>
                                    <div className={view ? 'freebie-card freebie-content' : 'freebie-card freebie-content-mobile'}>
                                        {
                                            accessories.map((v, i) => {
                                                return <aside key={i}>
                                                    <img style={{ height: "90px", width: "90px" }} src={imagesrc(v?.images)} alt='' />
                                                    <p>{v?.name}</p>
                                                </aside>
                                            })
                                        }
                                    </div>
                                    <Typography style={{ display: "flex", alignSelf: "end", padding: "5px", fontSize: "13px", color: "white" }}>* Color and type may vary</Typography>

                                </div>
                            </Box>}

                            <div className="bottom-section clear">
                                {Boolean(userData.length) &&
                                    <div className="select left">
                                        <label><strong>Quantity</strong></label><br />
                                        <select onChange={(e) => qtyChange(e.target.value)} name="Quantity" id="Quantity" value={qty}>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </div>
                                }
                                {isCart ? <Button
                                    sx={{ backgroundColor: theme => `${theme.palette.orange.main} !important`, marginLeft: "30px !important", marginTop: "33px !important", color: "#FFFFFF !important", zIndex: 1 }}
                                    onClick={() => navigate('/cart')}
                                > Go To Cart
                                </Button>
                                    :
                                    <Button
                                        disabled={!stock}
                                        sx={{ backgroundColor: !stock ? "#f2f3f7" : theme => `${theme.palette.orange.main} !important`, marginLeft: "30px !important", marginTop: "33px !important", color: !stock ? "grey" : "#FFFFFF !important", zIndex: 1 }}
                                        onClick={() => addToCart()}
                                    > Add To Cart
                                    </Button>}
                            </div>
                            {/* </form> */}
                        </fieldset>
                    </aside>
                    {/* </Box> */}
                </section>

                <Box ref={myRef}>
                    <TabContent setValue={setValue} value={value} />
                </Box>
                <Box>
                    <BestBudget />
                </Box>

                <Box>
                    <SamsungMobiles />
                </Box>

            </section>;

            {cartCount > 0 && (<section className="container orders clear product-detail" sx={{ backgroundColor: "primaryColors.productListItemBg" }}>
                <div className="left" style={{ display: "flex", alignSelf: "flex-start", alignItems: "center" }}>

                    <Button style={{ marginRight: "20px", border: "1px solid black", minWidth: "5px", color: "white" }} onClick={() => { setToggle(!toggle) }}> {toggle ? <ArrowDropDownIcon color='black' /> : <ArrowDropUpIcon color='black' />} </Button>
                    {!toggle && <h2>Your Order ({cartCount}) </h2>}
                </div>
                <div>
                    {toggle && (
                        <div>
                            <h2>Your Order ({cartCount}) </h2>
                            <Grid container>
                                <Grid lg={7} md={7} sm={12} xs={11} style={{ overflowY: "scroll", height: "300px" }}>
                                    <ProductCart />
                                </Grid>
                                <Grid lg={5} md={5} sm={12} xs={11}>
                                    <OrderSummary pageType='productCart' />
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </div>
                {!toggle && <aside className="right">
                    <h2>Subtotal: ₹{cartAmount}</h2>
                    <Link to="/checkout">Checkout</Link>
                </aside>}
            </section>)}
        </DocumentMeta>
    </>
};

export default Index;