import { takeEvery, call, put } from "redux-saga/effects";
import homeService from "../../services/home";
import { accessoriesBrandsList, addHomeLoading, bestBudgetList, brandNewLaunchesList, caseAndCoversList, dealsList, headphonesBrandsList, homeNewLaunchesList, mobilesBrandsList, productCardsList, samsungMobilesList, smartWatchList, topRatedProductList, wearablesBrandsList } from "../actionCreators/home";
import { shopByPriceList, shopByBrandList } from "../actionCreators/mobile";
import { GET_ACCESSORIES_BRANDS_WATCHER, GET_BEST_BUDGET_WATCHER, GET_BRAND_NEW_LAUNCHES_WATCHER, GET_CASE_AND_COVERS_WATCHER, GET_DEALS_WATCHER, GET_HEADPHONES_BRANDS_WATCHER, GET_HOME_NEW_LAUNCHES_WATCHER, GET_MOBILES_BRANDS_WATCHER, GET_PRODUCT_CARDS_WATCHER, GET_SAMSUNG_MOBILES_WATCHER, GET_SHOP_BY_BRAND_WATCHER, GET_SHOP_BY_PRICE_WATCHER, GET_SMART_WATCH_WATCHER, GET_TOP_RATED_PRODUCT_WATCHER, GET_WEARABLES_BRANDS_WATCHER } from "../actionTypes/home";
import { updateOpenLoader, updateAlert, updateFalseLoader } from '../actionCreators/common'


function* newLaunchesListEffect(action) {
  try {
    // yield put(updateOpenLoader())
    yield put(addHomeLoading("HomeNewLaunchesListLoader", true))
    let { data } = yield call(
      homeService.getAllHomeNewLaunchesList,
      action.payload
    );

    yield put(homeNewLaunchesList(data));
    // yield put(updateFalseLoader())
    yield put(addHomeLoading("HomeNewLaunchesListLoader", false))

  } catch (e) {
    // yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
    yield put(addHomeLoading("HomeNewLaunchesListLoader", false))

  }
}

function* topRatedProductEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(
      homeService.getTopRatedProductList,
      action.payload
    );

    yield put(topRatedProductList(data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* smartWatchEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(
      homeService.getAllHomeSmartWatchList,
      action.payload
    );

    yield put(smartWatchList(data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* samsungMobilesEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(
      homeService.getAllHomeSamsungMobilesList,
      action.payload
    );

    yield put(samsungMobilesList(data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* bestBudgetEffect(action) {
  try {
    // yield put(updateOpenLoader())
    yield put(addHomeLoading("BestBudgetListLoader", true))
    let { data } = yield call(
      homeService.getAllBestBudgetList,
      action.payload
    );

    yield put(bestBudgetList(data));
    yield put(addHomeLoading("BestBudgetListLoader", false))
    // yield put(updateFalseLoader())

  } catch (e) {
    // yield put(updateFalseLoader());
    yield put(addHomeLoading("BestBudgetListLoader", false))
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* shopByBrandEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(
      homeService.getAllHomeShopByBrandList,
      action.payload
    );

    yield put(shopByBrandList(data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* shopByPriceEffect(action) {
  try {
    yield put(updateOpenLoader())

    let { data } = yield call(
      homeService.getShopByPriceList,
      action.payload
    );

    yield put(shopByPriceList(data));
    yield put(updateFalseLoader())

  } catch (e) {
    yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* dealsEffect(action) {
  try {
    // yield put(updateOpenLoader())
    yield put(addHomeLoading("DealsListLoader", true))
    let { data } = yield call(
      homeService.getDealsList,
      action.payload
    );

    yield put(dealsList(data));
    yield put(addHomeLoading("DealsListLoader", false))
    // yield put(updateFalseLoader())

  } catch (e) {
    // yield put(updateFalseLoader());
    yield put(addHomeLoading("DealsListLoader", false))
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* productCardsEffect(action) {
  try {
    // yield put(updateOpenLoader())

    let { data } = yield call(
      homeService.getProductCardsList,
      action.payload
    );

    yield put(productCardsList(data));
    // yield put(updateFalseLoader())

  } catch (e) {
    // yield put(updateFalseLoader());
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* mobilesBrandsListEffect(action) {
  try {
    let { data } = yield call(
      homeService.getMobilesBrandsList,
      action.payload
    );
    console.log(data, "dataa")
    yield put(mobilesBrandsList(data));

  } catch (e) {
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* accessoriesBrandsListEffect(action) {
  try {
    let { data } = yield call(
      homeService.getAccessoriesBrandsList,
      action.payload
    );
    console.log(data, "dataa")
    yield put(accessoriesBrandsList(data));

  } catch (e) {
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* wearablesBrandsListEffect(action) {
  try {
    let { data } = yield call(
      homeService.getWearablesBrandsList,
      action.payload
    );
    console.log(data, "dataa")
    yield put(wearablesBrandsList(data));

  } catch (e) {
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

function* headphonesBrandsListEffect(action) {
  try {
    let { data } = yield call(
      homeService.getHeadphonesBrandsList,
      action.payload
    );
    console.log(data, "dataa")
    yield put(headphonesBrandsList(data));

  } catch (e) {
    yield put(updateAlert(e?.message || "something went wrong", 'error'));
  }
}

export const HomeSaga = [
  takeEvery(GET_HOME_NEW_LAUNCHES_WATCHER, newLaunchesListEffect),
  takeEvery(GET_TOP_RATED_PRODUCT_WATCHER, topRatedProductEffect),
  takeEvery(GET_SMART_WATCH_WATCHER, smartWatchEffect),
  takeEvery(GET_SAMSUNG_MOBILES_WATCHER, samsungMobilesEffect),
  takeEvery(GET_SHOP_BY_BRAND_WATCHER, shopByBrandEffect),
  takeEvery(GET_SHOP_BY_PRICE_WATCHER, shopByPriceEffect),
  takeEvery(GET_BEST_BUDGET_WATCHER, bestBudgetEffect),
  takeEvery(GET_DEALS_WATCHER, dealsEffect),
  takeEvery(GET_PRODUCT_CARDS_WATCHER, productCardsEffect),
  takeEvery(GET_MOBILES_BRANDS_WATCHER, mobilesBrandsListEffect),
  takeEvery(GET_ACCESSORIES_BRANDS_WATCHER, accessoriesBrandsListEffect),
  takeEvery(GET_WEARABLES_BRANDS_WATCHER, wearablesBrandsListEffect),
  takeEvery(GET_HEADPHONES_BRANDS_WATCHER, headphonesBrandsListEffect),
];
