import React, { useEffect } from "react";
import { Grid, Typography, Button, Box } from '@mui/material';
import ProductItemCard from "../../components/ProductListItemCard"
import { useDispatch, useSelector } from "react-redux";
import { addPrimarycategory, filterTagBasedProductWatcher } from "../../store/actionCreators/common";
import { Link } from "react-router-dom";
import ScrollLoader from "../Loader/scrollLoader";
import { useInView } from 'react-intersection-observer';
import { getMobileRecommendedWatcher } from "../../store/actionCreators/mobile";
import { getprimarycolor } from "../../utils/common";


export default function RecommendedItem({ category }) {
    const dispatch = useDispatch()
    const { mobileReducers: { recommendedMobileList, recommendedMobileListLoading } } = useSelector(state => state)

    const mapData = recommendedMobileList.length ? recommendedMobileList : []
    const searchPath = '/search'

    const { ref, inView, entry } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    useEffect(() => {
        let data = {
            pageNO: 1,
            perPage: 4
        }
        if (category !== undefined) {
            data = { ...data, category }
        }
        if (inView) {
            dispatch(getMobileRecommendedWatcher(data))
        }
    }, [inView])

    const viewFunction = () => {
        // let data = {
        //     perPage: 50,
        //     name: "RecommendedItem",
        //     tag: 392
        // }
        // if (category !== undefined) {
        //     data = { ...data, category }
        // }
        // const pathName = window.location.pathname.substr(1,).toLowerCase()
        // dispatch(addPrimarycategory(pathName))
        // dispatch(filterTagBasedProductWatcher(data))
    }
    let url = "/RecommendedItem?name=RecommendedItem&tag=392"
    if(category) {
        url += "&category="+category
    }

    return (
        <>
            <section className="container products-container padding-top-none clear" ref={ref}>
                <Grid item container>
                    <Grid lg={8} md={8} sm={8} xs={12}>
                        <h2 className="left">
                            Recommended Items<small>Based on your interest</small>
                        </h2>
                    </Grid>
                    <Grid lg={4} md={4} sm={4} xs={12}>
                        {Boolean(mapData.length >= 4) &&
                            <Link to={url} state={{ tag: 392 }}>
                                <Box display='flex'>
                                    <Button variant='contained'
                                        sx={{
                                            width: '130px', height: '40px', backgroundColor: "#F26B23",
                                            '&:hover': { backgroundColor: "#F26B23" }, textTransform: 'none', m: '0px 0px 10px auto'
                                        }} onClick={viewFunction} width='100px' >
                                        <Typography color='white !important'>View All</Typography>
                                    </Button>
                                </Box>
                            </Link>
                        }
                    </Grid>
                </Grid>
            </section>

            <Grid item container spacing={3} sx={{ pr: '20px', pl: '20px', }} >
                {recommendedMobileListLoading ? <ScrollLoader /> :
                    mapData?.map((m, i) => {
                        return <Grid item lg={3} xs={12} sm={6} md={4} key={m.id}>
                            <ProductItemCard productData={m} bgclr={'primaryColors.productListItemBg'} categoryName='Recommended Items' categoryType='Mobile' />
                        </Grid>
                    })}
            </Grid>
        </>
    )
}