// Login Watcher Actions

// Login Watcher Actions

export const GET_WISH_LIST_PRODUCT_ID_WATCHER = "GET_WISH_LIST_PRODUCT_ID_WATCHER";
export const GET_WISH_LIST_PRODUCT_DATA_WATCHER = "GET_WISH_LIST_PRODUCT_DATA_WATCHER";
export const REMOVE_FROM_WISH_LIST_WATCHER = "REMOVE_FROM_WISH_LIST_WATCHER";
export const ADD_TO_WISH_LIST_WATCHER = "ADD_TO_WISH_LIST_WATCHER";
export const GET_PRODUCT_DETAIL_WATCHER = "GET_PRODUCT_DETAIL_WATCHER";
export const ADD_USER_PINCODE_WATCHER = "ADD_USER_PINCODE_WATCHER";
export const GET_PRODUCT_REVIEWS_WATCHER = "GET_PRODUCT_REVIEWS_WATCHER";
export const GET_MULTIPLE_PRODUCT_DATA = "GET_MULTIPLE_PRODUCT_DATA";
export const GET_PRODUCT_ACCESSORIES_WATCHER = "GET_PRODUCT_ACCESSORIES_WATCHER";
export const ADD_PRODUCT_REVIEW = "ADD_PRODUCT_REVIEW";
export const GET_PRODUCT_VARIANTS = "GET_PRODUCT_VARIANTS";
export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";


// Login Reducer Actions

export const WISH_LIST_PRODUCT_ID_LIST = "WISH_LIST_PRODUCT_ID_LIST";
export const WISH_LIST_PRODUCT_LIST = "WISH_LIST_PRODUCT_LIST";
export const PRODUCT_DETAIL_DATA_LIST = "PRODUCT_DETAIL_DATA_LIST";
export const USER_PINCODE_LIST = "USER_PINCODE_LIST";
export const PRODUCT_REVIEWS_LIST = "PRODUCT_REVIEWS_LIST";
export const MULTIPLE_PRODUCT_LIST = "MULTIPLE_PRODUCT_LIST"
export const ADD_SELECTED_TAB = "ADD_SELECTED_TAB"
export const ACCESSORIES_SINGLE_PRODUCT = "ACCESSORIES_SINGLE_PRODUCT"
export const ADD_PRODUCT_VARIANTS = "ADD_PRODUCT_VARIANTS"
export const ADD_VARIANT_DETAIL = "ADD_VARIANT_DETAIL"
export const PRODUCTREVIEW_EMPTY = "PRODUCTREVIEW_EMPTY";
export const MODIFY_PRODUCT_DETAIL = "MODIFY_PRODUCT_DETAIL"
export const ADD_PRODUCT_BREADCRUMB = "ADD_PRODUCT_BREADCRUMB"
export const ADD_PAYMENT_METHODS = "ADD_PAYMENT_METHODS"



